import React from 'react';
import axios from 'axios';
import * as escape from 'escape-html';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { HomeStyle, Blocks, Block, BlockContent } from 'component/common/style/Home';
import { white, black, red, blue, green, yellow } from 'component/common/style/Colors';


class Home extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { }
  }

  componentDidMount = () => { 
    this.props.logoAnimation('kodo');
    this.props.helloLab();
  } 


  render() {

    let items = [
      {'name':'core', 'pathname':'post_core', 'genre':'post'},
      {'name':'oso', 'pathname':'post_oso', 'genre':'post'},
      {'name':'study', 'pathname':'post_study', 'genre':'post'},
      {'name':'review', 'pathname':'post_review', 'genre':'post'},
      {'name':'science', 'pathname':'post_science', 'genre':'post'},
      {'name':'design', 'pathname':'post_design', 'genre':'post'},
      {'name':'trade', 'pathname':'post_trade', 'genre':'post'},
      {'name':'business', 'pathname':'post_business', 'genre':'post'},
      {'name':'program', 'pathname':'post_program', 'genre':'post'},
      {'name':'porn', 'pathname':'post_porn', 'genre':'post'},
      {'name':'etc', 'pathname':'post_etc', 'genre':'post'},

      {'name':'Layered Note', 'pathname':'layerednote', 'genre':'layerednote'},
      {'name':'Distributed Learnings', 'pathname':'distributed_learnings', 'genre':'distributed_learnings'},
      {'name':'Translate', 'pathname':'translate', 'genre':'translate'},
      {'name':'Asset Management', 'pathname':'assetmanagement', 'genre':'asset_management'},
      {'name':'Studies', 'pathname':'studies', 'genre':'studies'},
      {'name':'PornDatas', 'pathname':'porndatas', 'genre':'porndatas'},
      {'name':'pareo man', 'pathname':'pareo_na_otoko', 'genre':'pareo'},
      {'name':'oauth', 'pathname':'oauth', 'genre':'oauth'},
      {'name':'Outside Upload', 'pathname':'outsideupload', 'genre':'outsideupload'},
      {'name':'SoundDev', 'pathname':'sounddev', 'genre':'sounddev'}
    ];



    let colorPalette = [
      [red, blue, green, yellow],
      ['#30d5c8', '#64db96', '#98e264', '#cbe832', '#ffee00'], // rime
      ['#8ddcd4', '#a5dfc3', '#bee2b2', '#d6e5a1', '#eee890'], // cream soda
      ['#ff8f8f', '#ffa390', '#ffb690', '#ffca91', '#ffdd91'], // peach
      ['#fffdcf', '#fff59d', '#fbe673', '#fadc57', '#fccf45'], // lemmon
      ['#95e7d9', '#7cccbe', '#6ab6b0', '#59a3a5', '#51707c'], // aqua
      ['#5de0f0', '#84d2f1', '#abc3f3', '#d1b5f4', '#f8a6f5'], // fantasy
      ['#efeee8', '#d7d6d0', '#bfbeb9', '#a7a6a2', '#8f8e8b'], // rock
      ['#968e86', '#928579', '#907766', '#967a6b', '#967f6f'], // wood
      ['#f6e9c8', '#ffc190', '#ff8c7b', '#ff4b91', '#e400c5'], // sunshine
      ['#defeff', '#d9faff', '#ceeaf2', '#cae3ff', '#bee0ff'], // sky
      ['#f9d0d0', '#f2d8d8', '#ecdfe0', '#e5e7e7', '#deeeef'] // pastel
    ]
    let colors = colorPalette[Math.floor(Math.random() * colorPalette.length)];



    let pathItems = window.location.pathname.slice(1).split('/');
    let blocks = items.map((item) => {
      let icon;
      if (item.genre == 'post') {
        icon = ( <i className='far fa-file' /> ); 
        //icon = ( <i className='fas fa-pager' /> ); 
      } else if (item.genre == 'layerednote') {
        icon = ( <i className='fas fa-align-left' /> ); 
      } else if (item.genre == 'translate') {
        icon = ( <i className='fas fa-exchange-alt' /> );
      } else if (item.genre == 'distributed_learnings') {
        icon = ( <i className='far fa-calendar-check' /> );
      } else if (item.genre == 'asset_management') {
        icon = ( <i className='fas fa-chart-line' /> );
      } else if (item.genre == 'studies') {
        icon = ( <i className='fas fa-graduation-cap' /> );
      } else if (item.genre == 'porndatas') {
        icon = ( <i className='far fa-meh-blank' /> );
      } else if (item.genre == 'oauth') {
        icon = ( <i className='fab fa-twitter' /> );
      } else if (item.genre == 'pareo') {
        icon = ( <i className='fas fa-cat' /> );
      } else if (item.genre == 'outsideupload') {
        icon = ( <i className='fas fa-upload' /> );
      } else if (item.genre == 'sounddev') {
        icon = ( <i className='fas fa-headphones' /> );
      } else {
        icon = ( <i className='fas fa-pager' /> ); 
      }
      return (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <BlockContent to={this.props.full ? '/' + item.pathname : this.props.one ? '/' + item.pathname + '/' + pathItems[1] : '/' + pathItems[0] + '/' + item.pathname} style={{'background':colors[Math.floor(Math.random() * colors.length)]}}>
            <div className='item-name-wrapper'>
              <div className='icon'>{icon}</div>
              <div className='item-name'>{item.name}</div>
            </div>
          </BlockContent>
        </Block>
      );
    });

    // pixelbookだと48個のブロックを並べるとフル画面でも２つに画面分割した場合もきれいにならぶので、その数になるようにダミーを配置
    for (var i=0; i<48-items.length; i++) {
      blocks.push(
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <BlockContent style={{'background':colors[Math.floor(Math.random() * colors.length)], 'opacity':'0.8'}}>
            <div className='item-name'></div>
          </BlockContent>
        </Block>
      );
    }

    return (
      <HomeStyle style={{'background':colors[Math.floor(Math.random() * colors.length)]}}>
        <Helmet title={'TakumaN Lab'} />
        <Blocks full={this.props.full} one={this.props.one} two={this.props.two}>{blocks}</Blocks>
        <ClearBoth/>
      </HomeStyle>
    );

  }

}
export default Home;
