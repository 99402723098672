import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { PostsBlockLayoutStyle, Blocks, Block, BlockContent, PdfPreview } from 'component/common/style/PostsBlockLayout';

import PostsPagination from 'component/common/module/PostsPagination';


class PostsBlockLayout extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      postsCount:this.props.postsCount, paginationNum:this.props.paginationNum,
      posts:this.props.posts,
    };
  }


  render() {

    let pathItems = window.location.pathname.slice(1).split('/');
    let paginations;
    let posts;
    if (this.state.posts.length != 0) {

      paginations = ( <PostsPagination postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} full={this.props.full} one={this.props.one} two={this.props.two} /> );

      posts = this.state.posts.map((post) => {

        let file;
        let imageLength = 0;
        let movieLength = 0;
        let pdfLength = 0;
        if (post.file != '') {
          let splitList = String(post.file).split('/');
          let fileName = splitList[splitList.length -1];
          let fileNameSplitList = String(fileName).split('.');
          let extension = fileNameSplitList[fileNameSplitList.length - 1];
          extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
          if (extension == 'pdf') {
            file = ( <PdfPreview src={post.file + '#view=FitH&toolbar=0&navpanes=0&scrollbar=0'} type='application/pdf' /> );
            pdfLength++;
          } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
              extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
              extension == 'ico' || extension == 'icns') {
            file = ( <img src={post.file} /> ); {/* 画像の場合 */}
            imageLength++;
          } else {
            let thumb_url = String(post.file).split('.').slice(0,-1).join('.') + '_thumb.jpg';
            file = ( <img src={thumb_url} /> );
            movieLength++;
          }
        } else {
          file = ( <span></span> );
        }

        let releaseRangeInfo;
        if (post.release_range == 'private') {
          releaseRangeInfo = ( <div className='info release-range'><i className='fas fa-lock' /></div> );
        } else {
          releaseRangeInfo = ( <span></span> );
        }


        if (post.postChilds) {
          for (var i=0; i<post.postChilds.length; i++) {
            if (post.postChilds[i].file != '') {
              let splitList = String(post.postChilds[i].file).split('/');
              let fileName = splitList[splitList.length -1];
              let fileNameSplitList = String(fileName).split('.');
              let extension = fileNameSplitList[fileNameSplitList.length - 1];
              extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
              if (extension == 'pdf') {
                pdfLength++;
              } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
                  extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
                  extension == 'ico' || extension == 'icns') {
                imageLength++;
              } else {
                movieLength++;
              }
            }
          }
        }

        let imageInfo;
        if (0 < imageLength) {
          imageInfo = ( <div className='info image-length'><i className='far fa-file-image' /> {imageLength}</div> );
        } else {
          imageInfo = ( <span></span> );
        }

        let movieInfo;
        if (0 < movieLength) {
          movieInfo = ( <div className='info movie-length'><i className='far fa-file-video' /> {movieLength}</div> );
        } else {
          movieInfo = ( <span></span> );
        }

        let pdfInfo;
        if (0 < pdfLength) {
          pdfInfo = ( <div className='info pdf-length'><i className='far fa-file-pdf' /> {pdfLength}</div> );
        } else {
          pdfInfo = ( <span></span> );
        }

        let postInfo = (
          <div className='post-info'>
            {releaseRangeInfo}
            {pdfInfo}
            {movieInfo}
            {imageInfo}
          </div>
        );

        return (
          <Block full={this.props.full} one={this.props.one} two={this.props.two}>
            <BlockContent to={this.props.full ? '/post_' + pathItems[0].split('_')[1] + '_' + post.hash : this.props.one ? '/post_' + pathItems[0].split('_')[1] + '_' + post.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/post_' + pathItems[1].split('_')[1] + '_' + post.hash}>
              <div className='file'>{file}</div>
              <div className='item-name'><h2>{post.title}</h2></div>
              {postInfo}
            </BlockContent>
          </Block> 
        );
      });
    } else {
      paginations = ( <span></span> );
      posts = ( <span></span> );
    }

    return (
      <PostsBlockLayoutStyle>
        {paginations}
        <ClearBoth/>
        <Blocks full={this.props.full} one={this.props.one} two={this.props.two}>{posts}</Blocks>
        <ClearBoth/>
        {paginations}
        <ClearBoth/>
      </PostsBlockLayoutStyle>
    );

  }
}
export default PostsBlockLayout;
