import axios from 'axios';

export default function ShakyoMatchCheck(sf, sb, sh, ss) {

  return new Promise((resolve, reject) => {

    // shakyoFrontとshakyoShadowは実際に入力する値を、shakyoBackとshakyoHighlightは写経する対象の文字列が入っている。
    //
    // 実際の入力は、透明かつ一番奥の層に配置されたtextareaのshakyoFrontを利用しているが、その入力値を利用して、
    // shakyoHighlightの各行と同じ間隔で見やすく整えられたshakyoShadowが入力値を視覚的に反映するための要素として使われている。
    //
    // shakyoBackは、textareaに写経対象の文字列をそのまま生の状態で格納しておくための要素であり、shakyoHighlightはその値を利用してstyleを反映させる。
    // shakyoHighlightは、shakyoShadowと同じく、行単位でハイライトを表示したり、折り返しでその行のheightを調整したりして、shakyoBackの値を視覚的に見やすく整えられた要素。
    //
    let shakyoFront = document.getElementById(sf); // textareaで実際に入力した文字列を格納した要素
    let shakyoBack = document.getElementById(sb); // textareaで写経する対象の文字列を格納した要素
    let shakyoHighlight = document.getElementById(sh); // divで行番号(.line-num)と写経対象(.text)を見やすく整えた要素
    let shakyoShadow = document.getElementById(ss); // divで行番号(.line-num)と入力した文字列(.text)を見やすく整えた要素

    // shakyoFrontとshakyoBackをそれぞれ行単位で分割した配列を取得
    let shakyoFrontNary = shakyoFront.value.split('\n');
    let shakyoBackNary = shakyoBack.value.split('\n');

    // shakyoBackの行数をshakyoFrontの行数が上回ったら、はみ出た部分を取り除く
    shakyoFront.value = shakyoFrontNary.slice(0, shakyoBackNary.length).join('\n');

    // shakyoBackとshakyoFrontをそれぞれ行単位でその値を比較し、それに応じて、
    // 各行ごとにdomを作成し、そのdomに対して、ハイライト色を識別するためのhighlightClassを指定したり、
    // 折り返しを考慮したその行のheightを指定したりして、適切な文字列(もしくはcaret)を挿入する。
    // 下記のような形にすればhighlightTextを最後にinnerHTMLで簡単に反映できるのだが、これだとshakyoBackNary[i]の値でhtmlタグを写経しようとした場合にエスケープされない。
    // highlightText += '<div class="line ' + highlightClass + '"><div class="line-num">' + (i+1) + '</div><div class="text">' + shakyoBackNary[i] + '</div></div>';
    // なので、手間ではあるが各行ごとのdomを作成して、そのdomをappendChildでshakyoHighlightに追加している。
    for (var i=0; i<shakyoBackNary.length; i++) {

      let text = shakyoFront.value; // shakyoFrontの文字列
      let left = shakyoFront.selectionStart; // 先頭から選択範囲の左端までの文字数
      let right = shakyoFront.selectionEnd; // 先頭から選択範囲の右端までの文字数
      let leftLineNum = text.substr(0, left).split('\n').length; // 選択範囲の左端の行数
      let rightLineNum = text.substr(0, right).split('\n').length; // 選択範囲の右端の行数

      // 初回の読み込み時以降は、入力箇所など変更があった行のみdomの書き換えを行うようにaddElementFlagで管理する。
      // こうすることで、処理の重さが軽減する。
      let addElementFlag = false;
      if (shakyoHighlight.children.length == 0 || // 初回の読み込み時の場合
          leftLineNum-1 == i+1 || // i行目が選択範囲の１つ手前の行である場合
          (leftLineNum <= i+1 && i+1 <= rightLineNum) || // i行目が選択範囲内である場合
          rightLineNum+1 == i+1 || // i行目が選択範囲の１つ後ろの行である場合
          shakyoFront != document.activeElement // shakyoFrontへのfocusが外れた場合
         ) {
        addElementFlag = true;
      } else if (shakyoHighlight.children[i]) {
        if (shakyoHighlight.children[i].classList.contains('select-line') && (i+1 < leftLineNum || rightLineNum < i+1)) {
          // select-lineクラスを持っているのに選択範囲外の場合
          addElementFlag = true;
        } else if (shakyoHighlight.children[i].classList.contains('select-wrong-line') && (i+1 < leftLineNum || rightLineNum < i+1)) {
          // select-wrong-lineクラスを持っているのに選択範囲外の場
          addElementFlag = true;
        } else if (i+1 <= shakyoFrontNary.length) {
          if ((shakyoBackNary[i] != shakyoFrontNary[i]) && shakyoHighlight.children[i].classList.contains('wrong-line') == false) {
            // textの値が不一致なのにwrong-lineクラスが付与されていない場合
            addElementFlag = true;
          } else if ((shakyoBackNary[i] == shakyoFrontNary[i]) && shakyoHighlight.children[i].classList.contains('correct-line') == false) {
            // textの値が一致しているのにcorrect-lineが付与されていない場合
            addElementFlag = true;
          }
        }
      }

      if (addElementFlag) {

        // 奇数行(odd)か偶数行(even)かでハイライトのclassを指定する
        //let statusFlagClass = i % 2 == 0 ? 'even-num-line' : 'odd-num-line';
        let highlightClass = i % 2 == 0 ? 'even-num-line' : 'odd-num-line';
        let statusFlagClass = '';

        // shakyoFrontがfocusされている場合は、キャレットのある行、選択した行を取得しselect-lineクラスを付与するようにする。
        if (shakyoFront == document.activeElement) {
          let text = shakyoFront.value; // shakyoFrontの文字列
          let left = shakyoFront.selectionStart; // 先頭から選択範囲の左端までの文字数
          let right = shakyoFront.selectionEnd; // 先頭から選択範囲の右端までの文字数
          let leftLineNum = text.substr(0, left).split('\n').length; // 選択範囲の左端の行数
          let rightLineNum = text.substr(0, right).split('\n').length; // 選択範囲の右端の行数
          if ((leftLineNum == rightLineNum && leftLineNum == i+1) || // 選択範囲が1行のみの場合
              (leftLineNum != rightLineNum && (leftLineNum <= i+1 && i+1 <= rightLineNum)) // 選択範囲が複数行ある場合はその間の行もすべてselect-lineクラスに変更する
             ) {
            statusFlagClass = 'select-line';
          }
        }

        // shakyoFrontが空白ではなく、i行目のshakyoBackとshakyoFrontの値が異なる場合は中の処理を実行
        if (shakyoFront.value != '' && shakyoBackNary[i] != shakyoFrontNary[i]) {
          // 不一致の行の場合
          if (shakyoFrontNary.length < i+1) {
            // まだ手を付けていない行の場合
            statusFlagClass = 'not-entered-line';
          } else if (statusFlagClass != 'select-line') {
            // 入力中(または選択中)の行ではない場合
            statusFlagClass = 'wrong-line';
          } else  if (shakyoBackNary[i].indexOf(shakyoFrontNary[i]) && statusFlagClass == 'select-line') {
            // 入力中(または選択中)の行であり、前方一致していない場合
            statusFlagClass = 'select-wrong-line';
          }
        } else if (shakyoFront.value != '' && shakyoBackNary[i] == shakyoFrontNary[i]) {
          // 一致している行の場合
          statusFlagClass = 'correct-line';
        }

        // line
        var lineElement = document.createElement('div');
        //lineElement.classList.add('line', statusFlagClass);
        lineElement.classList.add('line', highlightClass);
        if (statusFlagClass != '') {
          lineElement.classList.add(statusFlagClass);
        }
        // line-num
        var lineNumElement = document.createElement('div');
        lineNumElement.classList.add('line-num');
        var lineNumContent = document.createTextNode(i+1);
        lineNumElement.appendChild(lineNumContent);
        // text
        var textElement = document.createElement('div');
        textElement.classList.add('text');
        var textContent = document.createTextNode(shakyoBackNary[i]);
        textElement.appendChild(textContent);
        // lineの子要素としてline-num、textを追加
        lineElement.appendChild(lineNumElement);
        lineElement.appendChild(textElement);
        // lineをshakyoHighlightに追加
        if (shakyoHighlight.children[i]) {
          // すでにdomが存在するのでreplaceChildで要素を置き換える。
          shakyoHighlight.replaceChild(lineElement, shakyoHighlight.children[i]);
        } else {
          // まだi行目にdomが存在しないのでappendChildで追加
          shakyoHighlight.appendChild(lineElement);
        }

      }


      // shakyoHighlightの文字数を超える文字数をshakyoFrontで入力できないように制御
      if (shakyoFrontNary[i]) {
        let marginLength = 10;
        if (shakyoBackNary[i].length + marginLength < shakyoFrontNary[i].length) { // ひらがなから漢字への変換時に少しはみ出すこともあるのでmarginLengthの数値分の余裕を持たせておく
          // はみ出ている(入力中の行のshakyoHighlight(+marginLength)よりもshakyoFrontのほうが文字数が多い。)
          let left = shakyoFront.selectionStart;
          shakyoFrontNary[i] = shakyoFrontNary[i].substring(0,shakyoBackNary[i].length + marginLength); // はみ出した部分をsubstringで取り除く
          shakyoFront.value = shakyoFrontNary.join('\n'); // はみ出し修正済みのshakyoFrontNary配列を改行コードで連結して、shakyoFrontの値に反映させる
          shakyoFront.setSelectionRange(left, left); // 上のスクリプトで反映させたらcaretの位置が自動的に最後尾に移動してしまうので、適切な位置(left変数)に再度セットする。
        }
      }


    }



    // shakyoFrontの入力情報を元に、caretまたは選択した文字列の位置にdivタグを挿入した形でshakyoShadowへ情報を反映させる。
    // 各行ごとにdomを作成し、そのdomに対して、caretまたは文字列の選択範囲に該当する行の場合はそれらの要素を含めた状態でそのdomをshakyoShadowへ追加する。
    // 下記のような形にすればshadowTextを最後にinnerHTMLで簡単に反映できるのだが、これだとshakyoFrontNary[i]の値でhtmlタグを写経しようとした場合にエスケープされない。
    // shadowText += '<div class="line"><div class="line-num">' + (i+1) + '</div><div class="text">' + shakyoFrontNary[i] + '</div></div>';
    // なので、手間ではあるが各行ごとのdomを作成して、そのdomをappendChildでshakyoShadowに追加している。
    let text = shakyoFront.value;
    let left = shakyoFront.selectionStart;
    let right = shakyoFront.selectionEnd;
    let leftLineNum = text.substr(0, left).split('\n').length; // 選択範囲の左端の行数
    let rightLineNum = text.substr(0, right).split('\n').length; // 選択範囲の右端の行数
    for (var i=0; i<shakyoHighlight.children.length; i++) {

      // 初回の読み込み時以降は、入力箇所など変更があった行のみdomの書き換えを行うようにaddElementFlagで管理する。
      // こうすることで、処理の重さが軽減する。
      let addElementFlag = false;
      if (shakyoHighlight.children.length == 0 || // 初回の読み込み時の場合
          (leftLineNum-1 == i+1 && i+1 <= shakyoBackNary.length) || // i行目が選択範囲の１つ手前の行である場合
          (leftLineNum <= i+1 && i+1 <= rightLineNum) || // i行目が選択範囲内である場合
          (rightLineNum+1 == i+1 && i+1 <= shakyoFrontNary.length) || // i行目が選択範囲の１つ後ろの行である場合
          shakyoFront != document.activeElement // shakyoFrontへのfocusが外れた場合
         ) {
        addElementFlag = true;
      } else if (shakyoShadow.children[i]) {
        if (shakyoShadow.children[i].classList.contains('select-line') && (i+1 < leftLineNum || rightLineNum < i+1)) {
          // select-lineクラスを持っているのに選択範囲外の場合
          addElementFlag = true;
        } else if (shakyoHighlight.children[i].classList.contains('correct-line') && shakyoHighlight.children[i].innerText != shakyoShadow.children[i].innerText) {
          // shakyoHighlightでcorrect-lineクラスが付与されているのに、i行目のshakyoHighlightとshakyoShadowのinnerText値が異なる場合
          addElementFlag = true;
        } else if (shakyoHighlight.children[i].classList.contains('correct-line') == false && shakyoHighlight.children[i].innerText == shakyoShadow.children[i].innerText) {
          // shakyoHighlightでcorrect-lineクラスが付与されていないのに、i行目のshakyoHighlightとshakyoShadowのinnerText値が一致する場合
          addElementFlag = true;
        }
      }

      // caretのある行または選択範囲内の行の場合は、selectLineFlagを立てて、あとでそのdomにselect-lineクラスを付与する。
      // これは上のスクリプトでaddElementFlagを立てるか判断する際の目印として利用する。
      let selectLineFlag = false;

      if (addElementFlag) {

        // i行目のshakyoHighlightの高さを取得
        let height = shakyoHighlight.children[i].clientHeight;

        // i行目のshakyoFrontの値を取得
        let text = '';
        if (shakyoFrontNary[i]) {
          text = shakyoFrontNary[i];
        }

        // 選択範囲が1行なのか、複数行なのか、1行の場合はそれがcaretなのか、文字選択なのか、
        // 複数行の場合は、その行(i行目)が選択範囲の左端が存在する行なのか、右端が存在する行なのか、その間の行なのか、
        // それぞれの状況に応じて適切なdomを追加する。
        // また、blur時にもこの関数内のスクリプトは実行されるので、focus時のみtextにcaretクラスやselect-textクラスのdivタグが挿入されるようにする。
        var textElement = document.createElement('div');
        textElement.classList.add('text');
        if (shakyoFront == document.activeElement) {
          // shakyoFrontがfocusされている場合

          // caretまたは選択範囲の文字列用のdomを作成
          let leftTextElement = document.createElement('span'); // caretまたは選択範囲よりも左の文字列のdom
          let centerTextElement = document.createElement('div'); // caretまたは選択範囲内の文字列のdom
          let rightTextElement = document.createElement('span'); // caretまたは選択範囲よりも右の文字列のdom

          if (leftLineNum == rightLineNum) {
            // 選択範囲が1行のみの場合

            if (leftLineNum == i+1 && rightLineNum == i+1) {
              // i行目がcaretまたは選択範囲の行であり、それが1行のみの場合
              selectLineFlag = true;

              // 1行目からi行目までの文字数をfrontTextLengthに代入
              let frontTextLength = shakyoFrontNary.slice(0,i).join('\n').length;
              // 選択範囲が2行目以降場合は最後尾にもう１つ改行コード(\n)が入るはずなのでその分を追加する。
              if (0 < i) {
                frontTextLength += 1;
              }

              // i行目(caretまたは選択した文字列がある行)において、選択範囲よりも左部分の文字列をleftText変数に代入し、leftTextElementに追加
              let leftText = text.substring(0, left - frontTextLength);
              leftTextElement.appendChild(document.createTextNode(leftText));

              // caretまたは選択した文字列の位置にそれぞれ適切なhtmlタグを挿入してcenterText変数に代入し、centerTextElementに追加
              let centerTextContent;
              if (left == right) {
                // caretの場合
                centerTextElement.classList.add('caret');
                centerTextContent = document.createTextNode('|');
              } else {
                // 文字列の選択の場合
                centerTextElement.classList.add('select-text');
                centerTextContent = document.createTextNode(text.substring(left - frontTextLength, right - frontTextLength));
              }
              centerTextElement.appendChild(centerTextContent);

              // i行目(caretまたは選択した文字列がある行)において、選択範囲よりも右部分の文字列をrightText変数に代入し、rightTextElementに追加
              let rightText = text.substring(right - frontTextLength, text.lengh);
              rightTextElement.appendChild(document.createTextNode(rightText));

              // textElementに、caretまたは選択範囲の左側の文字列、caretまたは選択範囲の文字列、caretまたは選択範囲の右側の文字列のdomを追加
              textElement.appendChild(leftTextElement);
              textElement.appendChild(centerTextElement);
              textElement.appendChild(rightTextElement);

            } else {
              // 選択範囲が1行のみ、i行目が選択範囲以外の場合
              textElement.appendChild(document.createTextNode(text));
            }

          } else {
            // 選択範囲が複数行にまたぐ場合

            // i行目が、選択範囲の左端に存在する行なのか、右端に存在する行なのか、その間に存在する行なのかで、
            // それぞれ適切なdomを追加する。
            if (leftLineNum == i+1) {
              // 選択範囲の左端が存在する行の場合
              selectLineFlag = true;

              // 1行目からi行目までの文字数をfrontTextLengthに代入
              let frontTextLength = shakyoFrontNary.slice(0,i).join('\n').length;
              // 選択範囲が2行目以降場合は最後尾にもう１つ改行コード(\n)が入るはずなのでその分を追加する。
              if (0 < i) {
                frontTextLength += 1;
              }

              // 選択範囲よりも左部分の文字列をleftText変数に代入し、leftTextElementに追加
              let leftText = text.substring(0, left - frontTextLength);
              leftTextElement.appendChild(document.createTextNode(leftText));

              // 選択範囲内の文字列を、select-textクラスを付与した上で、centerTextElementに追加
              centerTextElement.classList.add('select-text');
              centerTextElement.appendChild(document.createTextNode(text.substring(left - frontTextLength, text.length)));

              // textElementに、選択範囲の左側の文字列、選択範囲の文字列のdomを追加
              textElement.appendChild(leftTextElement);
              textElement.appendChild(centerTextElement);

            } else if  (leftLineNum < i+1 && i+1 < rightLineNum) {
              // 選択範囲の左端が存在する行と右端が存在する行の間の行の場合
              selectLineFlag = true;

              // i行目の文字列すべてを、select-textクラスを付与した上で、centerTextElementに追加
              centerTextElement.classList.add('select-text');
              centerTextElement.appendChild(document.createTextNode(text));

              // textElementに、選択範囲の文字列のdomを追加
              textElement.appendChild(centerTextElement);

            } else if (rightLineNum == i+1) {
              // 選択範囲の右端が存在する行の場合
              selectLineFlag = true;

              // 1行目からi行目までの文字数をfrontTextLengthに代入
              let frontTextLength = shakyoFrontNary.slice(0,i).join('\n').length;
              // 選択範囲が2行目以降場合は最後尾にもう１つ改行コード(\n)が入るはずなのでその分を追加する。
              if (0 < i) {
                frontTextLength += 1;
              }

              // 選択範囲内の文字列を、select-textクラスを付与した上で、centerTextElementに追加
              centerTextElement.classList.add('select-text');
              centerTextElement.appendChild(document.createTextNode(text.substring(0, right - frontTextLength)));

              // 選択範囲よりも右部分の文字列をrightText変数に代入し、rightTextElementに追加
              let rightText = text.substring(right - frontTextLength, text.length);
              rightTextElement.appendChild(document.createTextNode(rightText));

              textElement.appendChild(centerTextElement);
              textElement.appendChild(rightTextElement);

            } else {
              // 選択範囲が複数行にまたいでいて、i行目が選択範囲以外の場合
              textElement.appendChild(document.createTextNode(text));
            }

          }

        } else {
          // shakyoFrontのfocusが外れている場合
          textElement.appendChild(document.createTextNode(text));
        }

        // line
        var lineElement = document.createElement('div');
        lineElement.classList.add('line');
        if (selectLineFlag) {
          lineElement.classList.add('select-line');
        }
        lineElement.style.height = height + 'px';
        // line-num
        var lineNumElement = document.createElement('div');
        lineNumElement.classList.add('line-num');
        var lineNumContent = document.createTextNode(i+1);
        lineNumElement.appendChild(lineNumContent);
        // lineの子要素としてline-num、textを追加
        lineElement.appendChild(lineNumElement);
        lineElement.appendChild(textElement);
        // lineをshakyoHighlightに追加
        if (shakyoShadow.children[i]) {
          // すでにdomが存在するのでreplaceChildで要素を置き換える。
          shakyoShadow.replaceChild(lineElement, shakyoShadow.children[i]);
        } else {
          // まだi行目にdomが存在しないのでappendChildで追加
          shakyoShadow.appendChild(lineElement);
        }

        // shakyoFrontを、shakyoHighlightのselect中の行と同じ高さにする。
        // そして、shakyoHighlightの位置にshakyoFrontを移動させる。
        // こうすることで、select中の行が常に画面内に収まるように自動でスクロールされるようになる。
        if ((leftLineNum == i+1 && rightLineNum == i+1) ||  // 選択範囲が1行の場合
            (leftLineNum != rightLineNum) // 選択範囲が複数行にまたぐ場合
           ) {
          shakyoFront.style.height = height + 'px';
          shakyoFront.style.marginTop = shakyoHighlight.children[i].offsetTop + 'px';
        }

      }

    }


    // 写経し終わったかどうかのステータスをリターンする。
    let status = false;
    if (shakyoBack.value == shakyoFront.value) {
      status = true;
    }

    // #shakyo要素の高さを#shakyo-highlightと同じに指定しておかないとCREATEボタンまたはUPDATEボタンの要素と重なってしまうので、#shakyo要素の高さを調整する。
    document.getElementById('shakyo').style.height = document.getElementById('shakyo-highlight').clientHeight + 'px';

    // ShakyoMatchCheck関数の呼び出し元に、写経が終了したかどうかのstatusをリターンする
    resolve({status:status});


  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
