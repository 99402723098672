import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { QaFormStyle, QaFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/QaForm';

// function
import GetLearning from 'component/common/function/GetLearning';
import UpdateDistributedLearning from 'component/common/function/UpdateDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import Polly from 'component/common/function/Polly';


class Qa extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      hash:'',
      learning:[],
      speeching:false,
    };
  }

  componentDidMount = () => {
    let hash = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      hash = pathItems[1].replace('distributed_learning_qa_','');
    } else {
      hash = pathItems[0].replace('distributed_learning_qa_','');
    }
    this.setState({ hash:hash, });
    this.getLearning(hash);
  }

  getLearning = (hash) => {
    GetLearning(hash).then((l) => {
      if (l) {
        this.setState({ learning:l, }, () => {
          TextHeightAdjustment('question');
          TextHeightAdjustment('answer');
        });
      }
    });
  }

  spanChange = (span) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.span = span;
    this.setState({ learning:learning, }, () => {
      this.saveQa();
    });
  }

  changeTimes = (event) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.times = event.target.value.replace(/[^0-9,]/gi, "").replace(",,", ","); // 整数値とカンマ以外の文字は削除。カンマが連続する場合は１つにする。
    this.setState({ learning:learning }, () => {
      this.saveQa();
    });
  }

  changeQuestion = (event) => {
    let l = this.state.learning;
    l.question = event.target.value;
    this.setState({ learning:l, }, () => {
      TextHeightAdjustment('question');
      this.saveQa();
    });
  }

  changeAnswer = (event) => {
    let l = this.state.learning;
    l.answer = event.target.value;
    this.setState({ learning:l, }, () => {
      TextHeightAdjustment('answer');
      this.saveQa();
    });
  }

  saveQa = () => {
    let l = this.state.learning;
    UpdateDistributedLearning(l.hash, l.type, l.question, l.answer, l.musiqui, l.shakyo, l.span, l.times).then((saved) => {
    });
  }

  speech = () => {
    let flang = '';
    if (this.state.learning.type.indexOf('-en-ja') != -1) {
      flang = 'en';
    } else if (this.state.learning.type.indexOf('-ja-en') != -1) {
      flang = 'ja';
    }

    var params = { speech_text:this.state.learning.question, from_lang:flang, };
    Polly(params).then((status) => {
      if (status == 'success') {
        let speech_path = 'https://s3-ap-northeast-1.amazonaws.com/media.takuman.me/static/speech.mp3';
        // 同一のurlの場合、キャッシュが効いて新しい音声が再生されないので、毎回乱数が挿入されるようにしてキャッシュを回避する。
        this.speeching = new Audio(speech_path + '?noCache=' + Math.floor(Math.random() * 1000000));
        this.setState({ speeching:true, });
        this.speeching.play();  // 再生

        // 読み上げが終了したらspeechingフラグを下ろす。
        this.speeching.onended = () => {
          this.setState({ speeching:false, });
        }
      }
    });
  }

  shutUp = () => {
    this.setState({ speeching:false, });
    this.speeching.pause(); // 一時停止
  }

  deleteItem = () => {
    let l = this.state.learning;
    DeleteDistributedLearning(l.type, l.question, l.answer, l.musiqui, l.shakyo).then(() => {
      this.props.handleFlashMessage('DELETED');
      // SHAKYOの詳細画面にリダイレクト
      let pathItems = window.location.pathname.slice(1).split('/');
      if (this.props.full) {
        History.push('/distributed_learning_qa_index_' + l.type);
      } else if (this.props.one) {
        History.push('/distributed_learning_qa_index_' + l.type + '/' + pathItems[1]);
      } else if (this.props.two) {
        History.push('/' + pathItems[0] + '/distributed_learning_qa_index_' + l.type);
      }
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }

    let speech;
    if (this.state.speeching) {
      speech = (
        <div className='speech'>
          <i className='fas fa-stop' onClick={ () => {this.shutUp()} }></i>
        </div>
      );
    } else {
      speech = (
        <div className='speech'>
          <i className='fas fa-volume-up' onClick={ () => {this.speech()} }></i>
        </div>
      );
    }


    let selectType;
    if (this.state.learning) {
      selectType = (
        <div className='select'>
          <div className='select-item'>type: {this.state.learning.type}</div>
        </div>
      );
    }

    let selectSpanField;
    if (this.state.learning) {
      let spanArray = [0, 1, 2, 4, 8, 16, 32, 64, 128];
      selectSpanField = (
        <div className='select-span-field'>
          {spanArray.map( (span,index) => {
            return ( <div className={'span' + (this.state.learning.span == span ? ' select-span' : '') + (index == 0 ? ' first-span' : index == spanArray.length - 1 ? ' last-span' : '')} onClick={ (event) => {this.spanChange(span)} }>{span}</div> );
          })}
        </div>
      );
    }

    let timesField;
    if (this.state.learning) {
      timesField = ( <div className="times-field"><input type="text" id="times" name="times" value={this.state.learning.times} onChange={ (event) => {this.changeTimes(event)} } /></div> );
    }



    let span;
    if (this.state.learning) {
      span = this.state.learning.span;
    }

    let question;
    if (this.state.learning) {
      question = (
        <textarea type='text' id='question' value={this.state.learning.question} onChange={ (event) => {this.changeQuestion(event)} } placeholder='question' />
      );
    }

    let answer;
    if (this.state.learning) {
      answer = (
        <textarea type='text' id='answer' value={this.state.learning.answer} onChange={ (event) => {this.changeAnswer(event)} } placeholder='answer' />
      );
    }

    let deleteBtn = ( <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} }></i></div> );


    let translateField;
    if (this.state.learning) {
      translateField = (
        <QaFormField>
          {/* <h1 className='title'>{this.state.learning.type}</h1> */}
          <TypeField>
            {selectType}
            {selectSpanField}
            {timesField}
          </TypeField>
          <TextField learning>
            <div className='title'>[question]</div>
            {speech}
            {question}
          </TextField>
          <TextField learning>
            <div className='title'>[answer] span:{span}</div>
            {deleteBtn}
            {answer}
          </TextField>
        </QaFormField>
      );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Q&A | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learning_qa_index_' + this.state.learning.type : this.props.one ? '/distributed_learning_qa_index_' + this.state.learning.type+ '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_qa_index_' + this.state.learning.type}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <QaFormStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
        </QaFormStyle>
      </div>
    );
  }

}

export default Qa;
