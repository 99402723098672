import axios from 'axios';

export default function LoginFunc(uinfo,password) {

  return new Promise((resolve, reject) => {

    axios
    .get('https://lab.takuman.me/api/user/uhash_response/' + uinfo)
    .then(res => {
      resolve({'user':res.data, 'password':password});
    })
    .catch(err => { reject() });

  }).then((p) => {

    return new Promise( (resolve, reject) => {
      // 取得したユーザーハッシュ(res.data.uhash)とパスワード(this.state.password)をDRFに渡しログイン認証を行う。
      //var params = JSON.stringify({uhash:p.user.uhash, password:p.password});
      var params = {uhash:p.user.uhash, password:p.password}
      var headers = { 'Content-Type': 'application/json' }
      axios
      .post('https://lab.takuman.me/token-auth/', params, headers)
      .then(res => {
        localStorage.setItem('token', res.data.token); // ブラウザのローカルストレージに暗号化されたキーとバリューのペアになっているトークンを格納。
        resolve(p.user);
      })
      .catch((error) => { reject() });
    });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
