import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, white } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const PostStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:300;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'}; overflow:scroll;
  }
`

const PostBlock = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:20px;
    h1, h2, h3, h4, p, span, div { color:${mainColor}; }
    h1 { color:${edgeColor}; font-size:28px; font-weight:900; line-height:30px; margin:10px 0; }
    h2 { font-size:25px; font-weight:900; line-height:27px; margin:60px 0 10px 0; }
    h3 { font-size:20px; font-weight:900; line-height:23px; margin:30px 0 10px 0; }
    h4 { font-size:18px; font-weight:900; line-height:20px; margin:20px 0 10px 0; }
    p { font-size:16px; font-weight:300; line-height:22px; margin:10px 0; padding:0; white-space:pre-wrap; }

    .code { color:${edgeColor}; padding:20px 10px; background:${darken(subColor,0.2)}; border-radius:5px; white-space:pre; overflow:scroll; }
    blockquote { border-left:6px solid ${mainColor}; border-bottom:1px solid ${rgba(white,0.1)}; border-right:1px solid ${rgba(white,0.1)};
                 width:calc(100%-40px); margin:0; padding:10px; font-style:italic; background:${rgba(white,0)}; }

    a { color:${linkColor}; margin:0; padding:0; border-radius:100000px; }
    a:hover { text-decoration:underline; text-decoration-color:${linkColor}; }

    .url { padding:5px 15px 5px 10px; }
    .url:hover { background:${rgba(linkColor,0.3)}; text-decoration:none; }

    table { border-collapse:collapse; border:1px solid ${mainColor}; color:${mainColor};
      th, td { border:1px solid ${mainColor}; color:${mainColor}; }
    }

    img { width:100%; height:auto; margin:10px 0; padding:0; }
    video { width:100%; height:auto; margin:auto; padding:0; cursor:pointer; }

    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '100'}%; border-radius:5px; box-shadow:0 0 ${props => props.full ? '6' : '0'}px ${shadowColor}; }
    @media screen and (min-width:960px) { width:${props => props.full ? '60' : '100'}%; }
  }
`

const Tag = styled.span`
  &&& {
    width:auto; height:auto; margin:5px 5px 5px 0; padding:2px 10px; background:${mainColor}; border-radius:2px;
    font-size:14px; line-height:30px; color:black; text-align:center; word-break:normal; cursor:pointer;
    &&& { color:${subColor}; }
  }
`

const PdfPreview = styled.embed`
  &&& {
    width:100%; height:95vh;
    filter:invert(100%) brightness(75%) sepia(80%) hue-rotate(130deg) saturate(800%) contrast(1);
  }
`


export { PostStyle, PostBlock, Tag, PdfPreview };
