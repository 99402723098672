import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const PostsFeedLayoutStyle = styled.div`
  &&& {
  }
`

const PostsStyle = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
  }
`

const Post = styled.div`
  &&& {
    width:100%; height:auto; margin:20px auto; padding:10px 20px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; clear:both;
    color:${mainColor}; font-size:18px; line-height:20px;

    .title { padding-bottom:10px;
      a { color:${linkColor}; }
      a:hover { color:${edgeColor}; }
    }
    .title-ja { color:${yellow}; font-size:16px; border-top:0.5px solid ${mainColor}; padding-top:10px; }

    @media screen and (min-width:750px) {
      width:${(props) => props.full ? '80%' : '100%'};
    }

    @media screen and (min-width:960px) {
      width:${(props) => props.full ? '60%' : '100%'};
    }
  }
`

export { PostsFeedLayoutStyle, PostsStyle, Post };
