import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import History from 'History';
import * as escape from 'escape-html';

import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import ContentEditable from 'react-contenteditable';

//import PlayAudio from 'component/common/PlayAudio'; // Web Audio APIでサウンド再生 
//import PlayVibrate from 'component/common/PlayVibrate'; // Vibration APIでデバイスを振動

// style
import { BackBtnField, MovieField } from 'component/common/style/GlobalStyle';
import { LayeredNoteStyle, NoteHeader, Item, AddBtn, OrderSelect, SelectTextMenu } from 'component/common/style/LayeredNote';

class LayeredNote extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      scrollTop:0,
      loading:true,
      note_hash:this.props.note_hash, title:'', items:[],
      focus:false, focusFieldHash:'', focusFieldType:'',
      blurMethod:true,
      scroll:false, scrollItemHash:'',
      orderChange:false, orderItemHash:'',
      palettePop:false,
      removeItemConfirm:false,
      lineHeight:false,
      menuOpen:false,
      selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', replaceDescriptionBuffer:'', selectTextMenuHover:false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);
    this.getNote();
    this.getNoteItems();
  }


  componentWillUpdate = () => {
    document.body.addEventListener('click', (event) => {
      // addEventListenerのclickはマウスクリックだけでなく、エンターキー押下でも発火するらしいので、event.detailでクリック数を取得し、
      // マウスクリックなのか、エンターキー押下なのかを識別し、マウスクリック時のみ中の処理を行うようにしている。
      if (event.detail !== 0) {
        //console.log('componentWillUpdate click event');
        //console.log(event.target);
        //console.dir(event.target);
        //console.log(event.target.closest('.menu-item'));
        //console.log(event.target.parentElement.closest('.menu-item'));
        //if (event.target.closest('.item-element')) {
        //if (event.target.closest('.item-element') || event.target.closest('.add-btn')) {
        //if (event.target.closest('.item-element') || event.target.closest('.add-btn') || event.target.closest('.menu-item') || event.target.parentElement.closest('.menu-item')) {
        //if (event.target.closest('.item-element') || event.target.closest('.add-btn') || event.target.closest('.menu-item')) {
        if (event.target.closest('.item-element') || event.target.closest('.add-btn') || event.target.closest('.menu-item') || event.target.closest('.select-text-menu')) {
          // item要素のどこかをクリックした場合
          console.log('item要素のどこかをクリックした場合');
        } else {
          // item要素以外のどこかをクリックした場合
          console.log('item要素以外のどこかをクリックした場合');

          // ポップアップメニューなどをすべて閉じる。
          //console.log('ポップアップメニューなどをすべて閉じる。');
          // selectTextMenuとselectTextMenuPosとselectTextMenuTypeとselectTextUrlとreplaceDescriptionBufferとselectTextMenuHoverはどうするか？
          //if (this.state.focus) { this.setState({ focus:false, focusFieldHash:'', focusFieldType:'', }); }
          if (this.state.focus) { this.setState({ focus:false, }); }
          if (this.state.focusFieldHash) { this.setState({ focusFieldHash:'', }); }
          if (this.state.focusFieldType) { this.setState({ focusFieldType:'', }); }

          if (this.state.orderChange) { this.setState({ orderChange:false, }); }
          if (this.state.palettePop) { this.setState({ palettePop:false, }); }
          if (this.state.removeItemConfirm) { this.setState({ removeItemConfirm:false, }); }
          if (this.state.selectTextMenu) { this.setState({ selectTextMenu:false, }); }
          //if (this.state.selectTextMenu && event.target.closest('.menu-item') == false && event.target.parentElement.closest('.menu-item') == false) { this.setState({ selectTextMenu:false, }); }

          if (this.state.scroll) { this.setState({ scroll:false, }); }
          if (this.state.scrollItemHash != '') { this.setState({ scrollItemHash:'', }); }
          if (this.state.orderItemHash != '') { this.setState({ orderItemHash:'', }); }
          if (Object.keys(this.state.selectTextMenuPos).length) { this.setState({ selectTextMenuPos:[], }); }
          if (this.state.selectTextUrl != '') { this.setState({ selectTextUrl:'', }); }
          if (this.state.replaceDescriptionBuffer != '') { this.setState({ replaceDescriptionBuffer:'', }); }
          if (this.state.selectTextMenuHover) { this.setState({ selectTextMenuHover:false, }); }
          if (this.state.blurMethod == false) { this.setState({ blurMethod:true, }); }
        }
      }
    });
  }


  componentDidUpdate = () => {
    //console.log('componentDidUpdate'); 

    this.props.helloLab();

    if (this.state.loading == true && this.state.items.length != 0) {
      this.props.logoAnimation('');
      this.setState({ loading:false });
    }


/*
    // 適切な場所をfocusさせる
    if (this.state.focus) {
      if (this.state.focusFieldType == 'name') {
        document.getElementById('name-' + this.state.focusFieldHash).focus();
      } else {
        document.getElementById('description-' + this.state.focusFieldHash).focus();
      }
      //this.setState({ focus:false, blurMethod:true });
      this.setState({ focus:false, blurMethod:true, }, () => {
        let dom;
        if (this.props.full) {
          dom = document.getElementById('layerednote');
        } else if (this.props.one) {
          dom = document.getElementById('layerednote-1');
        } else {
          dom = document.getElementById('layerednote-2');
        }
        //let scrollTop = dom.scrollTop;
        //console.log('scrollTop: ' + scrollTop);
        console.log('scroolTopを調整');
        if (0 < this.state.scrollTop) {
          dom.scrollTop = this.state.scrollTop;
        }
      });
    }
*/

/*
    // orderChangeがtrueの際にスクロール位置がいい感じの場所にくるように調整
    if (this.state.scroll) {

      var element = document.getElementById('name-' + this.state.scrollItemHash); // 要素を取得
      var rect = element.getBoundingClientRect(); // 取得した要素の座標値を取得
      var scrollTop = rect.top + window.pageYOffset; // ページ全体から見た場合の縦位置を取得

      var clientHeight = document.documentElement.clientHeight; // 表示画面の高さを取得 
      scrollTop = scrollTop - (clientHeight/2); // 移動する項目を画面の中央付近にもってきたいので表示画面の高さの半分を足す。 
      window.scrollTo(0,scrollTop);
      this.setState({ scroll:false, scrollItemHash:'', });

    }
*/

/*
    const items = this.state.items.slice();
    for (var i=0; i<items.length; i++) {
      if (items[i].hash != 'order-select') {
        this.itemUpdate(items, items[i].hash);
      }
    }
*/
    this.itemsUpdate();

  }




  getNote = () => {
    //console.log('getNote');
    this.props.logoAnimation('kodo');
    var params = { }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios
      .get('https://lab.takuman.me/api/layerednote/read/' + this.state.note_hash + '/', params, headers)
      .then(res => {
        let note = res.data;
        //this.setState({ title:note.title });
        this.setState({ title:note.title }, () => {
          this.textareaHeightAdjustment('note-title');
        });
      })
      .catch(err => {  }); // エラーログを出力
  }


  getNoteItems = () => {
    //console.log('getNoteItems');
    var params = { }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios
      .post('https://lab.takuman.me/api/layerednoteitem/index/' + this.state.note_hash + '/', params, headers)
      .then(res => {
        let items = res.data;

        // orderの調整
        items = this.orderAdjustment(items);
        //console.log(items);

        // グラデーション率の調整
        //items = this.gradationAdjustment(items, block);

        // block単位で最前列の項目はfirst:true、最後尾の項目はlast:trueにする。
        items = this.firstLast(items);

        this.setState({ items:items, lineHeight:true });
      })
      .catch(err => {
        // 項目がまだ１つもないので新規で１つ作成(あとでオフライン時のエラーかどうか識別するようにする)
        let item_hash = this.itemHashCreate();
        this.setState({ items: this.state.items.concat([
          {hash:item_hash, parent:'', block:0, layer:0, order:0, name:'', description:'', descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:true, last:true},
        ]) });
        const items = [{hash:item_hash, parent:'', block:0, layer:0, order:0, name:'', description:'', descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:true, last:true},];
        this.itemCreate(items, item_hash);
      }); // エラーログを出力
  }




  /******************************
   *                            * 
   *     使いまわしメソッド     *
   *                            *
   ******************************/

  // ハッシュ作成
  itemHashCreate = () => {
    const hashLength = 8; //生成したい文字列の長さ
    const source = "abcdefghijklmnopqrstuvwxyz0123456789"; //元になる文字
    var items = this.state.items.slice();
    let item_hash = '';
    var notUniqueHash = true; // ユニークネスであればfalseになる

    // ユニークネスなハッシュが生成されるまで繰り返し処理を続ける
    while (notUniqueHash) {
      item_hash = 'item-';
      // ハッシュを作成
      for(let i=0; i<hashLength; i++){
        item_hash += source[Math.floor(Math.random() * source.length)];
      }
      // 生成したハッシュがユニークネスであればfalseを返しwhile文の処理から抜ける
      notUniqueHash = items.some(item => item.hash == item_hash);
    }

    if (notUniqueHash == false) {
      return item_hash;
    }
  }

  // description内で任意のaタグを識別するためのユニークネスなハッシュをを作成する。
  descriptionNodeHashCreate = (description) => {
    const hashLength = 4; //生成したい文字列の長さ
    const source = "abcdefghijklmnopqrstuvwxyz0123456789"; //元になる文字
    let descriptionNode = document.createRange().createContextualFragment(description); // selectTags[i]のタグの中身のテキストの部分を取得
    let nodes = descriptionNode.childNodes;

    let nodesIdArray = [];
    for (var i=0; i<nodes.length; i++) {
      nodesIdArray.push(nodes[i].id);
    }

    let hash = '';
    var notUniqueHash = true; // ユニークネスであればfalseになる


    // ユニークネスなハッシュが生成されるまで繰り返し処理を続ける
    while (notUniqueHash) {
      hash = '';
      // ハッシュを作成
      for(let i=0; i<hashLength; i++){
        hash += source[Math.floor(Math.random() * source.length)];
      }
      // 生成したハッシュがユニークネスであればfalseを返しwhile文の処理から抜ける
      notUniqueHash = nodesIdArray.some(id => id == hash);
    }

    if (notUniqueHash == false) {
      return hash;
    }
  }


  // block単位で最前列の項目はfirst:true、最後尾の項目はlast:trueにする
  firstLast = (items) => {
    //console.log('firstLast');

    // blockの数を取得
    var maxBlock = 0;
    items.map((item, index) => {
      if (maxBlock < item.block) {
        maxBlock = item.block;
      }
    });
    maxBlock += 1; // 0からはじまるインデックスの値が入っているので+1して整数に調整

    // block単位で処理を行う
    for (var i=0; i<maxBlock; i++) {
      const itemBlock = items.filter(item => item.block == i);
      itemBlock.map((item, index) => {
        item.first = false; // あとで適切なオブジェクトに更新し直すのでとりあえずすべての項目のfirstをfalseにしておく 
        item.last = false; // あとで適切なオブジェクトに更新し直すのでとりあえずすべての項目のlastをfalseにしておく 
      });

      const firstItemIndex = items.findIndex(item => item.block == i && item.layer == 0);
      //console.log('i: ' + i + ', firstItemIndex: ' + firstItemIndex); 
      items[firstItemIndex].first = true;

      // block内でインデックス番号が一番大きい項目(最後尾)を取得
      let lastItemHash = itemBlock.slice(-1)[0].hash;
      const lastItemIndex = items.findIndex(item => item.hash == lastItemHash);
      items[lastItemIndex].last = true;
    }

    return items;
  }


  // items配列全体をlayerとorderを基準に並べ替え 
  orderAdjustment = (items) => {
    //console.log('orderAdjustment');

    var orderlyItems = [];

    var maxBlock = 0;
    items.map((item, index) => {
      if (maxBlock < item.block) {
        maxBlock = item.block;
      }
    });


    // block単位で項目の順番を調整
    for (var i=0; i<=maxBlock; i++) {
      const blockItems = items.filter(item => item.block == i);

      // 第一優先にlayer、第二優先にorderをおいて並べ替え
      blockItems.sort(function(a,b){
        if(a.layer>b.layer) return 1;
        if(a.layer<b.layer) return -1;
        if(a.order>b.order) return 1;
        if(a.order<b.order) return -1;
        return 0;
      });

      blockItems.map((item,index) => {
        let someChildItems = blockItems.some(blockitem => blockitem.parent == item.hash);
        if (someChildItems) {
          // item.item_hashをparentにもつ子項目を抽出 
          const childItems = blockItems.filter(blockitem => blockitem.parent == item.hash);

          // もとの配列から抽出した項目オブジェクトを削除
          blockItems = blockItems.filter(itemA =>
            childItems.indexOf(itemA) == -1
          );

          // 親項目のインデックス番号を取得
          const parentItemIndex = blockItems.findIndex(blockitem => blockitem.hash == item.hash);

          // 親項目の次に子項目の配列を挿入
          Array.prototype.splice.apply(blockItems,[parentItemIndex+1,0].concat(childItems));
        }
      });
      orderlyItems = orderlyItems.concat(blockItems);
    }

    // 順番を調整したitemsをreturn
    return orderlyItems;
  }

  // グラデーション率の算出
  gradationAdjustment = (items, block) => {
    //console.log('gradationAdjustment');

    const itemBlock = items.filter(item => item.block == block);

    // 階層数を取得
    var maxLayer = 0;
    itemBlock.map((item, index) => {
      if (maxLayer < item.layer) {
        maxLayer = item.layer;
      }
    });

    // それぞれの項目が属する階層に応じてグラデーション率を割り当てる
    items.map((item, index) => {
      if (item.block == block) {
        if (item.layer == maxLayer && item.layer == 0) {
          // 階層化されていない単発の項目(layerが0で下の階層がない)
          item.gradation = 0;
        } else if (item.layer == maxLayer && item.layer != 0) {
          // 最下層のレイヤに属する項目、または階層化されていない単発の項目(layerが0で下の階層がない)
          // 最下層のレイヤに属する項目
          item.gradation = 1;
        } else if (item.layer == 0) {
          // 階層化された項目の最上層(layerが0)の項目
          item.gradation = 0;
        } else {
          // 中間層の項目
          item.gradation = (100 / maxLayer) * item.layer;
          item.gradation = item.gradation / 100; // 0〜100のパーセンテージを0〜1の範囲の値をとるようにする。
          item.gradation = Math.round(item.gradation * 100) / 100; // 小数点第三位を四捨五入
        }

        if (item.layer == 0) {
          item.layer_percentage = '0%';
        } else {
          // 100でかけてからMath.roundをすることで少数第二位で四捨五入を行っている。そして、その後に100で割って元の桁数に戻している。
          // DRF側でlayer_percentageがmax_length=10になっているので'%'という文字列と組み合わせて10文字以内に収めないといけない。
          let layer_percentage = Math.round((item.gradation * 100 * 0.4) * 100) / 100;
          item.layer_percentage = String(layer_percentage) + '%';
        }

      }
    });

    return items;
  }


  progenyItemIndex = (items, ancesterItemIndex, layer) => {
    var lastProgenyItem = '';
    const progenyItem = items.some(item => item.parent == items[ancesterItemIndex].hash);
    if (progenyItem == true) {
      // 子孫項目あり
      var searchProgenyItem = '';
      items.map((item, index) => {
        if (ancesterItemIndex < index && searchProgenyItem == '') {
          // item.layerがremoveするlayer以上になるまで処理を行う。直前の項目が子孫項目の最後尾ということになる。
          if (layer < item.layer) {
            lastProgenyItem = item.hash;
          } else {
            searchProgenyItem = 'finish';
          }
        }
      });
    } else {
      // 子孫項目なし
      lastProgenyItem = items[ancesterItemIndex].hash;
    }

    const lastProgenyItemIndex = items.findIndex(item => item.hash == lastProgenyItem);
    return lastProgenyItemIndex;
  }


  // 任意の項目にfocusを当て、その後に任意の位置へスクロールするためのメソッド
  itemAutoFocus = (type, hash, scrollTop) => {
    if (type && hash) {
      if (type == 'name') {
        document.getElementById('name-' + hash).focus();
      } else {
        document.getElementById('description-' + hash).focus();
      }
    }
    if (scrollTop) {
      let dom;
      if (this.props.full) {
        dom = document.getElementById('layerednote');
      } else if (this.props.one) {
        dom = document.getElementById('layerednote-1');
      } else {
        dom = document.getElementById('layerednote-2');
      }
      dom.scrollTop = scrollTop;
    }
    this.setState({ focusFieldHash:hash, });
  }


  // 現在のスクロール量を取得する
  getScrollTop = () => {
    let scrollTop;
    if (this.props.full) {
      scrollTop = document.getElementById('layerednote').scrollTop;
    } else if (this.props.one) {
      scrollTop = document.getElementById('layerednote-1').scrollTop;
    } else if (this.props.two) {
      scrollTop = document.getElementById('layerednote-2').scrollTop;
    }
    return scrollTop;
  }

  // 指定したidのtextareaのheightを折り返しの行数ぴったりの高さになるように調整
  textareaHeightAdjustment = (id) => {
    let target = document.getElementById(id);
    if (target.scrollHeight > target.offsetHeight) {
      target.style.height = target.scrollHeight + 'px';
    }else{
      var height, lineHeight;
      let i = 0;
      while (true){
        height = Number(target.style.height.split('px')[0]); // テキストエリア全体の高さ 
        lineHeight = Number(target.style.lineHeight.split('px')[0]); // 1行あたりの高さ
        target.style.height = '0px'; // 上記の計算で初期値を入れてしまうと配列の追加・削除でindexがずれた際に別の項目の初期値をとってしまい縦幅がおかしくなってしまう。 
        if (target.scrollHeight >= target.offsetHeight) { // スクロール込みの高さが全体の高さよりも大きければ、全体の高さにスクロール込みの高さの値を代入して処理を抜ける
          target.style.height = target.scrollHeight + 'px';
          break;
        } else {
          if (100 < i) {
            target.style.height = target.scrollHeight + 'px';
            break;
          } else {
            i++;
          }
        }
      }
    }
  }


  noteUpdate = () => {
    var params = { title:this.state.title }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/layerednote/update/' + this.state.note_hash + '/', params, headers)
    .then(res => {
      if (res.data['hash']) {
        //console.log('保存しました');
      } else {
        //console.log('保存できませんでした。');
      }
    })
    .catch(err => console.log(err))
  }

  itemCreate = (items, item_hash) => {
    //console.log('itemCreate');
    const createItemIndex = items.findIndex(item => item.hash == item_hash);
    const item = items[createItemIndex];

    var params = { hash:item.hash, parent:item.parent, block:item.block, layer:item.layer, order:item.order, name:item.name, description:item.description, color:item.color, background:item.background, gradation:item.gradation, layer_percentage:item.layer_percentage,  }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/layerednoteitem/create/' + this.state.note_hash + '/', params, headers)
    .then(res => {
      //if (res.data['hash']) {
        //console.log('itemCreate: DBに保存しました');
      //} else {
        //console.log('itemCreate: DBに保存できませんでした。');
      //}
    })
    .catch(err => {console.log('エラーが発生しました');})
  }


  itemsUpdate = () => {
    const items = this.state.items.slice();
    for (var i=0; i<items.length; i++) {
      if (items[i].hash != 'order-select') {
        this.itemUpdate(items, items[i].hash);
      }
    }
  }

  itemUpdate = (items, item_hash) => {
    //console.log('itemUpdate');
    const updateItemIndex = items.findIndex(item => item.hash == item_hash);
    const item = items[updateItemIndex];

    var params = { hash:item.hash, parent:item.parent, block:item.block, layer:item.layer, order:item.order, name:item.name, description:item.description, description_show:item.description_show, color:item.color, background:item.background, gradation:item.gradation, layer_percentage:item.layer_percentage,  }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/layerednoteitem/update/' + this.state.note_hash + '/' + item_hash + '/', params, headers)
    .then(res => {
      //if (res.data['hash']) {
        //console.log('itemUpdate: 更新しました');
      //} else {
        //console.log('itemUpdate: 更新できませんでした。');
      //}
    })
    .catch(err => {
      console.log('エラーにより更新できませんでした。');
    })
  }

  itemDelete = (items, item_hash) => {
    //console.log('itemDelete');
    const deleteItemIndex = items.findIndex(item => item.hash == item_hash);
    const item = items[deleteItemIndex];
    //console.log(item.name + 'を削除します。');

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.delete('https://lab.takuman.me/api/layerednoteitem/delete/' + this.state.note_hash + '/' + item_hash + '/', headers)
    .then(res => {
      //console.log('itemDelete: 削除しました。');
    })
    .catch(err => console.log(err))
  }



  /************************* 
   *                       * 
   *     キホン_キノウ     * 
   *                       * 
   *************************/

  resetNote = () => {
    //console.log('resetNote');

    // 項目をすべて削除
    var items = this.state.items.slice();
    for (var i=0; i<items.length; i++) {
      console.log(items[i].hash);
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      axios.delete('https://lab.takuman.me/api/layerednoteitem/delete/' + this.state.note_hash + '/' + items[i].hash + '/', headers)
      .then(res => {
        //console.log('itemDelete: 削除しました。');
      })
      .catch(err => console.log(err))
    }

    // 新規で１つだけ項目を追加 
    items = [];
    // ユニークネスなハッシュを作成
    let item_hash = this.itemHashCreate();
    items.splice(0, 0, {hash:item_hash, parent:'', block:0, layer:0, order:0, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:true, last:true});
    this.itemCreate(items, item_hash);
    this.setState({ items:items, });

    this.noteUpdate();

    this.props.handleFlashMessage('リセットしました');
  }


  removeNote = () => {
    //console.log('removeNote');
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.delete('https://lab.takuman.me/api/layerednote/delete/' + this.state.note_hash + '/', headers)
    .then(res => {
      //console.log('removeNote: 削除しました。');
      this.props.handleFlashMessage('削除しました');
      let pathItems = window.location.pathname.slice(1).split('/');
      if (this.props.full) {
        History.push('/layerednote');
      } else if (this.props.one) {
        History.push('/layerednote/' + pathItems[1]);
      } else if (this.props.two) {
        History.push('/' + pathItems[0] + '/layerednote');
      }
    })
    .catch(err => console.log(err))

    this.noteUpdate();
  }


  // 編集する項目を選択
  itemNameFocus = (event, item_hash) => {
    console.log('itemNameFocus');
    var items = this.state.items.slice();
    const focusItemIndex = items.findIndex(item => item.hash == item_hash);
    if (items[focusItemIndex].name != '') {
      //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, });
      this.setState({ blurMethod:true, }, () => {
        this.itemAutoFocus('name', item_hash);
      });
    }
    // もし空白の項目があるのであれば削除
    const blankItemIndex = items.findIndex(item => item.name == '' && item.description == '');
    if (blankItemIndex != -1) {
      this.removeItem(event, items[blankItemIndex].hash);
    }
  }


  // 編集する説明文を選択
  itemDescriptionFocus = (event, item_hash) => {
    console.log('itemDescriptionFocus');

    if (event.target.tagName != 'A') {
      var items = this.state.items.slice();
      const focusItemIndex = items.findIndex(item => item.hash == item_hash);

      if (items[focusItemIndex].name != '') {
        //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'description', blurMethod:true, orderChange:false });
        //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'description', blurMethod:true, orderChange:false }, () => {
        this.setState({ blurMethod:true, orderChange:false }, () => {
          console.log('selection');
          console.log(window.getSelection());
          this.itemAutoFocus('description', item_hash);
        });
      }
      // もし空白の項目があるのであれば削除
      const blankItemIndex = items.findIndex(item => item.name == '' && item.description == '');
      if (blankItemIndex != -1) {
        this.removeItem(event, items[blankItemIndex].hash);
      }
    }
  }


  // タイトルを更新
  noteTitleChange = (event) => {
    //console.log('noteTitleChange'); 

    this.textareaHeightAdjustment('note-title');
    this.setState({ title:event.target.value });

    var params = { title:event.target.value }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/layerednote/update/' + this.state.note_hash + '/', params, headers)
    .then(res => {
      if (res.data['hash']) {
        //console.log('保存しました');
      } else {
        //console.log('保存できませんでした。');
      }
    })
    .catch(err => console.log(err))
  }

/*
  noteTitleChange = (event) => {
    //console.log('noteTitleChange'); 
    this.setState({ title:event.target.value });

    var params = { title:event.target.value }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/layerednote/update/' + this.state.note_hash + '/', params, headers)
    .then(res => {
      if (res.data['hash']) {
        //console.log('保存しました');
      } else {
        //console.log('保存できませんでした。');
      }
    })
    .catch(err => console.log(err))
  }
*/

  noteTitleSubmit = (event) => {
    //console.log('noteTitleSubmit');
    event.preventDefault();
    document.activeElement.blur();
  }

  // 名前を更新
  itemNameChange = (event, item_hash) => {
/*
      orderChange:false, orderItemHash:'',

      palettePop:false,
      removeItemConfirm:false,
      lineHeight:false,
      menuOpen:false,

      selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', replaceDescriptionBuffer:'', selectTextMenuHover:false,
*/
    if (this.state.orderChange == false && this.state.palettePop == false && this.state.removeItemConfirm == false && this.state.menuOpen == false && this.state.selectTextMenu == false) {
      var items = this.state.items.slice();
      const changeItemIndex = items.findIndex(item => item.hash == item_hash);
      items[changeItemIndex].name = event.target.value;
      this.setState({ items:items, orderChange:false, focus:false, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true });
      this.noteUpdate();
    }
  }

  // description内でカーソルが移動、または文字を選択状態にしたときのメソッド
  itemDescriptionSelect = (event, item_hash) => {
    console.log('itemDescriptionSelect');

    var items = this.state.items.slice();
    const insertItemIndex = items.findIndex(item => item.hash == item_hash);

    var selectText = window.getSelection().toString();
    if (event.target.className.split(' ').some(className => className == 'description') && 0 < selectText.length) {
      // 文字を選択した場合
      console.log('文字を選択した場合');

      // 選択した文字列を取得
      let selectText = window.getSelection().toString();
      console.log('selectText: ' + selectText);


      let selectStartPos, selectEndPos;
      const sel = window.getSelection();
      const range = sel.getRangeAt(0);

      // 最初に選択した文字の座標
      //console.log('最初に選択した文字の座標');
      const startTextRange = document.createRange();
      startTextRange.setStart(range.startContainer, range.startOffset);
      startTextRange.setEnd(range.startContainer, range.startOffset + 1);
      selectStartPos = startTextRange.getBoundingClientRect();
      //console.log('selectStartPos');
      //console.log(selectStartPos);

      // 最後に選択した文字の座標
      //console.log('最後に選択した文字の座標');
      const endTextRange = document.createRange();
      endTextRange.setStart(range.endContainer, range.endOffset - 1);
      endTextRange.setEnd(range.endContainer, range.endOffset);
      selectEndPos = endTextRange.getBoundingClientRect();
      //console.log('selectEndPos');
      //console.log(selectEndPos);


      let descriptionDom = document.getElementById('description-' + item_hash);
      //console.log('descriptionDom');
      //console.log(descriptionDom);
      //console.dir(descriptionDom);
      let replaceDescriptionBuffer = this.selectTextMultiLayereLoopCheck(descriptionDom, range);

      //let replaceDescriptionBuffer = '';
      //for (var i=0; i<descriptionDom.childNodes.length; i++) {
        //replaceDescriptionBuffer = this.selectTextMultiLayereLoopCheck(descriptionDom.childNodes[i], range);
      //}

      //console.log('items[insertItemIndex].description');
      //console.log(items[insertItemIndex].description);
      console.log('replaceDescriptionBuffer');
      console.log(replaceDescriptionBuffer);


      // 選択したテキストが含まれるnodeを取得できなかった、つまり複数のnodeにまたがって選択していたということなので、その場合はテキスト選択ポップアップメニューを表示しない。
      let selectTextMenu, pos;
      if (selectStartPos && selectEndPos) {
        // テキスト選択ポップアップを表示
        selectTextMenu = true;
        let linkEditPopupWidth = 40;
        //console.log('window.pageYOffset: ' + window.pageYOffset);
        let scrollTop = this.getScrollTop();
        //console.log('scrollTop');
        //console.log(scrollTop);

        if (selectStartPos.top == selectEndPos.top && selectStartPos.bottom == selectEndPos.bottom) {
          pos = {'top':(scrollTop + selectStartPos.top) + 'px', 'left':selectStartPos.left + 'px', 'right':'auto', 'bottom':(scrollTop + selectStartPos.bottom) + 'px'};
          if (selectStartPos.left + linkEditPopupWidth <= window.innerWidth) {
            // テキスト選択ポップアップメニューが画面内におさまる
            pos.left = selectStartPos.left + 'px';
            pos.right = 'auto';
          } else {
            // テキスト選択ポップアップメニューが画面を右にはみ出る
            pos.left = 'auto';
            pos.right = (window.innerWidth - selectStartPos.right) + 'px';
          }
        } else if (selectStartPos.bottom < selectEndPos.bottom) {
          pos = {'top':(scrollTop + selectEndPos.top) + 'px', 'left':'auto', 'right':(window.innerWidth - selectEndPos.right) + 'px', 'bottom':(scrollTop + selectEndPos.bottom) + 'px'};
        }


      } else {
        // テキスト選択ポップアップを非表示
        selectTextMenu = false;
        pos = [];
      }

      this.setState({ selectTextMenu:selectTextMenu, selectTextMenuPos:pos, selectTextMenuType:'', selectTextUrl:'', replaceDescriptionBuffer:replaceDescriptionBuffer, });

    } else {
      // テキストの選択ではなく、ただのカーソル移動の場合
      console.log('カーソル移動');

      // カーソルの位置がaタグ内の場合はurl編集ポップアップを表示し、それ以外の場合はテキスト選択ポップアップメニューを表示しない
      if (window.getSelection().focusNode.parentNode.nodeName == 'A') {
        // カーソルの位置がaタグ内の場合
        // urlを編集するときのために、いまカーソルがあっているaタグの部分をselect-textクラスのspanタグに置き換えた文字列をreplaceDescriptionBufferに格納して保持しておく

        let cursorPos;
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        // 最初に選択した文字の座標
        console.log('最初に選択した文字の座標');
        const cursorRange = document.createRange();
        cursorRange.setStart(range.startContainer, range.startOffset);
        //cursorRange.setEnd(range.startContainer, range.startOffset + 1);
        console.log('range');
        console.dir(range);
        console.log('cursorRange');
        console.log(cursorRange);
        if (range.endOffset < range.startOffset + 1) {
          cursorRange.setEnd(range.startContainer, range.endOffset);
        } else {
          cursorRange.setEnd(range.startContainer, range.startOffset + 1);
        }
        cursorPos = cursorRange.getBoundingClientRect();
        console.log('cursorPos');
        console.log(cursorPos);

        let selectTextMenu, pos;
        // テキスト選択ポップアップを表示
        selectTextMenu = true;
        let linkEditPopupWidth = 370;
        console.log('window.pageYOffset: ' + window.pageYOffset);

        let scrollTop = this.getScrollTop();
        console.log('scrollTop');
        console.log(scrollTop);

        pos = {'top':(scrollTop + cursorPos.top) + 'px', 'left':cursorPos.left + 'px', 'right':'auto', 'bottom':(scrollTop + cursorPos.bottom) + 'px'};
        if (cursorPos.left + linkEditPopupWidth <= window.innerWidth) {
          // テキスト選択ポップアップメニューが画面内におさまる
          pos.left = cursorPos.left + 'px';
          pos.right = 'auto';
        } else {
          // テキスト選択ポップアップメニューが画面を右にはみ出る
          pos.left = 'auto';
          pos.right = (window.innerWidth - cursorPos.right) + 'px';
        }


        let url = window.getSelection().focusNode.parentNode.href;
        let id = window.getSelection().focusNode.parentNode.id;
        let selectTextUrl = url; // リンク挿入の編集ポップアップの初期値で入れる値
        let regexpUrl = url.replace(/[-\/\\^$*+?.()|\[\]{}]/g, '\\$&'); // 正規表現に組み込むためにメタ文字をエスケープしてある。
        let regexp = new RegExp('<a.[^<]*?id=.' + id + '.*?>.*?<\/a>', '');
        let urlTags = items[insertItemIndex].description.match(regexp);
        let replaceDescriptionBuffer = '';
        console.log('select-textクラスのspanタグで選択したテキストを挟む');
        if (urlTags) {
          let selectText = window.getSelection().focusNode.parentNode.textContent;
          let replaceText = '<span class="select-text">' + selectText + '</span>';
          replaceDescriptionBuffer = items[insertItemIndex].description.replace(urlTags, replaceText);
        }
        console.log(replaceDescriptionBuffer);
        this.setState({ selectTextMenu:true, selectTextMenuPos:pos, selectTextMenuType:'url', selectTextUrl:selectTextUrl, replaceDescriptionBuffer:replaceDescriptionBuffer, });
      } else {
        // カーソルの位置がaタグ以外の場合
        // テキスト選択ポップアップメニューを非表示にする
        this.setState({ selectTextMenu:false, selectTextMenuFloat:'left', selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', });
      }

    }
  }


  selectTextMultiLayereLoopCheck = (dom, range) => {
    //console.log('selectTextMultiLayereLoopCheck');
    //console.log(dom);
    //console.dir(dom);

    let outerHTML = '';
    if (dom == range.startContainer && dom == range.endContainer) {
      // startContainerかつendContainer
      if (dom.nodeName == '#text') {
        outerHTML += dom.textContent.substring(0,range.startOffset) + '<span class="select-text">' + dom.textContent.substring(range.startOffset, range.endOffset) + '</span>' + dom.textContent.substring(range.endOffset, dom.textContent.length);
      } else {
        outerHTML += dom.outerHTML.substring(0,range.startOffset) + '<span class="select-text">' + dom.outerHTML.substring(range.startOffset, range.endOffset) + '</span>' + dom.outerHTML.substring(range.endOffset, dom.outerHTML.length);
      }
    } else if (dom == range.startContainer) {
      // startContainer
      if (dom.nodeName == '#text') {
        outerHTML += dom.textContent.substr(0,range.startOffset) + '<span class="select-text">' + dom.textContent.substr(range.startOffset, dom.textContent.length);
      } else {
        outerHTML += dom.outerHTML.substr(0,range.startOffset) + '<span class="select-text">' + dom.outerHTML.substr(range.startOffset, dom.outerHTML.length);
      }
    } else if (dom == range.endContainer) {
      // endContainer
      if (dom.nodeName == '#text') {
        outerHTML += dom.textContent.substr(0, range.endOffset) + '</span>' + dom.textContent.substr(range.endOffset, dom.textContent.length);
      } else {
        outerHTML += dom.outerHTML.substr(0, range.endOffset) + '</span>' + dom.outerHTML.substr(range.endOffset, dom.outerHTML.length);
      }
    } else if (0 < dom.childNodes.length) {
      // childNodesが存在するのでdomのタグの間でさらにchildNodesをselectTextMultiLayereLoopCheckメソッドでチェックする
      //console.log('childNodesが存在するのでdomのタグの間でさらにchildNodesをselectTextMultiLayereLoopCheckメソッドでチェックする');
      //console.log(dom.classList);
      if (dom.classList.contains('description')) {
        //console.log('descriptionクラスあり');
      } else {
        //console.log('descriptionクラスなし');
      }


      if (dom.classList.contains('description') || (dom.childNodes.length == 1 && dom.childNodes[0].nodeName == 'DIV')) {
        // domのタグ不要
        //console.log('domのタグ不要');
        for (var i=0; i<dom.childNodes.length; i++) {
          outerHTML += this.selectTextMultiLayereLoopCheck(dom.childNodes[i], range);
        }
      } else {
        // domのタグで挟む
        //console.log('domのタグで挟む');

        let startTag, endTag;
        let regexp;
        if (dom.nodeName == 'A') {
          regexp = new RegExp('<a.[^<]*?.*?href=.*?>', '');
          endTag = '</a>';
        } else if (dom.nodeName == 'SPAN' && dom.classList.contains('select-text')) {
          regexp = new RegExp('<span.[^(<|select-text)]*?>', '');
          //regexp = new RegExp('<span>|<span.[^<]*?>', '');
          endTag = '</span>';
        //} else if (dom.nodeName == 'SPAN') {
          //regexp = new RegExp('<span.[^(<|select-text)]*?>', '');
          //regexp = new RegExp('<span>|<span.[^<]*?>', '');
          //endTag = '</span>';
        } else if (dom.nodeName == 'DIV') {
          regexp = new RegExp('<div>|<div.[^<]*?>', '');
          endTag = '</div>';
        }
        startTag = dom.outerHTML.match(regexp); // <a id="hoge" href="https://~~~~"> の部分を取得

        if (startTag) { outerHTML += startTag; }
        for (var i=0; i<dom.childNodes.length; i++) {
          outerHTML += this.selectTextMultiLayereLoopCheck(dom.childNodes[i], range);
        }
        if (endTag) { outerHTML += endTag; }

      }





/*
      let startTag, endTag;
      let regexp;
      //if (dom.childNodes.length != 1 || dom.childNodes[0].nodeName != 'DIV') { // <div><div>hogehoge</div></div>みたいに無駄にdivタグを重ねている場合はタグを追加しない
      //if (dom.childNodes.length != 1 || dom.childNodes[0].nodeName != 'DIV' || dom.classList.contains('description')) { // <div><div>hogehoge</div></div>みたいに無駄にdivタグを重ねている場合はタグを追加しない
      //if (dom.childNodes.length != 1 || dom.childNodes[0].nodeName != 'DIV' || dom.classList.contains('description') == false) { // <div><div>hogehoge</div></div>みたいに無駄にdivタグを重ねている場合はタグを追加しない
      if ((dom.childNodes.length != 1 || dom.childNodes[0].nodeName != 'DIV') && dom.classList.contains('description') == false) { // <div><div>hogehoge</div></div>みたいに無駄にdivタグを重ねている場合はタグを追加しない
        if (dom.nodeName == 'A') {
          regexp = new RegExp('<a.[^<]*?.*?href=.*?>', '');
          endTag = '</a>';
        } else if (dom.nodeName == 'SPAN') {
          regexp = new RegExp('<span.[^(<|select-text)]*?>', '');
          //regexp = new RegExp('<span>|<span.[^<]*?>', '');
          endTag = '</span>';
        } else if (dom.nodeName == 'DIV') {
          regexp = new RegExp('<div>|<div.[^<]*?>', '');
          endTag = '</div>';
        }
      }
      startTag = dom.outerHTML.match(regexp); // <a id="hoge" href="https://~~~~"> の部分を取得

      if (startTag) { outerHTML += startTag; }

      for (var i=0; i<dom.childNodes.length; i++) {
        outerHTML += this.selectTextMultiLayereLoopCheck(dom.childNodes[i], range);
      }

      if (endTag) { outerHTML += endTag; }
*/

    } else if (dom.childNodes.length == 0) {
      //console.log('childNodes.length == 0');
      if (dom.nodeName == '#text') {
        outerHTML += dom.textContent;
      } else if (dom.nodeName == 'BR') {
        outerHTML += dom.outerHTML;
      } else {
        if (0 < dom.innerText.length) { // 中身が空の要素ではないのであれば追加 ------------------------------------------
          outerHTML += dom.outerHTML;
        }
      }
    }

    return outerHTML;
  }



  // descriptionにホバーしたときにそれがdescription内のaタグ上かそうでないかでcontenteditableのbooleanを切り替える
  itemDescriptionMouseOver = (event, item_hash) => {
    console.log('itemDescriptionMouseOver');
    if (this.state.focusFieldHash != item_hash) {
      var items = this.state.items.slice();
      const hoverItemIndex = items.findIndex(item => item.hash == item_hash);

      // description内のaタグはフォーカスが外れたらcontenteditable="true"に変更して、リンクをクリックしたら挿入してあるリンク先に飛べるようにする。
      if (event.target.tagName == 'A') {
        // aタグをhoverした場合
        //console.log('aタグをホバーしている');
        // ほかのLayeredNoteからコピペしてきたものはブラウザの仕様により、contenteditable属性が破棄されているので、
        // ホバーしたaタグにcontenteditableがない場合は付与した状態に置き換える。
        let ceRegexp = new RegExp('<a.[^<]*?.*?contenteditable=.*?.*?href=.*?>', '');
        let ce = event.target.outerHTML.match(ceRegexp);
        //console.log('--------------------------------------------');
        //console.log(items[hoverItemIndex].description);
        if (ce) {
          // contenteditable属性あり
          //console.log('contenteditableの属性あり');
          let regexp = /contenteditable=.true./g;
          items[hoverItemIndex].description = items[hoverItemIndex].description.replace(regexp, 'contenteditable="false"');
        } else {
          // contenteditable属性なし。よって、contenteditable属性を付与。
          //console.log('contenteditableの属性なし');
          // ホバーしたaタグにcontenteditableをつけたものを生成
          let regexp = /<a /g;
          let ceAtag = event.target.outerHTML.replace(regexp, '<a contenteditable="false" ');
          // もとのaタグ(contenteditableなし)からcontenteditableつきのaタグにreplace
          items[hoverItemIndex].description = items[hoverItemIndex].description.replace(event.target.outerHTML, ceAtag);
        }
        //console.log(' ');
        //console.log(items[hoverItemIndex].description);
        //console.log('--------------------------------------------');

      } else {
        // aタグ以外をhoverした場合
        //console.log('aタグ以外をホバーしている');
        let regexp = /contenteditable=.false./g;
        //console.log('--------------------------------------------');
        //console.log(items[hoverItemIndex].description);
        items[hoverItemIndex].description = items[hoverItemIndex].description.replace(regexp, 'contenteditable="true"');
        //console.log(items[hoverItemIndex].description);
        //console.log('--------------------------------------------');
      }
      //console.log('description');
      //console.log(items[hoverItemIndex].description);
      this.setState({ items:items, });
    }
  }

  // descriptionのホバーを解除したときにcontenteditableのbooleanをtrueに切り替える
  itemDescriptionMouseLeave = (event, item_hash) => {
    console.log('itemDescriptionMouseLeave');
    var items = this.state.items.slice();
    const leaveItemIndex = items.findIndex(item => item.hash == item_hash);
    let regexp = /contenteditable=.false./g;
    items[leaveItemIndex].description = items[leaveItemIndex].description.replace(regexp, 'contenteditable="true"');
    //console.log('description');
    //console.log(items[leaveItemIndex].description);
    this.setState({ items:items, });
  }

  // 説明文の更新
  itemDescriptionChange = (event, item_hash) => {
    //console.log('change-----------------------');

    //if (this.state.orderChange == false && this.state.palettePop == false && this.state.removeItemConfirm == false && this.state.menuOpen == false && this.state.selectTextMenu == false) {
    if (this.state.orderChange == false && this.state.palettePop == false && this.state.removeItemConfirm == false && this.state.menuOpen == false) {

      var items = this.state.items.slice();
      const changeItemIndex = items.findIndex(item => item.hash == item_hash);
      items[changeItemIndex].description = event.target.value;

      // コピペした要素はstyleがついている場合があり、邪魔なので取り除く
      let regexp = / style=.[^>]*?.*?"/g;
      items[changeItemIndex].description = event.target.value.replace(regexp, '');

      this.setState({ items:items, orderChange:false, focus:false, focusFieldHash:item_hash, focusFieldType:'description', blurMethod:true, });
      this.noteUpdate();
    }
  }

  descriptionToggle = (item_hash) => {
    let items = this.state.items.slice();
    let toggleItemIndex = items.findIndex(item => item.hash == item_hash);
    let description_show = true;
    if (items[toggleItemIndex].description_show) {
      description_show = false;
    }
    items[toggleItemIndex].description_show = description_show;

    this.setState({ items:items, }, () => {
      if (description_show == false) {
        var element = document.getElementById('name-' + item_hash); // 要素を取得
        var rect = element.getBoundingClientRect(); // 取得した要素の座標値を取得
        var scrollTop = rect.top + window.pageYOffset; // ページ全体から見た場合の縦位置を取得
        var clientHeight = document.documentElement.clientHeight; // 表示画面の高さを取得 
        scrollTop = scrollTop - (clientHeight/4); // 移動する項目を画面の中央付近にもってきたいので表示画面の高さの半分を足す。 
        //window.scrollTo(0,scrollTop);
        //this.setState({ scroll:false, scrollItemHash:'', });
        this.itemAutoFocus(undefined, undefined, scrollTop);
      }

      this.itemsUpdate();
    });
  }

  // 新規項目の追加
  handleSubmit = (event, item_hash, addLayer) => {
    //console.log('submit: ' + addLayer); 
    event.preventDefault();
    if (this.state.orderChange == false && this.state.palettePop == false && this.state.removeItemConfirm == false && this.state.menuOpen == false && this.state.selectTextMenu == false) {

    var items = this.state.items.slice();
    // submitした項目のインデックスを取得
    const submitItemIndex = items.findIndex(item => item.hash === item_hash);
    let parent = items[submitItemIndex].parent;
    let block = items[submitItemIndex].block;
    let layer = items[submitItemIndex].layer;
    let order = items[submitItemIndex].order;
    let gradation = items[submitItemIndex].gradation;
    let layer_percentage = items[submitItemIndex].layer_percentage;
    let name = items[submitItemIndex].name;


    // 項目名が空でないならばorderを調整したり、items配列に新しい要素を追加したりする。
    if (name != '') {

      /********************************* 
       *                               * 
       *     items配列の順番を調整     * 
       *                               * 
       *********************************/

      if (addLayer == 'same') {
        // submitした項目よりインデックスが後ろの同階層項目すべてのorderを１つずらす。
        items = items.map((item, index) => {
          if (submitItemIndex < index && item.layer == layer) {
            item.order += 1;
          }
          if (layer == 0 && item.block > block) {
            item.block += 1;
          }
          return item;
        });
      } else if (addLayer == 'lower') {
        // 下位項目を追加時
        // submitした項目の１つ下の階層のorder最後尾に項目を追加するだけなので順番の調整は不要。
      } else {
        // 上位項目を追加時

        if (layer == 0) {
          // submit下項目が属するblock全体のlayerを１つずつずらす。
          items.map((item, index) => {
            if (item.block == block) {
              item.layer += 1;
            }
          });

        } else {
          // submitした項目の子孫項目すべてのlayerを１つずらす。
          items.map((item, index) => {
            if (item.block == block && submitItemIndex <= index) {
              item.layer += 1;
            }
          });

        }
        // submit項目は自動的に１つ下の階層に繰り下げられてorderは必ず0をとるので、orderを0に更新。
        items[submitItemIndex].order = 0;
      }


      /********************************** 
       *                                * 
       *     項目フィールドを追加     *
       *                                *
       **********************************/

      // ユニークネスなハッシュを作成
      let item_hash = this.itemHashCreate();

      if (addLayer == 'same') {
        if (layer == 0) {
          // 新しい項目ブロックとなる項目を追加。
          items.splice(submitItemIndex+1, 0, {hash:item_hash, parent:'', block:block+1, layer:0, order:order+1, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:false, last:false});
        } else {
          let parent = items[submitItemIndex].parent; // submitした項目と同階層の項目を追加するので、同じハッシュを持たせるために取得
          items.splice(submitItemIndex+1, 0, {hash:item_hash, parent:parent, block:block, layer:layer, order:order+1, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:gradation, layer_percentage:layer_percentage, first:false, last:false});
        }
      } else if (addLayer == 'lower') {
        let parent = items[submitItemIndex].hash; // 親項目のハッシュを取得
        items.splice(submitItemIndex+1, 0, {hash:item_hash, parent:parent, block:block, layer:layer+1, order:0, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:false, last:false});
      } else {
        if (layer == 0) {
          items.splice(submitItemIndex, 0, {hash:item_hash, parent:'', block:block, layer:layer, order:order, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:gradation, layer_percentage:layer_percentage, first:false, last:false});
        } else {
          items.splice(submitItemIndex, 0, {hash:item_hash, parent:parent, block:block, layer:layer, order:order, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:gradation, layer_percentage:layer_percentage, first:false, last:false});
        }
        items[submitItemIndex+1].parent = item_hash; // 新規で追加する上位項目のハッシュを１つ下の項目のparentに持たせておく 
      }


      // orderの調整
      items = this.orderAdjustment(items);

      // グラデーション率の調整
      if (addLayer != 'same') {
        items = this.gradationAdjustment(items, block);
      }


      // block単位で最前列の項目はfirst:true、最後尾の項目はlast:trueにする。
      items = this.firstLast(items);

      // stateを更新
      //console.log(items); 
      //this.setState({ items:items, focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, orderChange:false, lineHeight:true, });
      this.setState({ items:items, blurMethod:true, orderChange:false, lineHeight:true, }, () => {
        this.itemAutoFocus('name', item_hash);
      });

      this.itemCreate(items, item_hash);
      this.noteUpdate();
    }

    }
  }

  // 新規項目をblockの最後尾に追加
  addItem = (event) => {
    //console.log('addItem');
    event.preventDefault();
    var items = this.state.items.slice();

    // 項目フィールドを追加
    let item_hash = this.itemHashCreate(); // ユニークネスなハッシュを作成 
    let block = items[items.length-1].block + 1;
    let order = block; // addItemで追加する項目はlayer0なので、blockとorderの値はおなじになる。
    items.splice(items.length, 0, {hash:item_hash, parent:'', block:block, layer:0, order:order, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:true, last:true});

    // stateを更新
    //console.log(items); 
    //this.setState({ items:items, focusFieldIndex:undefined, blurMethod:true, orderChange:false, focus:true, focusFieldHash:item_hash, focusFieldType:'name', lineHeight:true, });
    this.setState({ items:items, focusFieldIndex:undefined, blurMethod:true, orderChange:false, lineHeight:true, }, () => {
      this.itemAutoFocus('name', item_hash);
    });

    this.itemCreate(items, item_hash);

    this.noteUpdate();
  }


  // itemBlurのキャンセル(中の処理を実行させない)
  cancelBlur = (event) => {
    console.log('cancelBlur');
    this.setState({ blurMethod:false, });
  }

  // focusが外れたときに空の項目を排除したり、blockやorderやgradationを調整したりする。
  itemBlur = (event, item_hash) => {
    console.log('itemBlurrrrrrrrrrr#########');
    console.log('blurMethod: ' + this.state.blurMethod);

    var items = this.state.items.slice();
    const blurItemIndex = items.findIndex(item => item.hash == item_hash);

    //console.log('description');
    //console.log(items[blurItemIndex].description);
    //console.log('replaceDescriptionBuffer');
    //console.log(this.state.replaceDescriptionBuffer);

    if (this.state.blurMethod && this.state.selectTextMenu == false) {
      // blurMethodがtrueで、テキスト選択ポップアップメニューも非表示の場合

      let block = items[blurItemIndex].block;
      let layer = items[blurItemIndex].layer;
      let order = items[blurItemIndex].order;
      let name = items[blurItemIndex].name;

      var childItem = items.some(item => item.parent == item_hash);
      // nameが空で子項目が存在する場合は削除すると面倒なので、nameに空白を挿入する。
      if (name == '' && childItem) { items[blurItemIndex].name = ' '; }

      // focusがはずれた項目のnameが空白であれば配列から取り除き、同階層のorderとグラデーション率を調整。
      // itemsが２個以下であれば空白であっても削除しない。
      // 子項目が存在する場合も削除しない。
      if (name == '' && items.length > 1 && childItem == false) {
        // 項目を削除する場合

        /********************************* 
         *                               * 
         *     items配列の順番を調整     * 
         *                               * 
         *********************************/

        this.itemDelete(items, item_hash);
        items.splice(blurItemIndex, 1); // focusがはずれた要素の名前が空なので配列から削除
        items = items.map((item, index) => {
          if (item.layer == layer && item.order > order) {
            item.order -= 1;
          }
          if (layer == 0 && item.block > block) {
            item.block -= 1;
          }
          return item;
        });


        // orderの調整
        items = this.orderAdjustment(items);

        // グラデーション率の調整
        items = this.gradationAdjustment(items, block);

        this.setState({ items:items, orderChange:false, palettePop:false, removeItemConfirm:false, focus:false, focusFieldHash:'', focusFieldType:'', lineHeight:true, selectTextMenu:false, replaceDescriptionBuffer:'', selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', });
      } else {
        // 項目を削除しない場合
        console.log('削除しない場合');

        // description内のaタグはフォーカスが外れたらcontenteditable="true"に変更して、リンクをクリックしたら挿入してあるリンク先に飛べるようにする。
        let regexp = /contenteditable=.false./g;
        items[blurItemIndex].description = items[blurItemIndex].description.replace(regexp, 'contenteditable="true"');
        //console.log('description');
        //console.log(items[blurItemIndex].description);

        this.setState({ orderChange:false, palettePop:false, removeItemConfirm:false, focus:false, focusFieldHash:'', focusFieldType:'', lineHeight:true, replaceDescriptionBuffer:'', selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', });
        this.cancelOrderItem();
      }

      //console.log(items);
    }

    // リンク挿入のポップアップが表示されているのであれば、選択箇所をspanタグで識別してあるreplaceDescriptionBufferの値にdescriptionを更新する。
    if (this.state.selectTextMenu && this.state.selectTextMenuType == 'url') {
      if (this.state.selectTextMenuHover) {
        // テキスト選択ポップアップをhoverしており、urlを編集する場合は、replaceDescriptionBuffer(aタグをselect-textクラスのspanタグに置換したやつ)でdescriptionを上書きする
        console.log('selectTextUrlが空白');
        let replaceDescriptionBuffer = items[blurItemIndex].description;
        console.log('replaceDescriptionBuffer select-textではなくaタグのはず');
        console.log(replaceDescriptionBuffer);
        items[blurItemIndex].description = this.state.replaceDescriptionBuffer;
        console.log('description');
        console.log(items[blurItemIndex].description);
        this.setState({ items:items, replaceDescriptionBuffer:replaceDescriptionBuffer, });
        //this.setState({ items:items, });
      } else {
        // テキスト選択ポップアップをhoverしているわけではないのでふつうにテキスト選択ポップアップを非表示にする
        console.log('selectTextUrlが空白ではない');
        this.setState({ orderChange:false, palettePop:false, removeItemConfirm:false, focus:false, focusFieldHash:'', focusFieldType:'', lineHeight:true, selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', replaceDescriptionBuffer:'', });
      }
    }

  }



  /********************************* 
   *                               * 
   *     オプション_キノウ     * 
   *                               * 
   *********************************/

  // 背景色を変更するポップアップの表示・非表示
  dripItem = (event, item_hash) => {
    console.log('dripItem');



    var items = this.state.items.slice();
    const dripItemIndex = items.findIndex(item => item.hash == item_hash);
    if (this.state.palettePop) {
      this.setState({ blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, });
    } else {
      //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, orderChange:false, palettePop:true, removeItemConfirm:false, });
      this.setState({ blurMethod:true, orderChange:false, palettePop:true, removeItemConfirm:false, }, () => {
        let scrollTop = this.getScrollTop();
        this.itemAutoFocus('name', item_hash, scrollTop);
      });
      //this.setState({ orderChange:false, palettePop:true, removeItemConfirm:false, });
    }
    this.cancelOrderItem();
  }

  // 変更する背景色を選択
  selectPaletteColor = (event, item_hash, color, background) => {
    var items = this.state.items.slice();
    const selectItemIndex = items.findIndex(item => item.hash == item_hash);
    items[selectItemIndex].color = color;
    items[selectItemIndex].background = background;
    //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, });
    this.setState({ blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, }, () => {
      let scrollTop = this.getScrollTop();
      this.itemAutoFocus('name', item_hash, scrollTop);
    });
    this.noteUpdate();
  }

  // 削除確認のポップアップの表示・非表示
  removeItemConfirm = (event, item_hash) => {
    //console.log('removeItemConfirm');
    var items = this.state.items.slice();
    const removeItemIndex = items.findIndex(item => item.hash == item_hash);
    if (this.state.removeItemConfirm) {
      this.setState({ blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false });
    } else {
      //this.setState({ focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:true, });
      this.setState({ blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:true, }, () => {
        let scrollTop = this.getScrollTop();
        this.itemAutoFocus('name', item_hash, scrollTop);
      });
    }
    this.cancelOrderItem();
  }


  // 削除
  removeItem = (event, item_hash) => {
    //console.log('removeItem');
    var items = this.state.items.slice();
    let removeItemIndex = items.findIndex(item => item.hash == item_hash);
    let layer = items[removeItemIndex].layer;
    let order = items[removeItemIndex].order;
    let block = items[removeItemIndex].block;


    /******************************************************* 
     *                                                     * 
     *    removeする項目とその子孫項目すべてを削除     *
     *                                                     * 
     *******************************************************/

    const progenyItemIndex = this.progenyItemIndex(items, removeItemIndex, layer);
    let removeCount = progenyItemIndex - removeItemIndex + 1; // 削除する項目の個数を算出
    for (var i=0; i<removeCount; i++) {
      if (items[removeItemIndex+i].hash != 'order-select') {
        this.itemDelete(items, items[removeItemIndex+i].hash);
      }
    }
    items.splice(removeItemIndex, removeCount);



    /********************************* 
     *                               * 
     *     items配列の順番を調整     * 
     *                               * 
     *********************************/

    items = items.map((item, index) => {
      if (item.layer == layer && item.order > order) {
        item.order -= 1;
      }
      if (layer == 0 && item.block > block) {
        item.block -= 1;
      }
      return item;
    });

    // もし項目がすべてなくなったのであれば、まっさらな項目を１つ追加
    if (items.length == 0) {
      // ユニークネスなハッシュを作成
      let item_hash = this.itemHashCreate();
      items.splice(0, 0, {hash:item_hash, parent:'', block:0, layer:0, order:0, name:'', description:'', description_show:true, descriptionLines:1, color:'#c8c8c8', background:'#151515', gradation:0, layer_percentage:'0%', first:true, last:true});
      this.itemCreate(items, item_hash);
    }

    // グラデーション率の調整
    items = this.gradationAdjustment(items, block);

    // firstとlastの値を更新
    this.firstLast(items);

    // stateを更新
    //console.log(items); 
    this.setState({ items:items, blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, lineHeight:true, });

    this.noteUpdate();
  }

  // 順番入れ替えモードへの切り替えとキャンセル
  orderItem = (event, item_hash) => {
    //console.log('orderItem');

    var items = this.state.items.slice();
    let orderItemIndex = items.findIndex(item => item.hash == item_hash);
    let parent = items[orderItemIndex].parent;
    let block = items[orderItemIndex].block;
    let layer = items[orderItemIndex].layer;
    let order = items[orderItemIndex].order;

    var orderitems = this.state.items.slice();

    if (items.length > 1) {
      if (layer == 0) {

        // 同階層の各項目の手前にorder-selectを追加(orderしようとしている項目以外)。そして、最後尾のブロック数も取得。
        var maxBlock = 0;
        var maxOrder = 0;
        orderitems.map((item, index) => {
          if (item.layer == layer && item.hash != 'order-select') {
            const orderSelectIndex = items.findIndex(selectitem => selectitem.hash == item.hash);

            // 同階層の各項目の手前にorder-selectを追加。
            items.splice(orderSelectIndex, 0, {hash:'order-select', parent:parent, block:item.block, layer:item.layer, order:item.order, name:'ここへ移動する', description:'', description_show:false, descriptionLines:1, color:'#c8c8c8', background:'none', gradation:0, layer_percentage:'0%', first:true, last:true});

            if (maxBlock < item.block) {
              maxBlock = item.block;
            }
            if (maxOrder < item.order) {
              maxOrder = item.order;
            }
          }
        });

      } else {

        // 同階層の各項目の手前にorder-selectを追加(orderしようとしている項目以外)。そして、最後尾もブロック数も取得。
        var maxBlock = 0;
        var maxOrder = 0;
        orderitems.map((item, index) => {
          if (item.block == block && item.layer == layer && item.parent == parent && item.hash != 'order-select') {
            const orderSelectIndex = items.findIndex(selectitem => selectitem.hash == item.hash);
            // 同階層の各項目の手前にorder-selectを追加。
            items.splice(orderSelectIndex, 0, {hash:'order-select', parent:parent, block:item.block, layer:item.layer, order:item.order, name:'ここへ移動する', description:'', description_show:false, descriptionLines:1, color:'#c8c8c8', background:'', gradation:0, layer_percentage:'0%', first:true, last:true});
            if (maxBlock < item.block) {
              maxBlock = item.block;
            }
            if (maxOrder < item.order) {
              maxOrder = item.order;
            }
          }
        });

      }
    }

    // orderしようとしている項目の手前に追加されたorder-selectを削除
    orderItemIndex = items.findIndex(item => item.hash == item_hash);
    if (items[orderItemIndex-1].hash == 'order-select') {
      items.splice(orderItemIndex-1, 1);
    }

    // orderしようとしている項目の後ろの項目の手前にorder-selectがあれば削除
    const nextItemIndex = items.findIndex(item => item.layer == layer && item.order - order == 1 && item.hash != 'order-select');
    if (nextItemIndex != -1) {
      if (items[nextItemIndex-1].hash == 'order-select') {
        items.splice(nextItemIndex-1, 1);
      }
    }

    // 同階層の最後尾の項目(子孫項目があればその最後尾)のうしろにorder-selectを追加(それがorderしようとしている項目でなければ)
    if (order != maxOrder) {
      let lastOrderItemIndex = items.findIndex(item => item.layer == layer && item.order == maxOrder && item.hash != 'order-select');
      let progenyItemIndex = this.progenyItemIndex(items, lastOrderItemIndex, layer); // 子孫項目の最後尾のインデックスを取得 
      if (progenyItemIndex != -1) {
        lastOrderItemIndex = progenyItemIndex;
      }
      let lastOrderItemBlock;
      if (layer == 0) {
        lastOrderItemBlock = maxBlock+1;
      } else {
        lastOrderItemBlock = block;
      }
      items.splice(lastOrderItemIndex+1, 0, {hash:'order-select', parent:parent, block:lastOrderItemBlock, layer:layer, order:maxOrder+1, name:'ここへ移動する', description:'', description_show:false, descriptionLines:1, color:'#c8c8c8', background:'none', gradation:0, layer_percentage:'0%', first:true, last:true});
    }

    //this.setState({ items:items, focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, scroll:true, scrollItemHash:item_hash, orderChange:true, orderItemHash:item_hash, palettePop:false, removeItemConfirm:false, lineHeight:true, });
    //this.setState({ items:items, focus:true, focusFieldHash:item_hash, focusFieldType:'name', blurMethod:true, orderChange:true, orderItemHash:item_hash, palettePop:false, removeItemConfirm:false, lineHeight:true, }, () => {
    this.setState({ items:items, blurMethod:true, orderChange:true, orderItemHash:item_hash, palettePop:false, removeItemConfirm:false, lineHeight:true, }, () => {

/*
      var element = document.getElementById('name-' + item_hash); // 要素を取得
      var rect = element.getBoundingClientRect(); // 取得した要素の座標値を取得
      var scrollTop = rect.top + window.pageYOffset; // ページ全体から見た場合の縦位置を取得
      var clientHeight = document.documentElement.clientHeight; // 表示画面の高さを取得 
      scrollTop = scrollTop - (clientHeight/2); // 移動する項目を画面の中央付近にもってきたいので表示画面の高さの半分を足す。 
      //window.scrollTo(0,scrollTop);
      //this.setState({ scroll:false, scrollItemHash:'', });
*/

      //let scrollTop = this.getScrollTop();
      //this.itemAutoFocus('name', item_hash, scrollTop);
      this.itemAutoFocus('name', item_hash);
    });
  }


  // 順番入れ替えモードを終了
  cancelOrderItem = () => {
    //console.log('cancelOrderItem');
    var items = this.state.items.slice();
    // 挿入場所選択用のフィールド(order-select)を配列から排除する
    items = items.filter(function(item, index) {
      if (item.hash != 'order-select') {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ items:items });
    //this.setState({ items:items, blurMethod:true, orderChange:false, orderItemHash:'', palettePop:false, removeItemConfirm:false, focusFieldHash:'' }); 
  }

  // 順番を入れ替える
  orderChange = (event, index) => {
    //console.log('orderChange');
    var items = this.state.items.slice();
    let orderItemIndex = items.findIndex(item => item.hash == this.state.orderItemHash);
    let item_hash = items[index].hash;
    let block = items[index].block;
    let layer = items[index].layer;
    let order = items[index].order;

    // クリックした項目がorder-selectであるならば処理を行う
    if (items[index].hash == 'order-select') {

      // blockやorderを１つずらす範囲を決めるために移動前後の項目のハッシュをもっておく。(indexだと順番を入れ替えたり、order-selectを排除すると変わってしまうため)
      let firstHash;
      let lastHash;
      let progenyItemIndex = this.progenyItemIndex(items, orderItemIndex, layer); // 子孫項目の最後尾のインデックスを取得 
      if (index < orderItemIndex) {
        // 移動前の場所が移動後の場所よりも後ろ(下)の場合 
        firstHash = items[index+1].hash;
        lastHash = items[orderItemIndex-1].hash;
      } else {
        // 移動前の場所が移動後の場所よりも前(上)の場合 
        if (progenyItemIndex != -1) {
          // 移動させる項目に子孫タスクがあるので、その最後尾の次のインデックスのハッシュを取得
          firstHash = items[progenyItemIndex+1].hash;
        } else {
          // 移動させる項目に子孫タスクがないので、その次のインデックスのハッシュを取得
          firstHash = items[orderItemIndex+1].hash;
        }
        lastHash = items[index-1].hash;
      }


      // 項目の場所を移動
      if (layer == 0) {
        // layerが0の場合、移動先のblockとorderに値を更新する。
        if (progenyItemIndex != -1) {
          // 移動させる項目のlayerが0で、子孫タスクを持っている場合、それらすべての項目のblockを移動先のblockに更新する。そして、layer0だけorderを更新する。
          items = items.map((item, i) => {
            if (orderItemIndex <= i && i <= progenyItemIndex) {
              if (index < orderItemIndex) {
                item.block = block;
              } else {
                item.block = block - 1; // 上から下に移動する場合は、あとでblockが１つ上にズレるので-1しておく。 
              }
              if (item.layer == 0) {
                if (index < orderItemIndex) {
                  item.order = order;
                } else {
                  item.order = order - 1; // 上から下に移動する場合は、あとでorderが１つ上にズレるので-1しておく。 
                }
              }
            }
            return item;
          });
        } else {
          // 移動させる項目のlayerが0で、子孫項目がない場合、その項目のblockを移動先のblockに更新する。そして、layer0だけorderを更新する。
          items = items.map((item, i) => {
            if (i == orderItemIndex) {
              if (index < orderItemIndex) {
                item.block = block;
              } else {
                item.block = block - 1; // 上から下に移動する場合は、あとでblockが１つ上にズレるので-1しておく。 
              }
              if (item.layer == 0) {
                if (index < orderItemIndex) {
                  item.order = order;
                } else {
                  item.order = order - 1; // 上から下に移動する場合は、あとでorderが１つ上にズレるので-1しておく。 
                }
              }
            }
            return item;
          });
        }
      } else {
        // layerが0ではない場合、移動する項目のorderを１つずらす。
        items = items.map((item, i) => {
          if (i == orderItemIndex) {
            if (index < orderItemIndex) {
              item.order = order;
            } else {
              item.order = order - 1; // 上から下に移動する場合は、あとでorderが１つ上にズレるので-1しておく。 
            }
          }
          return item;
        });
      }


      // 移動する項目、その子孫タスク(あるのであれば)をitems配列から分離し、orderItems配列に格納
      let orderItems;
      if (progenyItemIndex != -1) {
        var progenyItemCnt = progenyItemIndex - orderItemIndex + 1;
        orderItems = items.splice(orderItemIndex, progenyItemCnt);
        Array.prototype.splice.apply(items,[index,0].concat(orderItems));
      } else {
        orderItems = items.splice(orderItemIndex, 1);
        Array.prototype.splice.apply(items,[index,0].concat(orderItems));
      }


      // order-selectを排除
      items = items.filter(function(item, index) {
        if (item.hash != 'order-select') {
          return true;
        } else {
          return false;
        }
      });


      // 項目の場所を移動し、order-selectも排除したので、blockやorderを１つずつずらす際のインデックスの最初と最後を取得
      let firstIndex = items.findIndex(item => item.hash == firstHash);
      let lastIndex = items.findIndex(item => item.hash == lastHash);

      // 移動前と移動後の間の項目のblockやorderを１つずつずらす
      if (index < orderItemIndex) {
        // 移動前の場所が移動後の場所よりも後ろ(下)の場合
        items = items.map((item, i) => {
          // 移動後の場所と移動前の場所の間の同階層の項目のblockとorderを１つずらす。
          if (firstIndex <= i && i <= lastIndex) {
            if (layer == 0) {
              item.block += 1;
            }
            if (item.layer == layer) {
              item.order += 1;
            }
          }
          return item;
        });
      } else {
        // 移動前の場所が移動後の場所よりも前(上)の場合
        items = items.map((item, i) => {
          // 移動後の場所と移動前の場所の間の同階層の項目のblockとorderを１つずらす。
          if (firstIndex <= i && i <= lastIndex) {
            if (layer == 0) {
              item.block -= 1;
            }
            if (item.layer == layer) {
              item.order -= 1;
            }
          }
          return item;
        });
      }


      // orderの調整
      items = this.orderAdjustment(items);

      // firstとlastの値を更新
      this.firstLast(items);

      //this.setState({ items:items, focus:true, focusFieldHash:this.state.orderItemHash, focusFieldType:'name', blurMethod:true, scroll:true, scrollItemHash:this.state.orderItemHash, orderChange:false, palettePop:false, removeItemConfirm:false, focus:true, focusFieldHash:this.state.orderItemHash, focusFieldType:'name', lineHeight:true, });
      //this.setState({ items:items, focus:true, focusFieldHash:this.state.orderItemHash, focusFieldType:'name', blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, focus:true, focusFieldHash:this.state.orderItemHash, focusFieldType:'name', lineHeight:true, }, () => {
      this.setState({ items:items, blurMethod:true, orderChange:false, palettePop:false, removeItemConfirm:false, focus:true, focusFieldHash:this.state.orderItemHash, focusFieldType:'name', lineHeight:true, }, () => {

/*
        var element = document.getElementById('name-' + this.state.orderItemHash); // 要素を取得
        var rect = element.getBoundingClientRect(); // 取得した要素の座標値を取得
        var scrollTop = rect.top + window.pageYOffset; // ページ全体から見た場合の縦位置を取得
        var clientHeight = document.documentElement.clientHeight; // 表示画面の高さを取得 
        scrollTop = scrollTop - (clientHeight/2); // 移動する項目を画面の中央付近にもってきたいので表示画面の高さの半分を足す。 
        //window.scrollTo(0,scrollTop);
*/
        //let scrollTop = this.getScrollTop();
        //this.itemAutoFocus('name', this.state.orderItemHash, scrollTop);
        this.itemAutoFocus('name', this.state.orderItemHash);
      });

    } else {
      this.cancelOrderItem();
    }

    this.noteUpdate();

  }

  componentWillUnmount = () => {
    //PlayAudio('click-01');
    //PlayVibrate('click-01');
  }

  handleMenu = () => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen:false, });
    } else {
      this.setState({ menuOpen:true, });
    }
  }

  // テキスト選択ポップアップメニューからなにかを選択したらその種類を保持する
  selectTextMenuTypeChange = (type) => {
    console.log('#####################################');
    console.log('selectTextMenuTypeChange');
    console.log('type: ' + type);
    this.cancelBlur(undefined);

    var items = this.state.items.slice();
    const insertItemIndex = items.findIndex(item => item.hash == this.state.focusFieldHash);
    console.log('this.state.focusFieldHash: ' + this.state.focusFieldHash);
    console.log('insertItemIndex: ' + insertItemIndex);
    console.log('this.state.replaceDescriptionBuffer ここかも！！');
    console.log(this.state.replaceDescriptionBuffer);

    // replaceDescriptionBuffer(選択したテキストをselect-textクラスのspanタグで囲ったやつ)でdescriptionを上書き
    items[insertItemIndex].description = this.state.replaceDescriptionBuffer;
    this.setState({ items:items, replaceDescriptionBuffer:'', });

    // selectTextMenuTypeに新しいtypeを反映したら(いまはurlしかない)、urlの入力欄に自動フォーカスさせる
    this.setState({ selectTextMenuType:type, selectTextUrl:'', }, () => {
      document.getElementById('select-text-url').focus();
    });
    console.log('######################################');
  }


  selectTextUrlFocus = () => {
    console.log('selectTextUrlFocus#############');
    var items = this.state.items.slice();
    const focusItemIndex = items.findIndex(item => item.hash == this.state.focusFieldHash);
    this.insertSelectText();
  }


  // select-textがないのであれば、selectTextUrlをhrefに持つaタグをselect-textタグのspanタグに変換
  insertSelectText = () => {
    console.log('insertSelectText');
    if (this.state.selectTextUrl != '') {
      console.log('this.state.selectTextUrlが空白の場合');
      var items = this.state.items.slice();
      const focusItemIndex = items.findIndex(item => item.hash == this.state.focusFieldHash);

      //let regexp = /<span.*?class=.select-text.*?>.*?<\/span>/g;
      let regexp = /<span.\<*?class=.select-text.*?>.*?<\/span>/g;
      let selectTextTags = items[focusItemIndex].description.match(regexp);
      console.log(selectTextTags);
      if (!selectTextTags) {
        console.log('description内にselect-textクラスのspanタグが存在しない場合');

        let url = this.state.selectTextUrl;
        let regexpUrl = url.replace(/[-\/\\^$*+?.()|\[\]{}]/g, '\\$&'); // 正規表現に組み込むためにメタ文字をエスケープしてある。
        let hash = this.descriptionNodeHashCreate(items[focusItemIndex].description);
        let regexp = new RegExp('<a.[^<]*?id=.' + hash + '.*?href=.' + regexpUrl + '.*?>.*?<\/a>', '');
        let urlTags = items[focusItemIndex].description.match(regexp);
        console.log(urlTags);
        let replaceDescriptionBuffer = '';
        if (urlTags) {
          replaceDescriptionBuffer = items[focusItemIndex].description;
          let selectText = document.createRange().createContextualFragment(urlTags[0]).textContent; // urlTags[0]のタグの中身のテキストの部分を取得
          let replaceText = '<span class="select-text">' + selectText + '</span>';
          items[focusItemIndex].description = items[focusItemIndex].description.replace(urlTags[0], replaceText);
        }
        this.setState({ items:items, replaceDescriptionBuffer:replaceDescriptionBuffer, });
      }
    }
  }


  selectTextUrlChange = (event) => {
    console.log('selectTextUrlChange');
    this.setState({ selectTextUrl:event.target.value, });
  }


  selectTextUrlBlur = () => {
    console.log('selectTextUrlBlur');
    var items = this.state.items.slice();
    const blurItemIndex = items.findIndex(item => item.hash == this.state.focusFieldHash);

    console.log('replace前');
    console.log(items[blurItemIndex].description);

    // replaceDescriptionBufferがあるかないかで、それぞれ適切な形でdescriptionを更新
    if (this.state.replaceDescriptionBuffer == '') {
      // 既存のselect-testのspanタグを普通のテキストにもどす
      //let regexp = /<span.*?class=.select-text.*?>.*?<\/span>/g;
      let regexp = /<span.\<*?class=.select-text.*?>.*?<\/span>/g;
      //let regexp = /<span.[^(<|select-text)]*?>.*?<\/span>/g; ///////////////////
      let selectTextTags = items[blurItemIndex].description.match(regexp);

      //let regexp = new RegExp('<span.[^(<|select-text)]*?>', '');
      //let regexp = new RegExp('<span.[^(<|select-text)]*?>.*?<\/span>', '');
      //let selectTextTags = items[blurItemIndex].description.match(regexp); // <a id="hoge" href="https://~~~~"> の部分を取得
      console.log('selectTextTags');
      console.log(selectTextTags);

      if (selectTextTags) {
        for (var i=0; i<selectTextTags.length; i++) {
          let selectText = document.createRange().createContextualFragment(selectTextTags[i]).textContent; // selectTextTags[i]のタグの中身のテキストの部分を取得
          items[blurItemIndex].description = items[blurItemIndex].description.replace(selectTextTags[i], selectText);
        }
      }
    } else {
      // replaceDescriptionBufferがあればそれをdescriptionに挿入する。
      // 既存のaタグを編集していたけど適用せずにblurした場合は、編集前の状態(select-textクラスのspanではなく、aタグの状態)にもどすということ。
      items[blurItemIndex].description = this.state.replaceDescriptionBuffer;
    }

    console.log('replace後');
    console.log(items[blurItemIndex].description);

    this.setState({ items:items, orderChange:false, palettePop:false, removeItemConfirm:false, focus:false, focusFieldHash:'', focusFieldType:'', lineHeight:true, selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', });
  }


  // テキスト選択ポップアップのurlを選択したテキストに挿入
  insertLink = (event) => {
    console.log('insertLink');
    event.preventDefault();

    var items = this.state.items.slice();
    const insertItemIndex = items.findIndex(item => item.hash == this.state.focusFieldHash);
    console.log('description');
    console.log(items[insertItemIndex].description);

    //let regexp = /<span.*?class=.select-text.*?>.*?<\/span>/g;
    //let regexp = /<span.[^<]*?class=.select-text.*?>.*?<\/span>/g;
    let regexp = /<span.\<*?class=.select-text.*?>.*?<\/span>/g;
              //regexp = new RegExp('<a.[^<]*?.*?href=.*?>', '');
    let selectTextTags = items[insertItemIndex].description.match(regexp);
    let hash = '';
    if (selectTextTags) {
      console.log('selectTextTags あり');
      console.log(selectTextTags[0]);

      let description = document.getElementById('description-' + this.state.focusFieldHash);
      var selectNode = selectTextTags[0];

      // selectTextUrlが空白ではないのであれば、その値をhrefにしたaタグで選択したテキストを囲む。空白であればただのテキストにする。
      let selectText = document.createRange().createContextualFragment(selectTextTags[0]).textContent; // selectTags[i]のタグの中身のテキストの部分を取得
      let replaceText = '';
      if (this.state.selectTextUrl != '') {
        // aタグにする
        console.log('aタグにする');
        hash = this.descriptionNodeHashCreate(items[insertItemIndex].description);
        replaceText = '<a contenteditable="true" id="' + hash + '" class="insert-tag" href="' + this.state.selectTextUrl + '" target="_blank">' + selectText + '</a>';
      } else {
        // ただのテキストにする
        console.log('ふつうのテキストにする');
        replaceText = selectText;
      }
      items[insertItemIndex].description = items[insertItemIndex].description.replace(selectTextTags[0], replaceText);

      if (this.state.selectTextUrl != '') {
        console.log('aタグにした後のdescription');
        console.log(items[insertItemIndex].description);
      }

    }


    // state変数を更新後、適切な位置にカーソルを自動フォーカスする
    this.setState({ items:items, selectTextMenu:false, selectTextMenuPos:[], selectTextMenuType:'', selectTextUrl:'', replaceDescriptionBuffer:'', }, () => {

      let cursorTag = '';
      let cursorIndex = 0;
      if (hash != '') {
        cursorTag = document.getElementById(hash);
        if (0 < cursorTag.childNodes.length) {
          cursorTag = cursorTag.childNodes[0];
          if (cursorTag.nodeName == '#text') {
            cursorIndex = cursorTag.textContent.length;
          } else {
            cursorIndex = cursorTag.innerHTML.length;
          }
        } else {
          cursorIndex = cursorTag.innerHTML.length;;
        }
        console.log('cursorTag');
        console.log(cursorTag);
        console.dir(cursorTag);
        console.log('cursorIndex');
        console.log(cursorIndex);
        var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(cursorTag, cursorIndex);
        range.setEnd(cursorTag, cursorIndex);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }

    });

  }


  // 挿入しているリンクを削除
  removeLink = (event) => {
    console.log('removeLink');

    return new Promise((resolve, reject) => {
      //console.log('select-text-urlにfocusする');
      document.getElementById('select-text-url').focus();
      resolve();
    }).then((post) => {
      return new Promise( (resolve, reject) => {
        //console.log('selectTextUrlを空白にする');
        this.setState({ selectTextUrl:'', }, () => {resolve()});
        resolve();
      });
    }).then((post) => {
      return new Promise( (resolve, reject) => {
        //console.log('insertLinkを実行する');
        this.insertLink(event);
        resolve();
      });
    }).catch(() => {
      return new Promise( (resolve, reject) => {
        resolve();
      });
    });

  }

  // itemBlurメソッドが発火したさいにテキスト選択ポップアップを非表示にするか、表示したままにしておくかを分岐させるためのメソッド
  selectTextMenuMouseOver = () => {
    console.log('selectTextMenuMouseOver');
    this.setState({ selectTextMenuHover:true, });
  }

  // itemBlurメソッドが発火したさいにテキスト選択ポップアップを非表示にするか、表示したままにしておくかを分岐させるためのメソッド
  selectTextMenuMouseLeave = () => {
    console.log('selectTextMenuMouseLeave');
    this.setState({ selectTextMenuHover:false, });
  }


  render() {

    const items = this.state.items.map((item, index) => {

      let addUpItem;
      if (this.state.focusFieldHash == item.hash && item.name != '') {
        addUpItem = (
          <div className='add-item-btn' onMouseDown={ (event) => {this.handleSubmit(event, item.hash, 'top')} }>上位項目を追加</div>
        );
      } else {
        addUpItem = ( <span></span> );
      }

      let addDownItem;
      if (this.state.focusFieldHash == item.hash && item.name != '') {
        addDownItem = (
          <div className='add-item-btn' onMouseDown={event => {this.handleSubmit(event, item.hash, 'lower')} }>下位項目を追加</div>
        );
      } else {
        addDownItem = ( <span></span> );
      }

      let submitBtn;
      if (this.state.focusFieldHash == item.hash && item.name != '') {
        submitBtn = (
          <div className='submit-btn' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.handleSubmit(event, item.hash, 'same')} }>保存</div>
        );
      } else {
        submitBtn = ( <span></span> );
      }


      let text = item.description;
      let descriptionField, description, descriptionToggle;
      if (item.description == '') {
        descriptionToggle = ( <span></span> );
      } else {
        if (item.description_show) {
          descriptionToggle = ( <div className='description-toggle hide' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ () => {this.descriptionToggle(item.hash)} }><i className='fas fa-chevron-up'></i> hide</div> );
        } else {
          descriptionToggle = ( <div className='description-toggle show' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ () => {this.descriptionToggle(item.hash)} }><i className='fas fa-chevron-down'></i> show more</div> );
        }
      }


      if (item.description_show) {
        description = (
          <ContentEditable
            id={item.hash != 'order-select' && 'description-' + item.hash} html={text} placeholder='enter description'
            className={'description ' + (item.description != '' || (this.state.focusFieldHash == item.hash && item.name != '') ? 'show-description' : 'hide-description')}
            onMouseOver={ (event) => {this.itemDescriptionMouseOver(event, item.hash)} }
            onMouseLeave={ (event) => {this.itemDescriptionMouseLeave(event, item.hash)} }
            onChange={ (event) => {this.itemDescriptionChange(event, item.hash) } }
            onFocus={ (event) => { this.itemDescriptionFocus(event, item.hash) } }
            onSelect={ (event) => { this.itemDescriptionSelect(event, item.hash) } }
          />
        );
      } else {
        description = ( <span></span> );
      }

{/*
      if (item.description == '') {
        description = (
          <ContentEditable
            id={item.hash != 'order-select' && 'description-' + item.hash} html={text} placeholder='enter description'
            className={'description ' + (item.description != '' || (this.state.focusFieldHash == item.hash && item.name != '') ? 'show-description' : 'hide-description')}
            onMouseOver={ (event) => {this.itemDescriptionMouseOver(event, item.hash)} }
            onMouseLeave={ (event) => {this.itemDescriptionMouseLeave(event, item.hash)} }
            onChange={ (event) => {this.itemDescriptionChange(event, item.hash) } }
            onFocus={ (event) => { this.itemDescriptionFocus(event, item.hash) } }
            onSelect={ (event) => { this.itemDescriptionSelect(event, item.hash) } }
          />
        );
      } else {
        if (item.description_show) {
          description = (
            <ContentEditable
              id={item.hash != 'order-select' && 'description-' + item.hash} html={text} placeholder='enter description'
              className={'description ' + (item.description != '' || (this.state.focusFieldHash == item.hash && item.name != '') ? 'show-description' : 'hide-description')}
              onMouseOver={ (event) => {this.itemDescriptionMouseOver(event, item.hash)} }
              onMouseLeave={ (event) => {this.itemDescriptionMouseLeave(event, item.hash)} }
              onChange={ (event) => {this.itemDescriptionChange(event, item.hash) } }
              onFocus={ (event) => { this.itemDescriptionFocus(event, item.hash) } }
              onSelect={ (event) => { this.itemDescriptionSelect(event, item.hash) } }
            />
          );
        } else {
          description = ( <span></span> );
        }
      }
*/}

      {/*
      if (item.description != '') {
        if (item.description_show) {
          description = (
            <div>
              <ContentEditable
                id={item.hash != 'order-select' && 'description-' + item.hash} html={text} placeholder='enter description'
                className={'description ' + (item.description != '' || (this.state.focusFieldHash == item.hash && item.name != '') ? 'show-description' : 'hide-description')}
                onMouseOver={ (event) => {this.itemDescriptionMouseOver(event, item.hash)} }
                onMouseLeave={ (event) => {this.itemDescriptionMouseLeave(event, item.hash)} }
                onChange={ (event) => {this.itemDescriptionChange(event, item.hash) } }
                onFocus={ (event) => { this.itemDescriptionFocus(event, item.hash) } }
                onSelect={ (event) => { this.itemDescriptionSelect(event, item.hash) } }
              />
              <div className='description-toggle hide' onClick={ () => {this.descriptionToggle(item.hash)} }><i className='fas fa-chevron-up'></i> hide</div>
            </div>
          );
        } else {
          description = (
            <div className='description-toggle show' onClick={ () => {this.descriptionToggle(item.hash)} }><i className='fas fa-chevron-down'></i> show more</div>
          );
        }
      } else {
        description = ( <span></span> );
      }
      */}
      descriptionField = (
        <span>
          {description}
          {descriptionToggle}
        </span>
      );

      let itemField;
      itemField = (
        <form name={'form' + index} onBlur={ (event) => {this.itemBlur(event, item.hash)} } onSubmit={event => {this.handleSubmit(event, item.hash, 'same')} }>
          <input type='text' name={'field' + index} id={item.hash != 'order-select' && 'name-' + item.hash} className='item-field' value={item.name} onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => { this.state.orderChange ? this.orderChange(event, index) : this.itemNameFocus(event, item.hash) } } onChange={ (event) => {this.itemNameChange(event, item.hash)} } placeholder='項目を入力' />
          {descriptionField}
        </form>
      );

      let palettePop;
      if (this.state.palettePop) {

        let paletteColors = [
          {'background':'#151515', 'color':'#FFFFFF'}, // マットブラック&ホワイト
          {'background':'#FFFFFF', 'color':'#151515'}, // ホワイト&マットブラック
          {'background':'#2ca9e1', 'color':'#000000'}, // Googleブルー&ブラック
          {'background':'#38b48b', 'color':'#000000'}, // Googleグリーン&ブラック
          {'background':'#d7003a', 'color':'#000000'}, // Googleレッド&ブラック
          {'background':'#f8b500', 'color':'#000000'}, // Googleイエロー&ブラック
          {'background':'#00FFFF', 'color':'#000000'}, // シアン&ブラック
          {'background':'#FF00FF', 'color':'#000000'}, // マゼンタ&ブラック
          {'background':'#FFFF00', 'color':'#000000'}, // イエロー&ブラック
          {'background':'#000000', 'color':'#00FFFF'}, // ブラック&シアン
          {'background':'#000000', 'color':'#FF00FF'}, // ブラック&マゼンタ
          {'background':'#000000', 'color':'#FFFF00'}, // ブラック&イエロー
          {'background':'#000000', 'color':'#FFFFFF'}, // ブラック*ホワイト
          {'background':'#FFFFFF', 'color':'#000000'}, // ホワイト&ブラック
          {'background':'#2ca9e1', 'color':'#f8b500'}, // Googleブルー&Googleイエロー
          {'background':'#f8b500', 'color':'#2ca9e1'}, // Googleブルー&Googleイエロー
          {'background':'#E9DFD3', 'color':'#19AFAA'}, // ホワイト&アクアブルー
          {'background':'#11425D', 'color':'#F83E45'}, // 紺&レッド
          //{'background':'', 'color':''}, // 
          //{'background':'', 'color':''}, // 
        ];
        let paletteItems = paletteColors.map((pc) => {
          return (
            <div className='palette-item' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.selectPaletteColor(event, item.hash, pc.color, pc.background)} }>
              <div className='sub-color' style={{'background':pc.background}} /><div className='main-color' style={{'background':pc.color}} />
            </div>
          );
        });

        palettePop = (
          <div className='palette-pop'>
            {paletteItems}
          </div>
        );
      } else {
        palettePop = ( <span></span> );
      }

      let removeItemConfirm;
      if (this.state.removeItemConfirm) {
        removeItemConfirm = (
          <div className='remove-item-confirm-pop'>
            <div className='remove-btn' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.removeItem(event, item.hash)} }>削除</div>
          </div>
        );
      } else {
        removeItemConfirm = ( <span></span> );
      }

      let itemOption;
      if (this.state.focusFieldHash == item.hash && item.name != '') {
        itemOption = (
          <div className='item-option'>
            <div className='btn order-btn'>
              <i className='fas fa-sort' onMouseDown={ (event) => {this.state.orderChange == false && this.cancelBlur(event)} } onClick={ (event) => {this.orderItem(event, item.hash)} }></i>
            </div>
            <div className='btn drip-btn'>
              {palettePop}
              <i className='fas fa-fill-drip' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.dripItem(event, item.hash)} }></i>
            </div>
            <div className='btn remove-btn'>
              {removeItemConfirm}
              <i className='far fa-trash-alt' onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.removeItemConfirm(event, item.hash)} }></i>
            </div>
          </div>
        );
      } else {
        itemOption = ( <span></span> );
      }

      let layer_percentage;
      if (item.layer_percentage != '0%') {
        layer_percentage = ( <div className='layer-percentage' style={{width:item.layer_percentage, }}></div> );
      } else {
        layer_percentage = ( <span></span> );
      }

      if (item.hash != 'order-select') {
        return (
          <Item full={this.props.full} one={this.props.one} two={this.props.two} first={item.first} last={item.last} mainColor={item.color} subColor={item.background} background={item.background} opacity={item.gradation}>
            <div className={'item-element'}>{layer_percentage}{addUpItem}{submitBtn}{itemField}{addDownItem}{itemOption}</div>
          </Item>
        );
      } else {
        return (
          <OrderSelect first={item.first} last={item.last} background={item.background}>
            <div className={'item-element'}>{layer_percentage}{addUpItem}{submitBtn}{itemField}{addDownItem}{itemOption}</div>
          </OrderSelect>
        );
      }
    });


    let addItemBtn;
    if (this.state.items.some(item => item.name == '') || this.state.orderChange) {
      addItemBtn = ( <span></span> );
    } else {
      addItemBtn = (
        <AddBtn full={this.props.full} one={this.props.one} two={this.props.two} onMouseDown={ (event) => {this.cancelBlur(event)} } onClick={ (event) => {this.addItem(event)} }>項目を追加</AddBtn>
      );
    }



    let selectTextMenu;
    if (this.state.selectTextMenu) {
      if (this.state.selectTextMenuType == '') {
        selectTextMenu = (
          <SelectTextMenu top={this.state.selectTextMenuPos.bottom} left={this.state.selectTextMenuPos.left} right={this.state.selectTextMenuPos.right} onMouseOver={ (event) => {this.selectTextMenuMouseOver()} } onMouseLeave={ (event) => {this.selectTextMenuMouseLeave()} }>
            <div className='menu-item' onClick={ (event) => {this.selectTextMenuTypeChange('url')} }><i className='fas fa-link'></i></div>
          </SelectTextMenu>
        );
      } else if (this.state.selectTextMenuType == 'url') {
        selectTextMenu = (
          <SelectTextMenu className='select-text-menu' top={this.state.selectTextMenuPos.bottom} left={this.state.selectTextMenuPos.left} right={this.state.selectTextMenuPos.right} onMouseOver={ (event) => {this.selectTextMenuMouseOver()} } onMouseLeave={ (event) => {this.selectTextMenuMouseLeave()} }>
            <form onSubmit={ (event) => {this.insertLink(event)} }>
              <input type='text' id='select-text-url' onFocus={ (event)=> {this.selectTextUrlFocus()} } onChange={ (event) => {this.selectTextUrlChange(event)} } onBlur={ (event) => {this.selectTextUrlBlur(event)} } value={this.state.selectTextUrl} placeholder='https://lab.takuman.me/posts_study' />
              <input type='submit' className='btn apply' value='APPLY' onMouseDown={ (event) => {this.insertLink(event)} } />
              <input type='button' className='btn remove' value='REMOVE' onMouseDown={ (event) => {this.removeLink(event)} } />
            </form>
          </SelectTextMenu>
        );
      }
    } else {
      selectTextMenu = ( <span></span> );
    }

    let rightBtn;
    if (this.state.menuOpen) {
      rightBtn = (
        <span>
          <div className='btn right cancel-btn'>
            <Link onClick={ () => {this.handleMenu()} }><i className='fas fa-times'></i></Link>
          </div>
          <div className='btn right delete-btn'>
            <Link onClick={ () => {this.removeNote()} }><i className='far fa-trash-alt'></i></Link>
          </div>
          <div className='btn right reset-btn'>
            <Link onClick={ () => {this.resetNote()} }><i className='far fa-sticky-note'></i></Link>
          </div>
        </span>
      );
    } else {
      rightBtn = (
        <div className='btn right'>
          <Link onClick={ () => {this.handleMenu()} }><i className='fas fa-ellipsis-h'></i></Link>
        </div>
      );
    }

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={this.state.title} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/layerednote' : this.props.one ? '/layerednote/' + pathItems[1] : '/' + pathItems[0] + '/layerednote'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
          {rightBtn}
        </BackBtnField>

        <LayeredNoteStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } id={this.props.full ? 'layerednote' : this.props.one ? 'layerednote-1' : 'layerednote-2'} full={this.props.full} one={this.props.one} two={this.props.two}>
          <textarea type='text' name={'note-title2'} id={'note-title'} className='note-title' value={this.state.title} onChange={ (event) => {this.noteTitleChange(event) } } placeholder='タイトルを入力' />
          {items}
          {selectTextMenu}
          <div className='add-btn'>{addItemBtn}</div>
        </LayeredNoteStyle>
      </div>
    );

  }

}

export default LayeredNote;
