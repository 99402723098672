import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const LayeredNotesSearchBoxStyle = styled.div`
  &&& {
    width:100%; height:auto; margin:0 auto; padding:0;
    @media screen and (min-width:750px) { width:80%; }
    @media screen and (min-width:960px) { width:60%; }
  }
`


const Type = styled.h1`
  &&& {
    color:${edgeColor}; text-align:center; font-size:50px; font-weight:100; margin:0; padding:0;
  }
`


/*
const SearchBox = styled.form`
  &&& { width:90%; height:35px; margin:0 auto; padding:0; border-radius:10000px;

    input[type='text'] { width:90%; height:35px; margin:0; padding:0 10px; background:${rgba(mainColor,0.5)}; font-size:18px; color:${subColor};
                         border:none; border-radius:10000px 0 0 10000px; display:inline-block; float:left; }
    input[type='text']:focus { background:${rgba(mainColor,0.95)}; outline:0; }
    input[type='text']:focus + .search-btn { background:${rgba(mainColor,1.0)}; }
    .search-btn { width:10%; height:35px; margin:0; padding:0; background:${rgba(edgeColor,0.6)}; border:none; color:${subColor}; border-radius:0 10000px 10000px 0;
                  display:inline-block; cursor:pointer; float:right; }
    .search-btn:hover { background:${rgba(edgeColor,1.0)}; }
    .search-btn:focus { outline:0; }
  }
`
*/

const SearchBox = styled.div`
  &&& {
  }
`

const SearchBoxItem = styled.div`
  &&& {
    width:100%; height:auto; padding:0; position:relative; overflow:hidden; box-shadow:0 0 6px ${shadowColor};
    margin-top:${(props) => props.first ? '20px' : '0px'}; margin-right:auto; margin-bottom:${(props) => props.last ? '20px' : '0px'}; margin-left:auto;
    border-radius:${(props) => props.first && props.last ? '5px' : props.first ? '5px 5px 0px 0px' : props.last ? '0px 0px 5px 5px' : '0px'};

    &::before {
     content:''; position:absolute; top:0; bottom:0; left:0; right:0; background:${(props) => getItemBackground(props)};
    }

    .item-element { position:relative; 
      .layer-percentage {
        width:30%; height:15px; margin:0; padding:0; background:none; 
        border-bottom:2px solid ${(props) => props.mainColor ? props.mainColor : mainColor};
      } 
      .item-field {
        width:100%; height:40px; margin:auto; padding:3px 10px; background:none; font-size:18px; border:none;
        color:${(props) => props.mainColor ? props.mainColor : mainColor};
      } 
      .item-field:-webkit-autofill { animation-name:onAutoFillStar; transition:background-color 50000s ease-in-out 0s; -webkit-text-fill-color: ${mainColor} !important; } 
      .item-field:focus { outline:0; } 
    } 

    .submitbtn-item-element { cursor:pointer;
      .layer-percentage { cursor:pointer; }
      form { cursor:pointer;
        .item-field { cursor:pointer; height:30px;
         i { font-size:20px; }
        }
      }
    }
  }
`

const getItemBackground = (props) => {
  if (props) {
    if (props.background && props.opacity) {
      return lighten(props.background,props.opacity);
    } else if (props.background) {
      return props.background
    } else {
      return 'none'
    }
  } else {
    return 'none'
  }
}


const SearchFilter = styled.div`
  &&& { width:100%; height:auto; margin:20px auto; padding:0;

    .filter-item { position:relative; width:auto; height:auto; margin:0; padding:0; color:${mainColor}; display:inline-block; float:left;
      .filter-item-state { width:60px; height:25px; padding:2px 10px; color:${mainColor}; font-size:14px; text-align:center; cursor:pointer; }
      .filter-item-state:hover { background:${rgba(mainColor,0.2)}; }
      .filter-menu { position:absolute; top:95%; left:-25%; right:-25%; z-index:100; width:auto; height:auto; margin:0; padding:10px 0; background:${subColor};
                     color:${mainColor}; text-align:center; border-radius:3px; box-shadow:0 0 10px ${shadowColor};
        span { display:block; padding:0 10px; cursor:pointer; }
        span:hover { background:${rgba(mainColor,0.1)}; }
      }
    }
    .filter-item:hover { background:${rgba(mainColor,0.2)}; }
  }
`



const Searching = styled.p`
  &&& { color:${mainColor}; font-size:18px; text-align:center; animation:fade-search 0.1s linear infinite;
    @keyframes fade-search {
      0% { opacity:1; }
      50% { opacity:0.9; }
      100% { opacity:1; }
    }
  }
`


const NotesCount = styled.div`
  &&& {
    width:120px; height:25px; padding:2px 10px; color:${mainColor}; font-size:14px; text-align:center; display:inline-block; }
  }
`

export { LayeredNotesSearchBoxStyle, Type, SearchBox, SearchBoxItem, SearchFilter, Searching, NotesCount };
