import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor, red, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

// DistributedLearnings.js

const DistributedLearningsStyle = styled.div`
  &&& {
    padding-top:50px;
  }
`

const Block = styled.div`
  &&& {
    ${fadeIn(1,0,-1)}
    width:98%; height:auto; margin:0 auto 20px auto; padding:0px 0px; opacity:0.95;
    background:${ props => props.on ? edgeColor : subColor};
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;

    label { color:${ props => props.on ? edgeColor : subColor}; background:${ props => props.on ? subColor : edgeColor}; border-radius:2px;
            font-size:12px; font-weight:900; margin:10px 0 0 20px; padding:2px 5px; display:inline-block; }
    .sub-label { color:${ props => props.on ? subColor : yellow}; border:1px solid ${props => props.on ? subColor : yellow}; background:none; margin:0 0 0 5px; }
    p { color:${ props => props.on ? subColor : mainColor}; font-size:18px; padding:10px 20px; }

    @media screen and (min-width:750px) {
      width:${(props) => props.full ? '80%' : '98%'};
    }
    @media screen and (min-width:960px) {
      width:${(props) => props.full ? '60%' : '98%'};
    }

  }
`

const BlockName = styled.div`
  &&& {
    padding:20px 20px 10px 20px;
    color:${props => (props.on ? subColor : lighten(mainColor,0.2))};
    font-size:22px; font-weight:300; text-align:left; 
  }
`

// ボタンフィールド
const LinkBtnField = styled.div`
  &&& {
    width:100%; height:auto; margin:0; padding:10px 0 20px 0; text-align:center; clear:both;
  }
`

// 一般的なボタン
const LinkBtn = styled(Link)`
  &&& {
    display:inline-block; width:auto; height:auto; margin:auto; padding:7.5px 15px; color:${subColor}; text-align:center; text-decoration:none; 
    background:${ props => props.off ? rgba(mainColor,0.2) : mainColor}; border-radius:5px;
    &:hover { background:${props => props.off ? rgba(mainColor,0.2) : lighten(mainColor,0.1)}; }
  }
`

const LearningTimeField = styled.div`
  &&& {
    width:100%; margin:auto;
    table { width:calc(100% - 40px); border-collapse:collapse; border:1px solid ${ props => props.on ? subColor : mainColor}; color:${ props => props.on ? subColor : mainColor}; margin:10px 20px;
      th, td { border:1px solid ${ props => props.on ? subColor : mainColor}; color:${ props => props.on ? subColor : mainColor}; text-align:center; padding:3px 5px; }
      tbody tr:hover { background:${ props => props.on ? yellow : yellow}; }
    }
    @media screen and (min-width:750px) {
      float:left; width:55%; height:150px;
    }
  }
`

const TotalDataImageField = styled.div`
  &&& {
    display:none;
    @media screen and (min-width:750px) {
      display:block; width:calc(45% - 30px); height:150px; margin:auto 20px auto 10px; overflow:hidden;
      img { width:100%; height:100%; margin:auto; display:block; border-radius:200px 10px 200px 10px; object-fit:cover; filter:invert(100%) brightness(75%) sepia(80%) hue-rotate(130deg) saturate(800%) contrast(1); }
    }
  }
`

const CalendarField = styled.div`
  &&& {
    width:calc(100% - 40px); margin:auto; float:left;
    label { margin-left:0; }
    @media screen and (min-width:750px) {
      width:50%; padding:${ props => props.calendarIndex < 0 ? "0 20px" : props.calendarIndex % 2 == 0 ? "0 5px 0 20px" : "0 20px 0 10px"}; 
      clear:${ props => props.calendarIndex % 2 == 0 ? "both" : "none"};
    }
  }
`

const Calendar = styled.div`
  &&& {
    width:calc(100% - 0px); height:auto; margin:10px auto 20px auto; padding:0; border:1.5px solid ${ props => props.on ? subColor : edgeColor}; border-radius:6px 6px 0 0; overflow:hidden;

    .calendar-header-block, .calendar-block {
      width:14.2857%; border:1px solid ${ props => props.on ? subColor : edgeColor}; float:left;
      color:${ props => props.on ? subColor : edgeColor}; font-size:12px;
    }
    .calendar-header-block {
      display:table; height:20px; padding:5px auto 0 auto; background:${ props => props.on ? subColor : edgeColor};
      color:${ props => props.on ? edgeColor : subColor}; font-size:14px; text-align:center; font-weight:300;
      .content { display:table-cell; vertical-align:middle; }
    }
    .calendar-block { height:40px; position:relative;
      .mday { position:absolute; top:0; left:0; font-size:14px; padding:3px; }
      .learn-time { position:absolute; bottom:0; right:0; margin:auto; padding:5px; font-size:18px; font-weight:900;
        .unit { font-size:10px; margin-left:2px; }
      }
    }
    .calendar-block:hover { background:${yellow}; }
    .past, .past:hover { background:${ props => props.on ? rgba(subColor,0.7) : rgba(mainColor,0.7)}; color:${ props => props.on ? rgba(edgeColor,0.7) : rgba(subColor,0.7) }; }
    .today, .today:hover { background:${ props => props.on ? subColor : edgeColor}; color:${ props => props.on ? edgeColor : subColor }; }
  }
`

export { DistributedLearningsStyle, Block, BlockName, LinkBtnField, LinkBtn, LearningTimeField,  TotalDataImageField, CalendarField, Calendar };
