import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, cyan, magenta, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn } from 'component/common/style/Animations';

const StudiesField = styled.div`
  &&& {
    width:100%; height:calc(100vh - 0px); margin:0; padding:50px 0 0 0; background:${subColor}; position:relative;
  }
`

const StudiesStyle = styled.div`
  &&& {
    width:${(props) => props.full ? 'calc(100% - 250px)' : '100%'};
    height:${(props) => props.full ? '100%' : 'auto'};
    padding-bottom:100px;
    float:right; overflow-y:scroll;
  }
`

const Study = styled.div`
  &&& {
    width:${(props) => props.full ? '70%' : '98%'};
    height:auto; margin:20px auto; padding:10px 20px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; clear:both;
    &:hover { width:${(props) => props.full ? '72%' : '100%'}; padding:15px 20px; }
    font-size:18px; line-height:20px;

    background:${(props) => props.status == 'no-check' ? subColor : props.status == 'check-later' ? '#f5f500' : props.status == 'checked' ? cyan : magenta};
    .title-ja { border-top:0.5px solid ${(props) => props.status == 'no-check' ? cyan : subColor}; }
    &, .title a, .title-ja .study-footer, .status-btns, .status-btns, .status-btns btn i {
      color:${(props) => props.status == 'no-check' ? cyan : subColor};
    }

    .title { padding-bottom:10px;
      a { text-decoration:underline; }
    }
    .title-ja { font-size:16px; padding-top:10px; }

    .study-footer { width:100%; padding-top:10px; font-size:14px;
      .search-keyword { width:300px; float:left; text-align:left; }

      .status-btns { width:300px; float:right; text-align:right; font-size:14px;
        .btn { position:relative; margin:0 5px; cursor:default; display:inline-block; }
        .btn i { opacity:1; cursor:default; }
        .no-select i { opacity:0.2; cursor:pointer; }
        .description { display:none; position:absolute; width:100px; padding:7px 10px 5px 10px; font-size:12px; text-align:center;
                       border-radius:5px; box-shadow:0 0 6px ${rgba(shadowColor,0.6)}; 
                       color:${(props) => props.status == 'no-check' ? subColor : mainColor};
                       background:${(props) => props.status == 'no-check' ? rgba(cyan,0.8) : rgba(subColor,0.8)};
        }
        .no-select:hover i { opacity:0.6; }
        .btn:hover .description { display:inline-block; top:-35px; left:-50px; }
      }

      .pub-date { width:100px; float:right; text-align:right; }
    }
  }
`

const StatusFilter = styled.div`
  &&& {
    position:absolute; right:0; height:30px; padding:0 5px; font-size:12px; text-align:left; border-radius:5px 0 0 5px; box-shadow:0 0 6px ${rgba(shadowColor,0.6)}; cursor:pointer;
    width:${(props) => props.on ? '100' : '50'}px;
    opacity:${(props) => props.on ? '0.9' : '0.6'}; &:hover { opacity:${(props) => props.on ? '1' : '0.8'}; }
    bottom:${(props) => props.status == 'no-check' ? '120' : props.status == 'check-later' ? '85' : props.status == 'checked' ? '50' : '15'}px;
    background:${(props) => props.status == 'no-check' ? subColor : props.status == 'check-later' ? '#f5f500' : props.status == 'checked' ? cyan : magenta};
    color:${(props) => props.status == 'no-check' ? cyan : subColor};
    display:table;
    .label { display:table-cell; vertical-align:middle; }
  }
`


export { StudiesField, StudiesStyle, Study, StatusFilter };
