import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

const Block = styled.div`
  &&& {
    ${fadeIn(1,0,-1)}
    width:98%; height:auto; margin:0 auto 20px auto; padding:0px 0px; opacity:0.95;
    background:${ props => props.on ? mainColor : subColor};
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;

    p { color:${ props => props.on ? subColor : mainColor}; font-size:15px; padding:10px 20px; }

    @media screen and (min-width:750px) {
      width:${(props) => props.full ? '80%' : '98%'};
    }
    @media screen and (min-width:960px) {
      width:${(props) => props.full ? '60%' : '98%'};
    }

  }
`

const BlockName = styled.div`
  &&& {
    padding:20px 20px 10px 20px;
    color:${props => (props.on ? subColor : lighten(mainColor,0.2))};
    background:${props => (props.on ? mainColor : 'none')};
    font-size:22px; font-weight:300; text-align:left; 
  }
`

const UserName = styled.div`
  &&& {
    width:100%; height:50px; margin:5px 0; padding:0; background:${subColor};
    background:${props => (props.on ? mainColor : subColor)};
    img { width:40px; height:40px; margin:5px 10px 5px 20px; border-radius:50%; border:0.05px solid ${mainColor}; display:inline-block; float:left; }
    p { color:${props => (props.on ? subColor : mainColor)}; font-size:20px; width:calc(100% - 70px); height:50px; margin:0px 0; padding:10px 0; display:inline-block; float:right; }
    a { color:${props => (props.on ? subColor : mainColor)}; }
    &:hover { background:${props => (props.on ? lighten(mainColor,0.05) : lighten(subColor,0.05))}; }
  }
`

const Media = styled.div`
  &&& {
    position:relative; margin:0 auto 10px auto; text-align:center;
    img { width:auto; height:auto; max-width:calc(100% - 40px); max-height:90vh; margin:auto; display:block; object-fit:contain; }
    video { width:auto; height:auto; max-width:calc(100% - 40px); max-height:90vh; margin:auto; display:block; object-fit:contain; }
    .play-btn { position:absolute; top:0; bottom:0; left:0; right:0; width:70px; height:70px; margin:auto;
                border:2px solid ${rgba(mainColor,0.6)}; border-radius:10000px; background:${rgba(subColor,0.8)}; cursor:pointer;
      .play-btn-triangle { width:100%; height:100%; display:table; text-align:center;
        i { color:${rgba(mainColor,0.8)}; font-size:40px; display:table-cell; vertical-align:middle; padding-left:5px; }
      }
    }
  }
`

const FormField = styled.div`
  &&& {
    width:100%; height:auto; margin:0 auto 20px auto; padding:0 20px;
    label { color:${mainColor}; font-size:15px; }
    input { width:100%; padding:10px; color:${mainColor}; border:none; border-bottom:1px solid ${mainColor}; background:${subColor}; }
    textarea { width:100%; height:300px; padding:10px; color:${mainColor}; border:1px solid ${rgba(mainColor,0.2)}; border-radius:5px; background:${subColor}; }
    input:focus { border-bottom:3px solid ${mainColor}; outline:0; }
    input:-webkit-autofill { animation-name:onAutoFillStar; transition:background-color 50000s ease-in-out 0s; -webkit-text-fill-color: ${mainColor} !important; }
    textarea:focus { border:1px solid ${mainColor}; outline:0; }
    .error { color:${errorColor}; }
    .correct { color:${correctColor}; }

    @media screen and (min-width:750px) {
      li:hover label{ color:${mainColor}; }
    }
  }
`

// ボタンフィールド
const LinkBtnField = styled.div`
  &&& {
    width:100%; height:auto; margin:0; padding:10px 0 20px 0; text-align:center; clear:both;
  }
`

// 一般的なボタン
const LinkBtn = styled(Link)`
  &&& {
    display:inline-block; width:auto; height:auto; margin:auto; padding:7.5px 15px; color:${subColor}; text-align:center; text-decoration:none; 
    background:${ props => props.off ? rgba(mainColor,0.2) : mainColor}; border-radius:5px;
    &:hover { background:${props => props.off ? rgba(mainColor,0.2) : lighten(mainColor,0.1)}; }
  }
`

const Links = styled.div`
  &&& {
    padding:40px 20px; text-align:center;
    a { color:${mainColor}; display:inline-block; padding:10px; }
    a:hover { background:${rgba(mainColor,0.05)}; border-radius:100000px; }
  }
`

// リンクブロック
const LinkBlock = styled(Block.withComponent(Link))`
  &&& {
    display:block; padding:10px; cursor:pointer;
    .block-name { text-align:center; color:${ props => props.on ? subColor : mainColor}; font-size:18px; line-height:20px; font-weight:100;
      i { margin-left:15px; }
    }
    &:hover { background:${ props => props.on ? lighten(mainColor,0.05) : lighten(subColor,0.05)}; }
  }
`

const ImageBlock = styled(Block.withComponent('img'))`
  &&& {
    height:200px; display:block; object-fit:cover;
  }
`

const FilterBlock = styled(Block)`
  &&& {
    animation:none; transform:none; overflow:visible;
  }
`

const Filter = styled.div`
  &&& {
    width:33.333%; height:auto; margin:0; padding:10px 20px 10px; color:${lighten(mainColor, 0.2)}; text-align:center; float:left; 
    label { font-size:12px; } 
    .filter { position:relative; width:100%; height:60px; 
      p { position:absolute; top:0; bottom:0; left:0; right:0; text-align:center; font-size:25px; cursor:pointer; } 
      p:hover { background:${lighten(subColor,0.2)}; }  
      .select-menu { position:absolute; top:60px; left:0; right:0; z-index:15000000; width:100%; height:auto; max-height:150px; overflow-x:hidden; overflow-y:scroll;
                     background:${subColor}; color:${mainColor}; text-align:center; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; 
        .menu-list { padding:3px 10px; cursor:pointer; } 
        .menu-list:hover { background:${lighten(subColor,0.2)}; } 
      } 
    } 
    .select-menu-wrapper { position:fixed; top:0; bottom:0; left:0; right:0; z-index:100; width:120%; height:120%; margin:auto; background:none; } 
  }
`

const ErrorBlock = styled(Block)`
  &&& {
    background:${subColor}; box-shadow:0 0 6px ${errorColor};
    p, span { color:${errorColor}; }
  }
`

const ErrorBlockName = styled(BlockName)`
  &&& {
    color:${errorColor};
  }
`

const MediaPanels = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;

    @media screen and (min-width:750px) {
      width:80%;
    }

  }
`

const MediaPanel = styled.div`
  &&& {
    ${fadeIn(1,0,-1)}
    width:50vw; height:50vw; float:left; position:relative;
    img { width:calc(100% - 5px); height:calc(100% - 5px); margin:2.5px; padding:0; display:block; object-fit:cover; }
    .play-btn { position:absolute; top:0; bottom:0; left:0; right:0; width:70px; height:70px; margin:auto;
                border:2px solid ${rgba(mainColor,0.6)}; border-radius:10000px; background:${rgba(subColor,0.8)}; cursor:pointer;
      .play-btn-triangle { width:100%; height:100%; display:table; text-align:center;
        i { color:${rgba(mainColor,0.8)}; font-size:40px; display:table-cell; vertical-align:middle; padding-left:5px; }
      }
    }

    @media screen and (min-width:750px) {
      width:20vw; height:20vw;
      img { width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; box-shadow:0 0 6px ${shadowColor}; cursor:pointer; }
      img:hover { transform:scale(1.2,1.2); }
    }
    @media screen and (min-width:960px) {
      width:13.333vw; height:13.333vw;
    }

  }
`

const MediaWindow = styled.div`
  &&& {
    ${fadeIn(1,0,-1)}
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:50; background:${rgba(subColor,0.2)}; 
    .media-wrapper { width:90vw; height:90vh; margin:5vh 5vw; display:block;
      img { width:auto; height:auto; max-width:100%; max-height:100%; margin:auto; display:block; object-fit:contain; }
      video { width:auto; height:auto; max-width:100%; max-height:100%; margin:auto; display:block; object-fit:contain; }
    }
  }
`

export { Block, BlockName, UserName, Media, FormField, LinkBtnField, LinkBtn, Links, LinkBlock, ImageBlock, FilterBlock, Filter, ErrorBlock, ErrorBlockName, MediaPanels, MediaPanel, MediaWindow };
