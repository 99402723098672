import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

const TranslateStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};
  }
`

const TranslateField = styled.div`
  &&& {
    width:98%; height:auto; margin:0px auto; padding:20px 10px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;
    .title { color:${edgeColor}; font-size:20px; }
    p { color:${mainColor}; font-size:16px; }
    .congratulation { width:100%; height:100%; object-fit:contain; }
  }
`

const TextField = styled.div`
  &&& {
    width:100%; position:relative;

    .select { display:block; position:relative; width:80%; height:40px; margin:0; padding:0; float:left;
      .select-item {
        width:98%; height:100%; margin:auto; color:${mainColor}; text-align:center; font-size:20px; font-weight:300; cursor:pointer;
      }
      .select-item:hover { background:${rgba(mainColor,0.1)}; }
      .select-menu { position:absolute; top:40px; left:0; right:0; z-index:150; width:100%; height:auto; max-height:150px; overflow-y:scroll; background:${subColor};
                     color:${mainColor}; text-align:center; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
        .menu-item { padding:1.5px 10px; cursor:pointer; }
        .menu-item:hover { background:${rgba(mainColor,0.1)}; }
      }
    }

    .speech, .save, .delete { width:40px; height:40px; color:${mainColor}; font-size:20px; text-align:center; display:table; float:right; 
      i { display:table-cell; vertical-align:middle; cursor:pointer; }
      i:hover { background:${rgba(mainColor,0.05)}; }
    }
    .speech { margin-right:20px; }

    .title { display:block; position:relative; width:50%; height:40px; padding:2px 10px; float:left; color:${mainColor}; text-align:left; font-size:20px; font-weight:300; cursor:pointer;
    }

    .switch { display:block; position:absolute; top:0; right:-15px; z-index:100; width:30px; height:30px; text-align:center; display:table; cursor:pointer; border:0.5px solid ${mainColor}; border-radius:1000px;
      i { display:table-cell; vertical-align:middle; color:${mainColor}; font-size:18px; }
    }
    .switch:hover { border:1px solid ${edgeColor};
      i { color:${edgeColor}; }
    }

    .translating { position:absolute; top:0; bottom:0; left:0; right:0; display:table; background:${edgeColor};
      p { display:table-cell; vertical-align:middle; text-align:center; color${mainColor}; }
    }

    textarea { width:100%; height:auto; padding:10px; color:${mainColor}; font-size:18px; border:none; outline:none; background:${subColor}; resize:none; overflow:hidden; }
    #from-text, #from-text:focus { border:none; border-bottom:0.5px solid ${rgba(mainColor,0.5)}; }

    .correct-btn, .forget-btn {
      width:calc(50% - 10px); margin:5px; padding:5px; border-radius:5px; color:${subColor}; font-size:18px; text-align:center; font-weight:900; display:inline-block; cursor:pointer;
    }
    .correct-btn { background:${correctColor}; }
    .forget-btn { background:${errorColor}; }
    .correct-btn:hover, .forget-btn:hover { border:3px solid ${edgeColor}; }

    @media screen and (min-width:750px) {
      width:50%; float:left;
      .select { width:150px; margin-left:20px; }
      textarea { min-height:${ props => props.learning ? '0' : '200'}px; }
      #from-text, #from-text:focus { border:none; border-right:0.5px solid ${rgba(mainColor,0.5)}; }
    }

  }
`

const SelectMenuWrapper = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:10; width:100%; height:100%; margin:auto; background:none;
  }
`

export { TranslateStyle, TranslateField, TextField, SelectMenuWrapper };
