import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

const DistributedLearningStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};

    .learning-hide { opacity:0.1; }
  }
`

const DistributedLearningField = styled.div`
  &&& {
    width:98%; height:auto; margin:0px auto; padding:${ props => props.congratulation ? '0' : '20px 0px 180px 0px'}; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;
    .title { color:${edgeColor}; font-size:20px; }
    p { color:${edgeColor}; font-size:16px; }
    .congratulation { width:100%; height:100%; object-fit:contain; }

    .head { width:100%; height:30px; margin-bottom:20px; position:relative; padding:0 18px;
      .type, .delete, .edit, .progress, .span, .read-time { display:block; position:relative; }
      .type { width:60%; height:100%; color:${edgeColor}; text-align:left; font-size:22px; font-weight:300;  float:left; }
      .delete, .edit, .progress, .span, .read-time { width:calc(10% - 4px); height:100%; margin:0 2px; font-size:15px; font-weight:200; float:right; }
      .progress, .span, .read-time { color:${edgeColor}; text-align:center; }
      .edit, .delete { font-size:16px; text-align:center; }
      .edit { padding:5px; color:${edgeColor}; font-weight:100; border:1px solid ${edgeColor}; border-radius:2px; cursor:pointer; }
      .edit:hover { color:${subColor}; font-weight:900; background:${edgeColor}; }
      .delete { padding:6px; color:${rgba(errorColor,0.5)}; pointer-events:none;
        i { cursor:pointer; pointer-events:auto; }
        i:hover { color:${errorColor}; }
      }
      .delete:hover { border:1px solid ${errorColor}; }
    }

    #answer-field {
      width:80%; height:auto; margin:30px auto;
      .retry-btn, .correct-btn, .forget-btn {
        width:calc(50% - 10px); margin:5px; padding:5px; border-radius:5px; color:${subColor}; font-size:18px; text-align:center; font-weight:900;
        display:inline-block; cursor:pointer;
      }
      .retry-btn { background:${yellow}; }
      .correct-btn { background:${correctColor}; }
      .forget-btn { background:${errorColor}; }
      .retry-btn:hover, .correct-btn:hover, .forget-btn:hover { border:3px solid ${edgeColor}; }
    }

    #complete-field {
      width:80%; height:auto; margin:30px auto;
      .complete-btn {
        width:100%; height:auto; margin:auto; padding:5px; border-radius:5px; color:${subColor}; font-size:18px; text-align:center; font-weight:900; display:inline-block;
        cursor:${ props => props.status ? 'pointer' : 'default'};
        background:${ props => props.status ? rgba(edgeColor,1) : rgba(edgeColor,0.3)};
        animation:${ props => props.status ? 'backgroundFlash 1s linear infinite' : ''};
      }
    }


    @media screen and (min-width:750px) {
      width:60%;
    }

  }
`






const TextField = styled.div`
  &&& {
    width:100%; position:relative;

    .select { display:block; position:relative; width:80%; height:40px; margin:0; padding:0; float:left;
      .select-item {
        width:98%; height:100%; margin:auto; color:${edgeColor}; text-align:center; font-size:20px; font-weight:300; cursor:pointer;
      }
      .select-item:hover { background:${rgba(mainColor,0.1)}; }
      .select-menu { position:absolute; top:40px; left:0; right:0; z-index:150; width:100%; height:auto; max-height:150px; overflow-y:scroll; background:${subColor};
                     color:${edgeColor}; text-align:center; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
        .menu-item { padding:1.5px 10px; cursor:pointer; }
        .menu-item:hover { background:${rgba(mainColor,0.1)}; }
      }
    }

    .speech, .save, .delete { width:40px; height:40px; color:${edgeColor}; font-size:20px; text-align:center; display:table; float:right; 
      i { display:table-cell; vertical-align:middle; cursor:pointer; }
      i:hover { background:${rgba(mainColor,0.05)}; }
    }
    .speech { margin-right:20px; }

    .title { display:block; position:relative; width:50%; height:40px; padding:2px 10px; float:left; color:${edgeColor}; text-align:left; font-size:20px; font-weight:300; cursor:pointer; }

    .switch { display:block; position:absolute; top:0; right:-15px; z-index:100; width:30px; height:30px; text-align:center; display:table; cursor:pointer; border:0.5px solid ${mainColor}; border-radius:1000px;
      i { display:table-cell; vertical-align:middle; color:${mainColor}; font-size:18px; }
    }
    .switch:hover { border:1px solid ${edgeColor};
      i { color:${edgeColor}; }
    }

    .translating { position:absolute; top:0; bottom:0; left:0; right:0; display:table; background:${edgeColor};
      p { display:table-cell; vertical-align:middle; text-align:center; color${edgeColor}; }
    }

    textarea { width:100%; height:auto; padding:10px; color:${edgeColor}; font-size:18px; border:none; outline:none; background:${subColor}; resize:none; overflow:hidden; }
    #from-text, #from-text:focus { border:none; border-bottom:0.5px solid ${rgba(mainColor,0.5)}; }

    .retry-btn, .correct-btn, .forget-btn {
      width:calc(50% - 10px); margin:5px; padding:5px; border-radius:5px; color:${subColor}; font-size:18px; text-align:center; font-weight:900; display:inline-block; cursor:pointer;
    }
    .retry-btn { background:${yellow}; }
    .correct-btn { background:${correctColor}; }
    .forget-btn { background:${errorColor}; }
    .retry-btn:hover, .correct-btn:hover, .forget-btn:hover { border:3px solid ${edgeColor}; }

    @media screen and (min-width:750px) {
      width:50%; float:left;
      .select { width:150px; margin-left:20px; }
      textarea { min-height:${ props => props.learning ? '0' : '200'}px; }
      #from-text, #from-text:focus { border:none; border-right:0.5px solid ${rgba(mainColor,0.5)}; }
    }

  }
`

const SelectMenuWrapper = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:10; width:100%; height:100%; margin:auto; background:none;
  }
`


const MusiquiField = styled.div`
  &&& {
    padding:30px 18px 20px 18px; color:${edgeColor}; font-size:18px; line-height:22px; word-break:break-all; clear:both;
    h1 { font-size:30px; line-height:32px; font-weight:600; }
    h2 { font-size:23px; line-height:25px; margin-top:80px; font-weight:500; border-bottom:inset 5px ${edgeColor}; }
    h3 { font-size:20px; line-height:22px; margin-top:40px; font-weight:400; border-left:inset 5px ${edgeColor};}
    h4 { font-size:16px; line-height:18px; margin-top:40px; margin-bottom:-12px; font-weight:300; }
    p { font-size:13px; font-weight:200; }
    b { color:${edgeColor}; }
    a { color:${mainColor}; border-bottom:1px solid ${mainColor}; }
    a:hover { color:${yellow}; font-weight:bold; }
    ul { padding-left:15px; }
    ul li { margin-bottom:10px; }
    .content_tag a { color:red; margin-left:5px; }
    img { width:90%; height:auto; margin:auto; display:block; filter:invert(100%) brightness(75%) sepia(80%) hue-rotate(130deg) saturate(800%) contrast(1); }
    i, i b, i a, i u, i s { font-style:normal; font-weight:bold; color:${yellow}; background:${yellow}; cursor:pointer; border-radius:3px; border:none; }
    .show-musiqui, .show-musiqui b, .show-musiqui a, .show-musiqui u, .show-musiqui s { color:${correctColor}; background:none; border-bottom:2px solid ${yellow};
      a { border-color:${mainColor}; }
    }
    .show-musiqui-incorrect, .show-musiqui-incorrect b, .show-musiqui-incorrect a, .show-musiqui-incorrect u, .show-musiqui-incorrect s { color:${errorColor}; background:none; border-bottom:2px solid ${yellow};
      a { border-color:${mainColor}; }
    }
    .code { font-size:13px; color:${edgeColor}; padding:20px 10px; background:${darken(subColor,0.2)}; border-radius:5px; white-space:pre; overflow:scroll; }
    table { border-collapse:collapse; border:1px solid ${edgeColor}; color:${edgeColor};
      th, td { border:1px solid ${edgeColor}; color:${edgeColor}; font-size:13px; padding:5px 10px; }
    }

    #summary-musiqui {
      .summary-node { display:block; padding:3px 5px; }
      .show-node { display:block; color:${edgeColor}; opacity:1; }
      .hide-node { display:none; opacity:0; }
      .highlight-node { background:${rgba(edgeColor,0.7)}; color:${subColor};
        a { color:${subColor}; border-color:${subColor}; font-weight:900; }
        i { background:${subColor}; color:${subColor}; }
        .show-musiqui, .show-musiqui-incorrect { background:${subColor}; border-bottom:none; }
        .show-musiqui { color:${correctColor}; }
        .show-musiqui-incorrect { color:${errorColor}; }
      }
      .stnby-node { display:block; color:${rgba(subColor,1)}; opacity:0.3;
        .list-tag { color:${subColor}; }
      }
      .focus-summary-node { background:${yellow}; font-weight:bold; }
    }

    @media screen and (min-width:750px) {
      img { width:40%; }
      img:hover { width:70%; }
    }

  }
`

const ShakyoField = styled.div`
  &&& {
    width:100%; height:auto; margin:0; padding:20px 10px; background:${subColor}; position:relative; clear:both;

    #shakyo-front, #shakyo-back, #shakyo-highlight, #shakyo-shadow { position:absolute; top:0; left:0; width:100%; height:auto; margin:0; padding:0; background:none; }
    #shakyo-front, #shakyo-back, #shakyo-highlight, #shakyo-highlight div, #shakyo-shadow, #shakyo-shadow div { line-height:1.5; font-size:14px; margin:0; padding:0; }
    #shakyo-front, #shakyo-back { padding-left:40px; border:none; outline:none; resize:none; overflow:hidden; tab-size:4; -moz-tab-size:4; }

    #shakyo-front { color:${rgba(mainColor,0)}; background:${rgba(subColor,0)}; min-height:auto; }
    #shakyo-front::selection { color:${rgba(mainColor,0)}; background:${rgba(mainColor,0)}; }
    #shakyo-front::-moz-selection { color:${rgba(mainColor,0)}; background:${rgba(mainColor,0)}; }

    #shakyo-back { color:${rgba(mainColor,0.3)}; opacity:0; }

    #shakyo-highlight, #shakyo-shadow {
      .line { width:100%; height:auto; margin:0; padding:0; display:flex; clear:both;
        // 行番号
        .line-num { width:40px; height:auto; margin:0; padding:0; display:inline-block; text-align:center; }
        // 写経文
        .text { width:calc(100% - 40px); height:auto; margin:0; padding:0; display:inline-block; word-break:break-all; white-space:break-spaces; }
      }
    }

    #shakyo-highlight {
      // 奇数行
      .odd-num-line { background:${lighten(subColor,0.02)}; color:${rgba(mainColor,0.3)}; }
      // 偶数行
      .even-num-line { background:${darken(subColor,0.02)}; color:${rgba(mainColor,0.3)}; }
      // キャレットのある行、選択状態の行
      .select-line { background:${rgba(edgeColor,0.2)}; color:${rgba(mainColor,0.3)}; }
      // 間違いのある行
      .wrong-line { background:${rgba(errorColor,0.2)}; color:${rgba(mainColor,0.3)}; }
      // キャレットのある行、選択状態で、間違いのある行
      .select-wrong-line { background:${rgba(yellow,0.2)}; color:${rgba(mainColor,0.3)}; }
      // 未入力の行
      .not-entered-line { background:${lighten(subColor,0.04)}; color:${rgba(mainColor,0.3)}; }
    }

    #shakyo-shadow {
      color:${ props => props.status ? rgba(mainColor,1) : rgba(mainColor,1)}; animation:${ props => props.status ? 'colorFlash 1s linear infinite' : ''};
      background:${ props => props.status ? rgba(correctColor, 0.1) : rgba(subColor,0)};
      .caret { width:1px; height:auto; margin:0; padding:0; display:inline-block; color:${rgba(edgeColor,0)}; background:${rgba(edgeColor,0.8)};
               animation:backgroundFlash 1s linear infinite; }
      .select-text { width:auto; height:auto; margin:0; padding:0; display:inline; color:${rgba(subColor,1)}; background:${rgba(edgeColor,0.8)}; }
    }
  }
`

const TimerField = styled.div`
  &&& {
    width:80px; height:80px; position:fixed; bottom:30px; right:30px; opacity:0.7;

    .btn { width:80px; height:80px; display:table; border-radius:10000px; position:absolute; top:0; bottom:0; left:0; right:0; cursor:pointer; transition:.4s; }
    .sub-btn { width:60px; height:60px; margin:10px; opacity:0; }
    .btn-name { display:table-cell; vertical-align:middle; text-align:center; }
    .btn:hover { font-weight:bold; }

    #start-btn, #status-count, #count-time { background:${mainColor}; color:${subColor}; }
    #start-btn { font-size:35px; padding-left:5px; }
    #status-count { font-size:35px;
      #clock-dot { font-size:15px; width:15px; display:inline-block; overflow:visible; }
    }
    #count-time { font-size:20px; }
    #start-btn { z-index:3; }
    #status-count { z-index:4; }
    #count-time { z-index:2; }

    #reset-btn, #stop-btn, #restart-btn { background:${mainColor}; color:${subColor}; font-size:16px; }
    #reset-btn { font-size:16px; }
    #stop-btn { font-size:22px; }
    #restart-btn { font-size:22px; padding-left:5px; }
    #reset-btn, #stop-btn, #restart-btn { z-index:1; }

    &:hover {
      width:${ props => props.status == "stand-by" ?  "none" : "150px"}; height:${ props => props.status == "stand-by" ? "none" : "150px"};
      border-radius:${ props => props.status == "stand-by" ? "0" : "100px 0 0 0"};
      opacity:1;

      .sub-btn { margin:0; opacity:1; }

      #start-btn, #status-count { background:${edgeColor}; }
      #status-count:hover { opacity:0; }
      #count-time { background:${ props => props.status == "stop" ? mainColor : edgeColor }; }
      #stop-btn:hover { background:${yellow}; }
      #restart-btn:hover { background:${edgeColor}; }
      #reset-btn:hover { background:${errorColor}; }

      #status-count, #count-time { top:auto; left:auto; }
      #stop-btn, #restart-btn { bottom:auto; left:auto; right:20px; }
      #reset-btn { top:auto; right:auto; bottom:20px; }
    }

  }
`

export { DistributedLearningStyle, DistributedLearningField, TextField, SelectMenuWrapper, MusiquiField, ShakyoField, TimerField };
