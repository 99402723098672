import axios from 'axios';
  
export default function GetPostPareoNaOtoko(url) {

  return new Promise((resolve, reject) => {

    let params = { url:url, };
    axios.post('https://lab.takuman.me/api/scraping/pareo_na_otoko/post', params)
    .then(res => {
      let post = res.data.post;
      resolve(post);
    })
    .catch(err => {});

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
