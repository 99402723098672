import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn } from 'component/common/style/Animations';

const PornDatasField = styled.div`
  &&& {
    width:100%; height:calc(100vh - 0px); margin:0; padding:50px 0 0 0; background:${subColor}; position:relative;
  }
`

const PornDatasStyle = styled.div`
  &&& {
    width:${(props) => props.full ? 'calc(100% - 250px)' : '100%'};
    height:${(props) => props.full ? '100%' : 'auto'};
    padding-bottom:100px;
    float:right; overflow-y:scroll;

    #display-pattern { width:300px; height:30px; margin:0 0 30px 20px; border:1px solid ${mainColor}; border-radius:5px;
      .pattern-name { width:50%; height:100%; padding:3px; color:${mainColor}; font-size:18px; text-align:center; cursor:pointer; display:inline-block; }
      .on { color:${subColor}; background:${mainColor}; cursor:default; }
    }

    // パネル表示時のパネルの大きさを調整するスライダー
    #panel-size-changer {
      -webkit-appearance:none; appearance:none; cursor:pointer;
      margin:0 0 30px 20px;
      // バーのスタイル
      background:${rgba(mainColor,0.6)}; width:80%; height:5px; border-radius:10px; border:0px solid ${edgeColor};
      outline:0; /* アウトラインを消して代わりにfocusのスタイルをあてる */
      &:focus {
        box-shadow:0 0 3px rgb(0, 161, 255);
      }
      // -webkit-向けのつまみ
      &::-webkit-slider-thumb {
        -webkit-appearance:none; background:${edgeColor}; border:3px solid ${yellow}; width:20px; height:20px; border-radius:50%; box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.15);
      }
      // -moz-向けのつまみ
      &::-moz-range-thumb {
        background:${edgeColor}; border:3px solid ${yellow}; width:20px; height:20px; border-radius:50%; box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.15); border:none; }
      // Firefoxで点線が周りに表示されてしまう問題の解消
      &::-moz-focus-outer { border:0; }
      // つまみをドラッグしているときのスタイル
      &:active::-webkit-slider-thumb { box-shadow:0px 5px 10px -2px rgba(0, 0, 0, 0.3); }
    }

    #check-panel-datas {
      width:100%; height:150px; margin:0; padding:60px 0; clear:both;
      #check-btn {
        width:300px; height:30px; margin:auto; padding:5px; background:${mainColor}; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
        color:${subColor}; font-size:18px; font-weight:600; text-align:center; cursor:pointer;
      }
      #check-btn:hover { background:${edgeColor}; padding:1px; border:4px solid ${yellow}; }
    }

  }
`

const PornData = styled.div`
  &&& {
    width:${(props) => props.full ? '70%' : '98%'};
    height:auto; margin:20px auto; padding:10px 20px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; position:relative; clear:both;
    color:${mainColor}; font-size:18px; line-height:20px;

    .count { width:50%; height:22px; margin:0; padding:5px; color:${mainColor}; font-size:12px; text-align:left; display:inline-block;float:left; }
    .trash-btn { width:22px; height:22px; margin:0; padding:5px; color:${attentionColor}; font-size:12px; text-align:right; diplay:inline-block; float:right; cursor:pointer; }
    .trash-btn:hover { background:${rgba(attentionColor,0.1)}; }
    .pub-date { width:30%; height:22px; margin:0; padding:5px; color:${mainColor}; font-size:12px; text-align:right; display:inline-block; float:right; }
    .delete-confirm { width:100%; height:35px; margin:20px 0 0 0; padding:5px; color:${attentionColor}; font-size:18px; text-align:center;
                      background:${subColor}; border:2px solid ${attentionColor}; border-radius:5px; cursor:pointer; }
    .delete-confirm:hover { color:${subColor};  background:${attentionColor}; }

    .contributor { width:100%; height:50px;
      .contributor-icon { width:50px; height:50px; margin:0; padding:5px; display:inline-block; float:left;
        img { width:40px; height:40px; border-radius:10000px; object-fit:cover; }
      }
      .contributor-name { width:calc(100% - 50px); height:50px; margin:0; padding:15px; color:${mainColor}; font-size:16px; float:right; }
    }

    .description { width:100%; height:auto; margin:0; padding:10px 0; color:${mainColor}; font-size:16px; clear:both; }

    .files { width:100%; height:auto;
      .file { width:50%; height:auto; display:inline-block;
        img { width:calc(100% - 20px); height:auto; margin:auto 10px; object-fit:contain; }
      }
    }

    .genre { position:absolute; top:20px; right:0; width:auto; height:auto; margin:0; padding:5px 10px 5px 20px; background:${mainColor}; border-radius:1000px 0 0 1000px;
             color:${subColor}; font-size:16px; text-align:center; }

  }
`

const PanelPornData = styled.div`
  &&& {
    width:auto; height:${ (props) => props.size ? props.size + 'px': '0'}; margin:5px; padding:0; float:left; border:1px solid ${edgeColor}; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; position:relative;
    .files { width:auto; height:calc(100% - 10px); margin:5px 2.5px; padding:0; cleat:both;
      .file { width:auto; height:100%; margin:0 2.5px; display:block; float:left; overflow:hidden;
        img { width:auto; height:100%; margin:0; }
      }
    }
    .delete-btn, .copy-btn-one, .copy-btn-two, .copy-text-one, .copy-text-two {
      display:none; position:absolute; z-index:100; width:25px; height:25px; padding:3px; background:${mainColor}; border-radius:50%; cursor:pointer;
      color:${subColor}; font-size:19px; text-align:center; font-weight:900;
    }
    .delete-btn { top:-12.5px; right:-12.5px; }
    .copy-btn-one { bottom:-12.5px; left:-12.5px; }
    .copy-btn-two { bottom:-12.5px; right:-12.5px; }
    .copy-text-one, .copy-text-two {
      z-index:90; background:${rgba(subColor,0.95)}; color:${mainColor}; width:200px; height:160px; padding:20px 10px 10px 10px;
      border-radius:5px; border:1px solid ${yellow}; cursor:default; font-size:10px; text-align:left; overflow:hidden;
    }
    .copy-text-one { bottom:-160px; left:-100px; }
    .copy-text-two { bottom:-160px; right:-100px; }
    &:hover {
      .delete-btn, .copy-btn-one, .copy-btn-two { display:block; }
      .delete-btn:hover { background:${attentionColor}; }
      .copy-btn-one:hover, .copy-btn-two:hover { background:${edgeColor}; }
      .copy-btn-one:hover ~ .copy-text-one { display:block; }
      .copy-btn-two:hover ~ .copy-text-two { display:block; }
    }
  }
`

const Media = styled.div`
  &&& {
    width:auto; height:100%;
    position:relative; margin:0 auto 10px auto; text-align:center;
    .thumb { cursor:pointer; }
    video {
      width:auto; height:auto; max-width:${ (props) => props.displayPattern == 'timeline' ? 'calc(100% - 40px)' : 'auto'}; max-height:${ (props) => props.displayPattern == 'timeline' ? '90vh' : '100%'};
      margin:auto; display:block; object-fit:contain;
    }
    .play-btn { position:absolute; top:0; bottom:0; left:0; right:0;
                width:${ (props) => props.displayPattern == 'timeline' ? '70px' : props.btnSize ? props.btnSize + 'px' : '70px'};
                height:${ (props) => props.displayPattern == 'timeline' ? '70px' : props.btnSize ? props.btnSize + 'px' : '70px'};
                margin:auto;
                border:2px solid ${rgba(mainColor,0.6)}; border-radius:10000px; background:${rgba(subColor,0.8)}; cursor:pointer;
      .play-btn-triangle { width:100%; height:100%; display:table; text-align:center;
        i {
          color:${rgba(mainColor,0.8)}; font-size:${ (props) => props.displayPattern == 'timeline' ? '40px' : props.btnSize ? (props.btnSize * 0.57) + 'px' : '40px'};
          display:table-cell; vertical-align:middle; padding-left:5px;
        }
      }
    }
  }
`

const Checked = styled.div`
  &&& {
    opacity:0; width:100%; height:10px; margin:-5px auto; padding:0; background:${rgba(mainColor,0.1)}; position:relative;
    .line { position:absolute; top:0; left:0; right:0; width:95%; height:50%; margin:auto; padding:0; border-bottom:1px dashed ${mainColor}; }
    .text { position:absolute; top:0; bottom:0; left:0; right:0; width:100px; height:100%; margin:auto; padding:0;
            color:${subColor}; font-size:16px; line-height:22px; text-align:center; background:${mainColor}; border:5px double ${subColor}; cursor:pointer; }
    .text:hover { border:6px double ${subColor}; }
    &:hover { opacity:1; height:34px; margin:20px auto; }
  }
`

const Loading = styled.div`
  &&& {
    position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; margin:auto; padding:0; background:${rgba(mainColor,0.1)}; display:table;
    .text { color:${mainColor}; font-size:22px; text-align:center; display:table-cell; vertical-align:middle; }
  }
`

export { PornDatasField, PornDatasStyle, PornData, PanelPornData, Media, Checked, Loading };
