import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

// style
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { PostsStyle } from 'component/common/style/Posts';

// module
import PostsSearchBox from 'component/common/module/PostsSearchBox';
import PostsBlockLayout from 'component/common/module/PostsBlockLayout';
import PostsFeedLayout from 'component/common/module/PostsFeedLayout';
import PostsFullpageLayout from 'component/common/module/PostsFullpageLayout';


class Posts extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    let pathItems = window.location.pathname.slice(1).split('/');
    let layout = 'block';
    if ( (this.props.two == false && pathItems[0].split('_')[1] == 'study') || (this.props.two && pathItems[1].split('_')[1] == 'study') ) {
      layout = 'feed';
    }
    this.state = {
      scrollTop:0,
      layout:layout, // block, feed, timeline, fullpage
      type:this.props.type, 
      postsCount:0, paginationNum:1,
      posts:[], // 記事一覧を格納するためのものを定義。
    };
  }

  componentDidMount = () => { 
    this.props.logoAnimation('kodo');
  }


  getPosts = (p) => {
    this.setState({ postsCount:p.postsCount, paginationNum:p.paginationNum, posts:p.posts, });
    this.props.helloLab();
  }

  getLayout = (layout) => {
    this.setState({ layout:layout, });
  }


  render() {


    let posts;
    if (this.state.posts <= 0) {
      posts = ( <span></span> );
    } else {
      if (this.state.layout == 'block') {
        posts = ( <PostsBlockLayout posts={this.state.posts} full={this.props.full} one={this.props.one} two={this.props.two} postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} /> );
      } else if (this.state.layout == 'feed') {
        posts = ( <PostsFeedLayout posts={this.state.posts} full={this.props.full} one={this.props.one} two={this.props.two} postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} /> );
      } else if (this.state.layout == 'fullpage') {
        posts = ( <PostsFullpageLayout posts={this.state.posts} full={this.props.full} one={this.props.one} two={this.props.two} postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} /> );
      }
    }



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={this.state.type + ' | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two} zIndex={200}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
          <div className='btn right'>
            <Link to={this.props.full ? '/' + pathItems[0] + '_multiupload' : this.props.one ? '/' + pathItems[0] + '_multiupload/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1] + '_multiupload'}><i className='far fa-images'></i></Link>
          </div>
          <div className='btn right'>
            <Link to={this.props.full ? '/' + pathItems[0] + '_new' : this.props.one ? '/' + pathItems[0] + '_new/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1] + '_new'}><i className='far fa-file'></i></Link>
          </div>
        </BackBtnField>

        <PostsStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          <PostsSearchBox type={this.state.type} layout={this.state.layout} getPosts={this.getPosts} getLayout={this.getLayout} full={this.props.full} one={this.props.one} two={this.props.two} />
          {posts}
        </PostsStyle>
      </div>
    );

  }
}
export default Posts;
