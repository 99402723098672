import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import { MusiquiField } from 'component/common/style/learning/DistributedLearning';


class NormalMusiquiField extends React.Component{

  static propTypes = {
    getMusiquiValid: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      learning:this.props.learning,
    };
  }

  componentDidMount = () => { 
    this.validMusiqui();
  }

  componentWillUpdate = () => {
    document.body.addEventListener('keydown', this.showMusiqui);
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('keydown', this.showMusiqui);
  }

  showMusiqui = (event) => {

    // textareaまたはinputにフォーカスが当たっていれば処理を終える
    if (document.activeElement.nodeName == "TEXTAREA" || document.activeElement.nodeName == "INPUT")
      return;

    if (this.props.disable)
      return;

    // エンターキーまたは右矢印キーを押下したときだけスクリプトを実行
    if (event.code == 'Enter' || event.code == 'ArrowRight' || event.code == 'ArrowDown') {
      event.preventDefault();

      // 一番上にある答え未表示の部分を「覚えていた答え」として表示させる。
      let musiqui = document.getElementById('musiqui');
      let musiquiItags = musiqui.getElementsByTagName('i');
      for (var i=0; i<musiquiItags.length; i++) {
        if (musiquiItags[i].classList.contains('show-musiqui') == false && musiquiItags[i].classList.contains('show-musiqui-incorrect') == false) {
          musiquiItags[i].classList.add('show-musiqui');
          break;
        }
      }
    } else if (event.code == 'ArrowUp') {
      event.preventDefault();

      // 一番上にある未表示の答えの１つ手前の答えを非表示に戻す。
      let musiqui = document.getElementById('musiqui');
      let musiquiItags = musiqui.getElementsByTagName('i');
      for (var i=0; i<musiquiItags.length; i++) {
        if (musiquiItags[i].classList.contains('show-musiqui') == false && musiquiItags[i].classList.contains('show-musiqui-incorrect') == false) {
          if (musiquiItags[i-1].classList.contains('show-musiqui')) {
            musiquiItags[i-1].classList.remove('show-musiqui');
          }
          if (musiquiItags[i-1].classList.contains('show-musiqui-incorrect')) {
            musiquiItags[i-1].classList.remove('show-musiqui-incorrect');
          }
          break;
        }
      }
    } else if (event.code == 'ArrowLeft') {
      event.preventDefault();

      // 一番上にある答え未表示の部分を「覚えていなかった答え」として、表示させる。
      let musiqui = document.getElementById('musiqui');
      let musiquiItags = musiqui.getElementsByTagName('i');
      for (var i=0; i<musiquiItags.length; i++) {
        if (musiquiItags[i].classList.contains('show-musiqui') == false && musiquiItags[i].classList.contains('show-musiqui-incorrect') == false) {
          musiquiItags[i].classList.add('show-musiqui-incorrect');
          break;
        }
      }
    }
    this.validMusiqui();
  }

  musiquiClick = (event) => {
    if (this.props.disable)
      return;

    // iタグの中のaタグなどをクリックしている可能性もあるのでクリックした要素がiタグではない場合はその親要素を取得する
    let target = (event.target.tagName == "I") ? event.target : event.target.parentElement;

    if (target.tagName == 'I') {
      if (target.classList.contains('show-musiqui') && target.classList.contains('show-musiqui-incorrect') == false) {
        // 覚えていた答えを非表示にする
        target.classList.remove('show-musiqui');
      } else if (target.classList.contains('show-musiqui-incorrect') && target.classList.contains('show-musiqui') == false) {
        // 覚えていなかった答えを非表示にする
        target.classList.remove('show-musiqui-incorrect');
      } else {
        // 覚えていた答えとして表示する。
        event.preventDefault();
        target.classList.add('show-musiqui');
      }
      this.validMusiqui();
    }
  }


  // すべての要素が表示されており、虫食い部分もすべて正解であればtrueをgetMusiquiValidへリターンする関数
  validMusiqui = () => {
    let musiqui = document.getElementById('musiqui');
    let musiquiItags = musiqui.getElementsByTagName('i');
    let showMusiqui = musiqui.querySelectorAll('i.show-musiqui');
    let showMusiquiIncorrect = musiqui.querySelectorAll('i.show-musiqui-incorrect');
    this.props.getMusiquiValid((musiquiItags.length == showMusiqui.length));
  }


  render() {

    if (this.state.learning) {
      return (
        <MusiquiField id='musiqui' dangerouslySetInnerHTML={{__html : this.state.learning.musiqui}} onClick={ (event) => {this.musiquiClick(event)} } />
      );
    } else {
      return ( <span /> );
    }

  }
}
export default NormalMusiquiField;
