import React, { Component } from 'react';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import History from 'History';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

import { MenuStyle, Displays } from 'component/common/style/Menu';
//import LinkBlock from 'component/common/module/block/LinkBlock';
//import {Block, CustomItemField, CustomBtn, CustomBtnLabel} from 'component/common/style/Block';
//import PlayAudio from 'component/common/PlayAudio'; // Web Audio APIでサウンド再生 
//import PlayVibrate from 'component/common/PlayVibrate'; // Vibration APIでデバイスを振動

class Menu extends React.Component {

  // propのタイプを定義
  static propTypes = {
    logoAnimation: PropTypes.func,
    showMenu: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    let pathItems = window.location.pathname.slice(1).split('/');
    let displays = pathItems.map((pathItem,i) => {
      return (
        <div className='path-name'>{pathItem == '' ? '/home' : pathItem} <Link className='close-btn' to={pathItems.length < 2 ? '/' : i == 0 ? '/' + pathItems[1] : '/' + pathItems[0]}><i className='far fa-times-circle'></i></Link></div>
      );
    });

    if (pathItems.length < 2) {
      displays.push( <Link className='add-btn' to={pathItems[0] == '' ? '/home/home' : '/' + pathItems[0] + '/home'}><i className='fas fa-plus'></i></Link> );
    }

    return (
      <MenuStyle show={this.props.show}>
        <Displays>
          <h1>Displays</h1>
          {displays}
        </Displays>
      </MenuStyle>
    );

  }

}

export default Menu;
