import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

// CreateDistributedLearningMusiqui.js, UpdateDistributedLearningMusiqui.js
// CreateDistributedLearningShakyo.js, UpdateDistributedLearningShakyo.js

/*

// TodayDistributedLearningQa.js
// CreateDistributedLearningQa.js
*/

const DistributedLearningFormStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};

    .koubun { width:98%; height:auto; margin:10px auto; padding:10px 20px; background:${edgeColor}; color:${subColor}; border-radius:5px; }
    .koubun:hover { width:99%; opacity:0.95; font-weight:900; cursor:pointer; }
  }
`

const DistributedLearningFormField = styled.div`
  &&& {
    width:98%; height:auto; margin:0px auto; padding:20px 10px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;
    p { color:${edgeColor}; font-size:16px; }
  }
`

const TypeField = styled.div`
  &&& {
    width:100%; height:60px; margin:0; padding:0;

    .select { display:inline-block; position:relative; width:auto; height:30px; margin:0 20px 0 0; padding:0; float:left;
      .select-item {
        width:100%; height:100%; margin:0; padding:6px 5px; color:${edgeColor}; text-align:left; font-size:18px; font-weight:300; cursor:pointer;
      }
      .select-item:hover { background:${rgba(edgeColor,0.1)}; }
      .select-menu { position:absolute; top:40px; left:0; right:-50%; z-index:150; height:auto; max-height:150px; overflow-y:scroll; background:${subColor};
                     color:${edgeColor}; text-align:center; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
        .menu-item { padding:5px 10px; font-size:16px; cursor:pointer; }
        .menu-item:hover { background:${rgba(edgeColor,0.1)}; }
      }
    }

    .select-span-field { display:inline-block; width:50%; height:30px; margin:0; padding:0; float:left; clear:both;
      .span { display:inline-block; width:50px; height:25px; margin:2.5px 0; padding:5px; color:${edgeColor}; font-size:12px; text-align:center; border:1px solid ${edgeColor}; cursor:pointer; }
      .span:hover { background:${rgba(edgeColor,0.2)}; }
      .select-span, .select-span:hover { color:${subColor}; background:${edgeColor}; border-color:${edgeColor}; cursor:default; }
      .first-span { border-radius:3px 0 0 3px; }
      .last-span { border-radius:0 3px 3px 0; }
    }

    .new-type-form {
      display:inline-block; width:200px; height:30px; margin:0; padding:8px 5px; font-size:18px; color:${edgeColor}; float:left;
      border:2px solid ${rgba(edgeColor,1)}; border-radius:3px; background:${rgba(subColor)}; 
    }
    .new-type-form:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
    .new-type-form:focus { border:3px solid ${edgeColor}; outline:0; }

    .times-field {
      display:inline-block; width:50%; height:30px; float:right;
      #times { width:450px; height:25px; margin:2.5px; padding:5px; color:${edgeColor}; font-size:12px; background:none; border:1px solid ${mainColor}; border-radius:3px; overflow:scroll; float:right; }
      #times:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
      #times:focus { border:2px solid ${edgeColor}; outline:0; }
    }
  }
`

const TextField = styled.div`
  &&& {
    width:100%; position:relative;

    .title { display:block; position:relative; width:50%; height:40px; padding:2px 10px; float:left; color:${edgeColor}; text-align:left; font-size:20px; font-weight:300; cursor:pointer;
    }

    textarea { width:100%; height:auto; padding:10px; color:${edgeColor}; font-size:18px; border:none; outline:none; background:${subColor}; resize:none; overflow:hidden; }
    #from-text, #from-text:focus { border:none; border-bottom:0.5px solid ${rgba(edgeColor,0.5)}; }

    @media screen and (min-width:750px) {
      width:50%; float:left;
      .select { width:150px; margin-left:20px; }
      textarea { min-height:${ props => props.learning ? '0' : '200'}px; }
      #from-text, #from-text:focus { border:none; border-right:0.5px solid ${rgba(edgeColor,0.5)}; }
    }

  }
`

const SelectMenuWrapper = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:10; width:100%; height:100%; margin:auto; background:none;
  }
`

// ボタンフィールド
const LinkBtnField = styled.div`
  &&& {
    width:100%; height:auto; margin:0; padding:10px 0 20px 0; text-align:center; clear:both;
  }
`

// 一般的なボタン
const LinkBtn = styled(Link)`
  &&& {
    display:inline-block; width:auto; height:auto; margin:auto; padding:7.5px 15px; color:${subColor}; text-align:center; text-decoration:none; 
    background:${ props => props.submit ? edgeColor : rgba(edgeColor,0.2)}; border-radius:5px; clear:both;
    &:hover {
      background:${ props => props.submit ? lighten(edgeColor,0.2) : rgba(edgeColor,0.1)};
      font-weight:${ props => props.submit ? '900' : '400'};
      cursor:${ props => props.submit ? 'pointer' : 'default'};
    }
  }
`

export { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn };
