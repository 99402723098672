import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { Block, BlockName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock } from 'component/common/style/Oauth';
import GetFollowers from 'component/common/function//GetFollowers';
import TwitterUsers from 'component/common/module/TwitterUsers';

class TwitterFollowers extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let twitter_id;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      twitter_id = pathItems[1].split('_')[3]
    } else {
      twitter_id = pathItems[0].split('_')[3]
    }

    this.state = {
      scrollTop:0,
      twitter_id:twitter_id,
      getFollowers:false,
      followers:[],
    };
  }

  componentDidMount = () => {
    this.getFollowers();
  }

  getFollowers = () => {
    this.props.logoAnimation('kodo');
    GetFollowers(this.state.twitter_id).then( (p) => {
      var followers = p.users;
      this.setState({ followers:followers, getFollowers:true, });
      this.props.logoAnimation('');
    });
  }

  render() {

    let followersBlock;
    if (this.state.getFollowers) {
      followersBlock = ( <TwitterUsers users={this.state.followers} blockName={'FOLLOWERS'} full={this.props.full} one={this.props.one} two={this.props.two} /> );
    } else {
      followersBlock = (
        <Block searching full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>FOLLOWERS SEARCHING...</p>
        </Block>
      );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <Helmet title={'TwitterFollowers | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {followersBlock}
      </div>
    );
  }

}
export default TwitterFollowers;
