import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningStyle, DistributedLearningField, TextField, SelectMenuWrapper, MusiquiField } from 'component/common/style/learning/DistributedLearning';

// function
import GetTodayLearnings from 'component/common/function/GetTodayLearnings';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import CheckSummary from "component/common/function/learning/CheckSummary";

// module
import LearningTimer from 'component/common/module/learning/LearningTimer';
import NormalMusiquiField from "component/common/module/learning/NormalMusiquiField";
import SummaryMusiquiField from "component/common/module/learning/SummaryMusiquiField";


class TodayMusiqui extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      type:'',
      learnings:[],
      selectLearningIndex:0,
      valid:false,
      congratulation:false,
      showMusiquiField:false,
      showTimer:true,
      timerStatus:"stand-by",
    };
  }

  componentDidMount = () => {
    let type = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      type = pathItems[1].replace('today_distributed_learning_musiqui_','');
    } else {
      type = pathItems[0].replace('today_distributed_learning_musiqui_','');
    }
    this.setState({ type:type, });
    this.getLearnings(type);
  }

  getLearnings = (type) => {
    console.log('getLearnings');
    let learnings = [];
    GetTodayLearnings(type).then((learnings) => {
      if (0 < learnings.length) {
        for (var i=0; i<learnings.length; i++) {
          learnings[i].summary = CheckSummary(learnings[i]);
        }

        this.setState({ learnings:learnings, showMusiquiField:true });
      } else {
        this.setState({ congratulation:true, });
      }
    });
  }

  componentWillUpdate = () => {
    document.body.addEventListener('keydown', this.showMusiqui);
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('keydown', this.showMusiqui);
  }

  clickRetryBtn = () => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    let musiqui = document.getElementById('musiqui');
    let musiquiItags = musiqui.getElementsByTagName('i');
    for (var i=0; i<musiquiItags.length; i++) {
      if (musiquiItags[i].classList.contains('show-musiqui-incorrect')) {
        musiquiItags[i].classList.remove('show-musiqui-incorrect');
      }
    }
    document.getElementById('musiqui-style').scrollTop = 0;
  }

  clickLearningBtn = (correct) => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];

    // 正解、不正解の場合に応じてselectLearningIndexを調整。
    let selectLearningIndex = this.state.selectLearningIndex;
    if (correct) {
      // learningsから正解した問題を除外。除外した問題のところに次の問題がシフトするのでselectLearningIndexはそのまま。
      learnings.splice(selectLearningIndex,1);
    } else {
      selectLearningIndex += 1;
      learnings[this.state.selectLearningIndex].span *= 0.5;
      if (learnings[this.state.selectLearningIndex].span < 1) {
        learnings[this.state.selectLearningIndex].span = 0;
      }
    }

    // learningsの最後尾まで回答したら先頭にもどる
    if (learnings.length < selectLearningIndex + 1) selectLearningIndex = 0;

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation = (0 < learnings.length) ? false : true;

    // timerCnt(秒数)を分数に変換し、timesに加える
    let min = Math.floor(learning.timerCnt/60); // 分
    let sec = learning.timerCnt % 60; // 秒
    if (30 < sec) min++; // 秒部分で30秒以上経過しているのであれば1分プラスする
    (!learning.times) ? learning.times = String(min) : learning.times += "," + String(min);

    this.setState({ valid:false, congratulation:congratulation, selectLearningIndex:selectLearningIndex, learnings:learnings, showMusiquiField:false, showTimer:false}, () => {
      document.getElementById('musiqui-style').scrollTop = 0;
      this.setState({ showMusiquiField:true, showTimer:true }); // MusiquiFieldとタイマーを再マウント
    });

    SaveDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo, learning.times, correct).then((saved) => {
    });

  }

  deleteItem = () => {
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = this.state.learnings[this.state.selectLearningIndex];

    // 削除する問題をlearnings配列から除外
    let selectLearningIndex = this.state.selectLearningIndex;
    learnings.splice(selectLearningIndex,1);

    // learningsの最後尾まで回答したら先頭にもどる
    if (learnings.length < selectLearningIndex + 1) {
      selectLearningIndex = 0;
    }

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation = (0 < learnings.length) ? false : true;

    this.setState({ congratulation:congratulation, selectLearningIndex:selectLearningIndex, learnings:learnings, showMusiquiField:false, showTimer: false}, () => {
      this.setState({ showMusiquiField:true, showTimer:true });
    });

    DeleteDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo).then(() => {
    });

  }

  getMusiquiValid = (valid) => {
    this.setState({ valid:valid });
  }

  getTimerCnt = (timerCnt) => {
    // 現在出題されている問題を取得
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];

    // timerCntを更新
    learning.timerCnt = timerCnt;
    this.setState({ learnings:learnings });
  }

  getTimerStatus = (timerStatus) => {
    this.setState({ timerStatus:timerStatus });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let span;
    if (0 < this.state.learnings.length) {
      span = this.state.learnings[this.state.selectLearningIndex].span;
    }

    let musiqui;
    if (this.state.learnings.length) {
      if (this.state.learnings[this.state.selectLearningIndex].summary && this.state.showMusiquiField) {
        musiqui = ( <SummaryMusiquiField learning={this.state.learnings[this.state.selectLearningIndex]} disable={this.state.timerStatus != "count"} getMusiquiValid={this.getMusiquiValid} /> );
      } else if (this.state.learnings[this.state.selectLearningIndex].summary == false && this.state.showMusiquiField) {
        musiqui = ( <NormalMusiquiField learning={this.state.learnings[this.state.selectLearningIndex]} disable={this.state.timerStatus != "count"} getMusiquiValid={this.getMusiquiValid} /> );
      }
    }

    let answerField;
    if (this.state.timerStatus == "count") {
      if (this.state.valid) {
        answerField = (
          <div id='answer-field'>
            <div className='forget-btn' onClick={ () => {this.clickLearningBtn(false)} }>forget</div>
            <div className='correct-btn' onClick={ () => {this.clickLearningBtn(true)} }>correct</div>
          </div>
        );
      } else {
        answerField = (
          <div id='answer-field'>
            <div className='retry-btn' onClick={ () => {this.clickRetryBtn()} }>retry</div>
            <div className='forget-btn' onClick={ () => {this.clickLearningBtn(false)} }>forget</div>
          </div>
        );
      }
    }


    let distributedLearningMusiqui;
    if (this.state.congratulation) {
      let imgs = [
                   'https://c.tenor.com/iNCbn635V04AAAAC/despicable-me2-minions.gif',
                   'https://media4.giphy.com/media/l1ugg8MSaCUpzyyyY/giphy.gif',
                   'https://thumbs.gfycat.com/EsteemedFloweryConch-max-1mb.gif',
                   'https://media0.giphy.com/media/5ULFBOHCMDiN4fUn2w/giphy.gif',
                   'https://media1.giphy.com/media/RMGHCIEkaey08FtcnJ/giphy-downsized-large.gif',
                   'https://media0.giphy.com/media/l4FGoHktFE5InlFEA/giphy.gif',
                   'http://24.media.tumblr.com/tumblr_mdj2jugP2B1ro9mijo1_500.gif',
                   'https://thumbs.gfycat.com/DecisiveMaleGoose-size_restricted.gif',
                   'https://i.pinimg.com/originals/d5/fc/51/d5fc51afb02bceb1de29e6b0eaebf3ac.gif',
                   'https://c.tenor.com/bQrjUlPjAnsAAAAC/despicable-me-minions.gif',
                   'https://i.makeagif.com/media/11-14-2015/Sy68Pp.gif',
                 ];
      distributedLearningMusiqui = (
        <DistributedLearningField congratulation={this.state.congratulation}>
          <div className='head'>
            <h1 className='type'>{this.state.type}</h1>
          </div>
          <img className='congratulation' src={imgs[Math.floor(Math.random() * imgs.length)]} />
        </DistributedLearningField>
      );
    } else {

      let readTime;
      if (0 < this.state.learnings.length) {
        if (this.state.learnings[this.state.selectLearningIndex].times) {
          readTime = this.state.learnings[this.state.selectLearningIndex].times.split(",")[this.state.learnings[this.state.selectLearningIndex].times.split(",").length-1];
        } else {
          readTime = "?";
        }
      }

      distributedLearningMusiqui = (
        <DistributedLearningField>
          <div className='head'>
            <h1 className='type'>{this.state.type}</h1>
            <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} } /></div>
            <div className='read-time'>read time<br />{readTime}min</div>
            <div className='span'>span<br />{span}</div>
            <div className='progress'>progress<br />{this.state.selectLearningIndex + 1} / {this.state.learnings.length}</div>
          </div>
          {musiqui}
          {answerField}
        </DistributedLearningField>
      );
    }

    let timer;
    if (0 < this.state.learnings.length && this.state.showTimer) {
      timer = ( <LearningTimer getTimerCnt={this.getTimerCnt} getTimerStatus={this.getTimerStatus} learningId="musiqui" /> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Musiqui | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningStyle id='musiqui-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {distributedLearningMusiqui}
          {timer}
        </DistributedLearningStyle>
      </div>
    );
  }

}

export default TodayMusiqui;
