import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn } from 'component/common/style/FadeIn';

let time = 5;
const InitializationBack = styled.div`
  &&& {
    animation:fadeOut ${time}s ease 0s forwards, hide 0s ease-in ${time}s forwards;
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:10000; width:120%; height:120%; margin:auto; padding:0; background:${subColor};
  }
`

const InitializationLogo = styled.div`
  &&& {
    animation:fadeOut ${time}s ease 0s forwards, hide 0s ease-in ${time}s forwards;
    position:fixed; bottom:48%; left:0; right:0; z-index:10100; width:100px; height:100px; margin:auto;
    .lo { animation:kodo 1s linear infinite, backgroundFlash 1s linear infinite;
          width:100%; height:100%; margin:auto; padding:0; border-radius:100000px; background:${mainColor}; position:relative;
      .go { animation:kodo 1s inear infinite;
            position:absolute; top:0; bottom:0; left:0; right:0; width:76.5%; height:76.5%; margin:auto; border-radius:100000px; background:${subColor}; }
    }
  }
`

const InitializationPhilosophy = styled.div`
  animation:fadeOut ${time}s ease 0s forwards, hide 0s ease-in ${time}s forwards;
  position:fixed; top:52%; left:0; right:0; z-index:10100; width:250px; height:75px; margin:auto; padding:auto;
  h1 { animation:kodo 1s linear infinite, colorFlash 1s linear infinite;
       color:${mainColor}; font-size:12px; font-weight:900; text-align:center; font-family:'Cairo', sans-serif; width:150px; margin:auto; }
  h1:after { animation:backgroundFlash 1s linear infinite;
             background:${mainColor}; border-radius:2px; content:''; display:block; height:10px; }
`

export { InitializationBack, InitializationLogo, InitializationPhilosophy };
