import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningStyle, DistributedLearningField, TextField, SelectMenuWrapper, ShakyoField } from 'component/common/style/learning/DistributedLearning';

// function
import GetLearning from 'component/common/function/GetLearning';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import ShakyoMatchCheck from 'component/common/function/ShakyoMatchCheck';
import ClickShakyoShadow from 'component/common/function/ClickShakyoShadow';


class Shakyo extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      hash:'',
      learning:[],
      status:false,
    };
  }

  componentDidMount = () => {
    let hash = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      hash = pathItems[1].replace('distributed_learning_shakyo_','');
    } else {
      hash = pathItems[0].replace('distributed_learning_shakyo_','');
    }
    this.setState({ hash:hash, });
    this.getLearning(hash);
  }

  componentWillUpdate = () => { document.body.addEventListener('keydown', this.keydownTab); }
  componentWillUnmount = () => { document.body.removeEventListener('keydown', this.keydownTab); }

  keydownTab = (event) => {
    // #shakyo-frontにfocusされていて、Tabキーが入力されたらタブ文字を挿入する
    if (document.getElementById('shakyo-front') == document.activeElement && event.code == 'Tab') {
      event.preventDefault();
      var elem = event.target;
      var val = elem.value;
      var pos = elem.selectionStart;
      elem.value = val.substr(0, pos) + '\t' + val.substr(pos, val.length);
      elem.setSelectionRange(pos + 1, pos + 1);
    }
  }

  getLearning = (hash) => {
    console.log('getLearning');
    GetLearning(hash).then((learning) => {
      if (learning) {
        console.log(learning);
        console.dir(learning);
        this.setState({ learning:learning, }, () => {

          TextHeightAdjustment('shakyo-back').then((res) => {
            // #shakyo-backの調整後の高さを取得し、#shakyoの高さも同じ値にそろえる。
            let height = res.height;
            document.getElementById('shakyo').style.height = height;
          });

          this.shakyoMatchCheck();
          document.getElementById('shakyo-front').focus();
        });
      }
    });
  }

  deleteItem = () => {
    DeleteDistributedLearning(this.state.learning.type, this.state.learning.question, this.state.learning.answer, this.state.learning.musiqui, this.state.learning.shakyo).then(() => {
      this.props.handleFlashMessage('DELETED');

      // SHAKYOの詳細画面にリダイレクト
      let pathItems = window.location.pathname.slice(1).split('/');
      if (this.props.full) {
        History.push('/distributed_learning_shakyo_index_' + this.state.learning.type);
      } else if (this.props.one) {
        History.push('/distributed_learning_shakyo_index_' + this.state.learning.type + '/' + pathItems[1]);
      } else if (this.props.two) {
        History.push('/' + pathItems[0] + '/distributed_learning_shakyo_index_' + this.state.learning.type);
      }

    });
  }

  clickShakyoShadow = (event) => {
    ClickShakyoShadow(event, 'shakyo-front', 'shakyo-shadow');
  }

  shakyoMatchCheck = () => {
    ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
      let status = res.status;
      this.setState({ status:status, });
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let span;
    if (this.state.learning) {
      span = this.state.learning.span;
    }

    let shakyo;
    if (this.state.learning) {
      shakyo = (
        <ShakyoField id='shakyo' status={this.state.status}>
          <textarea type='text' id='shakyo-front' onSelect={ () => {this.shakyoMatchCheck()} } onChange={ () => {this.shakyoMatchCheck()} } onBlur={ () => {this.shakyoMatchCheck()} } />
          <textarea type='text' id='shakyo-back' value={this.state.learning.shakyo} readonly />
          <div id='shakyo-highlight'></div>
          <div id='shakyo-shadow' onClick={ (event) => {this.clickShakyoShadow(event)} }></div>
        </ShakyoField>
      );
    }


    let distributedLearningShakyo = (
      <DistributedLearningField>
        <div className='head'>
          <h1 className='type'>{this.state.learning.type}</h1>
          <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} } /></div>
          <Link className='edit' to={this.props.full ? '/distributed_learning_shakyo_update_' + this.state.learning.hash : this.props.one ? '/distributed_learning_shakyo_update_' + this.state.learning.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_shakyo_update_' + this.state.learning.hash}>edit</Link>
          <div className='read-time'>read time<br />{(this.state.learning.times) ? this.state.learning.times.split(",")[this.state.learning.times.split(",").length-1] : "?"}min</div>
          <div className='span'>span<br />{span}</div>
        </div>
        {shakyo}
      </DistributedLearningField>
    );


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Shakyo | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learning_shakyo_index_' + this.state.learning.type : this.props.one ? '/distributed_learning_shakyo_index_' + this.state.learning.type+ '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_shakyo_index_' + this.state.learning.type}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningStyle id='shakyo-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {distributedLearningShakyo}
        </DistributedLearningStyle>
      </div>
    );
  }

}

export default Shakyo;
