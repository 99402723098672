import axios from 'axios';
  
export default function GetNewPostsPareoNaOtoko() {

  return new Promise((resolve, reject) => {

    axios.get('https://lab.takuman.me/api/scraping/pareo_na_otoko/new_posts')
    .then(res => {
      let posts = res.data.posts;
      resolve(posts);
    })
    .catch(err => { resolve() });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
