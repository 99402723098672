import axios from 'axios';

export default function LayeredNotesSearch(searchParams) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios
    .post('https://lab.takuman.me/api/layerednote/search/', searchParams, headers)
    .then(res => {
      var notesCount = res.data.notes_count; 
      let notes = res.data.notes;
      resolve({'notesCount':notesCount, 'notes':notes});
    })
    .catch(err => { 
      reject();
    }); // エラーログを出力


  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve({'notesCount':0, 'notes':[]});
    });
  });

}
