import axios from 'axios';
  
export default function CheckedPornData(genre, created_at) {

  return new Promise((resolve, reject) => {

    let params = { genre:genre, created_at:created_at, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/porn_datas/checked/', params, headers)
    .then(res => {
      resolve(true);
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
