import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { TranslateStyle, TranslateField, TextField, SelectMenuWrapper } from 'component/common/style/Translate';

// function
import Deepl from 'component/common/function/Deepl';
import Polly from 'component/common/function/Polly';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';


class Translate extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      languages:[{'name':'English', 'code':'en'}, {'name':'Japanese', 'code':'ja'}, {'name':'Chinese', 'code':'zh'}, {'name':'Dutch', 'code':'nl'}, {'name':'French', 'code':'fr'}, {'name':'German', 'code':'de'}, {'name':'Italian', 'code':'it'}, {'name':'Polish', 'code':'pl'}, {'name':'Portuguese', 'code':'pt'}, {'name':'Portuguese(BR)', 'code':'pt'}, {'name':'Russian', 'code':'ru'}, {'name':'Spanish', 'code':'es'}], // 翻訳可能な言語
      fromLangMenu:false, // 翻訳前の言語選択メニューの表示・非表示
      fromLangIndex:0, // 翻訳前の言語のlanguages配列のインデックス番号
      toLangMenu:false, // 翻訳後の言語選択メニューの表示・非表示
      toLangIndex:1, // 翻訳後の言語のlanguages配列のインデックス番号

      fromText:'', // 翻訳前の文章
      toText:'', // 翻訳後の文章

      typing:false,
      typingTimeout:0,

      selecting:false,
      selectingTimeout:0,

      fromWord:'', // 翻訳したい単語や文章
      toWord:'', // 翻訳前の全文の部分的な翻訳文
      histories:[], // 部分的な翻訳履歴一覧

      speeching:false,

      saved:false,
    };
  }

  componentDidMount = () => {
  }

  selectFromLang = (index) => {
    this.setState({ fromLangIndex:index, fromLangMenu:false, });
  }

  handleFromLangMenu = () => {
    if (this.state.fromLangMenu) {
      this.setState({ fromLangMenu:false, toLangMenu:false, });
    } else {
      this.setState({ fromLangMenu:true, toLangMenu:false, });
    }
  }

  selectToLang = (index) => {
    this.setState({ toLangIndex:index, toLangMenu:false, });
  }

  handleToLangMenu = () => {
    if (this.state.toLangMenu) {
      this.setState({ toLangMenu:false, fromLangMenu:false, });
    } else {
      this.setState({ toLangMenu:true, fromLangMenu:false, });
    }
  }

  handleCloseLangMenu = () => {
    this.setState({ fromLangMenu:false, toLangMenu:false, });
  }

  changeFromText = (event) => {
    console.log('changeFromText');
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    this.textHeightAdjustment('from-text');

    self.setState({
       fromText:event.target.value,
       fromWord:'',
       toWord:'',
       typing:false,
       fromLangMenu:false,
       toLangMenu:false,
       typingTimeout: setTimeout(function () {
         self.translate('text', self.state.fromText);
       }, 2000)
    });
  }

  textHeightAdjustment = (elementId) => {
    let target = document.getElementById(elementId);
    if (target.scrollHeight > target.offsetHeight) {
      target.style.height = target.scrollHeight + 'px';
    }else{
      var height, lineHeight;
      let i = 0;
      while (true){
        height = Number(target.style.height.split('px')[0]); // テキストエリア全体の高さ 
        lineHeight = Number(target.style.lineHeight.split('px')[0]); // 1行あたりの高さ
        target.style.height = '0px'; // 上記の計算で初期値を入れてしまうと配列の追加・削除でindexがずれた際に別の項目の初期値をとってしまい縦幅がおかしくなってしまう。 
        if (target.scrollHeight >= target.offsetHeight) { // スクロール込みの高さが全体の高さよりも大きければ、全体の高さにスクロール込みの高さの値を代入して処理を抜ける
          target.style.height = target.scrollHeight + 'px';
          break;
        } else {
          if (100 < i) {
            target.style.height = target.scrollHeight + 'px';
            break;
          } else {
            i++;
          }
        }
      }
    }
  }

  selectFromText = () => {
    const selectedText = window.getSelection().toString();

    const self = this;

    if (self.state.selectingTimeout) {
      clearTimeout(self.state.selectingTimeout);
    }

    self.setState({
       selecting:false,
       fromLangMenu:false,
       toLangMenu:false,
       selectingTimeout: setTimeout(function () {
         self.translate('word', selectedText);
       }, 2000)
    });
  }

  forcusFromText = () => {
    this.setState({ fromWord:'', toWord:'', });
  }

  blurFromText = () => {
    this.setState({ fromWord:'', toWord:'', });
  }

  clickFromText = () => {
    this.setState({ fromWord:'', toWord:'', });
  }

  translate = (type, fromText) => {
    if (fromText != '') {
      console.log('translateを実行');
      this.setState({ saved:false, });

      if (type == 'text') {
        this.setState({ toText:'translating...', });
      } else {
        this.setState({ toWord:'translating...', });
      }


      let fromLang = this.state.languages[this.state.fromLangIndex].code;
      let toLang = this.state.languages[this.state.toLangIndex].code;
      Deepl(fromText, fromLang, toLang).then((text) => {
        if (text != '') {
          if (type == 'text') {
            this.setState({ toText:text, });
            this.textHeightAdjustment('to-text');
          } else {
            this.setState({ fromWord:fromText, toWord:text, });
            this.saveDistributedLearning(fromText, text);
          }
        } else {
          this.setState({ toText:'translate fails', });
        }
      });

/*
      let fromLang = this.state.languages[this.state.fromLangIndex];
      let toLang = this.state.languages[this.state.toLangIndex];
      let params = { from_text:fromText, from_lang:fromLang.code, to_lang:toLang.code, }
      //var headers = { headers: { 'content-type': 'application/json'} }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      axios.post('https://lab.takuman.me/api/translate/deepl/', params, headers)
      .then(res => {
        if (type == 'text') {
          let toText = res.data.to_text;
          this.setState({ toText:toText, });
          this.textHeightAdjustment('to-text');
        } else {
          let toWord = res.data.to_text;
          this.setState({ fromWord:fromText, toWord:toWord, });
          this.saveDistributedLearning(fromText, toWord);
        }
      })
      .catch(err => { this.setState({ toText:'translate fails', }); });
*/
    }
  }

  switchLang = () => {
    this.setState({ fromLangIndex:this.state.toLangIndex, toLangIndex:this.state.fromLangIndex, fromText:this.state.toText, toText:this.state.fromText, });
  }

  speech = () => {
    let params;
    if (this.state.toWord == '') {
      params = { speech_text:this.state.fromText, from_lang:this.state.languages[this.state.fromLangIndex].code, }
    } else {
      params = { speech_text:this.state.fromWord, from_lang:this.state.languages[this.state.fromLangIndex].code, }
    }


    Polly(params).then((status) => {
      if (status == 'success') {
        let speech_path = 'https://s3-ap-northeast-1.amazonaws.com/media.takuman.me/static/speech.mp3';
        // 同一のurlの場合、キャッシュが効いて新しい音声が再生されないので、毎回乱数が挿入されるようにしてキャッシュを回避する。
        this.speeching = new Audio(speech_path + '?noCache=' + Math.floor(Math.random() * 1000000));
        this.setState({ speeching:true, });
        this.speeching.play();  // 再生

        // 読み上げが終了したらspeechingフラグを下ろす。
        this.speeching.onended = () => {
          this.setState({ speeching:false, });
        }
      }
    });

/*
    //var headers = { headers: { 'content-type': 'application/json'} }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/speech/polly/', params, headers)
    .then(res => {
      if (res.data.status == 'success') {
        let speech_path = 'https://s3-ap-northeast-1.amazonaws.com/media.takuman.me/static/speech.mp3';
        // 同一のurlの場合、キャッシュが効いて新しい音声が再生されないので、毎回乱数が挿入されるようにしてキャッシュを回避する。
        this.speeching = new Audio(speech_path + '?noCache=' + Math.floor(Math.random() * 1000000));
        this.setState({ speeching:true, });
        this.speeching.play();  // 再生

        // 読み上げが終了したらspeechingフラグを下ろす。
        this.speeching.onended = () => {
          this.setState({ speeching:false, });
        }
      }
    })
    .catch(err => { });
*/

  }

  shutUp = () => {
    this.setState({ speeching:false, });
    this.speeching.pause(); // 一時停止
  }

  saveDistributedLearning = (question, answer) => {

    //let type = 'translate-' + this.state.languages[this.state.fromLangIndex].code + '-' + this.state.languages[this.state.toLangIndex].code;
    let type = 'eitango-en-ja';
    let musiqui = '';
    let correct = false;
    SaveDistributedLearning(type, question, answer, musiqui, correct).then((saved) => {
      if (this.state.fromText == question && this.state.toText == answer) {
        this.setState({ saved:saved, });
      }
    });

/*
    let type = 'translate-' + this.state.languages[this.state.fromLangIndex].code + '-' + this.state.languages[this.state.toLangIndex].code;
    var params = { type:type, question:question, answer:answer, correct:false, }
    //var headers = { headers: { 'content-type': 'application/json'} }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/distributed_learning/create_or_update/', params, headers)
    .then(res => {
      console.log('distributed_learning_save');
      if (this.state.fromText == question && this.state.toText == answer) {
        this.setState({ saved:true, });
      }
    })
    .catch(err => { });
*/
  }



  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectFromLangMenu = (
      <div className='select-menu'>
        {this.state.languages.map( (lang,index) => {
          if (this.state.toLangIndex != index) {
            return ( <div className='menu-item' onClick={ (event) => {this.selectFromLang(index)} }>{lang.name}</div> );
          }
        })}
      </div>
    );

    let selectFromLang = (
      <div>
        <div className='select'>
          <div className='select-item' onClick={ (event) => {this.handleFromLangMenu()} }>from: {this.state.languages[this.state.fromLangIndex].name}</div>
          {this.state.fromLangMenu ? selectFromLangMenu : ''}
        </div>
      </div>
    );


    let speech;
    if (this.state.speeching) {
      speech = (
        <div className='speech'>
          <i className='fas fa-stop' onClick={ () => {this.shutUp()} }></i>
        </div>
      );
    } else {
      speech = (
        <div className='speech'>
          <i className='fas fa-volume-up' onClick={ () => {this.speech()} }></i>
        </div>
      );
    }


    let selectToLangMenu = (
      <div className='select-menu'>
        {this.state.languages.map( (lang,index) => {
          if (this.state.fromLangIndex != index) {
            return ( <div className='menu-item' onClick={ (event) => {this.selectToLang(index)} }>{lang.name}</div> );
          }
        })}
      </div>
    );

    let selectToLang = (
      <div className='select'>
        <div className='select-item' onClick={ (event) => {this.handleToLangMenu()} }>to: {this.state.languages[this.state.toLangIndex].name}</div>
        {this.state.toLangMenu ? selectToLangMenu : ''}
      </div>
    );

    let save;
    if (this.state.fromText != '' && this.state.toText != '' && this.state.toText != 'translating...') {
      if (this.state.saved) {
        save = ( <div className='save'><span>saved</span></div> );
      } else {
        save = (
          <div className='save'>
            <i className='far fa-plus-square' onClick={ () => {this.saveDistributedLearning(this.state.fromText, this.state.toText)} }></i>
          </div>
        );
      }
    } else {
      save = ( <span></span> );
    }


    let toTextarea;
    if (this.state.toWord != '') {
      toTextarea = ( <textarea type='text' id='to-text' value={this.state.toWord} placeholder='' readonly='readonly' /> );
    } else {
      toTextarea = ( <textarea type='text' id='to-text' value={this.state.toText} placeholder='' readonly='readonly' /> );
    }

    let translateField = (
      <TranslateField>
        <TextField>
          {selectFromLang}
          {speech}
          <div className='switch' onClick={ () => {this.switchLang()} }><i className='fas fa-exchange-alt'></i></div>
          <textarea type='text' id='from-text' onChange={ (event) => {this.changeFromText(event)} } onSelect={ () => {this.selectFromText()} } onForcus={ () => {this.forcusFromText()} } onBlur={ () => {this.blurFromText()} } onClick={ () => {this.clickFromText()} } value={this.state.fromText} placeholder='Enter the text you want to translate.' />
        </TextField>
        <TextField>
          {selectToLang}
          {save}
          {toTextarea}
        </TextField>
      </TranslateField>
    );


    let selectMenuWrapper;
    if (this.state.fromLangMenu || this.state.toLangMenu) {
      selectMenuWrapper = ( <SelectMenuWrapper onClick={ (event) => {this.handleCloseLangMenu()} } /> );
    } else {
      selectMenuWrapper = ( <span></span> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Translate | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <TranslateStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
          {selectMenuWrapper}
        </TranslateStyle>
      </div>
    );
  }

}
export default Translate;
