import axios from 'axios';
  
export default function GetPornDatas(genre) {

  return new Promise((resolve, reject) => {

    let params = { genre:genre, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/get_porn_datas/', params, headers)
    .then(res => {
      let genres = res.data.genres;
      let pornDatas = res.data.porn_datas;
      resolve({'genres':genres, 'pornDatas':pornDatas});
    })
    .catch(err => { reject() });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve({ 'genres':[], 'pornDatas':[], });
    });
  });

}
