import React from 'react';
import axios from 'axios';
import History from 'History';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { Block, BlockName, UserName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock, ImageBlock } from 'component/common/style/Oauth';

class TwitterUser extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      user:this.props.user,
    };
  }

  componentDidMount = () => {
  }


  render() {

    let backgroundImageBlock = (
      <ImageBlock src={this.state.user.profile_background_image_url != null ? this.state.user.profile_background_image_url : 'https://www.wallpapertip.com/wmimgs/30-309624_techno-music-wallpapers-hd-sonic-full-hd.jpg'} />
    );

    let name;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.state.user.protected) {
      name = ( <p><i class="fas fa-lock"></i> <Link to={this.props.full ? '/oauth_twitter_account_' + this.state.user.id_str : this.props.one ? '/oauth_twitter_account_' + this.state.user.id_str + '/' + pathItems[1] : '/' + pathItems[0] + '/oauth_twitter_account_' + this.state.user.id_str}>{this.state.user.name}(@{this.state.user.screen_name})</Link></p> );
    } else {
      name = ( <p><Link to={this.props.full ? '/oauth_twitter_account_' + this.state.user.id_str : this.props.one ? '/oauth_twitter_account_' + this.state.user.id_str + '/' + pathItems[1] : '/' + pathItems[0] + '/oauth_twitter_account_' + this.state.user.id_str}>{this.state.user.name}(@{this.state.user.screen_name})</Link></p> );
    }
    let username = (
      <UserName>
        <img src={this.state.user.profile_image_url != null ? this.state.user.profile_image_url : 'https://www.wallpapertip.com/wmimgs/30-309624_techno-music-wallpapers-hd-sonic-full-hd.jpg'} />
        {name}
      </UserName>
    );

    let description;
    if (this.state.user.description != '') {
      description = ( <p>{this.state.user.description}</p> );
    } else {
      description = ( <span></span> );
    }


    let friends;
    let followers;
    let favorites;
    let tweets;

    let url;
    if (this.state.user.url != null) {
      url = ( <span>link: <Link to={this.state.user.url} target='_blank' /></span> );
    } else {
      url = ( <span>link: none</span> );
    }

    let createdAt = ( <span>created_at: {this.state.user.created_at}</span> );

    let location;
    if (this.state.user.location != '') {
      location = ( <span>location: {this.state.user.location}</span> );
    } else {
      location = ( <span>location: none</span> );
    }

    let status = (
      <p>
        {url}<br/>
        {createdAt}<br/>
        {location}
      </p>
    );

    let accountBlock;
    accountBlock = (
      <Block full={this.props.full} one={this.props.one} two={this.props.two}>
        {username}
        {description}
        {status}
      </Block>
    );


    return (
      <div>
        {backgroundImageBlock}
        {accountBlock}
      </div>
    );

  }

}
export default TwitterUser;
