import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningStyle, DistributedLearningField, TextField, SelectMenuWrapper, MusiquiField } from 'component/common/style/learning/DistributedLearning';

// function
import GetLearning from 'component/common/function/GetLearning';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import CheckSummary from "component/common/function/learning/CheckSummary";

// module
import NormalMusiquiField from "component/common/module/learning/NormalMusiquiField";
import SummaryMusiquiField from "component/common/module/learning/SummaryMusiquiField";


class Musiqui extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      hash:'',
      learning:[],
    };
  }

  componentDidMount = () => {
    let hash = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      hash = pathItems[1].replace('distributed_learning_musiqui_','');
    } else {
      hash = pathItems[0].replace('distributed_learning_musiqui_','');
    }
    this.setState({ hash:hash, });
    this.getLearning(hash);
  }

  getLearning = (hash) => {
    GetLearning(hash).then((learning) => {
      if (learning) {
        learning.summary = CheckSummary(learning);
        this.setState({ learning:learning, });
      }
    });
  }

  deleteItem = () => {
    DeleteDistributedLearning(this.state.learning.type, this.state.learning.question, this.state.learning.answer, this.state.learning.musiqui, this.state.learning.shakyo).then(() => {
      this.props.handleFlashMessage('DELETED');

      // MUSIQUIの詳細画面にリダイレクト
      let pathItems = window.location.pathname.slice(1).split('/');
      if (this.props.full) {
        History.push('/distributed_learning_musiqui_index_' + this.state.learning.type);
      } else if (this.props.one) {
        History.push('/distributed_learning_musiqui_index_' + this.state.learning.type + '/' + pathItems[1]);
      } else if (this.props.two) {
        History.push('/' + pathItems[0] + '/distributed_learning_musiqui_index_' + this.state.learning.type);
      }

    });
  }

  getMusiquiValid = (valid) => {
    this.setState({ valid:valid });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let span;
    if (this.state.learning) {
      span = this.state.learning.span;
    }

    let musiqui;
    if (this.state.learning) {
      if (this.state.learning.summary == true) {
        musiqui = ( <SummaryMusiquiField learning={this.state.learning} getMusiquiValid={this.getMusiquiValid} /> );
      } else if (this.state.learning.summary == false) {
        musiqui = ( <NormalMusiquiField learning={this.state.learning} getMusiquiValid={this.getMusiquiValid} /> );
      }
    }


    let distributedLearningMusiqui = (
      <DistributedLearningField>
        <div className='head'>
          <h1 className='type'>{this.state.learning.type}</h1>
          <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} } /></div>
          <Link className='edit' to={this.props.full ? '/distributed_learning_musiqui_update_' + this.state.learning.hash : this.props.one ? '/distributed_learning_musiqui_update_' + this.state.learning.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_musiqui_update_' + this.state.learning.hash}>edit</Link>
          <div className='read-time'>read time<br />{(this.state.learning.times) ? this.state.learning.times.split(",")[this.state.learning.times.split(",").length-1] : "?"}min</div>
          <div className='span'>span<br />{span}</div>
        </div>
        {musiqui}
      </DistributedLearningField>
    );



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Musiqui | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learning_musiqui_index_' + this.state.learning.type : this.props.one ? '/distributed_learning_musiqui_index_' + this.state.learning.type+ '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_musiqui_index_' + this.state.learning.type}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningStyle id='musiqui-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {distributedLearningMusiqui}
        </DistributedLearningStyle>
      </div>
    );
  }

}

export default Musiqui;
