import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { Block, BlockName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock, FilterBlock, Filter, ErrorBlock, ErrorBlockName } from 'component/common/style/Oauth';

import GetTwitterAPIRateLimit from 'component/common/function/GetTwitterAPIRateLimit';
import GetAccount from 'component/common/function/GetAccount';
import GetTweets from 'component/common/function/GetTweets';
import GetFavorites from 'component/common/function/GetFavorites';
import GetFriends from 'component/common/function/GetFriends';
import GetFollowers from 'component/common/function//GetFollowers';

import TwitterUser from 'component/common/module/TwitterUser';
import Tweets from 'component/common/module/Tweets';
import TweetsMediaPanels from 'component/common/module/TweetsMediaPanels';
import TwitterUsers from 'component/common/module/TwitterUsers';

class TwitterAccount extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let twitter_id;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      twitter_id = pathItems[1].split('_')[3]
    } else {
      twitter_id = pathItems[0].split('_')[3]
    }

    this.state = {
      scrollTop:0,

      twitter_id:twitter_id,
      rateLimits:[], // Twitter APIの各レート情報を格納
      rateLimitErrorMessages:[], // Twitter APIのレート制限に引っかかったらここにエラーメッセージを格納する

      account:[], getAccount:false,
      showData:'', // tweets, favorites, friends, followersのいずれか表示したいデータの名前を持たせる
      tweetsAttribute:'mediaOnly', // default, mediaOnlyのいずれかで取得したツイートを絞り込む
      tweetsLayout:'mediaPanels', // default, mediaPanelsのいずれかで取得したツイートをレイアウトする
      tweets:[], tweetsCount:0, getTweets:false,
      favorites:[], favoritesCount:0, getFavorites:false,
      friends:[], getFriends:false,
      followers:[], getFollowers:false,

      attributeMenu:false,
      layoutMenu:false,
    };
  }

  componentDidMount = () => {
    this.getAccount();
  }

  componentWillReceiveProps(nextProps) {
    let twitter_id;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      twitter_id = pathItems[1].split('_')[3]
    } else {
      twitter_id = pathItems[0].split('_')[3]
    }
    if (twitter_id !== this.state.twitter_id) {
      return new Promise((resolve, reject) => {
        window.scrollTo(0,0);
        this.setState({
          twitter_id:twitter_id,
          account:[], getAccount:false,
          showData:'',
          tweets:[], tweetsCount:0, getTweets:false,
          favorites:[], favoritesCount:0, getFavorites:false,
          friends:[], getFriends:false,
          followers:[], getFollowers:false,
        });
        resolve();
      }).then(() => {
        return new Promise( (resolve, reject) => {
          this.getAccount();
          resolve();
        });

      });
    }
  }

  checkTwitterAPIRateLimit = () => {
    console.log('checkTwitterAPIRateLimit');
    this.props.logoAnimation('kodo');
    GetTwitterAPIRateLimit().then( (p) => {
      console.log('レート取得');
      var rateLimit = p.rateLimit;
      var rateLimits = p.rateLimits;
      console.log(rateLimits);
      var rateLimitErrorMessages = p.rateLimitErrorMessages;
      this.setState({ rateLimits:rateLimits, rateLimitErrorMessages:rateLimitErrorMessages, });
      this.props.helloLab();
    });
  }

  getAccount = () => {
    this.props.logoAnimation('kodo');
    GetAccount(this.state.twitter_id).then( (p) => {
      var account = p.account;
      this.setState({ account:account, getAccount:true, });
      this.checkTwitterAPIRateLimit();
      //clearInterval(this.checkRateLimit);
      this.props.helloLab();
    });
  }

  getTweetsCount = (tweets) => {
    let tweetsCount;
    if (this.state.tweetsAttribute == 'default') {
      tweetsCount = tweets.length;
    } else if (this.state.tweetsAttribute == 'mediaOnly') {
      tweetsCount = tweets.filter( tweet => tweet.extended_entities ).length;
    } else {
      tweetsCount = tweets.filter( tweet => tweet.extended_entities != undefined ).length;
    }
    return tweetsCount;
  }

  getTweets = () => {
    this.props.logoAnimation('kodo');
    GetTweets(this.state.twitter_id).then( (p) => {
      var tweets = p.tweets;
      let tweetsCount = this.getTweetsCount(tweets);
      this.setState({ tweets:tweets, tweetsCount:tweetsCount, getTweets:true, });
      this.checkTwitterAPIRateLimit();
      //clearInterval(this.checkRateLimit);
      this.props.helloLab();
    });
  }

  getFavoritesCount = (favorites) => {
    let favoritesCount;
    if (this.state.tweetsAttribute == 'default') {
      favoritesCount = favorites.length;
    } else if (this.state.tweetsAttribute == 'mediaOnly') {
      favoritesCount = favorites.filter( favorite => favorite.extended_entities ).length;
    } else {
      favoritesCount = favorites.filter( favorite => favorite.extended_entities != undefined ).length;
    }
    return favoritesCount;
  }

  getFavorites = () => {
    this.props.logoAnimation('kodo');
    GetFavorites(this.state.twitter_id).then( (p) => {
      var favorites = p.tweets;
      let favoritesCount = this.getFavoritesCount(favorites);
      this.setState({ favorites:favorites, favoritesCount:favoritesCount, getFavorites:true, });
      this.checkTwitterAPIRateLimit();
      //clearInterval(this.checkRateLimit);
      this.props.helloLab();
    });
  }

  getFriends = () => {
    this.props.logoAnimation('kodo');
    //this.hoge();
    GetFriends(this.state.twitter_id).then( (p) => {
      console.log('friends get2');
      var friends = p.users;
      this.setState({ friends:friends, getFriends:true, });
      this.checkTwitterAPIRateLimit();
      //clearInterval(this.checkRateLimit);
      this.props.helloLab();
    });
  }

  getFollowers = () => {
    this.props.logoAnimation('kodo');
    GetFollowers(this.state.twitter_id).then( (p) => {
      var followers = p.users;
      this.setState({ followers:followers, getFollowers:true, });
      this.checkTwitterAPIRateLimit();
      //clearInterval(this.checkRateLimit);
      this.props.helloLab();
    });
  }

  showData = (event,data) => {
    event.preventDefault();
    if (data == 'tweets') {
      if (this.state.getTweets == false) {
        this.getTweets();
      }
    } else if (data == 'favorites') {
      if (this.state.getFavorites == false) {
        this.getFavorites();
      }
    } else if (data == 'friends') {
      if (this.state.getFriends == false) {
        this.getFriends();
      }
    } else if (data == 'followers') {
      if (this.state.getFollowers == false) {
        this.getFollowers();
      }
    }
    if ((this.state.showData == 'tweets' && data == 'tweets') || (this.state.showData == 'favorites' && data == 'favorites') || (this.state.showData == 'friends' && data == 'friends') || (this.state.showData == 'followers' && data == 'followers')) {
      this.setState({ showData:'', });
    } else {
      this.setState({ showData:data, });
    }
  }


  selectAttributeMenu = () => {
    if (this.state.attributeMenu) {
      this.setState({ attributeMenu:false, layoutMenu:false, });
    } else {
      this.setState({ attributeMenu:true, layoutMenu:false, });
    }
  }

  attributeMenu = (x) => {
    return new Promise((resolve, reject) => {
      this.setState({ tweetsAttribute:x, attributeMenu:false, });
    }).then((p) => {
      return new Promise( (resolve, reject) => {
        let tweetsCount = this.getTweetsCount(this.state.tweets.slice());
        let favoritesCount = this.getFavoritesCount(this.state.favorites.slice());
        this.setState({ tweetsCount:tweetsCount, favoritesCount:favoritesCount, });
      });
    });
  }

  selectLayoutMenu = () => {
    if (this.state.layoutMenu) {
      this.setState({ layoutMenu:false, attributeMenu:false, });
    } else {
      this.setState({ layoutMenu:true, attributeMenu:false, });
    }
  }

  layoutMenu = (x) => {
    return new Promise((resolve, reject) => {
      this.setState({ tweetsLayout:x, layoutMenu:false, });
    }).then((p) => {
      return new Promise( (resolve, reject) => {
        let tweetsCount = this.getTweetsCount(this.state.tweets.slice());
        let favoritesCount = this.getFavoritesCount(this.state.favorites.slice());
        this.setState({ tweetsCount:tweetsCount, favoritesCount:favoritesCount, });
      });
    });
  }



  render() {

    let accountBlock;
    if (this.state.getAccount) {
      accountBlock = ( <TwitterUser user={this.state.account} /> );
    } else {
      accountBlock = (
        <Block on full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>ACCOUNT SEARCHING...</p>
        </Block>
      );
    }

    let showTweetsBlock;
    if (this.state.getAccount) {
      showTweetsBlock = (
        <LinkBlock on={this.state.showData == 'tweets' ? true : false} full={this.props.full} one={this.props.one} two={this.props.two} onClick={ (event) => {this.showData(event,'tweets')} }>
          <h1 className='block-name'>TWEETS({this.state.account.statuses_count})</h1>
        </LinkBlock>
      );
    } else {
      showTweetsBlock = ( <span></span> );
    }

    let showFavoritesBlock;
    if (this.state.getAccount) {
      showFavoritesBlock = (
        <LinkBlock on={this.state.showData == 'favorites' ? true : false} full={this.props.full} one={this.props.one} two={this.props.two} onClick={ (event) => {this.showData(event,'favorites')} }>
          <h1 className='block-name'>FAVORITES({this.state.account.favourites_count})</h1>
        </LinkBlock>
      );
    } else {
      showFavoritesBlock = ( <span></span> );
    }

    let showFriendsBlock;
    if (this.state.getAccount) {
      showFriendsBlock = (
        <LinkBlock on={this.state.showData == 'friends' ? true : false} full={this.props.full} one={this.props.one} two={this.props.two} onClick={ (event) => {this.showData(event,'friends')} }>
          <h1 className='block-name'>FRIENDS({this.state.account.friends_count})</h1>
        </LinkBlock>
      );
    } else {
      showFriendsBlock = ( <span></span> );
    }

    let showFollowersBlock;
    if (this.state.getAccount) {
      showFollowersBlock = (
        <LinkBlock on={this.state.showData == 'followers' ? true : false} full={this.props.full} one={this.props.one} two={this.props.two} onClick={ (event) => {this.showData(event,'followers')} }>
          <h1 className='block-name'>FOLLOWERS({this.state.account.followers_count})</h1>
        </LinkBlock>
      );
    } else {
      showFollowersBlock = ( <span></span> );
    }


    let rateLimitsBlock;
    if (0 < this.state.rateLimits.length) {
      let rateLimits = this.state.rateLimits.map((rateLimit,index) => {
        if (index == 0) {
          return ( <span>{rateLimit}</span> );
        } else {
          return ( <span>, {rateLimit}</span> );
        }
      });
      rateLimitsBlock = (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <BlockName>RATE LIMITS</BlockName>
          <p>{rateLimits}</p>
        </Block>
      );
    } else {
      rateLimitsBlock = ( <span></span> );
    }

    let rateLimitErrorMessagesBlock;
    if (0 < this.state.rateLimitErrorMessages.length) {
      let messages = this.state.rateLimitErrorMessages.map((message, index) => {
        return (
          <span>{message}<br/></span>
        );
      });
      rateLimitErrorMessagesBlock = (
        <ErrorBlock full={this.props.full} one={this.props.one} two={this.props.two}>
          <ErrorBlockName>API Too Many Request</ErrorBlockName>
          <p>{messages}</p>
        </ErrorBlock>
      );
    } else {
      rateLimitErrorMessagesBlock = ( <span></span> );
    }

    let tweetsCountBlock;
    let tweetsBlock;
    if (this.state.showData == 'tweets') {
      if (this.state.getTweets) {
        tweetsCountBlock = (
          <Block full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>POSTS: {this.state.tweetsCount}</p>
          </Block>
        );
        if (this.state.tweetsLayout == 'default') {
          tweetsBlock = ( <Tweets tweets={this.state.tweets} tweetsCount={this.state.tweetsCount} tweetsAttribute={this.state.tweetsAttribute} full={this.props.full} one={this.props.one} two={this.props.two} /> );
        } else if (this.state.tweetsLayout == 'mediaPanels') {
          tweetsBlock = ( <TweetsMediaPanels tweets={this.state.tweets} tweetsCount={this.state.tweetsCount} full={this.props.full} one={this.props.one} two={this.props.two} /> );
        }
      } else {
        tweetsCountBlock = ( <span></span> );
        tweetsBlock = (
          <Block on full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>TWEETS SEARCHING...</p>
          </Block>
        );
      }
    } else {
      tweetsCountBlock = ( <span></span> );
      tweetsBlock = ( <span></span> );
    }

    let favoritesCountBlock;
    let favoritesBlock;
    if (this.state.showData == 'favorites') {
      if (this.state.getFavorites) {
        favoritesCountBlock = (
          <Block full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>POSTS: {this.state.favoritesCount}</p>
          </Block>
        );
        if (this.state.tweetsLayout == 'default') {
          favoritesBlock = ( <Tweets tweets={this.state.favorites} tweetsCount={this.state.favoritesCount} tweetsAttribute={this.state.tweetsAttribute} full={this.props.full} one={this.props.one} two={this.props.two} /> );
        } else if (this.state.tweetsLayout == 'mediaPanels') {
          favoritesBlock = ( <TweetsMediaPanels tweets={this.state.favorites} tweetsCount={this.state.favoritesCount} full={this.props.full} one={this.props.one} two={this.props.two} /> );
        }
      } else {
        favoritesCountBlock = ( <span></span> );
        favoritesBlock = (
          <Block on full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>FAVORITES SEARCHING...</p>
          </Block>
        );
      }
    } else {
      favoritesCountBlock = ( <span></span> );
      favoritesBlock = ( <span></span> );
    }

    let friendsBlock;
    if (this.state.showData == 'friends') {
      if (this.state.getFriends) {
        friendsBlock = ( <TwitterUsers users={this.state.friends} blockName={'FRIENDS'} full={this.props.full} one={this.props.one} two={this.props.two} /> );
      } else {
        friendsBlock = (
          <Block on full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>FRIENDS SEARCHING...</p>
          </Block>
        );
      }
    } else {
      friendsBlock = ( <span></span> );
    }

    let followersBlock;
    if (this.state.showData == 'followers') {
      if (this.state.getFollowers) {
        followersBlock = ( <TwitterUsers users={this.state.followers} blockName={'FOLLOWERS'} full={this.props.full} one={this.props.one} two={this.props.two} /> );
      } else {
        followersBlock = (
          <Block on full={this.props.full} one={this.props.one} two={this.props.two}>
            <p>FOLLOWERS SEARCHING...</p>
          </Block>
        );
      }
    } else {
      followersBlock = ( <span></span> );
    }


    let filterBlock;
    if (this.state.showData == 'tweets' || this.state.showData == 'favorites') {

      let attributeMenu;
      if (this.state.attributeMenu) {
        attributeMenu = (
          <div className='select-menu'>
            <div className='menu-list' onClick={ (event) => {this.attributeMenu('default')} }>default</div>
            <div className='menu-list' onClick={ (event) => {this.attributeMenu('mediaOnly')} }>mediaOnly</div>
          </div>
        );
      } else {
        attributeMenu = ( <span></span> );
      }

      let layoutMenu;
      if (this.state.layoutMenu) {
        layoutMenu = (
          <div className='select-menu'>
            <div className='menu-list' onClick={ (event) => {this.layoutMenu('default')} }>default</div>
            <div className='menu-list' onClick={ (event) => {this.layoutMenu('mediaPanels')} }>mediaPanels</div>
          </div>
        );
      } else {
        layoutMenu = ( <span></span> );
      }

      filterBlock = (
        <FilterBlock full={this.props.full} one={this.props.one} two={this.props.two}>
          <Filter>
            <label>attribute</label>
            <div className='filter'>
              <p onClick={ (event) => {this.selectAttributeMenu()} }>{this.state.tweetsAttribute}</p>
              {attributeMenu}
            </div>
          </Filter>
          <Filter>
            <label>layout</label>
            <div className='filter'>
              <p onClick={ (event) => {this.selectLayoutMenu()} }>{this.state.tweetsLayout}</p>
              {layoutMenu}
            </div>
          </Filter>
          <div style={{'clear':'both'}}></div>
          <BlockName>          </BlockName>
        </FilterBlock>
      );
    } else {
      filterBlock = ( <span></span> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <Helmet title={'TwitterAcount | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {accountBlock}

        {filterBlock}

        {showTweetsBlock}
        {showFavoritesBlock}
        {showFriendsBlock}
        {showFollowersBlock}

        {rateLimitsBlock}
        {rateLimitErrorMessagesBlock}

        {tweetsCountBlock}
        {tweetsBlock}
        {favoritesCountBlock}
        {favoritesBlock}
        {friendsBlock}
        {followersBlock}
      </div>
    );
  }

}
export default TwitterAccount;
