import axios from 'axios';
  
export default function Deepl(fromText, fromLang, toLang) {

  return new Promise((resolve, reject) => {

    let params = { from_text:fromText, from_lang:fromLang, to_lang:toLang, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/translate/deepl/', params, headers)
    .then(res => {
      let toText = res.data.to_text;
      resolve(toText);
    })
    .catch(err => { reject() });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve('');
    });
  });

}
