import axios from 'axios';
  
export default function GetAccount(twitterId) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` }, timeout:1000 * 60 * 60 }
    axios.get('https://lab.takuman.me/api/oauth/twitter/' + twitterId + '/', headers)
    .then(res => {
      let account = JSON.parse(res.data);
      console.log(account);
      resolve({ 'account':account, });
    })
    .catch(err => {
      reject();
    });

  }).then((p) => {
    let account = p.account;
    return new Promise( (resolve, reject) => {
      let created_at = new Date(account.created_at);
      account.created_at = created_at.getFullYear() + '/' + (created_at.getMonth() + 1) + '/' + created_at.getDate() + ' ' + created_at.getHours() + ':' + created_at.getMinutes();
      resolve({ 'account':account, });
    });
  }).then((p) => {
    let account = p.account;
    return new Promise( (resolve, reject) => {
      resolve({ 'account':account, });
    });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve({ 'account':[], });
    });
  });

}
