import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/Form';
import { MusiquiField } from 'component/common/style/learning/DistributedLearning';

// function
import GetDistributedLearningTypes from 'component/common/function/GetDistributedLearningTypes';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import CheckSummary from "component/common/function/learning/CheckSummary";

// module
import NormalMusiquiField from "component/common/module/learning/NormalMusiquiField";
import SummaryMusiquiField from "component/common/module/learning/SummaryMusiquiField";


class CreateMusiqui extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      musiqui:"<h1>Part0　タイトル</h1>\n<p>h1タグにsummaryというidを付与すれば階層化された学習形態を利用可能。</p>",
      musiquiTypes:[],
      typeIndex:0,
      typeMenu:false,
      newType:"",
      summary:false,
      showMusiquiField:true,
    };
  }

  componentDidMount = () => {
    this.getDistributedLearningTypes();
    document.getElementById('from-text').focus();
  }

  getDistributedLearningTypes = () => {
    GetDistributedLearningTypes('musiqui').then((res) => {
      let musiquiTypes = res.musiquiTypes;
      musiquiTypes.push({'name':'new type',});
      this.setState({ musiquiTypes:musiquiTypes });
    });
  }

  selectType = (index) => {
    this.setState({ typeIndex:index, typeMenu:false, }, () => {
      if (this.state.musiquiTypes[index].name == 'new type') {
        document.getElementById('new-type').focus();
      }
    });
  }

  handleTypeMenu = () => {
    if (this.state.typeMenu) {
      this.setState({ typeMenu:false, });
    } else {
      this.setState({ typeMenu:true, });
    }
  }

  handleCloseTypeMenu = () => {
    this.setState({ typeMenu:false, });
  }

  changeMusiqui = (event) => {
    let musiqui = event.target.value;
    let summary = CheckSummary({"musiqui":musiqui});

    let musiquiStyle = document.getElementById("musiqui-style");
    let musiquiStyleScrollTop = musiquiStyle.scrollTop;

    this.setState({ musiqui:musiqui, summary:summary, showMusiquiField:false }, () => {
      TextHeightAdjustment("from-text");
      this.setState({ showMusiquiField:true });
      musiquiStyle.scrollTop = musiquiStyleScrollTop;
    });
  }

  saveDistributedLearning = () => {

    let type;
    if (this.state.musiquiTypes[this.state.typeIndex].name == 'new type') {
      type = this.state.newType;
    } else {
      type = this.state.musiquiTypes[this.state.typeIndex].name;
    }

    let question = '';
    let answer = '';
    let shakyo = '';

    if (type != '') {
      SaveDistributedLearning(type, question, answer, this.state.musiqui, shakyo).then((saved) => {
        if (saved) {
          this.props.handleFlashMessage('CREATED');
          this.setState({ musiqui:'', }, () => {
            TextHeightAdjustment("from-text");
            document.getElementById("musiqui-style").scrollTop = 0;
          });
        } else {
          this.props.handleFlashMessage('ERROR');
        }
      });
    }

  }

  changeNewType = (event) => {
    let newType = event.target.value;
    this.setState({ newType:newType, });
  }

  getMusiquiValid = (valid) => {
  }

  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectType;
    if (0 < this.state.musiquiTypes.length) {
      let selectTypeMenu = (
        <div className='select-menu'>
          {this.state.musiquiTypes.map( (type,index) => {
            if (this.state.typeIndex != index) {
              return ( <div className='menu-item' onClick={ (event) => {this.selectType(index)} }>{type.name}</div> );
            }
          })}
        </div>
      );
      selectType = (
        <div className='select'>
          <div className='select-item' onClick={ (event) => {this.handleTypeMenu()} }>type: {this.state.musiquiTypes[this.state.typeIndex].name}</div>
          {this.state.typeMenu ? selectTypeMenu : ''}
        </div>
      );
    } else {
      selectType = ( <div className='select'><div className='select-item'></div></div> );
    }

    let newTypeForm;
    if (0 < this.state.musiquiTypes.length) {
      if (this.state.musiquiTypes[this.state.typeIndex].name == 'new type') {
        newTypeForm = ( <input type='text' id='new-type' className='new-type-form' onChange={ (event) => {this.changeNewType(event)} } value={this.state.newType} placeholder='new type' /> );
      } else {
        newTypeForm = ( <span></span> );
      }
    } else {
      newTypeForm = ( <span></span> );
    }


    let musiqui;
    if (this.state.musiqui != "") {
      if (this.state.summary && this.state.showMusiquiField) {
        musiqui = ( <SummaryMusiquiField learning={{"musiqui":this.state.musiqui}} getMusiquiValid={this.getMusiquiValid} initShow={true} /> );
      } else if (this.state.summary == false && this.state.showMusiquiField) {
        musiqui = ( <NormalMusiquiField learning={{"musiqui":this.state.musiqui}} getMusiquiValid={this.getMusiquiValid} /> );
      }
    }


    let saveBtnField;
    if (0 < this.state.musiquiTypes.length) {
      if (this.state.musiquiTypes[this.state.typeIndex].name != 'new type' || (this.state.musiquiTypes[this.state.typeIndex].name == 'new type' && this.state.newType != '')) {
        if (this.state.musiqui != '') {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn submit onClick={ () => {this.saveDistributedLearning()} }>CREATE MUSIQUI</LinkBtn>
            </LinkBtnField>
          );
        } else {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn>CREATE MUSIQUI</LinkBtn>
            </LinkBtnField>
          );
        }
      } else {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn>CREATE MUSIQUI</LinkBtn>
          </LinkBtnField>
        );
      }
    } else {
      saveBtnField = (
        <LinkBtnField>
          <LinkBtn>CREATE MUSIQUI</LinkBtn>
        </LinkBtnField>
      );
    }


    let learningField = (
      <DistributedLearningFormField>
        <TypeField>
          {selectType}
          {newTypeForm}
        </TypeField>
        <TextField>
          <textarea type='text' id='from-text' onChange={ (event) => {this.changeMusiqui(event)} } value={this.state.musiqui} placeholder='musiqui' />
        </TextField>
        <TextField>
          {musiqui}
        </TextField>
        {saveBtnField}
      </DistributedLearningFormField>
    );


    let selectMenuWrapper;
    if (this.state.typeMenu) {
      selectMenuWrapper = ( <SelectMenuWrapper onClick={ (event) => {this.handleCloseTypeMenu()} } /> );
    } else {
      selectMenuWrapper = ( <span></span> );
    }



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Create Musiqui | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningFormStyle id="musiqui-style" onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {learningField}
          {selectMenuWrapper}
        </DistributedLearningFormStyle>
      </div>
    );
  }

}

export default CreateMusiqui;
