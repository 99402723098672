import axios from 'axios';
  
export default function SearchStudies(keyword) {

  return new Promise((resolve, reject) => {

    let params = { keyword:keyword, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/get_studies/', params, headers)
    .then(res => {
      let searchKeywords = res.data.search_keywords;
      let studies = res.data.studies;
      console.log('SearchStudies function');
      console.log(studies);

      for (var i=0; i<studies.length; i++) {
        studies[i].titleJa = '**********';
        if (studies[i].pub_date) {
          studies[i].pub_date = studies[i].pub_date.split('-')[0] + '/' + studies[i].pub_date.split('-')[1] + '/' + studies[i].pub_date.split('-')[2].substr(0,2);
        }
      }

      resolve({'searchKeywords':searchKeywords, 'studies':studies});
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve({ 'searchKeywords':[], 'studies':[], });
    });
  });

}
