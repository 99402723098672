import axios from 'axios';

export default function GetPost(hash) {

  return new Promise((resolve, reject) => {
    axios
    .get(`https://lab.takuman.me/api/post/read/` + hash + '/')
    .then(res => {
      let post = res.data.post;
      post.file = post.file.replace('http://', 'https://s3-ap-northeast-1.amazonaws.com/');
      post.postChilds = [];
      resolve(post);
    })
    .catch(err => { reject() });

  }).then((post) => {

    return new Promise( (resolve, reject) => {
      axios
      .get('https://lab.takuman.me/api/post_child/index/' + post.hash + '/')
      .then(res => {
        const postChilds = res.data.map((postChild) => {
          if (postChild.file) {
            postChild.file = postChild.file.replace('http://', 'https://s3-ap-northeast-1.amazonaws.com/');
          } else {
            postChild.file = '';
          }
          return postChild;
        });
        post.postChilds = postChilds;
        resolve(post);
      })
      .catch(err => { resolve(post) });

    });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
