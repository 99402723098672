import axios from 'axios';

export default function GetMyUserData() {

  return new Promise((resolve, reject) => {
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios
    .get('https://lab.takuman.me/api/user/read/', headers)
    .then(res => {
      let user = res.data;
      resolve(user);
    })
    .catch(err => { reject() });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
