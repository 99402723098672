import axios from 'axios';
  
export default function GetLearning(hash) {

  return new Promise((resolve, reject) => {
    console.log('function GetLearning');
    console.log('hash: ' + hash);

    var params = { hash:hash, };
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/learning/get_learning/', params, headers)
    .then(res => {
      let learning = res.data.learning;
      resolve(learning);
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
