import React, { Component } from 'react';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import {TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの


import TextComputeEffect from 'component/common/TextComputeEffect'; // テキストをババっと切り替えていくアニメーション

class ConfirmPopup extends React.Component {

  // propのタイプを定義
  static propTypes = {
    popupHide: PropTypes.func, // 確認ポップアップを非表示
    popupYes: PropTypes.func, // 確認ポップアップの承諾
  };

  // 初期値はモーダルを非表示に設定
  constructor(props) {
    super(props);
    this.state = {
      message:'',
    };
  }

  textEffectFunc = (stateName, stateValue) => {
    console.log('###############################################');
    console.log('textEffectFuncが実行されました！！！！！！！！！');
    console.log('stateName: ' + stateName);
    console.log('stateValue: ' + stateValue);
    console.log('###############################################');
    this.setState({ [stateName]:stateValue });
  }

  // 確認ポップアップの非表示
  handlePopupHide = () => {
    this.props.popupHide();
  }

  // 確認ポップアップの承諾
  handleYes = (e) => {
    this.props.popupYes(e);
  }

  render() {

    {/* 確認メッセージのエフェクト */}
    let textComputeEffect;
    if (this.props.popupShow) {
      textComputeEffect = (
        <TextComputeEffect text={this.props.message} stateName='message' delay='100' setStateFunc={this.textEffectFunc} />
      );
    } else {
      textComputeEffect = <span></span>
    }

    {/* 確認ポップアップのwrapper */}
    let confirmPopupWrapper;
    if (this.props.popupShow) {
      confirmPopupWrapper = (
        <div className='confirm-popup-wrapper' onClick={ () => {this.handlePopupHide()} }></div>
      );
    } else {
      confirmPopupWrapper = ( <span></span> );
    }

    {/* 確認ポップアップ */}
    let confirmPopup;
    if (this.props.popupShow) {
      confirmPopup = (
        <div className='confirm-popup'>
          <p>{this.state.message}</p>
          <div className='btns'>
            <div className='btn-field'>
              <div className='btn no' onClick={ () => {this.handlePopupHide()} }>{this.props.no}</div>
            </div>
            <div className='btn-field'>
              <div className='btn yes' onClick={ (e) => {this.handleYes(e) } }>{this.props.yes}</div>
            </div>
          </div>
        </div>
      );
    } else {
      confirmPopup = ( <span></span> );
    }

    {/* 確認ポップアップとそのwrapper */}
    let popup;
    popup = (
      <div>
        <CSSTransition in={this.props.popupShow} timeout={800} classNames="fade">{confirmPopupWrapper}</CSSTransition>
        <CSSTransition in={this.props.popupShow} timeout={800} classNames="fade-vertical-up">{confirmPopup}</CSSTransition>
      </div>
    );

    return(
      <div>
        {textComputeEffect}
        {popup}
      </div>
    );

  }

}

export default ConfirmPopup;

