import axios from 'axios';
  
export default function GetDistributedLearningSchedule() {

  return new Promise((resolve, reject) => {

    var params = { };
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/learning/get_schedule/', params, headers)
    .then(res => {
      console.log("res");
      console.log(res.data.schedule_infos);
      resolve({"schedule_infos":res.data.schedule_infos});
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve({});
    });
  });

}
