import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningStyle, DistributedLearningField, TextField, SelectMenuWrapper, ShakyoField } from 'component/common/style/learning/DistributedLearning';

// function
import GetTodayLearnings from 'component/common/function/GetTodayLearnings';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import ShakyoMatchCheck from 'component/common/function/ShakyoMatchCheck';
import ClickShakyoShadow from 'component/common/function/ClickShakyoShadow';

// module
import LearningTimer from 'component/common/module/learning/LearningTimer';


class TodayShakyo extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      type:'',
      learnings:[],
      selectLearningIndex:0,
      congratulation:false,
      status:false,
      showTimer:true,
      timerStatus:"stand-by",
    };
  }

  componentDidMount = () => {
    let type = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      type = pathItems[1].replace('today_distributed_learning_shakyo_','');
    } else {
      type = pathItems[0].replace('today_distributed_learning_shakyo_','');
    }
    this.setState({ type:type, });
    this.getLearnings(type);
  }

  componentWillUpdate = () => { document.body.addEventListener('keydown', this.keydownTab); }
  componentWillUnmount = () => { document.body.removeEventListener('keydown', this.keydownTab); }

  keydownTab = (event) => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    // #shakyo-frontにfocusされていて、Tabキーが入力されたらタブ文字を挿入する
    if (document.getElementById('shakyo-front') == document.activeElement && event.code == 'Tab') {
      event.preventDefault();
      var elem = event.target;
      var val = elem.value;
      var pos = elem.selectionStart;
      elem.value = val.substr(0, pos) + '\t' + val.substr(pos, val.length);
      elem.setSelectionRange(pos + 1, pos + 1);
    }
  }

  getLearnings = (type) => {
    let learnings = [];
    GetTodayLearnings(type).then((learnings) => {
      if (0 < learnings.length) {

        for (let i=0; i<learnings.length; i++) {
          learnings[i].timerCnt = 0;
        }

        this.setState({ learnings:learnings, }, () => {

          TextHeightAdjustment('shakyo-back').then((res) => {
            // #shakyo-backの調整後の高さを取得し、#shakyoの高さも同じ値にそろえる。
            let height = res.height;
            document.getElementById('shakyo').style.height = height;
          });

          this.shakyoMatchCheck();
        });
      } else {
        this.setState({ congratulation:true, });
      }
    });
  }


  clickCompleteBtn = () => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];
    let selectLearningIndex = this.state.selectLearningIndex;

    // 終了した写経を配列から除外。除外したところに次の写経がシフトするのでselectLearningIndexはそのまま。
    learnings.splice(selectLearningIndex,1);

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation;
    if (0 < learnings.length) {
      congratulation = false;
      // #shakyo-frontと#shakyo-backと#shakyo-shadowをリセットし、shakyoMatchCheck()でdomを更新
      document.getElementById('shakyo-front').value = '';
      document.getElementById('shakyo-back').value = learnings[0].shakyo;
      document.getElementById('shakyo-highlight').innerHTML = '';
      document.getElementById('shakyo-shadow').innerHTML = '';
      TextHeightAdjustment('shakyo-back').then((res) => {
        // #shakyo-backの調整後の高さを取得し、#shakyoの高さも同じ値にそろえる。
        let height = res.height;
        document.getElementById('shakyo').style.height = height;
      });
      this.shakyoMatchCheck();
    } else {
      congratulation = true;
    }

    // timerCnt(秒数)を分数に変換し、timesに加える
    let min = Math.floor(learning.timerCnt/60); // 分
    let sec = learning.timerCnt % 60; // 秒
    if (30 < sec) min++; // 秒部分で30秒以上経過しているのであれば1分プラスする
    (!learning.times) ? learning.times = String(min) : learning.times += "," + String(min);
    console.log("learning.times: " + learning.times);

    this.setState({ status:false, congratulation:congratulation, selectLearningIndex:selectLearningIndex, learnings:learnings, showTimer:false }, () => {
      document.getElementById('shakyo-style').scrollTop = 0;
      this.setState({ showTimer:true }); // タイマーを再マウント
    });

    let correct = true;

    SaveDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo, learning.times, correct).then((saved) => {
    });
  }

  deleteItem = () => {
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = this.state.learnings[this.state.selectLearningIndex];

    // 削除する問題をlearnings配列から除外
    let selectLearningIndex = this.state.selectLearningIndex;
    learnings.splice(selectLearningIndex,1);

    // learningsの最後尾まで回答したら先頭にもどる
    if (learnings.length < selectLearningIndex + 1) {
      selectLearningIndex = 0;
    }

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation;
    if (0 < learnings.length) {
      congratulation = false;
      // #shakyo-frontと#shakyo-backと#shakyo-shadowをリセットし、shakyoMatchCheck()でdomを更新
      document.getElementById('shakyo-front').value = '';
      document.getElementById('shakyo-back').value = learnings[0].shakyo;
      document.getElementById('shakyo-highlight').innerHTML = '';
      document.getElementById('shakyo-shadow').innerHTML = '';
      TextHeightAdjustment('shakyo-back').then((res) => {
        // #shakyo-backの調整後の高さを取得し、#shakyoの高さも同じ値にそろえる。
        let height = res.height;
        document.getElementById('shakyo').style.height = height;
      });
      this.shakyoMatchCheck();
    } else {
      congratulation = true;
    }

    this.setState({ congratulation:congratulation, selectLearningIndex:selectLearningIndex, learnings:learnings, });

    DeleteDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo).then(() => {
    });

  }

  clickShakyoShadow = (event) => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない
    ClickShakyoShadow(event, 'shakyo-front', 'shakyo-shadow');
  }

  shakyoMatchCheck = () => {
    ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
      let status = res.status;
      this.setState({ status:status, });
    });
  }

  getTimerCnt = (timerCnt) => {
    // 現在出題されている問題を取得
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];

    // timerCntを更新
    learning.timerCnt = timerCnt;
    this.setState({ learnings:learnings });
  }

  getTimerStatus = (timerStatus) => {
    this.setState({ timerStatus:timerStatus }, () => {
      if (timerStatus == "count")  document.getElementById('shakyo-front').focus();
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let shakyo;
    if (0 < this.state.learnings.length) {
      shakyo = (
        <ShakyoField id='shakyo' status={this.state.status}>
          <textarea type='text' id='shakyo-front' onSelect={ () => {this.shakyoMatchCheck()} } onChange={ () => {this.shakyoMatchCheck()} } onBlur={ () => {this.shakyoMatchCheck()} } />
          <textarea type='text' id='shakyo-back' value={this.state.learnings[this.state.selectLearningIndex].shakyo} readonly />
          <div id='shakyo-highlight'></div>
          <div id='shakyo-shadow' onClick={ (event) => {this.clickShakyoShadow(event)} }></div>
        </ShakyoField>
      );
    }

    let completeField;
    if (this.state.timerStatus == "count") {
      if (this.state.status) {
        completeField = (
          <div id='complete-field'>
            <div className='complete-btn' onClick={ () => {this.clickCompleteBtn()} }>complete</div>
          </div>
        );
      } else {
        completeField = (
          <div id='complete-field'>
            <div className='complete-btn'>complete</div>
          </div>
        );
      }
    }


    let distributedLearningShakyo;
    if (this.state.congratulation) {
      let imgs = [
                   'https://c.tenor.com/iNCbn635V04AAAAC/despicable-me2-minions.gif',
                   'https://media4.giphy.com/media/l1ugg8MSaCUpzyyyY/giphy.gif',
                   'https://thumbs.gfycat.com/EsteemedFloweryConch-max-1mb.gif',
                   'https://media0.giphy.com/media/5ULFBOHCMDiN4fUn2w/giphy.gif',
                   'https://media1.giphy.com/media/RMGHCIEkaey08FtcnJ/giphy-downsized-large.gif',
                   'https://media0.giphy.com/media/l4FGoHktFE5InlFEA/giphy.gif',
                   'http://24.media.tumblr.com/tumblr_mdj2jugP2B1ro9mijo1_500.gif',
                   'https://thumbs.gfycat.com/DecisiveMaleGoose-size_restricted.gif',
                   'https://i.pinimg.com/originals/d5/fc/51/d5fc51afb02bceb1de29e6b0eaebf3ac.gif',
                   'https://c.tenor.com/bQrjUlPjAnsAAAAC/despicable-me-minions.gif',
                   'https://i.makeagif.com/media/11-14-2015/Sy68Pp.gif',
                 ];
      distributedLearningShakyo = (
        <DistributedLearningField congratulation={this.state.congratulation}>
          <div className='head'>
            <h1 className='type'>{this.state.type}</h1>
          </div>
          <img className='congratulation' src={imgs[Math.floor(Math.random() * imgs.length)]} />
        </DistributedLearningField>
      );
    } else {

      let readTime;
      if (0 < this.state.learnings.length) {
        if (this.state.learnings[this.state.selectLearningIndex].times) {
          readTime = this.state.learnings[this.state.selectLearningIndex].times.split(",")[this.state.learnings[this.state.selectLearningIndex].times.split(",").length-1];
        } else {
          readTime = "?";
        }
      }

      let span;
      if (0 < this.state.learnings.length) {
        span = this.state.learnings[this.state.selectLearningIndex].span;
      }

      distributedLearningShakyo = (
        <DistributedLearningField status={this.state.status}>
          <div className='head'>
            <h1 className='type'>{this.state.type}</h1>
            <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} } /></div>
            <div className='read-time'>read time<br />{readTime}min</div>
            <div className='span'>span<br />{span}</div>
            <div className='progress'>progress<br />{this.state.selectLearningIndex + 1} / {this.state.learnings.length}</div>
          </div>
          {shakyo}
          {completeField}
        </DistributedLearningField>
      );
    }

    let timer;
    if (0 < this.state.learnings.length && this.state.showTimer) {
      timer = ( <LearningTimer getTimerCnt={this.getTimerCnt} getTimerStatus={this.getTimerStatus} learningId="shakyo" /> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Shakyo | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningStyle id='shakyo-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {distributedLearningShakyo}
          {timer}
        </DistributedLearningStyle>
      </div>
    );
  }

}

export default TodayShakyo;
