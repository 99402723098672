import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { PostsFeedLayoutStyle, PostsStyle, Post } from 'component/common/style/PostsFeedLayout';

import PostsPagination from 'component/common/module/PostsPagination';
import Deepl from 'component/common/function/Deepl';


class PostsFeedLayout extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      postsCount:this.props.postsCount, paginationNum:this.props.paginationNum,
      posts:this.props.posts,
    };
  }

  componentDidMount = () => {
    if (0 < this.state.postsCount) {
      let posts = this.state.posts.slice();
      for (var i=0; i<posts.length; i++) {
        posts[i].titleJa = '**********';
      }
      this.setState({ posts:posts, });
    }
  }

  translate = (i,title) => {
    if (title != '' && this.state.posts[i].titleJa == '**********') {
      let posts = this.state.posts.slice();
      posts[i].titleJa = 'translating...';
      this.setState({ posts:posts, });

      Deepl(title, 'en', 'ja').then((text) => {
        if (text != '') {
          posts[i].titleJa = text;
        } else {
          posts[i].titleJa = 'translate failes';
        }
        this.setState({ posts:posts, });
      });
    }
  }


  render() {

    let pathItems = window.location.pathname.slice(1).split('/');
    let paginations;
    let posts;
    if (0 < this.state.posts.length) {
      paginations = ( <PostsPagination postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} full={this.props.full} one={this.props.one} two={this.props.two} /> );
      let postList = this.state.posts.map((post,i) => {
        if (0 < post.title.length) {
          return (
            <Post full={this.props.full} one={this.props.one} two={this.props.two}>
              <div className='title'><Link to={this.props.full ? '/post_' + pathItems[0].split('_')[1] + '_' + post.hash : this.props.one ? '/post_' + pathItems[0].split('_')[1] + '_' + post.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/post_' + pathItems[1].split('_')[1] + '_' + post.hash}>{post.title}</Link></div>
              <div className='title-ja' onClick={ () => {this.translate(i,post.title)} }>{post.titleJa}</div>
              <ClearBoth />
            </Post>
          );
        }
      });
      posts = ( <PostsStyle id={'post-datas-style'} full={this.props.full} one={this.props.one} two={this.props.two}>{postList}</PostsStyle> );
    } else {
      paginations = ( <span></span> );
      posts = ( <span></span> );
    }

    return (
      <PostsFeedLayoutStyle>
        {paginations}
        <ClearBoth/>
        {posts}
        <ClearBoth/>
        {paginations}
        <ClearBoth/>
      </PostsFeedLayoutStyle>
    );

  }
}
export default PostsFeedLayout;
