import axios from 'axios';
  
export default function GetFollowers(twitterId) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` }, timeout:1000 * 60 * 60 }
    axios.get('https://lab.takuman.me/api/oauth/twitter/' + twitterId + '/friends/', headers)
    .then(res => {
      console.log('friends get1');
      let users = JSON.parse(res.data);
      console.log(users);
      resolve({ 'users':users, });
    })
    .catch(err => {
      console.log('friends get error1');
      console.log(err);
      reject();
    });

  }).then((p) => {
    let users = p.users;
    return new Promise( (resolve, reject) => {
      let created_at;
      for (var i=0; i<users.length; i++) {
        created_at = new Date(users[i].created_at);
        users[i].created_at = created_at.getFullYear() + '/' + (created_at.getMonth() + 1) + '/' + created_at.getDate() + ' ' + created_at.getHours() + ':' + created_at.getMinutes();
      }
      resolve({ users:users, });
    });
  }).then((p) => {
    let users = p.users;
    return new Promise( (resolve, reject) => {
      resolve({ 'users':users, });
    });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve({ 'users':[], });
    });
  });

}
