import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, white } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const UserFormStyle = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:50px 0; background:${subColor};
  }
`

const UserFormBlock = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:40px;
    h1 { color:${edgeColor}; font-size:20px; font-weight:100; border:1px solid ${edgeColor}; border-radius:2px;
         width:auto; height:auto; margin-bottom:30px; padding:2px 10px; display:inline-block; }
    p { color:${mainColor}; font-size:15px; margin:0; padding:0; }
    .error-message { color:${attentionColor}; }

    @media screen and (min-width:750px) { width:80%; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; }
    @media screen and (min-width:960px) { width:60%; }
  }
`

const Field = styled.div`
  &&& {
    width:100%; height:auto; margin:0 auto 20px auto; padding:0 auto 100px auto; clear:both; 
    label { color:${mainColor}; font-size:15px; }
    input { width:100%; padding:10px 0; color:${mainColor}; border:none; border-bottom:1px solid ${rgba(mainColor,0.2)}; background:${subColor}; }
    input:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
    input:focus { border-bottom:3px solid ${mainColor}; outline:0; }
    .error { color:${attentionColor}; }
  }
`

const SubmitField = styled.div`
  &&& { text-align:center; margin-top:30px; clear:both;

    .submit-btn { padding:8px; display:inline-block; 
      .btn { padding:10px 15px; color:black; background:${edgeColor}; border:none; border-radius:2px; transition:.2s; }
      .btn:disabled { background:${rgba(edgeColor,0.5)}; }
      .btn:enabled { background:${edgeColor}; cursor:pointer; }
    }
    .submit-btn:active, .submit-btn:hover {
      .btn { opacity:0.9; padding:13px 18px; font-weight:900; }
    }
    .submit-now { animation:fade 0.5s linear infinite; }
    @keyframes fade {
      0% { opacity:1; }
      50% { opacity:0.7; }
      100% { opacity:1; }
    }
    @keyframes guni {
      0% { transform:scale(1,1); }
      50% { transform:scale(1.6,0.8); }
      100% { transform:scale(1,1); }
    }
  }
`


export { UserFormStyle, UserFormBlock, Field, SubmitField };
