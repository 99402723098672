import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import { Block, BlockName } from 'component/common/style/learning/Top';


class LearningTypeBlock extends React.Component{

  static propTypes = {
    getTimerCnt: PropTypes.func,
    getTimerStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => { 
  }


  render() {

    let types;
    let totalPercentage = 0;
    let totalEstimateReadTime = 0;
    let totalNoTimeCnt = 0;
    if (0 < this.props.types.length) {

      totalPercentage = Math.round(this.props.types.reduce((sum, i) => sum + i.avgPercentage, 0) / this.props.types.length);

      types = this.props.types.map((type, index) => {

        let url;
        if (this.props.full) {
          url = "/distributed_learning_" + this.props.urlType + "_index_" + type.name;
        } else if (this.props.one) {
          url = "/distributed_learning_" + this.props.urlType + "_index_" + type.name + "/" + window.location.pathname.slice(1).split("/")[1];
        } else {
          url = "/" + window.location.pathname.slice(1).split("/")[0] + "/distributed_learning_" + this.props.urlType + "_index_" + type.name;
        }

        if (type.name.includes("basic-")) totalEstimateReadTime += type.totalEstimateReadTime;
        if (type.name.includes("basic-")) totalNoTimeCnt += type.totalNoTimeCnt;

        return (
          <p>・<Link to={url}>{type.name}<label className='sub-label'>N={type.totalCount}</label><label className='sub-label'>{type.avgPercentage}%</label><label className='sub-label'>{(type.totalNoTimeCnt == 0) ? type.totalEstimateReadTime + "min" : type.totalEstimateReadTime + "min(" + type.totalNoTimeCnt + "?)"}</label></Link></p>
        );

      });

    } else {
      types = ( <p>分散学習のリストはまだありません。</p> );
    }

    {/*
      <Block full={this.props.full} one={this.props.one} two={this.props.two} onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
    */}

    return (
      <Block full={this.props.full} one={this.props.one} two={this.props.two}>
        <BlockName>{this.props.label} INDEX ----- {totalPercentage}% --- {totalEstimateReadTime}min({Math.round(totalEstimateReadTime / 16)}min/week){(totalNoTimeCnt == 0) ? "" : "(" + totalNoTimeCnt + "?)"}</BlockName>
        {types}
      </Block>
    );

  }
}
export default LearningTypeBlock;
