import axios from 'axios';
  
export default function UpdateDistributedLearning(hash, type, question, answer, musiqui, shakyo, span, times) {

  return new Promise((resolve, reject) => {

    var params = { hash:hash, type:type, question:question, answer:answer, musiqui:musiqui, shakyo:shakyo, span:span, times:times}
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/distributed_learning/update/', params, headers)
    .then(res => {
      resolve(true);
    })
    .catch(err => { });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
