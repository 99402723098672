import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, white, black, red, blue, green, yellow, cyan, magenta } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

// DistributedLearningMusiquiIndex.js
// DistributedLearningShakyoIndex.js

const DistributedLearningIndexStyle = styled.div`
  &&& {
    padding-top:40px;
  }
`

const Blocks = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '40'}vw; }
  }
`

const Block = styled.div`
  &&& {
    width:20vw; height:20vw; display:inline-block; float:left; background:${subColor};
    &:hover { ${borderFlash(2)} }
  }
`

const BlockContent = styled(Link)`
  &&& {
    width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; padding:0; background:${subColor}; display:block; position:relative;
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    .item-name { position:absolute; top:0; bottom:0; left:0; right:0; z-index:30; width:100%; height:100%; padding:0; display:table;
      h2 { display:table-cell; vertical-align:middle; padding:10px; color:${mainColor}; font-size:18px; text-align:center; line-height:20px; font-weight:100; }
    }
    .span, .read-time { position:absolute; right:0; z-index:35; width:80px; height:auto; margin:0; padding:1.5px 5px;
                        background:${mainColor}; color:${subColor}; font-size:12px; text-align:center; border-radius:0px 0 0 0px; }
    .span { top:20px; }
    .level-1 { background:${white}; } // span=0
    .level-2 { background:${white}; } // span=1
    .level-3 { background:${white}; } // span=2
    .level-4 { background:${yellow}; } // span=4
    .level-5 { background:${yellow}; } // span=8
    .level-6 { background:${yellow}; } // span=16
    .level-7 { background:${blue}; } // span=32
    .level-8 { background:${green}; } // span=64
    .level-max { background:${red}; } // span=128
    .read-time { top:45px; }
  }
`

export { DistributedLearningIndexStyle, Blocks, Block, BlockContent };
