import React from 'react';
import { Router, Route, Link, Switch, Redirect } from 'react-router-dom'; // SPA化するためのもの   
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player';

// function
import CreatePost from 'component/common/function/CreatePost';

// style
import { BackBtnField, MovieField } from 'component/common/style/GlobalStyle';
import { PostFormStyle, PostFormBlock, Field, PdfPreview, Selectbox, FileBtn, Booleans, SubmitField, FieldBtns, BtnField, Btn, MinusField, PlusField } from 'component/common/style/PostForm';

class NewPost extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  // 初期値
  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      errorMessage:'', //エラーメッセージ
      type:this.props.type, // コンテンツのタイプ
      releaseRange:'public', // 公開範囲
      tag:'', // タグ
      title:'', // タイトルの初期値
      writes:'', // コンテンツの初期値
      file:'',
      prevPdf:'', // PDFプレビュー
      prevImage:'', // 画像プレビュー
      prevMovie:'', // 動画プレビュー
      checkValid:true, // バリデーションチェック。バリデーションに引っかかった場合はtrue
      postChilds:[],
      submit:false, 
    }
  }

  componentDidMount = () => {
    this.props.logoAnimation('kodo');
    this.props.helloLab();
  }

  handleValueChange = (event, stateName) => {
    if (stateName != 'file') {
      this.setState({ [stateName]:event.target.value });
    } else {

      if (event.target.files.length != 0) { 
        let postChilds = this.state.postChilds.slice(); 
        for (var i=0; i<event.target.files.length; i++) { 
          let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
          let fileUrl = createObjectURL(event.target.files[i]);
          let fileSplit = event.target.files[i].name.split('.');
          let extension = fileSplit[fileSplit.length - 1];
          extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
          if (extension == 'pdf') {
            if (i == 0) {
              this.setState({ [stateName]:event.target.files[0], prevPdf:fileUrl, prevImage:'', prevMovie:'' });
            } else {
              if (1 < event.target.files.length) {
                // 複数選択の場合は、postChildにファイルがない場合のみ追加する。
                if (this.state.postChilds.length == 0) {
                  postChilds.push({ hash:'', order:i-1, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:fileUrl, prevImage:'', prevMovie:'', })

                }
              }
            }
          } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
              extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
              extension == 'ico' || extension == 'icns') {
            if (i == 0) { 
              this.setState({ [stateName]:event.target.files[0], prevPdf:'', prevImage:fileUrl, prevMovie:'' });
            } else { 
              if (1 < event.target.files.length) { 
                // 複数選択の場合は、postChildにファイルがない場合のみ追加する。
                if (this.state.postChilds.length == 0) { 
                  postChilds.push({ hash:'', order:i-1, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:'', prevImage:fileUrl, prevMovie:'', })

                } 
              } 
            } 
          } else {
            if (i == 0) { 
              this.setState({ [stateName]:event.target.files[0], prevPdf:'', prevImage:'', prevMovie:fileUrl });
            } else { 
              if (1 < event.target.files.length) { 
                // 複数選択の場合は、postChildにファイルがない場合のみ追加する。
                if (this.state.postChilds.length == 0) { 
                  postChilds.push({ hash:'', order:i-1, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:'', prevImage:'', prevMovie:fileUrl, })
                } 
              } 
            } 
          }
        } 

        this.setState({ postChilds:postChilds }); 
      } 

    }
    if (stateName == 'title' || stateName == 'file') {
      if (event.target.value.length>0 || this.state.title>0 || this.state.file>0) {
        this.setState({ checkValid:false });
      } else {
        this.setState({ checkValid:true });
      }
    }
  }


  handleChildValueChange(event, stateName, order) {
    const sliceArray = this.state.postChilds.slice();
    if (stateName == 'file') {


      for (var i=0; i<event.target.files.length; i++) { 
        let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
        //let fileUrl = createObjectURL(event.target.files[i]);
        let fileUrl = event.target.files.length===0 ? "" : createObjectURL(event.target.files[i]);
        let fileSplit = event.target.files[i].name.split('.');
        let extension = fileSplit[fileSplit.length - 1];
        extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。

        if (extension == 'pdf') {
          if (i == 0) {
            sliceArray[order + i]['prevPdf'] = fileUrl;
            sliceArray[order + i]['prevImage'] = '';
            sliceArray[order + i]['prevMovie'] = '';
            sliceArray[order + i][stateName] = event.target.files[i];
          } else if (this.state.postChilds.length <= order + 1) {
            // ファイルが複数枚あり、orderがpostchildsの最後尾の場合は、2枚目以降のファイルデータ用のpostChildを追加する。
            sliceArray.push({ hash:'', order:order + i, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:fileUrl, prevImage:'', prevMovie:'', })
          }
        } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
            extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
            extension == 'ico' || extension == 'icns') {
          if (i == 0) {
            sliceArray[order + i]['prevPdf'] = '';
            sliceArray[order + i]['prevImage'] = fileUrl;
            sliceArray[order + i]['prevMovie'] = '';
            sliceArray[order + i][stateName] = event.target.files[i];
          } else if (this.state.postChilds.length <= order + 1) {
            // ファイルが複数枚あり、orderがpostchildsの最後尾の場合は、2枚目以降のファイルデータ用のpostChildを追加する。
            sliceArray.push({ hash:'', order:order + i, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:'', prevImage:fileUrl, prevMovie:'', })
          }
        } else {
          if (i == 0) {
            sliceArray[order + i]['prevPdf'] = '';
            sliceArray[order + i]['prevImage'] = '';
            sliceArray[order + i]['prevMovie'] = fileUrl;
            sliceArray[order + i][stateName] = event.target.files[i];
          } else if (this.state.postChilds.length <= order + 1) {
            // ファイルが複数枚あり、orderがpostchildsの最後尾の場合は、2枚目以降のファイルデータ用のpostChildを追加する。
            sliceArray.push({ hash:'', order:order + i, titleShow:false, codeShow:false, writesShow:false, fileShow:true, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:event.target.files[i], file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:'', prevImage:'', prevMovie:fileUrl, })
          }
        }
      }

    } else if (stateName == 'title_size') { 
      if (sliceArray[order][stateName] == 'h1') {
        sliceArray[order][stateName] = 'h2';
      } else if (sliceArray[order][stateName] == 'h2') { 
        sliceArray[order][stateName] = 'h3'; 
      } else if (sliceArray[order][stateName] == 'h3') {
        sliceArray[order][stateName] = 'h4'; 
      } else {
        sliceArray[order][stateName] = 'h1';
      }
    } else if (stateName == 'code_html_esc') {
      if (sliceArray[order][stateName]) {
        sliceArray[order][stateName] = false;
      } else {
        sliceArray[order][stateName] = true;
      }
    } else if (stateName == 'code_show') {
      if (sliceArray[order][stateName]) {
        sliceArray[order][stateName] = false;
      } else {
        sliceArray[order][stateName] = true;
      }
    } else {
      sliceArray[order][stateName] = event.target.value;
    }

    this.setState({ postChilds:sliceArray })
  }

  showField = (event, stateName, order) => {
    const sliceArray = this.state.postChilds.slice();
    if (sliceArray[order][stateName + 'Show']) {
      sliceArray[order][stateName + 'Show'] = false;
      sliceArray[order][stateName] = '';
      this.setState({ postChilds:sliceArray });
    } else {
      sliceArray[order][stateName + 'Show'] = true;
      this.setState({ postChilds:sliceArray });
    }
  }

  createPost = (event) => {
    event.preventDefault();
    this.setState({ submit:true, });

    CreatePost(this.state).then((success) => {
      if (success) {
        this.props.handleFlashMessage('CREATED');
        this.setState({ submit:false, errorMessage:'', type:'no-choice', releaseRange:'private', tag:'', titleInit:true, title:'', hasTitleError:true, writes:'', fileInit:true, file:'', user:this.state.user, checkValid:true, postChilds:[] });

        // Posts画面にリダイレクト
        let pathItems = window.location.pathname.slice(1).split('/');
        if (this.props.full) {
          History.push('/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1]);
        } else if (this.props.one) {
          History.push('/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '/' + pathItems[1]);
        } else if (this.props.two) {
          History.push('/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1]);
        }
      }
    });
  }


  removeChild = (order) => {
    const filterArray = this.state.postChilds.filter( function(element, array){ return ( element.order != order ); });
    // filterで排除した要素以降の配列要素のorderをそれぞれ-1して調整する。
    for(var i=0; i<filterArray.length; i++){ 
      if (i >= order) { filterArray[i].order = i; }
    }
    this.setState({ postChilds:filterArray });
  }

  addChild = () => {
    // 新しいフォームをpostChilds配列の後ろに追加
    const order = this.state.postChilds.length // 配列の個数を取得し、最後尾となるorderの数を取得
    this.setState({ postChilds: this.state.postChilds.concat([ {hash:'', order:order, titleShow:false, codeShow:false, writesShow:false, fileShow:false, urlShow:false, embedHashShow:false, title:'', title_size:'h1', code:'', code_html_esc:false, code_show:true, writes:'', file:'', file_url:'', file_name:'', url:'', url_name:'', embed_hash:'', post:'', user:'', prevPdf:'', prevImage:'', prevMovie:'', } ]) });
  }


  render() {


    const postChildsForm = this.state.postChilds.map((postChild, index) => {

      let prevPdfChild;
      if (postChild.prevPdf != '') {
        prevPdfChild = ( <PdfPreview src={postChild.prevPdf + '#zoom=32'} type='application/pdf' /> );
      } else {
        prevPdfChild = ( <span></span> );
      }

      let prevImageChild;
      if (postChild.prevImage != '') {
        prevImageChild = ( <img className='prev-image' src={postChild.prevImage} /> );
      } else {
        prevImageChild = ( <span></span> );
      }

      let prevMovieChild;
      if (postChild.prevMovie != '') {
        prevMovieChild = ( <ReactPlayer url={postChild.prevMovie} className='prev-movie' width='100%' height='auto' controls /> );
      } else {
        prevMovieChild = ( <span></span> );
      }

      return (
        <span>

          <Field show={postChild.titleShow}>
            <Booleans>
              <span className={'boolean'} onClick={ (event) => {this.handleChildValueChange(event, 'title_size', postChild.order)} }>{postChild.title_size}</span>
            </Booleans>
            <input type='text' name='title' value={postChild.title} onChange={ (event) => {this.handleChildValueChange(event, 'title', postChild.order)} }  />
          </Field>

          <Field show={postChild.codeShow}>
            <Booleans>
              <span className={'boolean'} onClick={ (event) => {this.handleChildValueChange(event, 'code_html_esc', postChild.order)} }>
                {postChild.code_html_esc ? 'esc' : 'no esc'}
              </span>
              <span className={'boolean'} onClick={ (event) => {this.handleChildValueChange(event, 'code_show', postChild.order)} }>
                {postChild.code_show ? 'show' : 'hide'}
              </span>
            </Booleans>
            <textarea value={postChild.code} onChange={ (event) => {this.handleChildValueChange(event, 'code', postChild.order)} }  />
          </Field>

          <Field show={postChild.writesShow}>
            <textarea value={postChild.writes} onChange={ (event) => {this.handleChildValueChange(event, 'writes', postChild.order)} }  />
          </Field>

          <Field show={postChild.fileShow}>
            {prevPdfChild}
            {prevImageChild}
            {prevMovieChild}
            <FileBtn>
              ファイルを選択
              <input type='file' ref='file' multiple='multiple' className='input' id='upload-img' onChange={ (event) => {this.handleChildValueChange(event, 'file', postChild.order)} } />
              <input type='text' id='uv' className='upload-value' disabled />
            </FileBtn>
          </Field>

          <Field show={postChild.urlShow}>
            <input type='text' name='url_name' value={postChild.url_name} onChange={ (event) => {this.handleChildValueChange(event, 'url_name', postChild.order)} }  />
            <input type='text' name='url' value={postChild.url} onChange={ (event) => {this.handleChildValueChange(event, 'url', postChild.order)} }  />
          </Field>

          <FieldBtns>

            <BtnField>
              <Btn>
                <div className={postChild.titleShow ? 'btn-content show':'btn-content'} onClick={ (event) => {this.showField(event, 'title', postChild.order)} }>
                  <div className='title-rect1'></div>
                </div>
              </Btn>
            </BtnField>

            <BtnField>
              <Btn>
                <div className={postChild.codeShow ? 'btn-content show':'btn-content'} onClick={ (event) => {this.showField(event, 'code', postChild.order)} }>
                  <div className='code-rect1'></div>
                  <div className='code-rect2'></div>
                  <div className='code-rect3'></div>
                  <div className='code-rect4'></div>
                  <div className='code-rect5'></div>
                </div>
              </Btn>
            </BtnField>

            <BtnField>
              <Btn>
                <div className={postChild.writesShow ? 'btn-content show':'btn-content'} onClick={ (event) => {this.showField(event, 'writes', postChild.order)} }>
                  <div className='writes-rect1'></div>
                  <div className='writes-rect2'></div>
                  <div className='writes-rect3'></div>
                </div>
              </Btn>
            </BtnField>

            <BtnField>
              <Btn>
                <div className={postChild.fileShow ? 'btn-content show':'btn-content'} onClick={ (event) => {this.showField(event, 'file', postChild.order)} }>
                  <div className='file-circle1'></div>
                  <div className='file-circle2'></div>
                </div>
              </Btn>
            </BtnField>

            <BtnField>
              <Btn>
                <div className={postChild.urlShow ? 'btn-content show':'btn-content'} onClick={ (event) => {this.showField(event, 'url', postChild.order)} }>
                  <div className='url-circle1'></div>
                  <div className='url-circle2'></div>
                </div>
              </Btn>
            </BtnField>

          </FieldBtns>

          <MinusField><div className='minus' onClick={ () => {this.removeChild(postChild.order)} }><div className='minus-o'></div><div className='minus-rect'></div></div></MinusField>

        </span>
      )
    });



    let prevPdf;
    if (this.state.prevPdf != '') {
      prevPdf = ( <PdfPreview src={this.state.prevPdf  + '#zoom=32'} type='application/pdf' /> );
    } else {
      prevPdf = ( <span></span> );
    }

    let prevImage;
    if (this.state.prevImage != '') {
      prevImage = ( <img className='prev-image' src={this.state.prevImage} /> );
    } else {
      prevImage = ( <span></span> );
    }

    let prevMovie;
    if (this.state.prevMovie != '') {
      prevMovie = ( <ReactPlayer url={this.state.prevMovie} className='prev-movie' width='100%' height='auto' controls /> );
    } else {
      prevMovie = ( <span></span> );
    }

    let submitBtn;
    if (this.state.submit) {
      submitBtn = ( <input type='submit' value='投稿中' className='btn fas submit-now' disabled /> ); // 投稿中
    } else {
      if (this.state.checkValid) {
        submitBtn = ( <input type='submit' value='投稿' className='btn' disabled /> ); // 投稿できない
      } else {
        submitBtn = ( <input type='submit' value='投稿' className='btn' enabled /> ); // 投稿できる
      }
    }


    let form = (
      <form onSubmit={ (event) => {this.createPost(event)} }>

        <Field show>
          <label>title</label>
          <input type='text' name='title' value={this.state.title} onChange={ (event) => {this.handleValueChange(event, 'title')} } />
        </Field>

        <Field show>
          <label>type</label>
          <Selectbox className='sb'>
            <select name='type' id='type' value={this.state.type} onChange={ (event) => {this.handleValueChange(event, 'type')} }>
              <option value='no-choice' key='no-choice'>no choice</option>
              <option value='core' key='core'>core</option>
              <option value='oso' key='oso'>oso</option>
              <option value='study' key='study'>study</option>
              <option value='review' key='review'>review</option>
              <option value='science' key='science'>science</option>
              <option value='design' key='design'>design</option>
              <option value='trade' key='trade'>trade</option>
              <option value='business' key='business'>business</option>
              <option value='program' key='program'>program</option>
              <option value='porn' key='porn'>porn</option>
              <option value='etc' key='etc'>etc</option>
            </select>
          </Selectbox>
        </Field>

        <Field show>
          <label>release range</label>
          <Selectbox className='sb'>
            <select name='release-range' id='release-range' value={this.state.releaseRange} onChange={ (event) => {this.handleValueChange(event, 'releaseRange')} }>
              <option value='private' key='private'>private</option>
              <option value='public' key='public'>public</option>
            </select>
          </Selectbox>
        </Field>

        <Field show>
          <label>tag</label>
          <textarea value={this.state.tag} className='textarea-tag' onChange={ (event) => {this.handleValueChange(event, 'tag')} } />
        </Field>

        <Field show>
          <label>writes</label>
          <textarea value={this.state.writes} onChange={ (event) => {this.handleValueChange(event, 'writes')} } />
        </Field>

        <Field show>
          <label>file</label>
          {prevPdf}
          {prevImage}
          {prevMovie}
          <FileBtn>
            ファイルを選択
            <input type='file' ref='file' multiple='multiple' className='input' id='upload-img' onChange={ (event) => {this.handleValueChange(event, 'file')} } />
            <input type='text' id='uv' className='upload-value' disabled />
          </FileBtn>
        </Field>

        {postChildsForm}

        <PlusField>
          <div className='plus' onClick={ () => {this.addChild()} }>
            <div className='plus-o'></div><div className='plus-rect1'></div><div className='plus-rect2'></div>>
          </div>
        </PlusField>

        <SubmitField>
          <div className='submit-btn'>{submitBtn}</div>
        </SubmitField>

      </form>
    );



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'NewPost | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] : this.props.one ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1]}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>

        <PostFormStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          <PostFormBlock full={this.props.full} one={this.props.one} two={this.props.two}>
            <h1>CREATE</h1>
            {form}
          </PostFormBlock>
        </PostFormStyle>
      </div>
    );

  }
}
export default NewPost;
