import React from 'react';
import axios from 'axios';
import History from 'History';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { Block, BlockName, UserName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock } from 'component/common/style/Oauth';

class TwitterUsers extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      users:this.props.users,
      blockName:this.props.blockName,
    };
  }

  componentDidMount = () => {
  }


  render() {

    let users;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (0 < this.state.users.length) {
      users = this.state.users.map((user, index) => {
        let name;
        if (user.protected) {
          name = ( <p><i className='fas fa-lock'></i> <Link to={this.props.full ? '/oauth_twitter_account_' + user.id_str : this.props.one ? '/oauth_twitter_account_' + user.id_str + '/' + pathItems[1] : '/' + pathItems[0] + '/oauth_twitter_account_' + user.id_str}>{user.name}(@{user.screen_name})</Link></p> );
        } else {
          name = ( <p><Link to={this.props.full ? '/oauth_twitter_account_' + user.id_str : this.props.one ? '/oauth_twitter_account_' + user.id_str + '/' + pathItems[1] : '/' + pathItems[0] + '/oauth_twitter_account_' + user.id_str}>{user.name}(@{user.screen_name})</Link></p> );
        }
        return (
          <UserName>
            <img src={user.profile_image_url != null ? user.profile_image_url : 'https://www.wallpapertip.com/wmimgs/30-309624_techno-music-wallpapers-hd-sonic-full-hd.jpg'} />
            {name}
          </UserName>
        );
      });
    } else {
      users = (
        <p>{this.state.blockName} NOTHING</p>
      );
    }

    let usersBlock;
    return usersBlock = (
      <Block full={this.props.full} one={this.props.one} two={this.props.two}>
        <BlockName>{this.state.blockName}</BlockName>
        {users}
      </Block>
    );

  }

}
export default TwitterUsers;
