import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
//import ReactPlayer from 'react-player'
//import throttle from 'lodash.throttle';

import { TimerField } from 'component/common/style/learning/DistributedLearning';


class LearningTimer extends React.Component{

  static propTypes = {
    getTimerCnt: PropTypes.func,
    getTimerStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      timer:null,
      timerStatus:'stand-by', // stand-by, count, stop
      timerCnt:0,
      timerCountTime:"00:00", // 00:00または00:00:00の形式の学習時間
      countStartTime:null, // タイマーをスタートまたはリスタートした時刻。ブラウザのタブが非アクティブ化したときの時間同期に使う
      timerCntBuffer:0, // タイマーを一時停止した際にそれまでにカウントした秒数の値を保持するための変数。ブラウザのタブが非アクティブ化したときの時間同期に使う
    };
  }

  componentDidMount = () => { 
    this.props.getTimerStatus(this.state.timerStatus);
    this.learningShowOrHide();
  }

  componentWillUnmount = () => {
    this.timerReset();
    document.removeEventListener('touchmove', this.disableScroll, { passive: false }); // スクロール許可
    document.removeEventListener('mousewheel', this.disableScroll, { passive: false }); // スクロール許可
  }

  // 引数で秒数を受け取り、00:00または00:00:00という形式に変換しリターンする関数
  getTime = (cnt) => {
      // 時間を取得
      let hour = Math.floor(cnt/3600); // 時間
      let min = Math.floor(cnt/60) % 60; // 分
      let sec = cnt % 60; // 秒

      // min、secが1桁であれば先頭に0を追加する
      let time_m = min < 10 ? "0" + min : min;
      let time_s = sec < 10 ? "0" + sec : sec;

      let time;
      if (hour == 0) {
        // 00:00という形式の時間を生成する
        time = time_m + ":" + time_s;
      } else {
        // 00:00:00という形式の時間を生成する
        let time_h = hour < 10 ? "0" + hour : hour; // hourが1桁であれば先頭に０を追加する
        time = time_h + ":" + time_m + ":" + time_s;
      }
      //console.log("time: " + time);

    return time;
  }

  // タイマーによる学習時間の計測をスタートまたはリスタートする関数
  timerCount = () => {

    // カウントをスタートまたはリスタートした時刻を取得
    let countStartTime = new Date();

    // すでに計測中であれば処理を終了
    if (this.state.timerStatus == "count") return;

    // 1秒間隔でタイマーを更新
    var timer = setInterval ( () => {

      // タイマーをスタートまたはリスタートした時刻と現在時刻の秒数の差を算出
      let now = new Date();
      let time;
      if (this.state.countStartTime) {
        time = now - this.state.countStartTime;
        time = Math.floor (time / 1000); // ミリ秒単位を秒単位に変換

        // 一度でもタイマーをストップしたのであれば、ストップした時点までにカウントした秒数を足す
        time += this.state.timerCntBuffer;

        let timerCountTime = this.getTime (time);
        this.setState ({ timerCnt:time, timerCountTime:timerCountTime });
        this.props.getTimerCnt(time);
      }

    }, 1000);

    this.setState ({ timer:timer, timerStatus:"count", countStartTime:countStartTime }, () => {
      this.props.getTimerStatus(this.state.timerStatus);
      this.learningShowOrHide();
    });
  }

  // タイマーをストップする関数
  timerStop = () => {
    clearInterval(this.state.timer);
    this.setState ({ timerStatus:"stop", timerCntBuffer:this.state.timerCnt }, () => {
      this.props.getTimerStatus(this.state.timerStatus);
      this.learningShowOrHide();
    });
  }

  // タイマーをリセットする関数
  timerReset = () => {
    if (this.state.timer != null) clearInterval(this.state.timer);
    this.setState({ timer:null, timerStatus:"stand-by", timerCnt:0, timerCountTime:"00:00", countStartTime:null, timerCntBuffer:0 }, () => {
      this.props.getTimerStatus(this.state.timerStatus);
      this.learningShowOrHide();
    });
  }

  learningShowOrHide = () => {
    let learningId = document.getElementById(this.props.learningId); // 分散学習問題の要素を取得
    if (this.state.timerStatus == "count") {
      // 表示モード
      learningId.classList.remove("learning-hide");
      document.removeEventListener('touchmove', this.disableScroll, { passive: false }); // スクロール許可
      document.removeEventListener('mousewheel', this.disableScroll, { passive: false }); // スクロール許可
    }
    if (this.state.timerStatus != "count") {
      // 非表示モード
      learningId.classList.add("learning-hide");
      document.addEventListener('touchmove', this.disableScroll, { passive: false }); // スクロール禁止
      document.addEventListener('mousewheel', this.disableScroll, { passive: false });  // スクロール禁止
    }
  }

  disableScroll = (event) => {
    event.preventDefault();
  }

  render() {

    if (this.state.timerStatus == "stand-by") {
      // 学習時間の計測前
      return (
        <TimerField status={this.state.timerStatus}>
          <div id='start-btn' className='btn' onClick={ () => {this.timerCount()} }><div className='btn-name'><i className='fas fa-play' /></div></div>
        </TimerField>
      );
    } else if (this.state.timerStatus == "count") {
      // 学習時間の計測中

      let clockDot;
      if (this.state.timerCnt % 3 == 0) clockDot = ( <span id='clock-dot'>.</span> );
      if (this.state.timerCnt % 3 == 1) clockDot = ( <span id='clock-dot'>..</span> );
      if (this.state.timerCnt % 3 == 2) clockDot = ( <span id='clock-dot'>...</span> );

      return (
        <TimerField status={this.state.timerStatus}>
          <div id='status-count' className='btn' onClick={ () => {this.timerStop()} }><div className='btn-name'><i className='fas fa-clock' />{clockDot}</div></div>
          <div id='count-time' className='btn' onClick={ () => {this.timerStop()} }><div className='btn-name'>{this.state.timerCountTime}</div></div>
          <div id='reset-btn' className='btn sub-btn' onClick={ () => {this.timerReset()}}><div className='btn-name'>RESET</div></div>
          <div id='stop-btn' className='btn sub-btn' onClick={ () => {this.timerStop()} }><div className='btn-name'><i className='fas fa-stop' /></div></div>
        </TimerField>
      );
    } else if (this.state.timerStatus == "stop") {
      // 学習時間の計測を一時停止中
      return (
        <TimerField status={this.state.timerStatus}>
          <div id='count-time' className='btn' onClick={ () => {this.timerCount()} }><div className='btn-name'>stop {this.state.timerCountTime}</div></div>
          <div id='reset-btn' className='btn sub-btn' onClick={ () => {this.timerReset()}}><div className='btn-name'>RESET</div></div>
          <div id='restart-btn' className='btn sub-btn' onClick={ () => {this.timerCount()} }><div className='btn-name'><i className='fas fa-play' /></div></div>
        </TimerField>
      );
    }

  }
}
export default LearningTimer;
