import React from 'react';
import ReactDOM from 'react-dom';
import App from 'component/common/App'; // すべてのコンポーネントを束ねるAppコンポーネントをimport
import * as serviceWorker from './serviceWorker'; // キャッシュを使って高速化するためのもの

// App.jsのJSXをHTMLに変換し、index.htmlのroodというidのところへ挿入
ReactDOM.render( <App />, document.getElementById('root') );

serviceWorker.unregister(); // キャッシュを使って高速化するためのもの

