import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const PostsBlockLayoutStyle = styled.div`
  &&& {
  }
`

const Blocks = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '40'}vw; }
  }
`

const Block = styled.div`
  &&& {
    width:20vw; height:20vw; display:inline-block; float:left; background:${subColor};
    &:hover { ${borderFlash(2)} }
  }
`

const BlockContent = styled(Link)`
  &&& {
    width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; padding:0; background:${subColor}; display:block; position:relative;
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    .file { position:absolute; top:0; bottom:0; left:0; right:0; z-index:10; width:100%; height:100%;
      img { object-fit:cover; width:100%; height:100%; }
    }
    .item-name { position:absolute; top:0; bottom:0; left:0; right:0; z-index:30; width:100%; height:100%; padding:0; display:table;
      h2 { display:table-cell; vertical-align:middle; padding:10px; color:${mainColor}; font-size:18px; text-align:center; line-height:20px; font-weight:100; }
    }
    .post-info { position:absolute; bottom:0; right:0; z-index:40; width:auto; height:25px; margin:0; padding:0; display:table;
                 background:${rgba(subColor,0.25)}; border-radius:3px 0 0 0;
      .info { color:${rgba(mainColor,1)}; font-size:15px; padding:5px; display:table-cell; vertical-align:middle; float:right; }
    }
    .post-info:hover { background:${rgba(subColor,0.8)}; }
  }
`

const PdfPreview = styled.embed`
  &&& {
    width:100%; height:100%;
    filter:invert(100%) brightness(75%) sepia(80%) hue-rotate(130deg) saturate(800%) contrast(1);
  }
`

export { PostsBlockLayoutStyle, Blocks, Block, BlockContent, PdfPreview };
