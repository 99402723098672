import React from 'react';
import { Router, Route, Link, Switch, Redirect } from 'react-router-dom'; // SPA化するためのもの   
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player';

// function
import MultiFileUploadPostFunc from 'component/common/function/MultiFileUploadPostFunc';

// style
import { BackBtnField, MovieField } from 'component/common/style/GlobalStyle';
import { PostFormStyle, PostFormBlock, Field, Selectbox, FileBtn, Booleans, SubmitField, FieldBtns, BtnField, Btn, MinusField, PlusField } from 'component/common/style/PostForm';

class MultiFileUploadPost extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  // 初期値
  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      errorMessage:'', //エラーメッセージ
      type:this.props.type, // コンテンツのタイプ
      releaseRange:'public', // 公開範囲
      tag:'', // タグ
      title:'', // タイトルの初期値
      writes:'', // コンテンツの初期値
      files:[], 
      filesLength:0, // アップロードするファイルの個数。アップロード完了率を測定するのにつかう。
      submit:false, // アップロードボタンを押したかどうか
      uploadPercentage:0, 
    }
  }

  componentDidMount = () => {
    this.props.logoAnimation('kodo');
    this.props.helloLab();
  }

  handleValueChange = (event, stateName) => {
    this.setState({ [stateName]:event.target.value }); 
  }

  handleChangeFile = (event) => {
    let files = event.target.files;
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
      var fileUrl = createObjectURL(files[i]);
      var fileSplit = files[i].name.split('.');
      var extension = fileSplit[fileSplit.length - 1];
      extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
      if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
          extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
          extension == 'ico' || extension == 'icns') {
        files[i]['prevImage'] = fileUrl;
        files[i]['prevMovie'] = '';
      } else {
        files[i]['prevImage'] = '';
        files[i]['prevMovie'] = fileUrl;
      }
      files[i]['order'] = i;
      filesArray.push(files[i]);
    }
    this.setState({ files:filesArray, filesLength:filesArray.length }); 
  }


  multiFileUploadPostFunc = (event) => {
    event.preventDefault();
    this.setState({ submit:true, });

    MultiFileUploadPostFunc(this.state).then((success) => {
      if (success) {
        this.props.handleFlashMessage('CREATED');
        this.setState({ submit:false, errorMessage:'', type:'no-choice', releaseRange:'private', tag:'', titleInit:true, title:'', hasTitleError:true, writes:'', fileInit:true, file:'', user:this.state.user, checkValid:true, postChilds:[] });

        // Posts画面にリダイレクト
        let pathItems = window.location.pathname.slice(1).split('/');
        if (this.props.full) {
          History.push('/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1]);
        } else if (this.props.one) {
          History.push('/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '/' + pathItems[1]);
        } else if (this.props.two) {
          History.push('/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1]);
        }
      }
    });
  }


  render() {


    const files = this.state.files.map((file, index) => {

      let prevImage;
      if (file.prevImage != '') {
        prevImage = ( <img className='prev-image' src={file.prevImage} /> );
      } else {
        prevImage = ( <span></span> );
      }

      let prevMovie;
      if (file.prevMovie != '') {
        prevMovie = ( <ReactPlayer url={file.prevMovie} className='prev-movie' width='100%' height='auto' controls /> );
      } else {
        prevMovie = ( <span></span> );
      }

      return (
        <div>
          {prevImage}
          {prevMovie}
        </div>
      );

    });



    let submitBtn;
    if (this.state.submit) {
      submitBtn = ( <input type='submit' value='投稿中' className='btn fas submit-now' disabled /> ); // 投稿中
    } else {
      if (this.state.checkValid) {
        submitBtn = ( <input type='submit' value='投稿' className='btn' disabled /> ); // 投稿できない
      } else {
        submitBtn = ( <input type='submit' value='投稿' className='btn' enabled /> ); // 投稿できる
      }
    }

    let form = (
      <form onSubmit={ (event) => {this.multiFileUploadPostFunc(event)} }>

        <Field show>
          <label>title</label>
          <input type='text' name='title' value={this.state.title} onChange={ (event) => {this.handleValueChange(event, 'title')} } />
        </Field>

        <Field show>
          <label>type</label>
          <Selectbox className='sb'>
            <select name='type' id='type' value={this.state.type} onChange={ (event) => {this.handleValueChange(event, 'type')} }>
              <option value='no-choice' key='no-choice'>no choice</option>
              <option value='core' key='core'>core</option>
              <option value='oso' key='oso'>oso</option>
              <option value='study' key='study'>study</option>
              <option value='review' key='review'>review</option>
              <option value='science' key='science'>science</option>
              <option value='design' key='design'>design</option>
              <option value='trade' key='trade'>trade</option>
              <option value='business' key='business'>business</option>
              <option value='program' key='program'>program</option>
              <option value='porn' key='porn'>porn</option>
              <option value='etc' key='etc'>etc</option>
            </select>
          </Selectbox>
        </Field>

        <Field show>
          <label>release range</label>
          <Selectbox className='sb'>
            <select name='release-range' id='release-range' value={this.state.releaseRange} onChange={ (event) => {this.handleValueChange(event, 'releaseRange')} }>
              <option value='private' key='private'>private</option>
              <option value='public' key='public'>public</option>
            </select>
          </Selectbox>
        </Field>

        <Field show>
          <label>tag</label>
          <textarea value={this.state.tag} className='textarea-tag' onChange={ (event) => {this.handleValueChange(event, 'tag')} } />
        </Field>

        <Field show>
          <label>writes</label>
          <textarea value={this.state.writes} onChange={ (event) => {this.handleValueChange(event, 'writes')} } />
        </Field>

        <Field show>
          <label>file(multi uploadavle)</label>
          <FileBtn>
            ファイルを選択
            <input type='file' ref='file' multiple='multiple' className='input' id='upload-img' onChange={ (event) => {this.handleChangeFile(event)} } />
            <input type='text' id='uv' className='upload-value' disabled />
          </FileBtn>
        </Field>

        {files}

        <SubmitField>
          <div className='submit-btn'>{submitBtn}</div>
        </SubmitField>

      </form>
    );

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'MultiFileUploadPost | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] : this.props.one ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1]}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>

        <PostFormStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          <PostFormBlock full={this.props.full} one={this.props.one} two={this.props.two}>
            <h1>MultiUpload</h1>
            {form}
          </PostFormBlock>
        </PostFormStyle>
      </div>
    );

  }
}
export default MultiFileUploadPost;
