import axios from 'axios';
  
export default function SaveDistributedLearning(type, question, answer, musiqui, shakyo, times, correct) {

  return new Promise((resolve, reject) => {

    var params = { type:type, question:question, answer:answer, musiqui:musiqui, shakyo:shakyo, times:times, correct:correct, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/distributed_learning/create_or_update/', params, headers)
    .then(res => {
      resolve(true);
    })
    .catch(err => { });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
