import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { PostsPaginationField, Paginations, Pagination } from 'component/common/style/PostsPagination';


class PostsPagination extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      postsCount:this.props.postsCount,
      paginationNum:this.props.paginationNum,
    };
  }


  render() {

    let paginations = [];
    let paginationCount = Math.ceil(this.state.postsCount / 100); 
    for (var i=1; i <= paginationCount; i++) { 
      if (paginationCount <= 10 || i == 1 || i == paginationCount || Math.abs(this.state.paginationNum - i) <= 3 || this.state.paginationNum == i) { 
        if (this.state.paginationNum == i) { 
          paginations.push( <Pagination select>{i}</Pagination>)
        } else { 
          paginations.push( <Pagination className={'pagination ' + (this.props.full ? 'full' : this.props.one ? 'one' : this.props.two ? 'two' : '')}>{i}</Pagination>)
        }
      } 
      if (10 <= paginationCount && Math.abs(this.state.paginationNum - i) == 4 && i != 1 && i != paginationCount) { 
        paginations.push( <Pagination shortened>...</Pagination> ); 
      } 
    } 
    if (0 < paginationCount) { 
      return ( 
        <PostsPaginationField>
          <Paginations>{paginations}</Paginations>
        </PostsPaginationField>
      ); 
    } else { 
      return ( <span></span> ); 
    }

  }
}
export default PostsPagination;
