import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { white, mainColor, subColor, edgeColor, linkColor, shadowColor } from 'component/common/style/Colors';
import { kodo, buru, rotate } from 'component/common/style/Animations';

const HeaderStyle = styled.div`
  header { width:50px; height:50px; position:fixed; top:0; left:0; right:0; background:none; border-bottom:0px solid ${rgba(mainColor,0.1)}; z-index:1000;
    .home-link { display:table; margin-left:10px;
      .logo { display:inline-block; width:40px; height:40px; margin:5px 0 5px 5px; position:relative; cursor:pointer; transition:0.5s;
        div { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; padding:0; transition:0.5s; transform:rotate(-5deg); }
        .logo-o1 { width:85%; height:85%; background:none; border:4px solid ${edgeColor}; border-radius:50%; }
        .kodo { animation:kodo 1s linear infinite, borderColorFlash 1s linear infinite; }
        .cold { border:4px solid ${white}; border-radius:0; ${buru(5,0.1)} } 
        .logo-x1 { width:4px; height:85%; background:${mainColor}; border-radius:5px; ${rotate(0,45,0.2)} }
        .logo-x2 { width:4px; height:85%; background:${mainColor}; border-radius:5px; ${rotate(0,135,0.2)} }
      }
      .logo:hover { border:${props => props.logoAnimation != 'close' ? edgeColor : mainColor} 1px solid; }
    }
`

export {HeaderStyle};
