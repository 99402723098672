import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { SearchBlock, RangeField, SearchField, PostsBlock } from 'component/common/style/PareoNaOtoko';

// function
import GetNewPostsPareoNaOtoko from 'component/common/function/GetNewPostsPareoNaOtoko';
import SearchPostsPareoNaOtoko from 'component/common/function/SearchPostsPareoNaOtoko';
import GetPostPareoNaOtoko from 'component/common/function/GetPostPareoNaOtoko';

class PareoNaOtoko extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      newPosts:[],
      searchPosts:[], searchKeyword:'', searchStartDate:'', searchEndDate:'', searching:false,
      post:[], showPost:false,
    };
  }

  componentDidMount = () => {
    this.scrapingNewPosts();
  }

  scrapingNewPosts = () => {
    GetNewPostsPareoNaOtoko().then((posts) => {
      this.setState({ newPosts:posts, });
    });

/*
    axios.get('https://lab.takuman.me/api/scraping/pareo_na_otoko/new_posts')
    .then(res => {
      let posts = res.data.posts;
      this.setState({ newPosts:posts, });
    })
    .catch(err => {});
*/
  }


  handleDateRangeValueChange = (event, stateName) => {
    let dateRange = String(event.target.value.replace(/[^0-9]/g, ''));
    if (dateRange.length < 7) {
      if (dateRange == '0') {
        dateRange = '';
      }
      if (4 <= dateRange.length) {
        dateRange = dateRange.split('');
        dateRange.splice(4,0,'-');
        dateRange = dateRange.join('');
      }
      this.setState({ [stateName]:dateRange, });
    }
  }

  handleSearchKeywordChange = (event) => {
    let searchKeywords = event.target.value;
    // 全角スペースを半角スペースに置換
    if (searchKeywords.includes('　')) {
      searchKeywords = searchKeywords.replace(/　/g," ");
    }
    this.setState({ searchKeyword:searchKeywords, });
  }

  scrapingSearchPosts = (event) => {
    event.preventDefault();
    this.setState({ searchPosts:[], searching:true, });

    let searchStartYear = '';
    let searchStartMonth = '';
    if (this.state.searchStartDate != '') {
      searchStartYear = this.state.searchStartDate.split('-')[0]
      searchStartMonth = this.state.searchStartDate.split('-')[1]
    }

    let searchEndYear = '';
    let searchEndMonth = '';
    if (this.state.searchEndDate != '') {
      searchEndYear = this.state.searchEndDate.split('-')[0]
      searchEndMonth = this.state.searchEndDate.split('-')[1]
    }

    let params = { search_type:'and', keyword:this.state.searchKeyword, start_year:searchStartYear, start_month:searchStartMonth, end_year:searchEndYear, end_month:searchEndMonth, };
    SearchPostsPareoNaOtoko(params).then((posts) => {
      this.setState({ searchPosts:posts, searching:false, });
    });

/*
    let params = { search_type:'and', keyword:this.state.searchKeyword, start_year:searchStartYear, start_month:searchStartMonth, end_year:searchEndYear, end_month:searchEndMonth, };
    axios.post('https://lab.takuman.me/api/scraping/pareo_na_otoko/search_posts', params)
    .then(res => {
      let posts = res.data.posts;
      this.setState({ searchPosts:posts, searching:false, });
    })
    .catch(err => {});
*/
  }

  scrapingPost = (url) => {
    GetPostPareoNaOtoko(url).then((post) => {
      this.setState({ post:post, showPost:true, });
    });

/*
    let params = { url:url, };
    axios.post('https://lab.takuman.me/api/scraping/pareo_na_otoko/post', params)
    .then(res => {
      let post = res.data.post;
      this.setState({ post:post, showPost:true, });
    })
    .catch(err => {});
*/
  }

  hidePost = () => {
    this.setState({ showPost:false, });
  }

  render() {

    let searchPostsBlock;
    let newPostsBlock;
    let post;
    if (this.state.showPost) {
      // 記事閲覧の場合
      searchPostsBlock = ( <span></span> );
      newPostsBlock = ( <span></span> );

      post = (
        <div dangerouslySetInnerHTML={{__html:this.state.post}} />
      );

    } else {
      // 記事一覧

      let searchPosts;
      if (0 < this.state.searchPosts.length) {
        searchPosts = this.state.searchPosts.map((post,index) => {
          return ( <p>・<span onClick={ (event) => {this.scrapingPost(post.url)} }>{post.title}</span> <a href={post.url}><i className='fas fa-link'></i></a></p> );
        });
      } else {
        if (this.state.searching) {
          searchPosts = ( <p>searching...</p> );
        } else {
          searchPosts = ( <span></span> );
        }
      }

      searchPostsBlock = (
        <SearchBlock>

          <form>
            <RangeField>
              <form style={{'display':'inline'}}>
                <input type='text' id='userCreatedRangeStart' className='range-input' value={this.state.searchStartDate} onChange={ (event) => {this.handleDateRangeValueChange(event, 'searchStartDate')} } placeholder='2016-02' />
              </form>
              <div className='range-space-icon'>〜</div>
              <form style={{'display':'inline'}}>
                <input type='text' id='userCreatedRangeEnd' className='range-input' value={this.state.searchEndDate} onChange={ (event) => {this.handleDateRangeValueChange(event, 'searchEndDate')} } placeholder='2020-11' />
              </form>
            </RangeField>
          </form>

          <SearchField>
            <form className={'searchbox'} onSubmit={ (event) => {this.scrapingSearchPosts(event)} }>
              <input type='text' name='search' value={this.state.searchKeyword} onChange={ (event) => {this.handleSearchKeywordChange(event)} } />
              <input type='submit' value='検索' className='search-btn' onClick={ (event) => {this.scrapingSearchPosts(event)} } />
            </form>
          </SearchField>

          {searchPosts}
        </SearchBlock>
      );


      let newPosts;
      if (0 < this.state.newPosts.length) {
        newPosts = this.state.newPosts.map((post,index) => {
          return ( <p>・<Link onClick={ (event) => {this.scrapingPost(post.url)} }>{post.title}</Link> <a href={post.url}><i className='fas fa-link'></i></a></p> );
        });
      } else {
        newPosts = ( <span></span> );
      }

      newPostsBlock = (
        <PostsBlock>
          {newPosts}
        </PostsBlock>
      );

      post = ( <span></span> );

    }

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <Helmet title={'PaleoMan | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {searchPostsBlock}
        {newPostsBlock}
        {post}
      </div>
    );

  }

}
export default PareoNaOtoko;
