import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const GlobalStyle = createGlobalStyle`
  body { margin:0; padding:0; box-sizing:border-box; background:${subColor}; }
  div, h1, h2, h3, h4, p, a, span, input, textarea, ul, li, header, footer { margin:0; padding:0; box-sizing:border-box; -webkit-tap-highlight-color:none; }
  a, a:hover, a:active { text-decoration:none; background:none; color:${linkColor}; }
  p { padding:10px; }

  // スクロールバー全体 
  div::-webkit-scrollbar { width:1px; height:1px; }
  // スクロールバーの軌道
  div::-webkit-scrollbar-track { border-radius:1000px; box-shadow:inset 0 0 6px ${rgba(mainColor,0)}; }
  // スクロールバーの動く部分
  div::-webkit-scrollbar-thumb { background:${rgba(mainColor,0.5)}; border-radius:1000px; box-shadow:0 0 0 1px ${rgba(mainColor,0)}; }

  // テキスト選択時の背景色と文字色 
  p::selection, h1::selection, h2::selection, h3::selection, h4::selection, div::selection, span::selection, label::selection, input::selection, textarea::selection {
    background:${edgeColor}; color:${subColor};
  }
  p::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, div::-moz-selection, span::-moz-selection, label::-moz-selection, input::-moz-selection, textarea::-moz-selection { 
    background:${edgeColor}; color:${subColor};
  }

  // font-famiry
  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400&text=0123456789&display=swap'); // 数字用フォント Rubik
  @import url('https://fonts.googleapis.com/css?family=Cairo:400,900|Titillium+Web&display=swap'); // 英語用フォント Cairo
  @import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap'); // タイマーの数字用フォント Arimo
  @import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap'); // 日本語 Kosugi Maru
  body, h1, h2, h3, h4, p, a { font-family:'Rubik', 'Cairo', 'Arimo', 'Kosugi Maru', sans-serif; }

  // アニメーション
  ${animations}
`

const ComponentField = styled.div`
  &&& {
    width:${props => props.full ? '100' : '50'}%; height:100vh; float:${props => props.full ? 'none' : props.one ? 'left' : 'right'};
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'}; overflow:scroll;
  }
`

const ConsoleField = styled.div`
  &&& {
    ${fadeIn(2,0,0)}
  }
`

const BackBtnField = styled.div`
  &&& {
    width:${props => props.full ? '100%' : '50%'}; height:50px; margin:0; padding:0 0 0 55px;
    position:fixed; left:${props => props.full ? '0' : props.one ? '0' : '50%'}; right:${ (props) => props.full ? '0' : props.one ? '50%' : '0'};
    z-index:${props => props.zIndex ? props.zIndex : '400'};

    .back-btn { width:25px; height:40px; margin:5px 0; padding:0; display:inline-block; position:relative; cursor:pointer;
      div { position:absolute; top:0; bottom:0; left:35%; right:0; width:4px; height:60%; background:${edgeColor}; border-radius:1000px; }
      .line-one { bottom:40%; transform:rotate(45deg); }
      .line-two { top:40%; transform:rotate(-45deg); }
    }
    .back-btn:hover { ${borderFlash(2)} }

    .btn { width:50px; height:50px; margin:0; padding:0; display:inline-block;
      a { width:50px; height:50px; margin:0; padding:0; color:${linkColor}; font-size:35px; text-align:center; display:table;
        i { display:table-cell; vertical-align:middle; }
      }
    }
    .cancel-btn { background:${mainColor};
      a { color:${subColor}; }
    }
    .delete-btn { ${fadeIn(0,2,0)} background:${attentionColor};
      a { color:${mainColor}; }
    }
    .reset-btn { ${fadeIn(0,2,0)} background:${mainColor};
      a { color:${attentionColor}; }
    }
    .cancel-btn:hover, .delete-btn:hover, .reset-btn:hover { opacity:0.95; }
    .left { float:left; }
    .right { float:right; }
    a:hover { ${borderFlash(2)} }

    .back-btn, .btn a { opacity:${ (props) => props.scrollTop ? props.scrollTop < 100 ? 1 - (props.scrollTop / 100) : '0.05' : '1'}; }
    .cancel-btn, .delete-btn, .reset-btn { opacity:${ (props) => props.scrollTop ? props.scrollTop < 100 ? 1 - (props.scrollTop / 100) : '0.5' : '1'};
      a { opacity:0.9; }
    }
    .back-btn:hover, .btn a:hover { opacity:1; }
  }
`


const MovieField = styled.div`
  &&& { position:relative; width:100%; height:auto; margin:20px auto; cursor:pointer;
    .thumb { position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:auto; margin:auto; padding:0; }
    .play-btn { position:absolute; top:0; bottom:0; left:0; right:0; width:70px; height:70px; margin:auto; padding:0;
                border:2px solid ${rgba(subColor,0.6)}; border-radius:100000px; background:${rgba(edgeColor,0.8)}; cursor:pointer; }
    .play-btn:hover { background:${rgba(edgeColor,1)}; }
    .play-btn-triangle { position:absolute; top:0; bottom:0; left:0; right:0; width:0; height:0; margin:auto; padding:0; cursor:pointer;
                         border-style:solid; border-width:20px 0 20px 40px; border-color:transparent transparent transparent ${rgba(mainColor,1)};
                         transition:.2s; transform:rotateZ(0deg); }
    .play-btn-triangle1 { left:10px; border-width:20px 0 20px 40px; border-color:transparent transparent transparent ${rgba(subColor,1)}; }
    .play-btn-triangle2 { left:5px; border-width:15px 0 15px 30px; border-color:transparent transparent transparent ${rgba(edgeColor,1)}; }
    .play-btn-triangle3 { left:0; border-width:10px 0 10px 20px; border-color:transparent transparent transparent ${rgba(subColor,1)}; }

    &:hover {
      .play-btn { background:${rgba(edgeColor,1)}; }
      .play-btn-triangle { transform:rotateZ(3600deg); }
    }
  }
`

const ClearBoth = styled.div`
  &&& {
    clear:both;
  }
`

const Menu = styled.div`
  &&& {
    width:${(props) => props.full ? '250px' : '100%'}; height:${(props) => props.full ? '100%' : 'auto'}; border-right:1px solid ${mainColor}; float:left; overflow:hidden;

    .item { width:${(props) => props.full ? '100%' : 'auto'}; height:30px; margin:${(props) => props.full ? 'auto' : '0 5px 0 0'}; padding:${(props) => props.full ? '0 20px' : '0 10px'}; overflow:scroll;
            border:${(props) => props.full ? 'none' : '0.5px solid ' + mainColor}; border-radius:${(props) => props.full ? '0 1000px 1000px 0' : '1000px'};
            display:inline-block; cursor:pointer;
      .item-name-wrapper { width:100%; height:100%; margin:0; padding:0; display:table;
        .item-name { display:table-cell; vertical-align:middle; color:${mainColor}; font-size:${(props) => props.full ? '18px' : '14px'}; text-align:left; white-space:nowrap; }
      }
    }
    .item:hover { background:${rgba(mainColor,0.1)}; }
    .select-item { background:${(props) => props.full ? rgba(mainColor,0.2) : 'none'}; border:${(props) => props.full ? 'none' : '0.5px solid ' + edgeColor};
      .item-name-wrapper {
        .item-name { color:${edgeColor}; }
      }
    }
    .select-item:hover { background:${rgba(mainColor,0.3)}; }

  }
`

export { GlobalStyle, ComponentField, ConsoleField, BackBtnField, MovieField, ClearBoth, Menu };
