import React, { PureComponent } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Text, Tooltip, Legend } from 'recharts';
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, yellow } from 'component/common/style/Colors';

// style
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { AssetManagementStyle, Card } from 'component/common/style/AssetManagement';


class AssetManagement extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      loading:true,
      nowAssets:[], calAssets:[],
      forecastAsset:'all', forecastAssetChoice:false,
      forecastInterestType:'half-compound', // 単利(simple)か半複利(half-compound)か複利(compound)か
      cryptoCurrenciesRate:[],
    };
  }

  componentDidMount = () => { 
    this.props.logoAnimation('kodo');
    this.getCryptoCurrenciesRate();
  }


  componentWillUpdate = () => {
    document.body.addEventListener('click', (event) => {
      if (this.state.forecastAssetChoice) {
        if (event.target.className != 'data select-btn' && event.target.className != 'value select-btn' && event.target.className != 'select-popup' && event.target.className != 'select-item') {
          this.setState({ forecastAssetChoice:false, });
        }
      }
    });
  }


  getCryptoCurrenciesRate = () => {
    let params = { }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/get_asset_rate/', params, headers)
    .then(res => {
      let cryptoCurrenciesRate = res.data.crypto_currencies;
      this.setState({ cryptoCurrenciesRate:cryptoCurrenciesRate, }, () => {
        let assets = [];
        let ethereum = this.state.cryptoCurrenciesRate.filter((cc,i) => cc.name == 'Ethereum')[0];
        let ethPrice = ethereum['ja-price'].substring(1,ethereum['ja-price'].length).split('.')[0].replace(/[^0-9]/g, '');

        // My Ether Wallet Staking
        assets.push({'genre':'crypt', 'type':'investment', 'name':'MEW Stake ETH', 'amount':Number(ethPrice) * 34.33, 'rAmount':this.roundNum(Number(ethPrice) * 34.33), 'yield':Number(Math.round(5.28 * 100) / 100), 'eth':34.33, 'taxType':'comprehensive', 'color':'#3FCCFB', });

        // Binance Staking
        assets.push({'genre':'crypt', 'type':'investment', 'name':'Binance Stake ETH', 'amount':Number(ethPrice) * 6.0, 'rAmount':this.roundNum(Number(ethPrice) * 6.0), 'yield':Number(Math.round(4.7 * 100) / 100), 'eth':6.0, 'taxType':'comprehensive', 'color':'#FFC300', });

        // ETH Pooling
        assets.push({'genre':'crypt', 'type':'pool', 'name':'Pool ETH', 'amount':Number(ethPrice) * 3.0, 'rAmount':this.roundNum(Number(ethPrice) * 3.0), 'yield':Number(Math.round(0 * 100) / 100), 'eth':3.0, 'taxType':'comprehensive', 'color':'#29F680', });
        //assets.push({'genre':'etf', 'type':'investment', 'name':'SPYD', 'amount':1211509, 'rAmount':this.roundNum(1211509), 'yield':Number(Math.round(3.14 * 100) / 100), 'taxType':'separate', 'color':'#F3644E', });
        //assets.push({'genre':'etf', 'type':'investment', 'name':'HDV', 'amount':2673422, 'rAmount':this.roundNum(2673422), 'yield':Number(Math.round(2.88 * 100) / 100), 'taxType':'separate', 'color':'#C74EF3', });
        assets.push({'genre':'pool', 'type':'pool', 'name':'プール資金', 'amount':0, 'rAmount':this.roundNum(0), 'yield':Number(Math.round(0 * 100) / 100), 'taxType':'', 'color':'#F93FFB', });
        let calAssets = this.calAssets(assets, 10);
        let nowAssets = this.calAssets(assets, 0)[0];
        this.setState({ loading:false, nowAssets:nowAssets, calAssets:calAssets, });
      });
    })
    .catch(err => { });
  }


  calAssets = (assets, years) => {
    console.log('calAssets');
    assets = JSON.parse(JSON.stringify(assets));
    console.log(assets);

    let calAssets = [];

    //let totalAssetAmount = Number(JSON.parse(JSON.stringify(assets)).reduce((sum, i) => sum + i.amount, 0));
    //assets = JSON.parse(JSON.stringify(assets));
    let totalAssetAmount = Number(assets.reduce((sum, i) => sum + i.amount, 0));


    let investmentAssets = JSON.parse(JSON.stringify(assets)).filter((asset, i) => asset.type == 'investment');

    // トータル運用額(実際に投資している金額)
    //let incomeAssets = JSON.parse(JSON.stringify(assets)).filter((asset, i) => 0 < asset.yield);
    //let poolAssets = JSON.parse(JSON.stringify(assets)).filter((asset, i) => asset.yield == 0);
    //let incomeAssets = JSON.parse(JSON.stringify(assets)).filter((asset, i) => asset.type == 'investment');
    //let poolAssets = JSON.parse(JSON.stringify(assets)).filter((asset, i) => asset.type == 'pool');
    //let totalInvestmentAmount;
    //if (0 < incomeAssets.length) {
      //let poolAssetAmount = Number(JSON.parse(JSON.stringify(poolAssets)).reduce((sum, i) => sum + i.amount, 0));
      //totalInvestmentAmount = totalAssetAmount - poolAssetAmount;
    //} else {
      //totalInvestmentAmount = this.state.calAssets[0].totalInvestmentAmount;
    //}
    let totalInvestmentAmount = Number(investmentAssets.reduce((sum, i) => sum + i.amount, 0));

    // トータル見回り
    //let investmentAssets = JSON.parse(JSON.stringify(assets)).filter((asset,i) => asset.type == 'investment');
    //let totalYield;
    //if (0 < investmentAssets.length) {
      //let totalReward = Number(JSON.parse(JSON.stringify(investmentAssets)).reduce((sum, i) => sum + i.yield, 0));
      //totalYield = Number(totalReward / investmentAssets.length);
    //} else {
      //totalYield = 0;
    //}
    let totalReward = Number(investmentAssets.reduce((sum, i) => sum + i.yield, 0));
    let totalYield = 0;
    if (0 < totalReward) {
      totalYield = Number(totalReward / investmentAssets.length);
    }

    let comprehensiveAssets = assets.filter((asset,i) => asset.taxType == 'comprehensive'); // 総合課税
    let separateAssets = assets.filter((asset,i) => asset.taxType == 'separate'); // 分離課税

    for (var y=0; y<=years; y++) {
      console.log('loop 1');

      // 年間推定収益額
      let estimatedAnnualRevenue = 0;
      for (var i=0; i<assets.length; i++) {
        console.log('loop 2');
        estimatedAnnualRevenue += assets[i].amount * (assets[i].yield/100);
      }

      // 年間推定収益額からtaxType別に課税後の金額を算出
      //let comprehensiveAssets = JSON.parse(JSON.stringify(assets)).filter((asset,i) => asset.taxType == 'comprehensive'); // 総合課税
      let comprehensiveEstimatedAnnualRevenue = 0;
      for (var i=0; i<comprehensiveAssets.length; i++) {
        console.log('loop 3');
        comprehensiveEstimatedAnnualRevenue += comprehensiveAssets[i].amount * (comprehensiveAssets[i].yield/100);
      }

      let taxRate = 0; // 税率
      let taxDeduction = 0; // 控除額
      if (40000000 <= comprehensiveEstimatedAnnualRevenue) {
        taxRate = 0.45;
        taxDeduction = 4796000;
      } else if (18000000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 39999000) {
        taxRate = 0.4;
        taxDeduction = 2796000;
      } else if (9000000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 17999000) {
        taxRate = 0.33;
        taxDeduction = 1536000;
      } else if (6950000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 8999000) {
        taxRate = 0.23;
        taxDeduction = 636000;
      } else if (3300000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 6949000) {
        taxRate = 0.2;
        taxDeduction = 427500;
      } else if (1950000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 3299000) {
        taxRate = 0.1;
        taxDeduction = 97500;
      } else if (1000 <= comprehensiveEstimatedAnnualRevenue && comprehensiveEstimatedAnnualRevenue <= 1949000) {
        taxRate = 0.05;
        taxDeduction = 0;
      }

      let basicDeduction = 480000; // 基礎控除
      let blueDeduction = 650000; // 青色申告特別控除(本来であれば改正により55万円だが、e-taxで申告すれば65万円が控除される)
      let businessCost = 500000; // 個人事業の経費
      let comprehensiveTotalDeduction = basicDeduction + blueDeduction + taxDeduction + businessCost; // トータルの控除額

      let comprehensiveTaxAmount = 0; // 総合課税(累進課税)による税額
      //comprehensiveTaxAmount = comprehensiveEstimatedAnnualRevenue * taxRate;
      comprehensiveTaxAmount = (comprehensiveEstimatedAnnualRevenue - comprehensiveTotalDeduction) * taxRate;
      //comprehensiveTaxAmount -= comprehensiveTotalDeduction;
      if (comprehensiveTaxAmount < 0) {
        comprehensiveTaxAmount = 0;
      }


      //let separateAssets = JSON.parse(JSON.stringify(assets)).filter((asset,i) => asset.taxType == 'separate'); // 分離課税
      let separateEstimatedAnnualRevenue = 0;
      for (var i=0; i<separateAssets.length; i++) {
        console.log('loop 4');
        separateEstimatedAnnualRevenue += separateAssets[i].amount * (separateAssets[i].yield/100);
      }
      // -----------------   ここでNISAの計算 --------------
      let separateTaxAmount = separateEstimatedAnnualRevenue * 0.2; // 分離課税による税額
      if (separateTaxAmount < 0) {
        separateTaxAmount = 0;
      }

      // 年間推定収益額から総合課税と分離課税の推定課税額を引く
      //estimatedAnnualRevenue -= (comprehensiveTaxAmount + separateTaxAmount);
      let totalTaxAmount = comprehensiveTaxAmount + separateTaxAmount;
      estimatedAnnualRevenue -= totalTaxAmount;


      // 月間推定収益額
      let estimatedMonthlyRevenue = estimatedAnnualRevenue / 12;

      // ランク
      let assetRank = '圏外';
      if (10000000 <= estimatedAnnualRevenue) {
        assetRank = 'X';
      } else if (4800000 <= estimatedAnnualRevenue) {
        assetRank = 'S';
      } else if (2400000 <= estimatedAnnualRevenue) {
        assetRank = 'A';
      } else if (1800000 <= estimatedAnnualRevenue) {
        assetRank = 'B';
      } else if (1200000 <= estimatedAnnualRevenue) {
        assetRank = 'C';
      }



      let reinvestmentAmount = 0; // 再投資額
      let usageLimitAmount = 0; // 使用上限額
      if (this.state.forecastInterestType == 'simple') {
        // 単利の場合
        reinvestmentAmount = 0;
        usageLimitAmount = 0;
      } else if (this.state.forecastInterestType == 'half-compound') {
        // 半複利の場合
        for (var i=0; i<assets.length; i++) {
          console.log('loop 5');
          if (0 < y) {
            assets[i].amount += assets[i].amount * (assets[i].yield/100) * 0.5;
            if ('eth' in assets[i]) {
              assets[i].eth += assets[i].eth * (assets[i].yield/100) * 0.5;
            }
          }
          if (assets[i].amount == null) {
            assets[i].amount = 0;
          }
          if ('eth' in assets[i]) {
            if (assets[i].eth == null) {
              assets[i].eth = 0;
            }
          }
        }
        if (0 < y) {
          totalAssetAmount += estimatedAnnualRevenue * 0.5;
          totalInvestmentAmount += estimatedAnnualRevenue * 0.5;
        }
        reinvestmentAmount = estimatedAnnualRevenue * 0.5;
        usageLimitAmount = estimatedAnnualRevenue * 0.5;
      } else {
        // 複利の場合
        for (var i=0; i<assets.length; i++) {
          console.log('loop 6');
          if (0 < y) {
            assets[i].amount += assets[i].amount * (assets[i].yield/100);
            if ('eth' in assets[i]) {
              assets[i].eth += assets[i].eth * (assets[i].yield/100);
            }
          }
          if (assets[i].amount == null) {
            assets[i].amount = 0;
          }
          if ('eth' in assets[i]) {
            if (assets[i].eth == null) {
              assets[i].eth = 0;
            }
          }
        }
        if (0 < y) {
          totalAssetAmount += estimatedAnnualRevenue;
          totalInvestmentAmount += estimatedAnnualRevenue;
        }
        reinvestmentAmount = estimatedAnnualRevenue;
        usageLimitAmount = 0;
      }



      // 1万の位で四捨五入し、円単位から万円単位の値に変換(10万円以下の場合は千の位で四捨五入)
      let rTotalAssetAmount = this.roundNum(totalAssetAmount);
      let rTotalInvestmentAmount = this.roundNum(totalInvestmentAmount);
      let rEstimatedAnnualRevenue = this.roundNum(estimatedAnnualRevenue);
      let rEstimatedMonthlyRevenue = this.roundNum(estimatedMonthlyRevenue);
      let rComprehensiveEstimatedAnnualRevenue = this.roundNum(comprehensiveEstimatedAnnualRevenue);
      let rComprehensiveTaxAmount = this.roundNum(comprehensiveTaxAmount)
      let rComprehensiveTotalDeduction = this.roundNum(comprehensiveTotalDeduction);
      let rBasicDeduction = this.roundNum(basicDeduction);
      let rBlueDeduction = this.roundNum(blueDeduction);
      let rTaxDeduction = this.roundNum(taxDeduction);
      let rBusinessCost = this.roundNum(businessCost);
      let rSeparateEstimatedAnnualRevenue = this.roundNum(separateEstimatedAnnualRevenue);
      let rSeparateTaxAmount = this.roundNum(separateTaxAmount);
      let rTotalTaxAmount = this.roundNum(totalTaxAmount);
      let rReinvestmentAmount = this.roundNum(reinvestmentAmount);
      let rUsageLimitAmount = this.roundNum(usageLimitAmount);

      for (var i=0; i<assets.length; i++) {
        console.log('loop 7');
        assets[i].rAmount = this.roundNum(assets[i].amount);
      }


      // 小数第二位で四捨五入
      let rTotalYield;
      if (0 < totalYield) {
        rTotalYield = Number(Math.round(totalYield * 100) / 100);
        if (Number.isInteger(rTotalYield)) {
          rTotalYield += '.0';
        }
      } else {
        rTotalYield = 0;
      }


      calAssets.push({
        assets:JSON.parse(JSON.stringify(assets)),
        totalAssetAmount:totalAssetAmount, rTotalAssetAmount:rTotalAssetAmount,
        totalYield:totalYield, rTotalYield:rTotalYield,
        totalInvestmentAmount:totalInvestmentAmount, rTotalInvestmentAmount:rTotalInvestmentAmount,
        estimatedAnnualRevenue:estimatedAnnualRevenue, estimatedMonthlyRevenue:estimatedMonthlyRevenue, rEstimatedAnnualRevenue:rEstimatedAnnualRevenue, rEstimatedMonthlyRevenue:rEstimatedMonthlyRevenue,
        assetRank:assetRank,

        comprehensiveEstimatedAnnualRevenue:comprehensiveEstimatedAnnualRevenue, comprehensiveTaxAmount:comprehensiveTaxAmount, comprehensiveTotalDeduction:comprehensiveTotalDeduction, basicDeduction:basicDeduction, blueDeduction:blueDeduction, taxDeduction:taxDeduction, businessCost:businessCost, rComprehensiveEstimatedAnnualRevenue:rComprehensiveEstimatedAnnualRevenue, rComprehensiveTaxAmount:rComprehensiveTaxAmount, rComprehensiveTotalDeduction:rComprehensiveTotalDeduction, rBasicDeduction:rBasicDeduction, rBlueDeduction:rBlueDeduction, rTaxDeduction:rTaxDeduction, rBusinessCost:rBusinessCost,
        separateEstimatedAnnualRevenue:separateEstimatedAnnualRevenue, separateTaxAmount:separateTaxAmount, rSeparateEstimatedAnnualRevenue:rSeparateEstimatedAnnualRevenue, rSeparateTaxAmount:rSeparateTaxAmount,
        totalTaxAmount:totalTaxAmount, rTotalTaxAmount:rTotalTaxAmount,
        reinvestmentAmount:reinvestmentAmount, usageLimitAmount:usageLimitAmount, rReinvestmentAmount:rReinvestmentAmount, rUsageLimitAmount:rUsageLimitAmount,
      });

    }

    this.props.logoAnimation('');

    console.log('calAssetsメソッドのreturn直前');
    console.log(calAssets);
    //return JSON.parse(JSON.stringify(calAssets));
    return calAssets;
  }



  forecastAssetChoice = () => {
    if (this.state.forecastAssetChoice) {
      this.setState({ forecastAssetChoice:false, });
    } else {
      this.setState({ forecastAssetChoice:true, });
    }
  }

  changeForecastAsset = (i) => {
    console.log('changeForecastAsset');
    let nowAssets = JSON.parse(JSON.stringify(this.state.nowAssets));

    let assets = [];
    if (i != undefined) {
      // 指定したアセット
      assets.push(nowAssets.assets[i]);
    } else {
      assets = nowAssets.assets;
    }
    console.log('assets');
    console.log(assets);
    let calAssets = this.calAssets(assets, 10);
    this.setState({ calAssets:calAssets, forecastAssetChoice:false, });
  }

  changeForecastInterestType = (type) => {
    this.setState({ forecastInterestType:type, }, () => {
      let assets = JSON.parse(JSON.stringify(this.state.calAssets))[0].assets;
      let calAssets = this.calAssets(assets, 10);
      this.setState({ calAssets:calAssets, });
    });
  }

  changeForecastInvestmentAmount = (event) => {
    console.log('----------------------------');
    console.log('changeForecastInvestmentAmount');

    let totalInvestmentAmount = Number(event.target.value.replace(/[^0-9]/g, ''));
    if (totalInvestmentAmount < 0) {
      totalInvestmentAmount = 0;
    }
    console.log('totalInvestmentAmount');
    console.log(totalInvestmentAmount);

    let nowAssets = JSON.parse(JSON.stringify(this.state.nowAssets));
    let calAssets = JSON.parse(JSON.stringify(this.state.calAssets));
    let investmentAssets = JSON.parse(JSON.stringify(this.state.calAssets))[0].assets.filter((asset,i) => asset.type == 'investment');

    // 運用額のアセットのみを計算に含めたいのでインカムアセットのみに絞り込み。
    //calAssets[0].assets = calAssets[0].assets.filter((asset,i) => 0 < asset.yield);

    //if (calAssets[0].assets.length == nowAssets.assets.length) {
    //if (2 <= calAssets[0].assets.length) {
    if (2 <= investmentAssets.length) {
      // すべてのインカムアセット
      console.log('すべてのインカムアセット');
      //let incomeAssets = JSON.parse(JSON.stringify(this.state.calAssets))[0].assets.filter((asset, i) => asset.type == 'investment');
      //let incomeAssetAmount = Number(incomeAssets.reduce((sum, i) => sum + i.amount, 0));
      let investmentAssetAmount = Number(investmentAssets.reduce((sum, i) => sum + i.amount, 0));
      //console.log('incomeAssets');
      //console.log(incomeAssets);
      //console.log('incomeAssetAmount: ' + incomeAssetAmount);
      console.log('investmentAssets');
      console.log(investmentAssets);
      console.log('investmentAssetAmount: ' + investmentAssetAmount);

      for (var i=0; i<calAssets[0].assets.length; i++) {
        console.log('loop 8');
        if (calAssets[0].assets[i].type == 'investment') {
          console.log(calAssets[0].assets[i].amount);
          let incomePercentage;
          //if (0 < incomeAssetAmount) {
          if (0 < investmentAssetAmount) {
            //incomePercentage = calAssets[0].assets[i].amount / incomeAssetAmount;
            incomePercentage = calAssets[0].assets[i].amount / investmentAssetAmount;
            calAssets[0].assets[i].amount = Number(totalInvestmentAmount * 10000) * incomePercentage;
          } else {
            incomePercentage = 1;
            calAssets[0].assets[i].amount = Number(totalInvestmentAmount * 10000) / investmentAssets.length;
          }
          console.log('incomePercentage: ' + incomePercentage);
          console.log(calAssets[0].assets[i].amount);
        }
      }
    } else {
      // 選択した１つのアセット
      console.log('選択した１つのインカムアセット');
      console.log(calAssets[0].assets[0].amount);
      calAssets[0].assets[0].amount = totalInvestmentAmount * 10000;
      calAssets[0].assets[0].rAmount = totalInvestmentAmount;
      console.log(calAssets[0].assets[0].amount);
    }

    calAssets = this.calAssets(calAssets[0].assets, 10);
    this.setState({ calAssets:calAssets, });


    console.log('なってほしい値');
    console.log(totalInvestmentAmount);
    console.log('最終的なやつ');
    console.log(calAssets[0].rTotalInvestmentAmount);

  }

  changeForecastYield = (event) => {
    console.log('----------------------------');
    console.log('changeForecastYield');

    let forecastYield = Number(event.target.value.replace(/[^0-9.]/g, ''));

    if (100 < forecastYield) {
      forecastYield = 100;
    } else if (forecastYield < 0) {
      forecastYield = 0;
    }
    console.log('forecastYield');
    console.log(forecastYield);

    let nowAssets = JSON.parse(JSON.stringify(this.state.nowAssets));
    let calAssets = JSON.parse(JSON.stringify(this.state.calAssets));
    let investmentAssets = JSON.parse(JSON.stringify(this.state.calAssets))[0].assets.filter((asset,i) => asset.type == 'investment');

    //if (calAssets[0].assets.length == nowAssets.assets.length) {
    //if (2 <= calAssets[0].assets.length) {
    if (2 <= investmentAssets.length) {
      // すべてのインカムアセット
      console.log('すべてのインカムアセット');

      let n;
      if (0 < forecastYield) {
        if (0 < calAssets[0].totalYield) {
          n = forecastYield / calAssets[0].totalYield;
        } else {
          n = forecastYield;
        }
      } else {
        n = 1;
      }

      for (var i=0; i<calAssets[0].assets.length; i++) {
        console.log('loop 9');

        if (calAssets[0].assets[i].type == 'investment') {
          console.log(calAssets[0].assets[i].yield);

          if (0 < forecastYield) {
            if (0 < calAssets[0].assets[i].yield) {
              calAssets[0].assets[i].yield = Number(Math.round(calAssets[0].assets[i].yield * n * 100) / 100);
            } else {
              calAssets[0].assets[i].yield = forecastYield;
            }
          } else {
            calAssets[0].assets[i].yield = 0;
          }

          console.log(calAssets[0].assets[i].yield);
        }

      }

    } else {
      // 選択した１つのアセット
      console.log('選択した１つのインカムアセット');
      console.log(calAssets[0].assets[0].yield);
      calAssets[0].assets[0].yield = Number(Math.round(forecastYield * 100) / 100);
      console.log(calAssets[0].assets[0].yield);
    }

    calAssets = this.calAssets(calAssets[0].assets, 10);
    this.setState({ calAssets:calAssets, });

    console.log('なってほしい値');
    console.log(forecastYield);
    console.log('最終的なやつ');
    console.log(calAssets[0].rTotalYield);
  }

  customTooltipOnYourLine(e) {
    if (e.active && e.payload!=null && e.payload[0]!=null) {

      if ('eth' in e.payload[0].payload) {
        return (
          <div className='custom-tooltip' style={{'color':e.payload[0].payload['color']}}>
            <p>{e.payload[0].payload['name']}<br/>
               運用額: {e.payload[0].payload['rAmount']}万円<br/>
               利回り: {e.payload[0].payload['yield']}%<br/>
               ETH: {e.payload[0].payload['eth']}</p>
          </div>
        );
      } else {
        return (
          <div className='custom-tooltip' style={{'color':e.payload[0].payload['color']}}>
            <p>{e.payload[0].payload['name']}<br/>
               運用額: {e.payload[0].payload['rAmount']}万円<br/>
               利回り: {e.payload[0].payload['yield']}%</p>
          </div>
        );
      }

    } else{
      return '';
    }
  }


  roundNum = (num) => {
    if (100000 <= num) {
      num = Number(Math.round(num / 10000) * 10000 / 10000).toLocaleString();
    } else {
      num = Number(Math.round(num / 1000) * 1000 / 10000).toLocaleString();
    }
    return num;
  }


  render() {


    {/********************
      *     トータル     *
      ********************/}
    let total;
    if (this.state.loading) {
      total = (
        <Card loading main width='75%' height='127px'>
          <h2 className='card-name'>トータル</h2>
        </Card>
      );
    } else {
      total = (
        <Card main width='75%' height='127px'>
          <h2 className='card-name'>トータル</h2>

          <div className='item'>
            <label>総資産額</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rTotalAssetAmount}</span><span className='unit'>万円</span></div>
          </div>

          <div className='item'>
            <label>総運用額</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rTotalInvestmentAmount}</span><span className='unit'>万円</span></div>
          </div>

          <div className='item'>
            <label>総利回り</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rTotalYield}</span><span className='unit'>%</span></div>
          </div>

          <div className='item'>
            <label>推定年収</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rEstimatedAnnualRevenue}</span><span className='unit'>万円</span></div>
          </div>

          <div className='item'>
            <label>推定月収</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rEstimatedMonthlyRevenue}</span><span className='unit'>万円</span></div>
          </div>

          <div className='item'>
            <label>税金</label>
            <div className='data'><span className='value'>{this.state.nowAssets.rTotalTaxAmount}</span><span className='unit'>万円</span></div>
          </div>

        </Card>
      );
    }


    {/********************
      *     ランク     *
      ********************/}
    let rank;
    if (this.state.loading) {
      rank = (
        <Card loading main width='25%' height='127px'>
          <h2 className='card-name'>ランク</h2>
        </Card>
      );
    } else {
      let max = 120 * 10000;
      if (this.state.nowAssets.assetRank == 'X') {
        max = 600 * 10000;
      } else if (this.state.nowAssets.assetRank == 'S') {
        max = 600 * 10000;
      } else if (this.state.nowAssets.assetRank == 'A') {
        max = 400 * 10000;
      } else if (this.state.nowAssets.assetRank == 'B') {
        max = 180 * 10000;
      } else if (this.state.nowAssets.assetRank == 'C') {
        max = 150 * 10000;
      }
      rank = (
        <Card main width='25%' height='127px'>
          <h2 className='card-name'>ランク</h2>
          <div className='asset-rank'>{this.state.nowAssets.assetRank}</div>
          <meter max={max} min='0' value={this.state.nowAssets.estimatedAnnualRevenue}></meter>
        </Card>
      );
    }


    {/************************
      *     資産推移予測     *
      ************************/}

    let forecastAsset, forecastAssetChoice;
    if (this.state.forecastAssetChoice) {
      let forecastAssets = this.state.nowAssets.assets.map((asset,i) => {
        if (asset.yield != 0) {
          return (
            <div className='select-item' onClick={ () => {this.changeForecastAsset(i)} }>{asset.name}</div>
          );
        }
      });
      forecastAssetChoice = (
        <div className='select-popup'>
          <div className='select-item' onClick={ () => {this.changeForecastAsset(undefined)} }>all</div>
          {forecastAssets}
        </div>
      );
    } else {
      forecastAssetChoice = ( <span></span> );
    }

    let name = 'all';
    if (0 < this.state.calAssets.length) {
      if ('assets' in this.state.calAssets[0]) {
        if (this.state.calAssets[0].assets.length == 1) {
          name = this.state.calAssets[0].assets[0].name;
        }
      }
    }

    forecastAsset = (
      <div className='item wide-item'>
        <label>アセット</label>
        <div className='data select-btn' onClick={ () => {this.forecastAssetChoice()} }>
          <span className='value select-btn'>{name}</span>
        </div>
        {forecastAssetChoice}
      </div>
    );

    let forecastInterestType;
    forecastInterestType = (
      <div className='interest-type'>
        <div className={'type ' + (this.state.forecastInterestType == 'simple' ? 'on':'off')} onClick={ () => {this.changeForecastInterestType('simple')} }>単利</div>
        <div className={'type ' + (this.state.forecastInterestType == 'half-compound' ? 'on':'off')} onClick={ () => {this.changeForecastInterestType('half-compound')} }>半複利</div>
        <div className={'type ' + (this.state.forecastInterestType == 'compound' ? 'on':'off')} onClick={ () => {this.changeForecastInterestType('compound')} }>複利</div>
      </div>
    );


    let forecastTable;
    let forecasts = [];
    for (var i=0; i<this.state.calAssets.length; i++) {
      if (0 < i) {
        forecasts.push(
          <tr>
            <td>{i}</td>
            <td>{this.state.calAssets[i].rTotalInvestmentAmount}万円</td>
            <td>{this.state.calAssets[i].rEstimatedAnnualRevenue}万円</td>
            <td>{this.state.calAssets[i].rEstimatedMonthlyRevenue}万円</td>
            <td>{this.state.calAssets[i].rReinvestmentAmount}万円</td>
            <td>{this.state.calAssets[i].rUsageLimitAmount}万円</td>
            <td>{this.state.calAssets[i].rTotalTaxAmount}万円</td>
            <td>{this.state.calAssets[i].assetRank}</td>
          </tr>
        );
      }
    }

    forecastTable = (
      <div className='table-wrapper'>
        <table>
          <tr>
            <th>年数</th><th>アセット</th><th>推定年収</th><th>推定月収</th><th>再投資額</th><th>使用上限</th><th>税金</th><th>ランク</th>
          </tr>
          {forecasts}
        </table>
      </div>
    );

    let forecast;
    if (this.state.loading) {
      forecast = (
        <Card loading width='70%' height='425px'>
          <h2 className='card-name'>資産推移予想</h2>
        </Card>
      );
    } else {
      forecast = (
        <Card width='70%' height='425px'>
          <h2 className='card-name'>資産推移予想</h2>
          {forecastAsset}

          <div className='item wide-item'>
            <label>運用額</label>
            <div className='data'>
              <span className='value'><input type='text' value={this.state.calAssets[0].rTotalInvestmentAmount} onChange={ (event) => {this.changeForecastInvestmentAmount(event)} } /></span>
              <span className='unit'>万円</span>
            </div>
          </div>

          <div className='item wide-item'>
            <label>利回り</label>
            <div className='data'>
              <span className='value'><input className='short' type='text' value={this.state.calAssets[0].rTotalYield} onChange={ (event) => {this.changeForecastYield(event)} } /></span>
              <span className='unit'>%</span>
            </div>
          </div>

          {forecastInterestType}
          {forecastTable}
        </Card>
      );
    }


    {/**************************
      *     ポートフォリオ     *
      **************************/}
    let assets;
    if ('assets' in this.state.nowAssets) {
      if (0 < this.state.nowAssets.assets.length) {
        assets = (
          <ResponsiveContainer width='95%' height={350}>
            <PieChart>
              <Pie data={this.state.nowAssets.assets} dataKey='amount' nameKey='name'>
                {this.state.nowAssets.assets.map((asset, i) => (
                  <Cell key={i} stroke={'red'} strokeWidth={0} fill={asset.color} />
                ))}
              </Pie>
              <Tooltip content={this.customTooltipOnYourLine} cursor={{stroke:'none', fill:rgba(mainColor,0.05)}} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        );
      } else {
        assets = ( <p>アセットがありません</p> );
      }
    } else {
      assets = ( <p>アセットがありません</p> );
    }

    let portfolio;
    if (this.state.loading) {
      portfolio = (
        <Card loading width='30%' height='425px'>
          <h2 className='card-name'>ポートフォリオ</h2>
        </Card>
      );
    } else {
      portfolio = (
        <Card width='30%' height='425px'>
          <h2 className='card-name'>ポートフォリオ</h2>
          {assets}
        </Card>
      );
    }


    {/**************************
      *     資産運用ルール     *
      **************************/}
    let rule = (
      <Card width='50%' height='167px'>
        <h2 className='card-name'>資産運用ルール</h2>
        <p>
          ・収益の半分を再投資、そして残りの半分を生活費に。<br/>
          ・手堅く長期的にインカムが得られるものに投資して、時間とお金を稼ぐ。
        </p>
      </Card>
    );


    {/**********************
      *     ランク設計     *
      **********************/}
    let rankDesign = (
      <Card width='50%' height='167px'>
        <h2 className='card-name'>目標</h2>
        <p>
          ・Xランク(年間600万円) -- 必要運用額2億円<br/>
          ・Sランク(年間400万円) -- 必要運用額1億3,333万円<br/>
          ・Aランク(年間180万円) -- 必要運用額6,000万円<br/>
          ・Bランク(年間150万円) -- 必要運用額5,000万円<br/>
          ・Cランク(年間120万円) -- 必要運用額4,000万円
        </p>
      </Card>
    );


    {/********************************
      *     仮想通貨のレート一覧     *
      ********************************/}
    let cryptoCurrenciesRate;
    let cryptoCurrencies = this.state.cryptoCurrenciesRate.map((cc,i) => {
      return (
        <div className='item'>
          <label>{cc.name}({cc.unit})</label>
          <div className='data'>
            <span className='value'>{cc['ja-price'].substring(1,cc['ja-price'].length).split('.')[0]}</span>
            <span className='unit'>円</span>
          </div>
        </div>
      );
    });
    if (this.state.loading) { 
      cryptoCurrenciesRate = (
        <Card loading width='100%' height='320px'>
          <h2 className='card-name'>仮想通貨のレート一覧</h2>
        </Card>
      );
    } else {
      cryptoCurrenciesRate = (
        <Card width='100%' height='320px'>
          <h2 className='card-name'>仮想通貨のレート一覧</h2>
          {cryptoCurrencies}
        </Card>
      );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'AssetManagement | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>

        <AssetManagementStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {total}
          {rank}
          {forecast}
          {portfolio}
          {rule}
          {rankDesign}
          {cryptoCurrenciesRate}
        </AssetManagementStyle>
      </div>
    );

  }
}
export default AssetManagement;
