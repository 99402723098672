import axios from 'axios';

export default function PostsSearch(searchParams) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    let posts;
    axios
    .post('https://lab.takuman.me/api/post/search/', searchParams, headers)
    .then(res => {
      var postsCount = res.data.posts_count; 
      let posts = res.data.posts;
      resolve({'postsCount':postsCount, 'posts':posts});
    })
    .catch(err => { 
      reject();
    }); // エラーログを出力


  }).then((p) => {
    let postsCount = p.postsCount;
    let posts = p.posts;

    return new Promise( (resolve, reject) => {

      if (0 < posts.length) { 
        posts = posts.map((post) => {
          post.show = false;
          post.file = post.file.replace('http://', 'https://s3-ap-northeast-1.amazonaws.com/');
          axios
          .get('https://lab.takuman.me/api/post_child/index/' + post.hash + '/')
          .then(res => {
            const postChilds = res.data.map((postChild) => {
              postChild.show = false; // 動画プレイヤーを表示するかどうかのフラグをもたせる。
              postChild.file = postChild['file'].replace('http://', 'https://s3-ap-northeast-1.amazonaws.com/');
              return postChild;
            });
            if (0 < postChilds.length) {
              if (post.id == postChilds[0].post) {
                post.postChilds = postChilds;
              }
            }
            resolve({'postsCount':postsCount, 'posts':posts});
          })
          .catch(err => { 
            reject();
          });

          return post;
        });
        resolve({'postsCount':postsCount, 'posts':posts});
      } else {
        reject();
      }

    });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve({'postsCount':0, 'posts':[]});
    });
  });

}
