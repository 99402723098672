import React from 'react';
import axios from 'axios';
import History from 'History';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import ReactPlayer from 'react-player';
import { Block, BlockName, UserName, Media, FormField, LinkBtnField, LinkBtn, Links, LinkBlock, MediaPanels, MediaPanel, MediaWindow } from 'component/common/style/Oauth';

class TweetsMediaPanels extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      tweets:this.props.tweets,
      tweetsCount:this.props.tweetsCount,
      showMediaWindow:false, showMediaType:'', windowMediaUrl:'',
    };
  }

  componentDidMount = () => {
    console.log('Tweets');
    console.log(this.props.tweets);
  }

  showMediaWindow = (event, showMediaType, windowMediaUrl) => {
    if (this.state.showMediaWindow) {
      if (event.target.closest('img') == null && event.target.closest('video') == null) {
        this.setState({ showMediaWindow:false, showMediaType:'', windowMediaUrl:'', });
      }
    } else {
      this.setState({ showMediaWindow:true, showMediaType:showMediaType, windowMediaUrl:windowMediaUrl, });
    }
  }

  render() {

    let mediaPanels;
    if (0 < this.state.tweets.length) {
      let i = 0;
      mediaPanels = this.state.tweets.map((tweet, index) => {


        let medias
        if (tweet.extended_entities) {
          medias = tweet.extended_entities.media.map((media, i) => {

            if (media.type == 'photo') {
              return (
                <MediaPanel>
                  <img src={media.media_url} onClick={ (event) => {this.showMediaWindow(event, 'photo', media.media_url)} } />
                </MediaPanel>
              );
            } else {
                return (
                <MediaPanel>
                  <img src={media.media_url} onClick={ (event) => {this.showMediaWindow(event, 'video', media.video_url)} } />
                  <div className='play-btn' onClick={ (event) => {this.showMediaWindow(event, 'video', media.video_url)} } ><div className='play-btn-triangle'><i class="fas fa-play"></i></div></div>
                </MediaPanel>
              );
            }

          });
        }


        return (
          <div>
            {medias}
          </div>
        );


      });
    } else {
      mediaPanels = (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>MEDIA NOTHING</p>
        </Block>
      );
    }


    let mediaWindowMedia;
    let mediaWindow;
    if (this.state.showMediaWindow) {
      if (this.state.showMediaType == 'photo') {
        mediaWindowMedia = ( <img src={this.state.windowMediaUrl} /> );
      } else {
        mediaWindowMedia = ( <ReactPlayer url={this.state.windowMediaUrl} width='90vw' height='90vh' controls playing /> );
      }

      mediaWindow = (
        <MediaWindow onClick={ (event) => {this.showMediaWindow(event,'','')} }>
          <div className='media-wrapper'>
            {mediaWindowMedia}
          </div>
        </MediaWindow>
      );
    } else {
      mediaWindowMedia = ( <span></span> );
      mediaWindow = ( <span></span> );
    }

    return (
      <MediaPanels>
        {mediaPanels}
        <div style={{'clear':'both'}}></div>
        {mediaWindow}
      </MediaPanels>
    );


  }

}
export default TweetsMediaPanels;
