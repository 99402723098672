import React from 'react';
import axios from 'axios';
import History from 'History';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import ReactPlayer from 'react-player';

// style
import { BackBtnField, ClearBoth, Menu } from 'component/common/style/GlobalStyle';
import { PornDatasField, PornDatasStyle, PornData, PanelPornData, Media, Checked, Loading } from 'component/common/style/PornDatas';

// function
import GetPornDatas from 'component/common/function/GetPornDatas';
import DeletePornData from 'component/common/function/DeletePornData';
import CheckedPornData from 'component/common/function/CheckedPornData';

class PornDatas extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      loading:false,
      genre:'',
      genres:[],
      pornDatas:[],
      showVideoIndex:undefined,
      deletePorndataIndex:undefined,
      displayPattern:'timeline', // timeline, panel
      panelSize:'100',
    };
  }

  componentDidMount = () => {
    this.searchPornDatas('All');
  }

  searchPornDatas = (genre) => {
    console.log('searchPornDatas');
    this.props.logoAnimation('kodo');
    this.setState({ loading:true, genre:genre, genres:[], pornDatas:[], showVideoIndex:undefined, deletePornDataIndex:undefined, });

    GetPornDatas(genre).then((p) => {
      this.setState({ loading:false, genres:p.genres, pornDatas:p.pornDatas, });
      if (document.getElementById('porn-datas-style')) {
        document.getElementById('porn-datas-style').scrollTop = 0;
      }
      this.props.logoAnimation('');
      this.props.helloLab();
    });

/*
    let params = { genre:genre, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/get_porn_datas/', params, headers)
    .then(res => {
      let genres = res.data.genres;
      let pornDatas = res.data.porn_datas;
      console.log('データ取得');
      console.log('pornDatas');
      console.log(pornDatas);
      this.setState({ loading:false, genres:genres, pornDatas:pornDatas, });
      document.getElementById('porn-datas-style').scrollTop = 0;
    })
    .catch(err => { this.setState({ loading:false, }) });
*/
  }

  componentWillUpdate = () => {
    document.body.addEventListener('keydown', this.scroll);
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('keydown', this.scroll);
  }

  scroll = (event) => {
    // エンターキーまたは右矢印キーを押下したときだけスクリプトを実行
    let scrollAmount = 0;
    if (event.code == 'Escape' || event.code == 'ArrowUp') {
      scrollAmount = -30;
    }
    if (event.code == 'Backquote' || event.code == 'ArrowDown') {
      scrollAmount = 30;
    }
    document.getElementById('porn-datas-style').scrollTop += scrollAmount;
  }

  showVideo = (i) => {
    console.log('showVideo: ' + i);
    if (i != undefined && this.state.pornDatas[i].files.indexOf('mp4')) {
      this.setState({ showVideoIndex:i, });
    }
  }

  deleteConfirm = (i) => {
    if (this.state.deletePorndataIndex == i) {
      this.setState({ deletePorndataIndex:undefined, });
    } else {
      this.setState({ deletePorndataIndex:i, });
    }
  }

  deletePornData = (hash) => {
    this.props.logoAnimation('kodo');
    DeletePornData(hash).then((p) => {
      this.props.handleFlashMessage('DELETE');

      // 削除したデータのジャンルを取得し、そのcountを減らす
      let deleteDataIndex = this.state.pornDatas.findIndex((data) => data.hash == hash);
      let deleteDataGenre = this.state.pornDatas[deleteDataIndex].genre;
      let genres = this.state.genres.slice();
      let index = genres.findIndex((genre) => genre.name == deleteDataGenre);
      genres[index].count--;

      // 削除したデータを取り除いた配列を取得
      let pornDatas = this.state.pornDatas.filter((data) => data.hash != hash);

      this.setState({ pornDatas:pornDatas, genres:genres, showVideoIndex:undefined, deletePorndataIndex:undefined, });
      this.props.helloLab();
    });

/*
    let params = { hash:hash, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/porn_data/deleteupdate/', params, headers)
    .then(res => {
      this.props.handleFlashMessage('DELETE');

      // 削除したデータのジャンルを取得し、そのcountを減らす
      let deleteDataIndex = this.state.pornDatas.findIndex((data) => data.hash == hash);
      let deleteDataGenre = this.state.pornDatas[deleteDataIndex].genre;
      let genres = this.state.genres.slice();
      let index = genres.findIndex((genre) => genre.name == deleteDataGenre);
      genres[index].count--;

      // 削除したデータを取り除いた配列を取得
      let pornDatas = this.state.pornDatas.filter((data) => data.hash != hash);

      this.setState({ pornDatas:pornDatas, genres:genres, showVideoIndex:undefined, deletePorndataIndex:undefined, });
    })
    .catch(err => { });
*/
  }

  checkedPornData = (created_at) => {
    if (this.state.genre != 'All') {
      this.props.logoAnimation('kodo');
      CheckedPornData(this.state.genre, created_at).then((checked) => {
        if (checked) {
          this.props.handleFlashMessage('CHECKED');
          this.setState({ showVideoIndex:undefined, deletePorndataIndex:undefined, });
          this.searchPornDatas(this.state.genre);
        }
        this.props.helloLab();
      });

/*
      let params = { genre:this.state.genre, created_at:created_at, }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      axios.put('https://lab.takuman.me/api/porn_datas/checked/', params, headers)
      .then(res => {
        this.props.handleFlashMessage('CHECKED');
        this.setState({ showVideoIndex:undefined, deletePorndataIndex:undefined, });
        this.searchPornDatas(this.state.genre);
      })
      .catch(err => { });
*/
    }
  }

  changeDisplayPattern = (name) => {
    this.setState({ displayPattern:name, showVideoIndex:undefined, });
  }

  panelSizeChange = () => {
    let size = document.getElementById('panel-size-changer').value;
    this.setState({ panelSize:size, });
  }

  copyText = (hash, type) => {
    let pds = this.state.pornDatas.filter( (data) => data.hash == hash);
    if (0 < pds.length) {
      let pd = pds[0];
      console.log('pd');
      console.log(pd);
      let ct = '';
      if (type == '@t') {
        // ユーザー名＋テキスト
        ct = pd.contributor_name + '\n' + pd.description;
      } else {
        // テキストオンリー
        ct = pd.description;
      }

      navigator.clipboard.writeText(ct).then( () => {
        // クリップボードへのコピーに成功した場合
        this.props.handleFlashMessage(type + ' COPIED');
      }, () => {
        // クリップボードへのコピーに失敗した場合
        this.props.handleFlashMessage('COPY FAILED');
      });

    }
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let loading;
    if (this.state.loading) {
      loading = (
        <Loading>
          <div className='text'>loading...</div>
        </Loading>
      );
    } else {
      loading = ( <span></span> );
    }

    let menu;
    if (0 < this.state.genres.length) {
      let genres = this.state.genres.map((genre,i) => {
        return (
          <div className={'item ' + (this.state.genre == genre.name ? 'select-item': '')} onClick={ () => {this.searchPornDatas(genre.name)} }>
            <div className='item-name-wrapper'><div className='item-name'>{genre.name} ({genre.count})</div></div>
          </div>
        );
      });
      menu = (
        <Menu full={this.props.full} one={this.props.one} two={this.props.two}>
          <div className={'item ' + (this.state.genre == 'All' ? 'select-item': '')} onClick={ () => {this.searchPornDatas('All')} }>
            <div className='item-name-wrapper'><div className='item-name'>All ({this.state.genres.reduce((sum,i) => sum+i.count,0)})</div></div>
          </div>
          {genres}
        </Menu>
      );
    } else {
      menu = ( <span></span> );
    }

    let pornDatas;
    if (0 < this.state.pornDatas.length) {
      let pornList = this.state.pornDatas.map((data,i) => {
        let files;
        if (0 < data.files.split(',').length) {
          files = data.files.split(',').map((file) => {
            if (file.indexOf('.mp4') < 0) {
              // 画像の場合

              if (0 <= file.indexOf('thumb')) {
                if (this.state.showVideoIndex == i) {
                  return ( <span></span> );
                } else {
                  return (
                    <Media displayPattern={this.state.displayPattern} btnSize={this.state.panelSize <= 140 ? this.state.panelSize * 0.5 : 70} onClick={ () => {this.showVideo(i)} }>
                      <div className='file thumb'><img src={file} /></div>
                      <div className='play-btn'><div className='play-btn-triangle'><i class="fas fa-play"></i></div></div>
                    </Media>
                  );
                }
              } else {
                return (
                  <div className='file'><a href={file} target='_blank'><img src={file} /></a></div>
                );
              }
            } else {
              if (this.state.showVideoIndex == i) {
                return (
                  <Media displayPattern={this.state.displayPattern} btnSize={this.state.panelSize <= 140 ? this.state.panelSize * 0.5 : 70}>
                    <ReactPlayer url={file} width={this.state.displayPattern == 'timeline' ? 'calc(100% - 40px)' : 'auto'} height={this.state.displayPattern == 'timeline' ? 'auto' : (this.state.panelSize - 10) + 'px'} controls playing />
                  </Media>
                );
              } else {
                return ( <span></span> );
              }
            }
          });
        } else {
          files = ( <span></span> );
        }

        let trashBtnIcon;
        let deleteConfirm;
        if (this.state.deletePorndataIndex == i) {
          trashBtnIcon = ( <i className='fas fa-times' /> );
          deleteConfirm = ( <div className='delete-confirm' onClick={ () => {this.deletePornData(data.hash)} }>Are you sure you want to delete it?</div> );
        } else {
          trashBtnIcon = ( <i className='far fa-trash-alt' /> );
          deleteConfirm = ( <span></span> );
        }

        let checked;
        if (this.state.genre != 'All') {
          checked = ( <Checked onClick={ () => {this.checkedPornData(data.created_at)} }><div className='line' /><div className='text'>checked</div></Checked> );
        } else {
          checked = ( <span></span> );
        }

        if (this.state.displayPattern == 'timeline') {
          return (
            <div>
              <PornData full={this.props.full} one={this.props.one} two={this.props.two}>
                <div className='contributor'>
                  <div className='contributor-icon'><img src={data.contributor_icon} /></div>
                  <div className='contributor-name'>{data.contributor_name}</div>
                </div>
                <div className='description'>{data.description}</div>
                <div className='files'>{files}</div>
                <div className='genre'>{data.genre}</div>
                <ClearBoth />
                <div className='count'>{i+1} / {this.state.pornDatas.length}</div>
                <div className='trash-btn' onClick={ () => {this.deletePornData(data.hash)} }>{trashBtnIcon}</div>
                {/*<div className='trash-btn' onClick={ () => {this.deleteConfirm(i)} }>{trashBtnIcon}</div>*/}
                <div className='pub-date'>{data.pub_date ? data.pub_date.substr(0,10) : 'NoGetAPI'}</div>
                <ClearBoth />
                {deleteConfirm}
              </PornData>
              {checked}
            </div>
          );
        } else {
          return (
            <div>
              <PanelPornData full={this.props.full} one={this.props.one} two={this.props.two} size={this.state.panelSize}>
                <div className='files'>{files}</div>
                <div className='delete-btn' onClick={ () => {this.deletePornData(data.hash)} }>×</div>
                <div className='copy-btn-one' onClick={ () => {this.copyText(data.hash, '@t')} }>@t</div>
                <div className='copy-btn-two' onClick={ () => {this.copyText(data.hash, 't')} }>t</div>
                <div className='copy-text-one'>{data.contributor_name}<br/>{data.description}</div>
                <div className='copy-text-two'>{data.description}</div>
              </PanelPornData>
            </div>
          );
        }
      });

      let panelSizeChanger;
      let checkPanelDatas;
      if (this.state.displayPattern == 'panel') {
        panelSizeChanger = (
          <input type='range' id='panel-size-changer' name='panel-size-changer' min='50' max='500' value={this.state.panelSize} step='10' onChange={ () => {this.panelSizeChange()} } />
        );
        if (this.state.genre != 'All') {
          checkPanelDatas = (
            <div id='check-panel-datas' onClick={ () => {this.checkedPornData(this.state.pornDatas[this.state.pornDatas.length - 1].created_at)} }>
              <div id='check-btn'>check</div>
            </div>
          );
        }
      }

      pornDatas = (
        <PornDatasStyle id='porn-datas-style' full={this.props.full} one={this.props.one} two={this.props.two}>
          <div id='display-pattern'>
            <div className={'pattern-name ' + (this.state.displayPattern == 'timeline' ? 'on' : '')} onClick={ () => {this.changeDisplayPattern('timeline')} }>timeline</div>
            <div className={'pattern-name ' + (this.state.displayPattern == 'panel' ? 'on' : '')} onClick={ () => {this.changeDisplayPattern('panel')} }>panel</div>
          </div>
          {panelSizeChanger}
          {pornList}
          {checkPanelDatas}
        </PornDatasStyle>
      );
    } else {
      pornDatas = ( <span></span> );
    }

    let pornDatasField = (
      <PornDatasField onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        {loading}
        {menu}
        {pornDatas}
      </PornDatasField>
    );

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {pornDatasField}
      </div>
    );
  }

}
export default PornDatas;
