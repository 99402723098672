import React from 'react';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

import LoginFunc from 'component/common/function/LoginFunc';
import { UserFormStyle, UserFormBlock, Field, SubmitField } from 'component/common/style/UserForm';


class Login extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
    loginStateFunc: PropTypes.func,
  };

  // 初期値
  constructor(props) {
    super(props);
    this.state = {
      errorMessage:'', // エラーメッセージ
      uhash:'aaa', // ユーザーハッシュの初期値
      uinfoInit:true,
      uinfoInit:true, // ユーザーインフォが初期状態かどうか。ユーザーインフォを入力したらfalseに変わる。
      uinfo:'', // ユーザー情報の初期値
      hasUinfoError:true, // ユーザーネームの入力エラーがあるかどうか。エラーありの場合はtrue。
      passwordInit:true, // パスワードが初期状態かどうか。パスワードを入力したらfalseに変わる。
      password:'', // パスワードの初期値
      hasPasswordError:true, // パスワードの入力エラーがあるかどうか。エラーありの場合はtrue。
      checkValid:true // バリデーションチェック。バリデーションに引っかかった場合はtrue
    }
  }

  // ユーザーネームの入力内容の反映と空白チェックをリアルタイムで行う。
  handleUinfoChange(event) {
    this.setState({ uinfoInit:false });
    const uinfo = event.target.value;
    if (uinfo == '') {
      this.setState({ uinfo:uinfo, hasUinfoError:true, checkValid:true }); // バリデーションに引っかかった
    } else {
      if (this.state.hasPasswordError) {
        this.setState({ uinfo:uinfo, hasUinfoError:false, checkValid:true }); // バリデーションに引っかかった
      } else {
        this.setState({ uinfo:uinfo, hasUinfoError:false, checkValid:false }); // バリデーション通過
      }
    }
  }

  // パスワードの入力内容の反映と空白チェックをリアルタイムで行う。
  handlePasswordChange(event) {
    this.setState({ passwordInit:false });
    const password = event.target.value;
    if (password == '') {
      this.setState({ password:password, hasPasswordError:true, checkValid:true }); // バリデーションに引っかかった
    } else {
      if (this.state.hasUinfoError) {
        this.setState({ password:password, hasPasswordError:false, checkValid:true }); // バリデーションに引っかかった
      } else {
        this.setState({ password:password, hasPasswordError:false, checkValid:false }); // バリデーション通過
      }
    }
  }


  login = (event) => {
    event.preventDefault();

    LoginFunc(this.state.uinfo, this.state.password).then((user) => {
      if (0 < Object.keys(user).length) {
        this.props.loginStateFunc(true); // App.jsへログイン状態とユーザーネームを渡す。App.js側でstateが変更される。
        this.props.handleFlashMessage('HELLO');
        History.push('/'); // トップページへリダイレクト
      } else {
        this.setState({errorMessage:'ユーザー名またはパスワードが間違っています。', uinfo:'', password:''});
      }
    });

  };


  render() {
    // ログインしていればトップページへリダイレクト
    if (this.props.loggedIn===true) { return <Redirect to='/' />; }

    {/* ユーザーネームの空白チェックに引っかかったらエラーメッセージを表示 */}
    let uinfoErrorText;
    if (this.state.uinfoInit) {
      uinfoErrorText = ( <span></span> );
    } else {
      if (this.state.hasUinfoError) {
        uinfoErrorText = ( <p className='error'>Please enter.</p> );
      } else {
        uinfoErrorText = ( <span></span> );
      }
    }

    {/*  パスワードの空白チェックに引っかかったらエラーメッセージを表示 */}
    let passwordErrorText;
    if (this.state.passwordInit) {
      passwordErrorText = ( <span></span> );
    } else {
      if (this.state.hasPasswordError) {
        passwordErrorText = ( <p className='error'>Please enter.</p> );
      } else {
        passwordErrorText = ( <span></span> );
      }
    }

    let submitBtn;
    if (this.state.checkValid) {
      submitBtn = ( <input type='submit' value='LOGIN' className='btn' disabled /> ); // 送信できない
    } else {
      submitBtn = ( <input type='submit' value='LOGIN' className='btn' enabled /> ); // 送信できる
    }


    {/* ログインボタンを押下する前後で表示内容を切り替える */}
    let loginForm;
    loginForm = (
      <form onSubmit={ (event) => {this.login(event)} }>

        <p className='error-message'>{this.state.errorMessage}</p>

        <Field>
          <label>username</label>
          <input value={this.state.uinfo} onChange={ (event) => {this.handleUinfoChange(event)} } />
          {uinfoErrorText}
        </Field>

        <Field>
          <label>password</label>
          <input type='password' value={this.state.password} onChange={ (event) => {this.handlePasswordChange(event)} } />
          {passwordErrorText}
        </Field>

        <SubmitField>
          <div className='submit-btn'>{submitBtn}</div>
        </SubmitField>
      </form>
    );

    return (
      <UserFormStyle>
        <Helmet title={'Login | TakumaN Lab'} />
        <div className='form-wrapper'></div>
        <UserFormBlock>
          <h1>LOGIN</h1>
          {loginForm}
        </UserFormBlock>
      </UserFormStyle>
    );

  }
}
export default Login;
