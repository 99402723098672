import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/Form';

// function
import GetLearning from 'component/common/function/GetLearning';
import UpdateDistributedLearning from 'component/common/function/UpdateDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import CheckSummary from "component/common/function/learning/CheckSummary";

// module
import NormalMusiquiField from "component/common/module/learning/NormalMusiquiField";
import SummaryMusiquiField from "component/common/module/learning/SummaryMusiquiField";


class UpdateMusiqui extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      hash:'',
      learning:[],
      showMusiquiField:false,
    };
  }

  componentDidMount = () => {
    let hash = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      hash = pathItems[1].replace('distributed_learning_musiqui_update_','');
    } else {
      hash = pathItems[0].replace('distributed_learning_musiqui_update_','');
    }
    this.setState({ hash:hash, });
    this.getLearning(hash);
  }

  getLearning = (hash) => {
    GetLearning(hash).then((learning) => {
      console.log(learning);
      if (learning) {
        learning.summary = CheckSummary(learning);
        this.setState({ learning:learning, showMusiquiField:true }, () => {
          TextHeightAdjustment('from-text');
        });
      }
    });
  }

  spanChange = (span) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.span = span;
    this.setState({ learning:learning, });
  }

  changeTimes = (event) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.times = event.target.value.replace(/[^0-9,]/gi, "").replace(",,", ","); // 整数値とカンマ以外の文字は削除。カンマが連続する場合は１つにする。
    this.setState({ learning:learning });
  }

  changeMusiqui = (event) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.musiqui = event.target.value;
    learning.summary = CheckSummary(learning);

    let musiquiStyle = document.getElementById("musiqui-style");
    let musiquiStyleScrollTop = musiquiStyle.scrollTop;

    this.setState({ learning:learning, showMusiquiField:false }, () => {
      TextHeightAdjustment('from-text');
      this.setState({ showMusiquiField:true });
      musiquiStyle.scrollTop = musiquiStyleScrollTop;
    });
  }

  updateDistributedLearning = () => {
    let learning = this.state.learning;
    if (learning.musiqui != '') {
      UpdateDistributedLearning(learning.hash, learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo, learning.span, learning.times).then((saved) => {
        if (saved) {
          this.props.handleFlashMessage('UPDATED');

          // MUSIQUIの詳細画面にリダイレクト
          let pathItems = window.location.pathname.slice(1).split('/');
          if (this.props.full) {
            History.push('/distributed_learning_musiqui_' + this.state.learning.hash);
          } else if (this.props.one) {
            History.push('/distributed_learning_musiqui_' + this.state.learning.hash + '/' + pathItems[1]);
          } else if (this.props.two) {
            History.push('/' + pathItems[0] + '/distributed_learning_musiqui_' + this.state.learning.hash);
          }

        } else {
          this.props.handleFlashMessage('ERROR');
        }
      });
    }
  }

  getMusiquiValid = (valid) => {
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectType;
    if (this.state.learning) {
      selectType = (
        <div className='select'>
          <div className='select-item'>type: {this.state.learning.type}</div>
        </div>
      );
    }

    let selectSpanField;
    if (this.state.learning) {
      let spanArray = [0, 1, 2, 4, 8, 16, 32, 64, 128];
      selectSpanField = (
        <div className='select-span-field'>
          {spanArray.map( (span,index) => {
            return ( <div className={'span' + (this.state.learning.span == span ? ' select-span' : '') + (index == 0 ? ' first-span' : index == spanArray.length - 1 ? ' last-span' : '')} onClick={ (event) => {this.spanChange(span)} }>{span}</div> );


          })}
        </div>
      );
    }

    let timesField;
    if (this.state.learning) {
      timesField = ( <div className="times-field"><input type="text" id="times" name="times" value={this.state.learning.times} onChange={ (event) => {this.changeTimes(event)} } /></div> );
    }

    let musiqui;
    if (this.state.learning) {
      if (this.state.learning.summary && this.state.showMusiquiField) {
        musiqui = ( <SummaryMusiquiField learning={JSON.parse(JSON.stringify(this.state.learning))} getMusiquiValid={this.getMusiquiValid} initShow={true} /> );
      } else if (this.state.learning.summary == false && this.state.showMusiquiField) {
        musiqui = ( <NormalMusiquiField learning={JSON.parse(JSON.stringify(this.state.learning))} getMusiquiValid={this.getMusiquiValid} /> );
      }
    }

    let saveBtnField;
    if (this.state.learning) {
      if (this.state.learning.musiqui) {
      if (0 < this.state.learning.musiqui.length) {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn submit onClick={ () => {this.updateDistributedLearning()} }>UPDATE MUSIQUI</LinkBtn>
          </LinkBtnField>
        );
      } else {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn>UPDATE MUSIQUI</LinkBtn>
          </LinkBtnField>
        );
      }
      }
    }


    let translateField = (
      <DistributedLearningFormField>
        <TypeField>
          {selectType}
          {selectSpanField}
          {timesField}
        </TypeField>
        <TextField>
          <textarea type='text' id='from-text' onChange={ (event) => {this.changeMusiqui(event)} } value={this.state.learning.musiqui} placeholder='musiqui' />
        </TextField>
        <TextField>
          {musiqui}
        </TextField>
        {saveBtnField}
      </DistributedLearningFormField>
    );



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Update Musiqui | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learning_musiqui_' + this.state.learning.hash : this.props.one ? '/distributed_learning_musiqui_' + this.state.learning.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_musiqui_' + this.state.learning.hash}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningFormStyle id='musiqui-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
        </DistributedLearningFormStyle>
      </div>
    );
  }

}

export default UpdateMusiqui;
