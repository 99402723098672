import axios from 'axios';
  
export default function GetDistributedLearningTypes(type) {

  return new Promise((resolve, reject) => {

    var params = { type:type, };
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/learning/get_distributed_learning_types/', params, headers)
    .then(res => {
      let qaTypes = res.data.qa_types;
      let musiquiTypes = res.data.musiqui_types;
      let shakyoTypes = res.data.shakyo_types;
      let koubun = res.data.koubun;
      resolve({'qaTypes':qaTypes, 'musiquiTypes':musiquiTypes, 'shakyoTypes':shakyoTypes, 'koubun':koubun});
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
