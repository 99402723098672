import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import { MusiquiField } from 'component/common/style/learning/DistributedLearning';


class SummaryMusiquiField extends React.Component{

  static propTypes = {
    getMusiquiValid: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      learning:this.props.learning,
      nodesInfo:[]
    };
  }

  componentDidMount = () => { 
    this.nodesInit();
  }

  componentWillUpdate = () => {
    document.body.addEventListener('keydown', this.showNode);
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('keydown', this.showNode);
  }


  nodesInit = () => {
    let learning = this.state.learning;
    let nodesInfo = [];
    let nodes = learning.musiqui.split("\n");
    let summaryMusiqui = "";
    let baseIndent = 0; // 空白何個分をインデントの間隔にしているか
    let nodeNum = 0;

    summaryMusiqui += "<div id='summary-musiqui'>";
    for (var i=0; i<nodes.length; i++) {
      if (nodes[i].includes("<h1 id=\"summary\">") || nodes[i].includes("<h1 id='summary'>")) continue; // タイトルの場合はスキップ
      if (nodes[i] == "") continue; // 空白行の場合はスキップ

      let nodeC = nodes[i].split("");

      // インデントは空白何個分を基準にしているかをチェックする
      if (baseIndent == 0) {
        if (nodeC[0] == " " || nodeC[0] == "　") {
          for (var j=0; j<nodeC.length; j++) {
            (nodeC[j] == " " || nodeC[j] == "　") ? baseIndent++ : j = nodeC.length; // 半角または全角のスペースの個数を取得
          }
        }
      }

      // nodeCの先頭から空白が何個続いているかをチェックする
      let spaceCnt = 0;
      if (nodeC[0] == " " || nodeC[0] == "　") {
        for (var j=0; j<nodeC.length; j++) {
          (nodeC[j] == " " || nodeC[j] == "　") ? spaceCnt++ : j = nodeC.length;
        }
      }

      // 0を最上位として、階層の深さを求める。
      let layerNum = (0 < baseIndent) ? Math.round(spaceCnt / baseIndent) : 0;

      let nodeId = "summary-node-" + nodeNum;
      let nodeClass = "layer-" + layerNum + " summary-node";
      if (this.props.initShow)
        nodeClass += "show-node"; // Create画面やUpdate画面での確認用として使いたいときはすべて表示した状態にする
      else
        nodeClass += (nodeNum == 0) ? " show-node focus-summary-node highlight-node" : " hide-node"
      let nodeStyle = (layerNum == 0) ? "" : "margin-left:" + 30 * layerNum + "px;";
      let listTag = (layerNum == 0) ? "" : "<span class='list-tag'>・</span>";
      let nodeValue = nodes[i].substr(spaceCnt, nodes[i].length - spaceCnt); // インデントを取り除いた値
      summaryMusiqui += "<div id='" + nodeId + "' class='" + nodeClass + "' style='" + nodeStyle + "'>" + listTag + nodeValue + "</div>";

      let nodeStatus;
      if (this.props.initShow)
        nodeStatus = "show";
      else
        nodeStatus = (nodeNum == 0) ? "focus" : "hide";

      // nodes[i]に親ノードがあれば、そのnodeIdを取得する
      let nodeParent = null;
      if (0 < nodesInfo.length) {
        for (var j = nodesInfo.length - 1; 0<=j; j--) { // nodesInfoを最後尾から順番に参照
          // すでにnodesInfoにデータが存在し、layerNumが小さいということは親ノードということなので、そのidをnodeParentに代入する
          if (nodesInfo[j].layer < layerNum) nodeParent = nodesInfo[j].id;
          if (nodeParent) j = 0;
        }
      }

      let nodeHighlight;
      if (this.props.initShow)
        nodeHighlight = false;
      else
         nodeHighlight = (nodeStatus == "focus") ? true : false;

      nodesInfo.push({"index":nodeNum, "id":nodeId, "layer":layerNum, "status":nodeStatus, "parent":nodeParent, highlight:nodeHighlight });

      nodeNum++;
    }
    summaryMusiqui += "</div>";
    learning.musiquiSummary = summaryMusiqui;

    this.setState({ learning:learning, nodesInfo:nodesInfo }, () => {
      this.setOrderNodesInfo();
    });
  }


  // nodesInfoの全要素を取得し、各要素が次にフォーカス移動すべき要素のインデックスをnextShowIndexに割り当てる
  setOrderNodesInfo = () => {
    let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

    for (var i=0; i<nodesInfo.length; i++) {
      // nodesInfo[i]の次にフォーカス移動するノードのインデックスを求める
      let targetIndex = i;
      let target = nodesInfo[targetIndex];

      /*****************************************************************
       *     targetを基準とした各種要素のインデックスを取得         *
       *     (要素が存在しない場合は*Index変数に-1が代入される)     *
       *****************************************************************/
      let bsIndex = nodesInfo.findIndex(({index, parent}) => targetIndex < index && parent == target.parent); // targetの一つ後ろの兄弟要素のインデックス
      let fsIndex = nodesInfo.findIndex(({index, parent}) => index < targetIndex && parent == target.parent); // targetの一番手前にある兄弟要素のインデックス
      let pIndex = nodesInfo.findIndex(({id}) => id == target.parent); // targetの親要素のインデックス
      let cIndex = nodesInfo.findIndex(({parent}) => parent == target.id); // targetの子要素のインデックス

      let fscIndex = this.getFrontSibringChildIndex(targetIndex); // targetの手前にある兄弟要素の子要素
      let abscIndex = this.getAncestorBackSibringChildIndex(targetIndex); // targetの先祖要素の兄弟要素の子要素


      /********************************************
       *     適切な要素へとフォーカスを移行     *
       ********************************************/

      let nextFocusIndex = -1; // 1番最後にフォーカスされる要素は-1のまま上書きされない

      // 1. 後ろに兄弟要素があるかどうか
      if (0 <= bsIndex) {
        nextFocusIndex = bsIndex; // 1つ後ろの兄弟要素のインデックス

      // 2. 手前に子持ちの兄弟要素があるかどうか
      } else if (0 <= fsIndex && 0 <= fscIndex) {
        nextFocusIndex = fscIndex; // 手前の子持ち兄弟要素のインデックス

      // 3. 子要素があるかどうか
      } else if (0 <= cIndex) {
        nextFocusIndex = cIndex; // targetの子要素のインデックス

      // 4. 親要素があるかどうか
      } else if (0 <= pIndex && 0 <= abscIndex) {
        nextFocusIndex = abscIndex; // 親要素のインデックス
      }

      nodesInfo[targetIndex].nextFocusIndex = nextFocusIndex;
    }

    this.setState({ nodesInfo:nodesInfo });
  }


  // フォーカス中のノードの手前にある兄弟要素を、一番手前から一つ手前まで順番に子要素があるか確認し、
  // 子要素があればそのインデックスをリターンする関数
  getFrontSibringChildIndex = (targetIndex) => {
    let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

    // フォーカス中のノードの一番手前にある兄弟要素のインデックス
    let fsIndex = nodesInfo.findIndex(({index, parent}) => index < targetIndex && parent == nodesInfo[targetIndex].parent);

    if (fsIndex < 0)
      return -1;

    let fscIndex = null;
    let i = fsIndex;
    let fscFlag = false;
    while (i < targetIndex) {
      fscIndex = nodesInfo.findIndex(({parent}) => parent == nodesInfo[i].id);
      if (0 <= fscIndex) {
        // ⇒その子要素を表示
        fscFlag = true;
        break;
      } else {
        i++;
      }
    }

    if (fscFlag)
      return fscIndex;
    else
      return -1;
  }


  // フォーカス中のノードの親ノードから順番に先祖要素をさかのぼっていき、
  // 先祖要素の後ろの兄弟要素が子要素を持っていればその子要素のインデックスをリターンする関数
  getAncestorBackSibringChildIndex = (targetIndex) => {
    let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

    let abscIndex = null;
    let aInfo = nodesInfo[targetIndex];
    let abscFlag = false;
    for (var i=nodesInfo[targetIndex].layer; 0<=i; i--) {
      let aIndex = nodesInfo.findIndex(({id}) => id == aInfo.parent); // layer i層目の先祖要素のインデックス

      if (aIndex < 0)
        break;

      aInfo = nodesInfo[aIndex]; // layer i層目の先祖要素

      // layer i層目の先祖要素の、後ろのすべての兄弟要素のどこかに子要素があるかどうか
      let j = targetIndex + 1;
      while (j < nodesInfo.length) {

        if (nodesInfo[j].parent == nodesInfo[aIndex].parent) {
          let absInfo = nodesInfo[j];
          abscIndex = nodesInfo.findIndex(({parent}) => parent == absInfo.id);
          if (0 <= abscIndex) {
            abscFlag = true;
            break;
          }
        }
        j++;
      }
      if (abscFlag == true)
        break;
    }

    if (abscFlag)
      return abscIndex;
    else
      return -1;
  }


  showNode = (event) => {

    // textareaまたはinputにフォーカスが当たっていれば処理を終える
    if (document.activeElement.nodeName == "TEXTAREA" || document.activeElement.nodeName == "INPUT")
      return;

    if (this.props.disable)
      return;

    // エンターキーまたは右矢印キーを押下したときだけスクリプトを実行
    if (event.code == 'Enter' || event.code == 'ArrowRight' || event.code == 'ArrowDown') {
      event.preventDefault();

      let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

      let focusIndex = nodesInfo.findIndex(({status}) => status == "focus"); // フォーカス中のノードのインデックス
      if (focusIndex < 0) focusIndex = nodesInfo.findIndex(({status}) => status == "stnby");
      if (focusIndex < 0) // フォーカス中のノードがないということはすでにすべて表示されているということなので何もせずに処理を終える
        return;

      let focusInfo = nodesInfo[focusIndex]; // フォーカス中のノードの情報

      // focusInfoのstatusがstnbyの場合は二段階処理の一段階のみ完了しているということなので、
      // 二段階目の処理としてstnbyをfocusに変更する処理のみを行い終了する。
      if (focusInfo.status == "stnby") {
        this.changeNodeFocus(focusIndex, "show");
        return;
      }

      /*************************************************************************************
       *     フォーカス中のノードに非表示のiタグが含まれているのであれば、             *
       *     そのiタグを表示し下記のフォーカスの移行処理を実行せずに処理を終える。     *
       *************************************************************************************/

      let focusElement = document.getElementById(focusInfo.id);
      let focusItags = focusElement.getElementsByTagName('i');
      if (0 < focusItags.length) {
        let skipChangeNodeFocus = false;
        for (var i=0; i<focusItags.length; i++) {
          if (focusItags[i].classList.contains('show-musiqui') == false && focusItags[i].classList.contains('show-musiqui-incorrect') == false) {
            focusItags[i].classList.add('show-musiqui');
            skipChangeNodeFocus = true;
            break;
          }
        }
        if (skipChangeNodeFocus)
          return;
      }


      /********************************************
       *     適切な要素へとフォーカスを移行     *
       ********************************************/

      if (0 < nodesInfo[focusIndex].nextFocusIndex) {
        this.changeNodeFocus(nodesInfo[focusIndex].nextFocusIndex, "show");
      } else {
        this.changeNodeFocus();
      }

    } else if (event.code == 'ArrowUp') {
      event.preventDefault();

      let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

      let focusIndex = nodesInfo.findIndex(({status}) => status == "focus"); // フォーカス中のノードのインデックス
      if (focusIndex < 0) focusIndex = nodesInfo.findIndex(({status}) => status == "stnby");
      if (focusIndex == 0) // フォーカス中のノードが最高階層なのであればそれ以上戻れる場所はないので処理を終える。
        return;

      let focusInfo = (0 <= focusIndex) ? nodesInfo[focusIndex] : []; // フォーカス中のノードの情報


      /******************************************************************************************
       *     フォーカス中のノードに表示中のiタグが含まれているのであれば、                  *
       *     そのiタグを非表示にし下記のフォーカスの移行処理を実行せずに処理を終える。     *
       ******************************************************************************************/

      // 一番上にある未表示の答えの１つ手前の答えを非表示に戻す。
      if (0 <= focusIndex) {
        let focusElement = document.getElementById(focusInfo.id);
        let focusItags = focusElement.getElementsByTagName('i');
        if (0 < focusItags.length) {
          let skipChangeNodeFocus = false;
          for (var i=focusItags.length - 1; 0<=i; i--) {
            if (focusItags[i].classList.contains('show-musiqui') || focusItags[i].classList.contains('show-musiqui-incorrect')) {

              if (focusItags[i].classList.contains('show-musiqui')) {
                focusItags[i].classList.remove('show-musiqui');
                skipChangeNodeFocus = true;
              }
              if (focusItags[i].classList.contains('show-musiqui-incorrect')) {
                focusItags[i].classList.remove('show-musiqui-incorrect');
                skipChangeNodeFocus = true;
              }
              break;
            }
          }
          if (skipChangeNodeFocus)
            return;
        }
      }


      /********************************************
       *     適切な要素へとフォーカスを移行     *
       ********************************************/

      let prevFocusIndex = nodesInfo.findIndex(({nextFocusIndex}) => nextFocusIndex == focusIndex);
      if (0 <= prevFocusIndex) {
        this.changeNodeFocus(prevFocusIndex, "hide");
      }


    } else if (event.code == 'ArrowLeft') {
      event.preventDefault();

      let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

      let focusIndex = nodesInfo.findIndex(({status}) => status == "focus"); // フォーカス中のノードのインデックス
      if (focusIndex < 0) focusIndex = nodesInfo.findIndex(({status}) => status == "stnby");
      if (focusIndex < 0) // フォーカス中のノードがないということはすでにすべて表示されているということなので何もせずに処理を終える
        return;

      let focusInfo = nodesInfo[focusIndex]; // フォーカス中のノードの情報


      /*************************************************************************************
       *     フォーカス中のノードに非表示のiタグが含まれているのであれば、             *
       *     そのiタグを表示し下記のフォーカスの移行処理を実行せずに処理を終える。     *
       *************************************************************************************/

      let focusElement = document.getElementById(focusInfo.id);
      let focusItags = focusElement.getElementsByTagName('i');
      if (0 < focusItags.length) {
        let skipChangeNodeFocus = false;
        for (var i=0; i<focusItags.length; i++) {
          if (focusItags[i].classList.contains('show-musiqui') == false && focusItags[i].classList.contains('show-musiqui-incorrect') == false) {
            focusItags[i].classList.add('show-musiqui-incorrect');
            skipChangeNodeFocus = true;
            break;
          }
        }
        if (skipChangeNodeFocus)
          return;
      }

    }
  }


  // フォーカスを移動する(引数がnullの場合は既存のフォーカスを外す処理のみ行う)ための関数
  changeNodeFocus = (focusIndex, blurNodeStatus) => {
    let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

    // フォーカス中のノードのインデックス番号を取得
    let blurIndex = nodesInfo.findIndex(({status}) => status == "focus"); // フォーカス中のノードのインデックス
    if (blurIndex < 0) blurIndex = nodesInfo.findIndex(({status}) => status == "stnby");

    if (blurNodeStatus == undefined) blurNodeStatus = "show";

    // フォーカスを外す
    if (nodesInfo[blurIndex]) {
      nodesInfo[blurIndex].status = blurNodeStatus;                                        // statusの変更
      let blurNodeElement = document.getElementById(nodesInfo[blurIndex].id);      // html要素を取得
      if (blurNodeElement) blurNodeElement.classList.remove("focus-summary-node"); // classの削除
      if (blurNodeElement && blurNodeStatus == "hide") blurNodeElement.classList.remove("show-node", "stnby-node", "highlight-node"); // classの削除
      if (blurNodeElement && blurNodeStatus == "hide") blurNodeElement.classList.add("hide-node"); // classの付与

      // 次にフォーカスする要素がない、つまり最後まで読み進めた場合はhighlightを消す。そしてフォーカスしていた要素のstatusをshowにする。
      if (focusIndex == undefined) {
        let highlightNodes = nodesInfo.filter((node) => { return node.highlight == true});
        if (0 < highlightNodes.length) {
          for (var i=0; i<highlightNodes.length; i++) {
            let highlightElement = document.getElementById(highlightNodes[i].id);
            if (highlightElement) {
              highlightNodes[i].highlight = false;
              highlightElement.classList.remove("highlight-node");
            }
          }
        }
      }
    }

    // フォーカスする
    if (focusIndex != undefined) {
      if (nodesInfo[focusIndex]) {

        if (blurNodeStatus == "show") {

          // フォーカスしたい要素がその階層において先頭の要素であるならば、一度はstnbyの状態で表示し、次の処理の際にfocusへと移行するという二段階式にする。
          let bsIndex = nodesInfo.findIndex(({parent, index}) => parent == nodesInfo[focusIndex].parent && index < nodesInfo[focusIndex].index);
          if (bsIndex < 0 && nodesInfo[focusIndex].status == "hide") {
            // stnby(一段階目)
            nodesInfo[focusIndex].status = "stnby";                                                  // statusの変更
            let focusNodeElement = document.getElementById(nodesInfo[focusIndex].id);                // html要素を取得
            if (focusNodeElement) focusNodeElement.classList.add("stnby-node", "highlight-node");    // classの付与
            if (focusNodeElement) focusNodeElement.classList.remove("hide-node");                    // classの削除
          } else {
            // focus(二段階目)
            nodesInfo[focusIndex].status = "focus";                                                  // statusの変更
            let focusNodeElement = document.getElementById(nodesInfo[focusIndex].id);                // html要素を取得
            if (focusNodeElement) focusNodeElement.classList.add("focus-summary-node", "show-node"); // classの付与
            if (focusNodeElement) focusNodeElement.classList.remove("hide-node");                    // classの削除
          }

        } else {
            nodesInfo[focusIndex].status = "focus";                                                  // statusの変更
            let focusNodeElement = document.getElementById(nodesInfo[focusIndex].id);                // html要素を取得
            if (focusNodeElement) focusNodeElement.classList.add("focus-summary-node", "show-node"); // classの付与
            if (focusNodeElement) focusNodeElement.classList.remove("hide-node");                    // classの削除

        }

      }
    }

    this.setState({ nodesInfo:nodesInfo }, () => {
      // フォーカスしたノードの後ろの兄弟要素をスタンバイ(opacity:0.?で表示)させる
      if (focusIndex != undefined) this.setStnbyAndHighlight();

      // nodesInfoのバリデーション
      this.validNodes();
    });
  }


  // フォーカス中の要素を基準に親要素と手前の兄弟要素と後ろの兄弟要素のスタンバイ/ハイライトを適切に管理するための関数
  setStnbyAndHighlight = () => {
    let nodesInfo = JSON.parse(JSON.stringify(this.state.nodesInfo));

    let focusIndex = nodesInfo.findIndex(({status}) => status == "focus"); // フォーカス中のノードのインデックス
    if (focusIndex < 0) focusIndex = nodesInfo.findIndex(({status}) => status == "stnby");
    if (focusIndex < 0) return;
    let focusNode = nodesInfo[focusIndex];

    // focusNodeとは兄弟要素ではない要素がスタンバイ状態であれば、非表示にする
    let otherStnbyNodes = nodesInfo.filter((node) => { return node.parent != focusNode.parent && node.status == "stnby"}); // focusNodeとは関係のないところでstatusがstnbyになっている要素
    if (0 < otherStnbyNodes.length) {
      for (var i=0; i<otherStnbyNodes.length; i++) {
        let otherElement = document.getElementById(nodesInfo[otherStnbyNodes[i].index].id);
        if (otherElement) {
          otherElement.classList.remove("stnby-node", "highlight-node");
          otherElement.classList.add("hide-node");
          nodesInfo[otherStnbyNodes[i].index].status = "hide";
        }
      }
    }

    // focusNodeの手前の兄弟要素と後ろの兄弟要素をそれぞれ取得
    let backSibringNodes = nodesInfo.filter((node) => { return node.parent == focusNode.parent && node.index < focusNode.index}); // focusNodeの手前の兄弟要素
    let frontSibringNodes = nodesInfo.filter((node) => { return node.parent == focusNode.parent && focusNode.index < node.index}); // focusNodeの後ろの兄弟要素

    // focusNodeの手前に兄弟要素がある場合はハイライト表示する
    if (0 < backSibringNodes.length) {
      for (var i=0; i<backSibringNodes.length; i++) {
        let backSibringElement = document.getElementById(backSibringNodes[i].id);
        if (backSibringElement) {
          backSibringElement.classList.remove("stnby-node");
          backSibringElement.classList.add("highlight-node");
          nodesInfo[backSibringNodes[i].index].highlight = true;
        }
      }
    }

    // focusNodeのstatusがstnbyではないのであれば、ハイライト表示する
    if (focusNode) {
      let focusNodeElement = document.getElementById(focusNode.id);
      if (focusNodeElement && focusNode.status != "stnby") {
        focusNodeElement.classList.remove("stnby-node");
        focusNodeElement.classList.add("highlight-node");
        nodesInfo[focusIndex].highlight = true;
      }
    }

    // focusNodeの兄弟要素ではない要素がハイライトされている場合、ハイライトを消す
    let otherHighlightNodes = nodesInfo.filter((node) => { return node.parent != focusNode.parent && node.highlight == true});
    if (0 < otherHighlightNodes.length) {
      for (var i=0; i<otherHighlightNodes.length; i++) {
        let otherHighlightElement = document.getElementById(otherHighlightNodes[i].id);
        if (otherHighlightElement) {
          otherHighlightElement.classList.remove("highlight-node");
          nodesInfo[otherHighlightNodes[i].index].highlight = false;
        }
      }
    }

    // focusNodeの親要素をハイライト表示
    if (focusNode.parent != null) {
      let parentIndex = nodesInfo.findIndex(({id}) => id == focusNode.parent); // focusNodeの親要素のインデックスを取得
      if (0 <= parentIndex) {
        let parentElement = document.getElementById(nodesInfo[parentIndex].id);
        if (parentElement) {
          parentElement.classList.add("highlight-node");
          nodesInfo[parentIndex].highlight = true;
        }
      }
    }

    // focusNodeの後ろの兄弟要素をスタンバイかつハイライトで表示
    for (var i=0; i<frontSibringNodes.length; i++) {
      let frontSibringElement = document.getElementById(frontSibringNodes[i].id);
      if (frontSibringElement) {
        frontSibringElement.classList.remove("hide-node");
        frontSibringElement.classList.add("stnby-node", "highlight-node");
        nodesInfo[frontSibringNodes[i].index].status = "stnby";
        nodesInfo[frontSibringNodes[i].index].highlight = true;
      }
    }

    this.setState({ nodesInfo:nodesInfo }, () => {
      // フォーカスした要素(の親要素)が画面上部の位置に固定表示されるように自動スクロール
      if (focusNode.parent != null) {
        let parentIndex = nodesInfo.findIndex(({id}) => id == focusNode.parent); // focusNodeの親要素のインデックスを取得
        if (0 <= parentIndex) {
          let targetElement = document.getElementById("musiqui-style");
          let parentElement = document.getElementById(nodesInfo[parentIndex].id);
          let focusElement = document.getElementById(nodesInfo[focusIndex].id);

          if (targetElement && parentElement && focusElement) {
            if ((focusElement.offsetTop + focusElement.clientHeight) - parentElement.offsetTop < window.innerHeight - 150) {
              // parentElementが画面上部から150px下になるように自動スクロールしても、
              // focusElementが画面外にならないのであればそのようにスクロール
              targetElement.scrollTop = parentElement.offsetTop - 150;
            } else if (window.innerHeight - 150 < focusElement.clientHeight) {
              // focusElementが画面幅-150pxより大きい場合、focusElementが画面上部から150px下になるように自動スクロール
              targetElement.scrollTop = focusElement.offsetTop - 150;
            } else {
              // focusElementが画面下部から150px上になるように自動スクロール
              targetElement.scrollTop = focusElement.offsetTop + focusElement.clientHeight - window.innerHeight + 150;
            }
          }
        }
      }
    });
  }


  musiquiClick = (event) => {
    if (this.props.disable)
      return;

    // iタグの中のaタグなどをクリックしている可能性もあるのでクリックした要素がiタグではない場合はその親要素を取得する
    let target = (event.target.tagName == "I") ? event.target : event.target.parentElement;

    if (target.tagName == 'I') {
      if (target.classList.contains('show-musiqui') && target.classList.contains('show-musiqui-incorrect') == false) {
        // 覚えていた答えを非表示にする
        target.classList.remove('show-musiqui');
      } else if (target.classList.contains('show-musiqui-incorrect') && target.classList.contains('show-musiqui') == false) {
        // 覚えていなかった答えを非表示にする
        target.classList.remove('show-musiqui-incorrect');
      } else {
        // 覚えていた答えとして表示する。
        event.preventDefault();
        target.classList.add('show-musiqui');
      }
      this.validNodes();
    }
  }


  // すべての要素が表示されており、虫食い部分もすべて正解であればtrueをgetMusiquiValidへリターンする関数
  validNodes = () => {
    let hideNodes = this.state.nodesInfo.filter((node) => { return node.status != "show"});
    if (hideNodes.length == 0) {
      let musiqui = document.getElementById('musiqui');
      let musiquiItags = musiqui.getElementsByTagName('i');
      let showMusiqui = musiqui.querySelectorAll('i.show-musiqui');
      let showMusiquiIncorrect = musiqui.querySelectorAll('i.show-musiqui-incorrect');
      this.props.getMusiquiValid((musiquiItags.length == showMusiqui.length));
    } else {
      this.props.getMusiquiValid(false);
    }
  }


  render() {

    if (this.state.learning) {
      return (
        <MusiquiField id='musiqui' dangerouslySetInnerHTML={{__html : this.state.learning.musiquiSummary}} onClick={ (event) => {this.musiquiClick(event)} } />
      );
    } else {
      return ( <span /> );
    }

  }
}
export default SummaryMusiquiField;
