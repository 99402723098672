import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';


const PostsPaginationField = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:20px; text-align:center; clear:both;
    @media screen and (min-width:750px) { width:80%; }
    @media screen and (min-width:960px) { width:60%; }
  }
`

const Paginations = styled.div`
  &&& {
    width:auto; height:auto; margin:auto; display:inline-block;
  }
`

const Pagination = styled.div`
  &&& {
    width:40px; height:40px; padding:10px 5px; font-size:16px; text-align:center; border-radius:3px; display:inline-block;
    margin:${props => props.shortened ? '0' : '5'}px; border:${props => props.shortened ? 'none' : '5'};
    color:${props => props.select ? edgeColor : mainColor};
    border:${props => props.select ? '2px solid ' + edgeColor : props.shortened ? 'none' : '2px solid ' + mainColor};
    cursor:${props => props.select ? 'default' : props.shortened ? 'default' : 'pointer'};
    &:hover { background:${props => props.select || props.shortened ? 'none' : rgba(mainColor,0.6)};
  }
`


export { PostsPaginationField, Paginations, Pagination };
