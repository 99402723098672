import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, white } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const PostFormStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'}; overflow:scroll;
  }
`

const PostFormBlock = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:20px;
    h1 { color:${edgeColor}; font-size:20px; font-weight:100; border:1px solid ${edgeColor}; border-radius:2px;
         width:auto; height:auto; margin-bottom:30px; padding:2px 10px; display:inline-block; }
    p { color:${mainColor}; font-size:15px; margin:20px; padding:10px 0; }
    img { width:100%; height:auto; margin:10px 0; padding:0; }
    video { width:100%; height:auto; margin:auto; padding:0; cursor:pointer; }

    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '100'}%; border-radius:5px; box-shadow:0 0 ${props => props.full ? '6' : '0'}px ${shadowColor}; }
    @media screen and (min-width:960px) { width:${props => props.full ? '60' : '100'}%; }
  }
`

const Field = styled.div`
  &&& {
    ${props => props.show ? fadeIn(2,0,0) : ''}
    display:${props => props.show ? 'block' : 'none'};
    width:95%; height:auto; margin:0 auto 20px auto; padding:0 auto 100px auto; clear:both; 
    label { color:${mainColor}; font-size:15px; }
    input { width:100%; padding:10px; color:${mainColor}; border:none; border-bottom:1px solid ${rgba(mainColor,0.2)}; background:${subColor}; }
    textarea { width:100%; height:300px; padding:10px; color:${mainColor}; border:1px solid ${rgba(mainColor,0.2)}; border-radius:2px; background:${subColor}; }
    .textarea-tag { height:100px; }
    input:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
    input:focus { border-bottom:3px solid ${mainColor}; outline:0; }
    textarea:focus { animation:borderflash 2s ease infinite alternate; outline:0; }
    img { width:100%; height:auto; margin:10px 0; padding:0; }
    video { width:100%; height:auto; margin:auto; padding:0; cursor:pointer; }
  }
`

const Selectbox = styled.div`
  &&& {
    overflow:hidden; width:80%; margin:2em auto; position:relative;
    select { width:100%; padding-right:1em; cursor:pointer; text-indent:0.01px; text-overflow:ellipsis; border: none; outline:none;
             background:transparent; background-image:none; box-shadow:none; -webkit-appearance:none; appearance:none; }
    select::-ms-expand { display:none; }
    &.sb { position:relative; border:0.5px solid ${mainColor}; border-radius:2px; background:${subColor}; }
    &.sb::before { position:absolute; top:0.8em; right:0.8em; width:0; height:0; padding:0;
                   content:''; border-left:6px solid transparent; border-right:6px solid transparent; border-top:6px solid ${mainColor}; pointer-events:none; }
    &.sb select { padding:8px 38px 8px 8px; color:${mainColor}; background:${subColor}; cursor:pointer; }
    &.sb select:hover { opacity:0.9; }
  }
`

const FileBtn = styled.div`
  &&& {
    display:block; position:relative; overflow:hidden; border-radius:2px; background:${rgba(mainColor,0.5)}; color:${subColor}; text-align:center; font-size:12px;
    width:120px; margin:10px auto; padding:10px; line-height:20px; cursor:pointer;
    input[type=file] { position:absolute; top:0; left:0; width:100%; height:100%; cursor:pointer; opacity:0; }
    .upload-value { display:none; background:${rgba(mainColor,0.2)}; border-radius:2px; padding:5px; color:${mainColor}; }
    &:hover { background:${rgba(mainColor,0.8)}; } 
  }
`

const Booleans = styled.div`
  &&& {
    width:100%; height:auto; margin:20px auto;
    .boolean { margin:3px 10px; padding:3px 6px; border:2px solid ${mainColor}; border-radius:2px; color:${mainColor}; text-align:center; font-size:15px; cursor:pointer; }
    .boolean:hover { background:${rgba(mainColor,0.1)}; }
  }
`

const SubmitField = styled.div`
  &&& { text-align:center; padding:30px; clear:both;

    .submit-btn { padding:8px; display:inline-block; 
      .btn { padding:10px 15px; color:black; background:${edgeColor}; border:none; border-radius:2px; transition:.2s; }
      .btn:disabled { background:${rgba(edgeColor,0.5)}; }
      .btn:enabled { background:${edgeColor}; cursor:pointer; }
    }
    .submit-btn:active, .submit-btn:hover {
      .btn { opacity:0.9; padding:13px 18px; font-weight:900; }
    }
    .submit-now { animation:fade 0.5s linear infinite; }
    @keyframes fade {
      0% { opacity:1; }
      50% { opacity:0.7; }
      100% { opacity:1; }
    }
    @keyframes guni {
      0% { transform:scale(1,1); }
      50% { transform:scale(1.6,0.8); }
      100% { transform:scale(1,1); }
    }
  }
`


const FieldBtns = styled.div`
  &&& {
    ${fadeIn(2,0,0)}
    width:80%; height:auto; margin:auto; padding:30px 0; clear:both; 
  }
`

const BtnField = styled.div`
  &&& {
    width:20%; height:50px; margin:0; padding:0; float:left; transition:.2s;
  }
`

const Btn = styled.div`
  &&& { padding:2px; cursor:pointer; display:inline-block; 

    .btn-content { width:50px; height:50px; margin:auto; border:3px solid ${mainColor}; border-radius:2px; position:relative; cursor:pointer;
      div { position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; margin:auto; transition:.2s; transform:rotate(0deg); }
      .title-rect1 { top:5px; bottom:auto; width:80%; height:40%; border:3px solid ${mainColor}; border-radius:3px; }
      .code-rect1 { left:8px; right:auto; bottom:20%; width:4px; height:35%; background:${mainColor}; border-radius:5px; transform:rotate(45deg); }
      .code-rect2 { left:8px; right:auto; top:20%; width:4px; height:35%; background:${mainColor}; border-radius:5px; transform:rotate(-45deg); }
      .code-rect3 { left:auto; right:8px; bottom:20%; width:4px; height:35%; background:${mainColor}; border-radius:5px; transform:rotate(-45deg); }
      .code-rect4 { left:auto; right:8px; top:20%; width:4px; height:35%; background:${mainColor}; border-radius:5px; transform:rotate(45deg); }
      .code-rect5 { width:4px; height:55%; background:${mainColor}; border-radius:5px; transform:rotate(18deg); }
      .writes-rect1 { top:13px; bottom:auto; width:80%; height:4px; background:${mainColor}; border-radius:5px; }
      .writes-rect2 { top:23px; bottom:auto; width:80%; height:4px; background:${mainColor}; border-radius:5px; }
      .writes-rect3 { top:33px; bottom:auto; left:10%; right:auto; width:50%; height:4px; background:${mainColor}; border-radius:5px; }
      .file-circle1 { width:55%; height:55%; border:3px solid ${mainColor}; border-radius:10000px; }
      .file-circle2 { top:10%; bottom:auto; left:auto; right:10%; width:5px; height:5px; background:${mainColor}; border-radius:10000px; }
      .url-circle1 { width:45%; height:80%; border:3px solid ${mainColor}; border-radius:10000px; transform:rotate(45deg); }
      .url-circle2 { width:8%; height:40%; background:${mainColor}; border-radius:10000px; transform:rotate(45deg); }
    }
    .show { background:${edgeColor}; border:3px solid ${subColor};
      .title-rect1 { border:3px solid ${subColor}; }
      .code-rect1, .code-rect2, .code-rect3, .code-rect4, .code-rect5 { background:${subColor}; }
      .writes-rect1, .writes-rect2, .writes-rect3 { background:${subColor}; }
      .file-circle1, .file-circle2 { border:3px solid ${subColor}; }
      .url-circle1 { border:3px solid ${subColor}; }
      .url-circle2 { background:${subColor}; }
    }

    &:hover { animation:borderflash 2s ease infinite alternate;  
      .btn-content { 
        .title-rect1 { transform:rotateZ(3600deg); }
        .code-rect1, .code-rect4 { transform:rotateZ(3645deg); }
        .code-rect2, .code-rect3 { transform:rotateZ(3555deg); }
        .code-rect5 { transform:rotateZ(3618deg); } 
        .writes-rect1, .writes-rect2, .writes-rect3 { transform:rotateZ(3600deg); }
        .file-circle1, .file-circle2 { transform:rotateZ(3600deg); }
        .url-circle1, .url-circle2 { transform:rotateZ(3645deg); }
      }
    }
    &:active, &:hover { border:2px solid ${edgeColor};
      .btn-content { 
        .title-rect1 { transform:rotateZ(3600deg); }
        .code-rect1, .code-rect4 { transform:rotateZ(3645deg); }
        .code-rect2, .code-rect3 { transform:rotateZ(3555deg); }
        .code-rect5 { transform:rotateZ(3618deg); } 
        .writes-rect1, .writes-rect2, .writes-rect3 { transform:rotateZ(3600deg); }
        .file-circle1, .file-circle2 { transform:rotateZ(3600deg); }
        .url-circle1, .url-circle2 { transform:rotateZ(3645deg); }
      }
    }

  }
`

const MinusField = styled.div`
  &&& { width:50%; height:auto; padding:10px; float:left; 

    .minus { width:40px; height:40px; margin:auto; position:relative; cursor:pointer; 
      div { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; transition:.2s; transform:rotateZ(0deg); }
      .minus-o { width:100%; height:100%; background:none; border:3px solid ${mainColor}; border-radius:1000000px; }
      .minus-rect { width:50%; height:3px; background:${mainColor}; border-radius:2px; }
    }
    .minus:active, .minus:hover { ${borderFlash(2)}
      .minus-o { background:${mainColor}; transform:rotateZ(3600deg); }
      .minus-rect { background:${subColor}; transform:rotateZ(3600deg); }
    }
  }
`

const PlusField = styled.div`
  &&& { width:50%; height:auto; padding:10px; float:right; 

    .plus { width:40px; height:40px; margin:auto; position:relative; cursor:pointer; 
      div { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; transition:.2s; transform:rotateZ(0deg); }
      .plus-o { position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; background:none; border:3px solid ${mainColor}; border-radius:1000000px; }
      .plus-rect1 { width:50%; height:3px; background:${mainColor}; border-radius:2px; }
      .plus-rect2 { width:3px; height:50%; background:${mainColor}; border-radius:2px; }
    }
    .plus:active, .plus:hover { ${borderFlash(2)}
      .plus-o { background:${mainColor}; transform:rotateZ(3600deg); }
      .plus-rect1 { background:${subColor}; transform:rotateZ(3600deg); }
      .plus-rect2 { background:${subColor}; transform:rotateZ(3600deg); }
    }
  }
`

const PdfPreview = styled.embed`
  &&& {
    width:100%; height:33vw;
    filter:invert(100%) brightness(75%) sepia(80%) hue-rotate(130deg) saturate(800%) contrast(1);
  }
`



export { PostFormStyle, PostFormBlock, Field, PdfPreview, Selectbox, FileBtn, Booleans, SubmitField, FieldBtns, BtnField, Btn, MinusField, PlusField };
