import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { PostsFullpageLayoutStyle, Blocks, Block, BlockContent } from 'component/common/style/PostsFullpageLayout';


class PostsFullpageLayout extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      posts:this.props.posts,
    };
  }

  showMoviePlayer = (pi, pci) => {
    //let posts = this.state.posts.slice();
    let posts = JSON.parse(JSON.stringify(this.state.posts));
    for (var i=0; i<posts.length; i++) {
      posts[i].show = false;
      if (('postChilds' in posts[i])) {
        for (var i2=0; i2<posts[i].postChilds.length; i2++) {
          posts[i].postChilds[i2].show = false;
        }
      }
    }
    if (pci == undefined) {
      posts[pi].show = true;
    } else {
      posts[pi].postChilds[pci].show = true;
    }
    this.setState({ posts:posts, });
  }

/*
  showMoviePlayer = (index) => {
    let posts = this.state.posts.slice();
    for (var i=0; i<posts.length; i++) {
      posts[i].show = false;
    }
    posts[index].show = true;
    this.setState({ posts:posts, });
  }
*/

  render() {


    let pathItems = window.location.pathname.slice(1).split('/');
    let posts;
    if (0 < this.state.posts.length) {
      posts = this.state.posts.map((post, index) => {

        if (post.file != '') {

          let section; 
          let splitList = String(post.file).split('/');
          let fileName = splitList[splitList.length -1];
          let fileNameSplitList = String(fileName).split('.');
          let extension = fileNameSplitList[fileNameSplitList.length - 1];
          extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
          if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
              extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
              extension == 'ico' || extension == 'icns') {
            section = (
              <Block className={'section section' + index}>
                <img className='image' src={post.file} />
              </Block>
            );
          } else {
            if (post.show) {
              section = (
                <Block className={'section section' + index}>
                  <ReactPlayer url={post.file} className='video' width='100%' height='100%' controls playing loop /> );
                </Block>
              );
            } else {
              let thumb_url = String(post.file).split('.').slice(0,-1).join('.') + '_thumb.jpg';
              section = (
                <Block className={'section section' + index}>
                  <img className='video' src={thumb_url} onClick={ () => {this.showMoviePlayer(index, undefined)} } />
                  <div className='play-btn' onClick={ () => {this.showMoviePlayer(index, undefined)}}><div className='play-btn-triangle'><i class="fas fa-play"></i></div></div>
                </Block>
              );
            }
          }

          if (('postChilds' in post)) {
            return (
              <div>
                {section}
                {post.postChilds.map((postChild, i) => {

                  let sectionChild; 
                  if (postChild.file != '') {
                    let splitList = String(postChild.file).split('/');
                    let fileName = splitList[splitList.length -1];
                    let fileNameSplitList = String(fileName).split('.');
                    let extension = fileNameSplitList[fileNameSplitList.length - 1];
                    extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
                    if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
                        extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
                        extension == 'ico' || extension == 'icns') {
                      sectionChild = (
                        <Block className={'section section' + index}>
                          <img className='image' src={postChild.file} />
                        </Block>
                      );
                    } else {
                      if (postChild.show) {
                        sectionChild = (
                          <Block className={'section section' + index}>
                            <ReactPlayer url={postChild.file} className='video' width='100%' height='100%' controls playing loop /> );
                          </Block>
                        );
                      } else {
                        let thumb_url = String(postChild.file).split('.').slice(0,-1).join('.') + '_thumb.jpg';
                        sectionChild = (
                          <Block className={'section section' + index}>
                            <img className='video' src={thumb_url} onClick={ () => {this.showMoviePlayer(index,i)}} />
                            <div className='play-btn' onClick={ () => {this.showMoviePlayer(index, i)}}><div className='play-btn-triangle'><i class="fas fa-play"></i></div></div>
                          </Block>
                        );
                      }
                    }
                  } else {
                    sectionChild = ( <span></span> );
                  }

                  return ( <div>{sectionChild}</div> );
                })}
              </div>
            );
          } else {
            // postChildsがない場合
            return (
              <div>
                {section}
              </div>
            );
          }

        }

      });
    } else {
      posts = ( <span></span> );
    }

    return (
      <div>
        <Blocks full={this.props.full} one={this.props.one} two={this.props.two}>{posts}</Blocks>
        <ClearBoth/>
      </div>
    );

  }
}
export default PostsFullpageLayout;
