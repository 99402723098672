import React, { Component } from 'react';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import History from 'History';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

//import PlayAudio from 'component/common/PlayAudio'; // Web Audio APIでサウンド再生 
//import PlayVibrate from 'component/common/PlayVibrate'; // Vibration APIでデバイスを振動
import {HeaderStyle} from 'component/common/style/Header';

class Header extends React.Component {

  // propのタイプを定義
  static propTypes = {
    showMenu: PropTypes.func,
  };

  // 初期値はモーダルを非表示に設定
  constructor(props) {
    super(props);
    this.state = {};
  }

  clickLogo = () => {
    this.props.showMenu();
    //PlayAudio('click-01');
    //PlayVibrate('click-01');
  }

  render() {


    let loadingWrapper;
    if (this.props.logoAnimation.includes('kodo')) {
      loadingWrapper = ( <div className='loading-wrapper'></div> );
    } else {
      loadingWrapper = ( <span></span> );
    }


    let logo;
    if (this.props.logoAnimation == '') {
      logo = (
        <div className='home-link' onClick={ () => {this.clickLogo()} }>
          <div className='logo'>
            <div className={'logo-o1'}></div>
          </div>
        </div>
      );
    } else if (this.props.logoAnimation == 'cold') {
      logo = (
        <Link className='home-link' onClick={ () => {this.clickLogo()} }>
          <div className='logo'>
            <div className={'logo-o1'}></div>
          </div>
        </Link>
      );
    } else if (this.props.logoAnimation == 'kodo') {
      logo = (
        <Link className='home-link' onClick={ () => {this.clickLogo()} }>
          <div className='logo'>
            <div className={'logo-o1 kodo'}></div>
          </div>
        </Link>
      );
    } else if (this.props.logoAnimation == 'close') {
      logo = (
        <div className='home-link' onClick={ () => {this.clickLogo()} }>
          <div className='logo'>
            <div className='logo-x1'></div>
            <div className='logo-x2'></div>
          </div>
        </div>
      );
    }

    return (
      <HeaderStyle logoAnimation={this.props.logoAnimation}>
        <header>
          {logo}
        </header>
        {/*loadingWrapper*/}
      </HeaderStyle>
    );

  }

}

export default Header;
