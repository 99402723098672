import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import styled from 'styled-components'
// source→https://diff001a.netlify.app/sass-styled-components/


const rgba = (hex, amount = 1) => {
  let color = getColor(hex, 0);
  //console.log('rgba color');
  //console.log(color);
  const temp = 'rgba(' + color.r + ',' + color.b + ',' + color.g + ',' + amount + ')'
  //console.log(temp);
  return temp
}

// lighten //
const lighten = (hex, amount = 1) => {
  const amt = amount * 100;
/*
  if (hex == '#00FFFF' && amount == 0.12) {
    console.log('################');
    console.log('amount');
    console.log(amount);
    console.log('amt');
    console.log(amt);
  }
*/
  const temp = getColor(hex, amt).temp;
/*
  if (hex == '#00FFFF' && amount == 0.12) {
    console.log('################');
    console.log('temp');
    console.log(temp);
  }
*/
  return temp
}

// darken //
const darken = (hex, amount = 1) => {
  const amt = -(amount * 100);
  const temp = getColor(hex, amt).temp;
  return temp
}

// 共通 //
const getColor = (hex, amt) => {
/*
  if (hex == '#00FFFF') {
    console.log('----------------');
    console.log('hex');
    console.log(hex);
    console.log('amt');
    console.log(amt);
  }
*/
  let usePound = false
  if (hex[0] == "#") {
    hex = hex.slice(1)
    usePound = true
  }
  const num = parseInt(hex, 16)
/*
  if (hex == '00FFFF') {
    console.log('num');
    console.log(num);
  }
*/
/*
  let r = (num >> 16) + amt
  if (r > 255) {
    //r = r - amt;
    r = 255
  } else if (r < 0) {
    //r = r - amt;
    r = 0
  }
  if ((r << 16).toString(16).length == 6) {
    //r = (r << 16).toString(16).substring(0,2);
  //} else if (r != 0) {
  } else {
    console.log('r false: ' + r.toString(16).length);
    console.log(r);
    //r = '0' + (r << 16).toString(16).substring(0,1);
    //r = (r << 16).toString(16).substring(0,2);
    console.log(r);
  }
*/
/*
  if (hex == '00FFFF') {
    console.log('r');
    console.log(r);
    //console.log(r << 16);
    //console.log((r << 16).toString(16));
  }
*/
/*
  let b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) {
    //b = b - amt;
    b = 255
  } else if (b < 0) {
    //b = b - amt;
    b = 0
  }
  if ((b << 8).toString(16).length == 4) {
    //b = (b << 8).toString(16).substring(0,2);
  //} else if (b != 0) {
  } else {
    console.log('b false: ' + b.toString(16).length);
    console.log(b);
    //b = '0' + (b << 8).toString(16).substring(0,1);
    //b = (b << 8).toString(16).substring(0,2);
    console.log(b);
  }
*/
/*
  if (hex == '00FFFF') {
    console.log('b');
    console.log(b);
    //console.log(b << 8);
    //console.log((b << 8).toString(16));
  }
*/
/*
  let g = (num & 0x0000ff) + amt
  if (g > 255) {
    //g = g - amt
    g = 255
  } else if (g < 0) {
    //g = g - amt
    g = 0
  }
  if (g.toString(16).length == 2) {
    //g = g.toString(16).substring(0,2);
  //} else if (g != 0) {
  } else {
    console.log('g false: ' + g.toString(16).length);
    console.log(g);
    //g = '0' + g.toString(16).substring(0,1);
    //g = g.toString(16).substring(0,2);
    console.log(g);
  }
*/
/*
  if (hex == '00FFFF') {
    console.log('g');
    console.log(g);
    //console.log(g);
    //console.log(g.toString(16));
  }
*/
  //let temp = (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  //let temp = (usePound ? "#" : "") + r + b + g;
/*
  if (hex == '00FFFF') {
    console.log('temp');
    console.log(temp);
  }
*/
  //return {temp:temp, r:r, b:b, g:g}

  let r = (num >> 16) + amt;
  let b = ((num >> 8) & 0x00ff) + amt;
  let g = (num & 0x0000ff) + amt;
  r = (r > 255 ? 255 : r < 0 ? 0 : r);
  b = (b > 255 ? 255 : b < 0 ? 0 : b);
  g = (g > 255 ? 255 : g < 0 ? 0 : g);
  let temp_r, temp_b, temp_g;
  if ((r << 16).toString(16).length == 6) {
    temp_r = (r << 16).toString(16).substring(0,2);
  } else if (r != 0) {
    //console.log('r false: ' + r.toString(16).length);
    //console.log(r);
    temp_r = '0' + (r << 16).toString(16).substring(0,1);
    //r = (r << 16).toString(16).substring(0,2);
    //console.log(r);
  }
  if ((b << 8).toString(16).length == 4) {
    temp_b = (b << 8).toString(16).substring(0,2);
  } else if (b != 0) {
    //console.log('b false: ' + b.toString(16).length);
    //console.log(b);
    temp_b = '0' + (b << 8).toString(16).substring(0,1);
    //b = (b << 8).toString(16).substring(0,2);
    //console.log(b);
  }
  if (g.toString(16).length == 2) {
    temp_g = g.toString(16).substring(0,2);
  } else if (g != 0) {
    //console.log('g false: ' + g.toString(16).length);
    //console.log(g);
    temp_g = '0' + g.toString(16).substring(0,1);
    //g = g.toString(16).substring(0,2);
    //console.log(g);
  }
  let temp = (usePound ? "#" : "") + temp_r + temp_b + temp_g;
  return {temp:temp, r:r, b:b, g:g}
  //return {temp:temp, r:(r > 255 ? 255 : r < 0 ? 0 : r), b:(b > 255 ? 255 : b < 0 ? 0 : b), g:(g > 255 ? 255 : g < 0 ? 0 : g)}
}

export { rgba, lighten, darken };
