import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import { Block, BlockName } from 'component/common/style/learning/Top';


class TodayLearning extends React.Component{

  static propTypes = {
    getTimerCnt: PropTypes.func,
    getTimerStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => { 
  }


  render() {


    if (0 < this.props.types.length) {
      let show = false;
      let types = this.props.types.map((type, index) => {
        if (0 < type.todayCount) {
          show = true;

          let url;
          if (this.props.full) {
            url = "/today_distributed_learning_" + this.props.urlType + "_" + type.name;
          } else if (this.props.one) {
            url = "/today_distributed_learning_" + this.props.urlType + "_" + type.name + "/" + window.location.pathname.slice(1).split("/")[1];
          } else {
            url = "/" + window.location.pathname.slice(1).split("/")[0] + "/today_distributed_learning_" + this.props.urlType + "_" + type.name;
          }

          {/*
          let sumEstimateReadTime = type.todayEstimateReadTime.split('-').reduce((sum, element) => Number(sum) + Number(element), 0);
          musiquiSumEstimateReadTime += sumEstimateReadTime;
            <p>・<Link to={this.props.full ? '/today_distributed_learning_musiqui_' + type.name : this.props.one ? '/today_distributed_learning_musiqui_' + type.name + '/' + window.location.pathname.slice(1).split('/')[1] : '/' + window.location.pathname.slice(1).split('/')[0] + '/today_distributed_learning_musiqui_' + type.name}>{type.name}<label className='sub-label'>N={type.todayCount}</label><label className='sub-label'>{sumEstimateReadTime}min({type.todayEstimateReadTime})</label></Link></p>
          return ( <p>・<Link to={url}>{type.name}<label className="sub-label">N={type.todayCount}</label></Link></p> );
          <p>・<Link to={url}>{type.name}<label className='sub-label'>N={type.totalCount}</label><label className='sub-label'>{type.avgPercentage}%</label><label className='sub-label'>{(type.totalNoTimeCnt == 0) ? type.totalEstimateReadTime + "min" : type.totalEstimateReadTime + "min(" + type.totalNoTimeCnt + "?)"}</label></Link></p>
          */}
          return ( <p>・<Link to={url}>{type.name}<label className="sub-label">N={type.todayCount}</label><label className="sub-label">{(type.todayNoTimeCnt == 0) ? type.todayEstimateReadTime + "min" : type.todayEstimateReadTime + "min(" + type.todayNoTimeCnt + "?)"}</label></Link></p> );
        }
      });

      if (show) {
        return (
          <div>
            <label>{this.props.label}</label>
            {types}
          </div>
        );
      } else {
        return ( <span></span> );
      }

    } else {
      return (
        <div>
          <label>{this.props.label}</label>
          <p>{this.props.label}のリストはまだありません。</p>
        </div>
      );
    }

  }
}
export default TodayLearning;
