import axios from 'axios';
  
export default function GetTwitterAPIRateLimit() {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` }, timeout:1000 * 60 * 60 }
    axios.get('https://lab.takuman.me/api/oauth/twitter/get_twitter_api_rate_limit/', headers)
    .then(res => {
      let rate_limit_status = JSON.parse(res.data);
      resolve({ 'rate_limit_status':rate_limit_status, });
    })
    .catch(err => {
      reject();
    });

  }).then((p) => {
    let rate_limit_status = p.rate_limit_status;
    return new Promise( (resolve, reject) => {

      let rateLimits = []; // 各レート情報を格納
      let rateLimitErrorMessages = []; // remainingが0になったレート情報を格納

      // api.get_user
      let user = rate_limit_status['resources']['users']['/users/show/:id'];
      let user_limit = user['limit'];
      let user_remaining = user['remaining'];
      let user_reset = new Date(user['reset'] * 1000);
      user_reset = user_reset.getFullYear() + '/' + (user_reset.getMonth() + 1) + '/' + user_reset.getDate() + ' ' + user_reset.getHours() + ':' + user_reset.getMinutes();
      rateLimits.push('get_user: ' + user_remaining + '/' + user_limit + ' ' + user_reset.split(' ')[1]);
      if (user_remaining == 0) {
        rateLimitErrorMessages.push('get_user: reset_time => ' + user_reset);
      }

      // api.followers
      let followers = rate_limit_status['resources']['followers']['/followers/list'];
      let followers_limit = followers['limit'];
      let followers_remaining = followers['remaining'];
      let followers_reset = new Date(followers['reset'] * 1000);
      followers_reset = followers_reset.getFullYear() + '/' + (followers_reset.getMonth() + 1) + '/' + followers_reset.getDate() + ' ' + followers_reset.getHours() + ':' + followers_reset.getMinutes();
      rateLimits.push('followers: ' + followers_remaining + '/' + followers_limit + ' ' + followers_reset.split(' ')[1]);
      if (followers_remaining == 0) {
        rateLimitErrorMessages.push('followers: reset_time => ' + followers_reset);
      }


      // api.friends
      let friends = rate_limit_status['resources']['friends']['/friends/list']
      let friends_limit = friends['limit']
      let friends_remaining = friends['remaining']
      let friends_reset = new Date(friends['reset'] * 1000)
      friends_reset = friends_reset.getFullYear() + '/' + (friends_reset.getMonth() + 1) + '/' + friends_reset.getDate() + ' ' + friends_reset.getHours() + ':' + friends_reset.getMinutes();
      rateLimits.push('friends: ' + friends_remaining + '/' + friends_limit + ' ' + friends_reset.split(' ')[1]);
      if (friends_remaining == 0) {
        rateLimitErrorMessages.push('friends: reset_time => ' + friends_reset);
      }


      // api.show_friendship
      let friendships = rate_limit_status['resources']['friendships']['/friendships/show']
      let friendships_limit = friendships['limit']
      let friendships_remaining = friendships['remaining']
      let friendships_reset = new Date(friendships['reset'] * 1000)
      friendships_reset = friendships_reset.getFullYear() + '/' + (friendships_reset.getMonth() + 1) + '/' + friendships_reset.getDate() + ' ' + friendships_reset.getHours() + ':' + friendships_reset.getMinutes();
      if (friendships_remaining == 0) {
        rateLimitErrorMessages.push('show_friendships: reset_time => ' + friendships_reset);
      }


      // api.home_timeline
      let timeline = rate_limit_status['resources']['statuses']['/statuses/home_timeline']
      let timeline_limit = timeline['limit']
      let timeline_remaining = timeline['remaining']
      let timeline_reset = new Date(timeline['reset'] * 1000)
      timeline_reset = timeline_reset.getFullYear() + '/' + (timeline_reset.getMonth() + 1) + '/' + timeline_reset.getDate() + ' ' + timeline_reset.getHours() + ':' + timeline_reset.getMinutes();
      if (timeline_remaining == 0) {
        rateLimitErrorMessages.push('hoge_timeline: reset_time => ' + timeline_reset);
      }

      // api.user_timeline
      let tweets = rate_limit_status['resources']['statuses']['/statuses/user_timeline'];
      let tweets_limit = tweets['limit'];
      let tweets_remaining = tweets['remaining'];
      let tweets_reset = new Date(tweets['reset'] * 1000);
      tweets_reset = tweets_reset.getFullYear() + '/' + (tweets_reset.getMonth() + 1) + '/' + tweets_reset.getDate() + ' ' + tweets_reset.getHours() + ':' + tweets_reset.getMinutes();
      rateLimits.push('user_timeline: ' + tweets_remaining + '/' + tweets_limit + ' ' + tweets_reset.split(' ')[1]);
      if (tweets_remaining == 0) {
        rateLimitErrorMessages.push('user_timeline: reset_time => ' + tweets_reset);
      }


      // api.favorites
      let favorites = rate_limit_status['resources']['favorites']['/favorites/list'];
      let favorites_limit = favorites['limit'];
      let favorites_remaining = favorites['remaining'];
      let favorites_reset = new Date(favorites['reset'] * 1000);
      favorites_reset = favorites_reset.getFullYear() + '/' + (favorites_reset.getMonth() + 1) + '/' + favorites_reset.getDate() + ' ' + favorites_reset.getHours() + ':' + favorites_reset.getMinutes();
      rateLimits.push('favorites: ' + favorites_remaining + '/' + favorites_limit + ' ' + favorites_reset.split(' ')[1]);
      if (favorites_remaining == 0) {
        rateLimitErrorMessages.push('favorites: reset_time => ' + favorites_reset);
      }


      // api.search
      let search = rate_limit_status['resources']['search']['/search/tweets'];
      let search_limit = search['limit'];
      let search_remaining = search['remaining'];
      let search_reset = new Date(search['reset'] * 1000);
      search_reset = search_reset.getFullYear() + '/' + (search_reset.getMonth() + 1) + '/' + search_reset.getDate() + ' ' + search_reset.getHours() + ':' + search_reset.getMinutes();
      if (search_remaining == 0) {
        rateLimitErrorMessages.push('search: reset_time => ' + search_reset);
      }


      resolve({ 'rateLimitStatus':rate_limit_status, 'rateLimits':rateLimits,  'rateLimitErrorMessages':rateLimitErrorMessages, });
    });


  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve({ 'rateLimit':[], 'rateLimits':[], 'rateLimitErrorMessages':[], });
    });
  });

}
