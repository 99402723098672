import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ReactPlayer from 'react-player'
import throttle from 'lodash.throttle';

import { ClearBoth } from 'component/common/style/GlobalStyle';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { LayeredNotesSearchBoxStyle, Type, SearchBox, SearchBoxItem, SearchFilter, Searching, NotesCount } from 'component/common/style/LayeredNotesSearchBox';
import LayeredNotesSearch from 'component/common/function/LayeredNotesSearch';


class LayeredNotesSearchBox extends React.Component{

  static propTypes = {
    getNotes: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      searching:false,
      order:'desc', showOrderFilterMenu:false, 
      searchTarget:'all', showSearchTargetFilterMenu:false, 
      searchWords:'', andSearchWords:'', orSearchWords:'', notSearchWords:'',
      paginationNum:1,
      notesCount:0,
    };
  }

  componentDidMount = () => { 
    this.search();
  }

  componentWillUpdate = () => {
    document.body.addEventListener('click', (event) => {

      // paginationをクリックした場合は、クリックしたページネーションでstateを更新し、そのあとに検索
      if (event.target.className.split(' ').includes('pagination')) {
        console.log('pagination click');
        console.log(event.target.className.split(' '));
        if ((this.props.full && event.target.className.split(' ').includes('full')) || 
            (this.props.one && event.target.className.split(' ').includes('one')) || 
            (this.props.two && event.target.className.split(' ').includes('two'))) {
          if (Number(event.target.innerText) != this.state.paginationNum) {
            this.setState({ paginationNum:Number(event.target.innerText), }, () => {
              this.search();
            });
          }
        }
      }

      // Postの新規作成または更新のフォームでsubmitを押下したら、またはPostの削除ボタンを押下したら、
      // メソッド終了後にurlが変更(一覧ページにリダイレクト)されたタイミングでsearchメソッドでPost一覧を取得しなおす
      if (event.target.type == 'submit' || event.target.parentNode.parentNode.className.split(' ').includes('delete-btn')) {
        clearInterval(this.urlCheckInterval);
        let pathItem = window.location.pathname.slice(1).split('/')[this.props.two ? 1 : 0];
        let count = 0;
        this.urlCheckInterval = setInterval(() => {
          if (pathItem !=  window.location.pathname.slice(1).split('/')[this.props.two ? 1 : 0]) {
            this.search();
            clearInterval(this.urlCheckInterval);
          }
          if (600 < count) {
            clearInterval(this.urlCheckInterval);
          }
          count = Math.round((count + 0.1) * 10) / 10;
        }, 100);
      }

      this.hideMenu(event);
    });
  }


  search = () => { 
    document.activeElement.blur();
    this.setState({ searching:true, });
    this.props.getNotes({'notesCount':0, 'paginationNum':this.state.paginationNum, 'notes':[]});

    let swc = this.searchWordsClassification(this.state.searchWords); 

    var searchParams = {
      order:this.state.order,
      search_words:this.state.searchWords,
      and_search_words:swc.andSearchWords,
      or_search_words:swc.orSearchWords,
      not_search_words:swc.notSearchWords,
      search_target:this.state.searchTarget,
      pagination_num:this.state.paginationNum
    };

    LayeredNotesSearch(searchParams).then((p) => {
      let notesCount = p.notesCount;
      let notes = p.notes;
      this.setState({ searching:false, notesCount:notesCount, });
      this.props.getNotes({'notesCount':notesCount, 'paginationNum':this.state.paginationNum, 'notes':notes});
    });
  }

  handleSearch = (event) => {
    event.preventDefault();
    this.setState({ searching:true, paginationNum:1 });
    this.search();
  }


  // search word
  searchWordsChange = (event) => {
    let searchWords = event.target.value; 

    // 全角スペースを半角スペースに置換
    if (searchWords.includes('　')) { 
      searchWords = searchWords.replace(/　/g," ");
    } 
    this.setState({ searchWords:searchWords });
    localStorage.setItem('searchWords' + this.props.order, searchWords);

    let swc = this.searchWordsClassification(searchWords); 
    this.setState({ andSearchWords:swc.andSearchWords, orSearchWords:swc.orSearchWords, notSearchWords:swc.notSearchWords, }); 
  }

  // 検索ワードからAND検索ワード、OR検索ワード、NOT検索ワードに分離するメソッド 
  searchWordsClassification = (searchWords) => { 
    // a AND b AND c → AND(a b c)
    // a AND b OR c → AND(a), OR(b c)
    // a OR b OR c → OR(a b c)
    // a AND b OR c NOT d → AND(a), OR(b c), NOT(d) 

    if (searchWords.includes('AND') || searchWords.includes('OR') || searchWords.includes('NOT')) { 
      let searchWordsSplit = searchWords.split(' ');
      searchWordsSplit = searchWordsSplit.filter(function(word) {
        return word != ''; // 空白の要素があれば除外する  
      });
      let andSearchWords = ''; 
      if (searchWords.includes('AND')) {
        // ANDの手前のワードがAND検索に含まれる
        for (var i=0; i<searchWordsSplit.length; i++) {
          // 後ろがANDの場合、手前がANDで後ろがNOTの場合、最後から二番目がANDの場合、２つ目がNOTの場合は後ろのワードも含める。
          if (searchWordsSplit[i+1] == 'AND' || (searchWordsSplit[i-1] == 'AND' && searchWordsSplit[i+1] == 'NOT') || (i == searchWordsSplit.length-1 && searchWordsSplit[searchWordsSplit.length-2] == 'AND') || (i == 0 && searchWordsSplit[i+1] == 'NOT')) {
            if (andSearchWords == '') {
              andSearchWords += searchWordsSplit[i];
            } else {
              andSearchWords += ' ' + searchWordsSplit[i];
            }
          }
        }
      } else {
        if (searchWordsSplit[1] == 'NOT') {
          andSearchWords = searchWordsSplit[0]; 
        }
      }
      let orSearchWords = ''; 
      if (searchWords.includes('OR')) {
        // ORの手前と後ろがOR検索に含まれる
        for (var i=0; i<searchWordsSplit.length; i++) {
          // ANDの文字列の手前のワードをandSearchWordsに含める。ただし、最後から二番目がANDの場合は後ろのワードも含める。
          if (searchWordsSplit[i+1] == 'OR' || (i != 0 && searchWordsSplit[i-1] == 'OR') || (i == searchWordsSplit.length-1 && searchWordsSplit[searchWordsSplit.length-2] == 'AND')) {
            if (orSearchWords == '') {
              orSearchWords += searchWordsSplit[i];
            } else {
              orSearchWords += ' ' + searchWordsSplit[i];
            }
          }
        }
      }

      let notSearchWords = '';
      if (searchWords.includes('NOT')) {
        // NOTの後ろがNOT検索に含まれる
        for (var i=0; i<searchWordsSplit.length; i++) {
          // ANDの文字列の手前のワードをandSearchWordsに含める。
          if (i != 0 && searchWordsSplit[i-1] == 'NOT') {
            if (notSearchWords == '') {
              notSearchWords += searchWordsSplit[i];
            } else {
              notSearchWords += ' ' + searchWordsSplit[i];
            }
          }
        }
      }
      return {'andSearchWords':andSearchWords, 'orSearchWords':orSearchWords, 'notSearchWords':notSearchWords, } 
    } else {
      return {'andSearchWords':searchWords, 'orSearchWords':'', 'notSearchWords':'', } 
    }

  } 




  // order
  handleOrderChange = (event) => { 
    if (this.state.showOrderFilterMenu) { 
      this.setState({ showOrderFilterMenu:false, showSearchTargetFilterMenu:false, });
    } else { 
      this.setState({ showOrderFilterMenu:true, showSearchTargetFilterMenu:false, }); 
    } 
  } 
  orderChange = (order) => {
    this.setState({ order:order, showOrderFilterMenu:false, showSearchTargetFilterMenu:false, }); 
  }


  // search target
  handleSearchTargetChange = (event) => {
    if (this.state.showSearchTargetFilterMenu) {
      this.setState({ showSearchTargetFilterMenu:false, showOrderFilterMenu:false, });
    } else {
      this.setState({ showSearchTargetFilterMenu:true, showOrderFilterMenu:false, });
    }
  }
  searchTargetChange = (target) => {
    this.setState({ searchTarget:target, showOrderFilterMenu:false, showSearchTargetFilterMenu:false, }); 
  }


  hideMenu = (event) => {
    if (event.target.parentNode.className != 'filter-menu' && (this.state.showOrderFilterMenu || this.state.showSearchTargetFilterMenu)) {
      this.setState({ showOrderFilterMenu:false, showSearchTargetFilterMenu:false, });
    }
  }



  render() {




   {/****************
    *     order     *
    ****************/}
    let order; 
    if (this.state.order == 'desc') { 
      order = ( <span>new</span> );
    } else { 
      order = ( <span>old</span> );
    } 

    let orderFilterMenu; 
    if (this.state.showOrderFilterMenu) { 
      orderFilterMenu = ( 
        <div className='filter-menu'>
          <span onClick={ (event) => {this.orderChange('desc')} }>new</span>
          <span onClick={ (event) => {this.orderChange('asc')} }>old</span>
        </div> 
      ); 
    } else { 
      orderFilterMenu = ( <span></span> ); 
    } 



   {/************************
    *     search target     *
    ************************/}
    let searchTarget;
    if (this.state.searchTarget == 'all') { 
      searchTarget = ( <span>all</span> ); 
    } else if (this.state.searchTarget == 'title') { 
      searchTarget = ( <span>title</span> ); 
    } else { 
      searchTarget = ( <span>writes</span> ); 
    } 

    let searchTargetFilterMenu; 
    if (this.state.showSearchTargetFilterMenu) { 
      searchTargetFilterMenu = ( 
        <div className='filter-menu'>
          <span onClick={ (event) => {this.searchTargetChange('all')} }>all</span>
          <span onClick={ (event) => {this.searchTargetChange('title')} }>title</span>
        </div>
      ); 
    } else { 
      searchTargetFilterMenu = ( <span></span> ); 
    } 




   {/********************
    *     searching     *
    ********************/}
    let searching; 
    if (this.state.searching) { 
      searching = ( <Searching>now searching</Searching> );
    } else { 
      searching = ( <span></span> ); 
    } 



    return (
      <LayeredNotesSearchBoxStyle>

{/*
        <Type>LayeredNote</Type>
        <SearchBox onSubmit={ (event) => {this.handleCreate(event)} }>
          <input type='text' name='search' value={this.state.searchWords} onChange={ (event) => {this.searchWordsChange(event)} } />
          <input type='submit' value='' className='search-btn' onClick={ (event) => {this.handleSearch(event)} } />
        </SearchBox>
*/}

        <SearchBox>

          <SearchBoxItem first={true} last={false} mainColor={edgeColor} subColor={subColor} background={subColor} opacity='0'>
            <div className='item-element'>
              <form onSubmit={ (event) => {event.preventDefault()} }>
                <input type='text' className='item-field' value='LayeredNotes' />
              </form>
            </div>
          </SearchBoxItem>

          <SearchBoxItem first={false} last={false} mainColor={mainColor} subColor={subColor} background={subColor} opacity='0.5'>
            <div className='item-element'>
              <div className='layer-percentage' style={{ width:'20%', }}></div>
              <form onSubmit={event => {this.handleSearch(event)} }>
                <input type='text' className='item-field' value={this.state.searchWords} placeholder='search keywords' onChange={ (event) => {this.searchWordsChange(event)} } />
              </form>
            </div>
          </SearchBoxItem>

          <SearchBoxItem first={false} last={true} mainColor={subColor} subColor={edgeColor} background={edgeColor} opacity='1'>
            <div className='item-element submitbtn-item-element' onClick={ (event) => {this.handleSearch(event)} }>
              <div className='layer-percentage' style={{ width:'40%', }}></div>
              <form>
                <div className='item-field'><i className='fas fa-search' /> Search</div>
              </form>
            </div>
          </SearchBoxItem>

        </SearchBox>

        <SearchFilter>
          <div className='filter-item'>
            <div className='filter-item-state' onClick={ (event) => {this.handleOrderChange(event)} }>{order}</div>
            {orderFilterMenu}
          </div>
          <div className='filter-item'>
            <div className='filter-item-state' onClick={ (event) => {this.handleSearchTargetChange(event)} }>{searchTarget}</div>
            {searchTargetFilterMenu}
          </div>
          <NotesCount>{this.state.notesCount}notes</NotesCount>
          <ClearBoth/>
        </SearchFilter>

        {/*
        <div>
          <p>AND検索ワード: {this.state.andSearchWords}<br/>
             OR検索ワード: {this.state.orSearchWords}<br/>
             NOT検索ワード: { this.state.notSearchWords}</p>
        </div>
        */}

        {searching}
      </LayeredNotesSearchBoxStyle>
    );


  }
}
export default LayeredNotesSearchBox;
