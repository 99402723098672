import axios from 'axios';
  
export default function GetLearnings(type, searchWords) {

  return new Promise((resolve, reject) => {

    var params = { type:type, search_words:searchWords, };
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/learning/search/', params, headers)
    .then(res => {
      let learnings = res.data.learnings;
      resolve(learnings);
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve([]);
    });
  });

}
