import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { Block, BlockName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock } from 'component/common/style/Oauth';
import GetFavorites from 'component/common/function/GetFavorites';
import Tweets from 'component/common/module/Tweets';

class TwitterFavorites extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let twitter_id;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      twitter_id = pathItems[1].split('_')[3]
    } else {
      twitter_id = pathItems[0].split('_')[3]
    }

    this.state = {
      scrollTop:0,
      twitter_id:twitter_id,
      getTweets:false,
      tweets:[], tweetsCount:0, tweetsFilter:'mediaOnly',
    };
  }

  componentDidMount = () => {
    this.getFavorites();
  }

  getFavorites = () => {
    this.props.logoAnimation('kodo');
    GetFavorites(this.state.twitter_id).then( (p) => {
      var tweets = p.tweets;
      let tweetsCount;
      if (this.state.tweetsFilter == 'default') {
        tweetsCount = tweets.length;
      } else if (this.state.tweetsFilter == 'mediaOnly') {
        tweetsCount = tweets.filter( tweet => tweet.extended_entities ).length;
      } else {
        tweetsCount = tweets.filter( tweet => tweet.extended_entities != undefined ).length;
      }
      this.setState({ tweets:tweets, tweetsCount:tweetsCount, getTweets:true, });
      this.props.logoAnimation('');
    });
  }

  render() {


    let tweetsCountBlock;
    let tweetsBlock;
    if (this.state.getTweets) {
      tweetsCountBlock = (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>POSTS: {this.state.tweetsCount}</p>
        </Block>
      );
      tweetsBlock = ( <Tweets tweets={this.state.tweets} tweetsCount={this.state.tweetsCount} tweetsFilter={this.state.tweetsFilter} full={this.props.full} one={this.props.one} two={this.props.two} /> );
    } else {
      tweetsCountBlock = ( <span></span> );
      tweetsBlock = (
        <Block searching full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>FAVORITES SEARCHING...</p>
        </Block>
      );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <Helmet title={'TwitterFavorites | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {tweetsCountBlock}
        {tweetsBlock}
      </div>
    );

  }

}
export default TwitterFavorites;
