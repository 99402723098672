import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { QaFormStyle, QaFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/QaForm';

// function
import Polly from 'component/common/function/Polly';
import GetTodayLearnings from 'component/common/function/GetTodayLearnings';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import DeleteDistributedLearning from 'component/common/function/DeleteDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';

// module
import LearningTimer from 'component/common/module/learning/LearningTimer';


class TodayQa extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      type:'',
      learnings:[],
      selectLearningIndex:0,
      showAnswer:false,
      speeching:false,
      congratulation:false,
      showTimer:true,
      timerStatus:"stand-by",
    };
  }

  componentDidMount = () => {
    let type = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      type = pathItems[1].replace('today_distributed_learning_qa_','');
    } else {
      type = pathItems[0].replace('today_distributed_learning_qa_','');
    }
    this.setState({ type:type, });
    this.getLearnings(type);
  }

  getLearnings = (type) => {
    console.log('getLearnings');

    GetTodayLearnings(type).then((learnings) => {
      if (0 < learnings.length) {

        for (let i=0; i<learnings.length; i++) {
          learnings[i].timerCnt = 0;
        }

        this.setState({ learnings:learnings, }, () => {
          TextHeightAdjustment('question');
          TextHeightAdjustment('answer');
        });
      } else {
        this.setState({ congratulation:true, });
      }
    });

  }

  showAnswer = () => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    if (this.state.showAnswer == false) {
      document.activeElement.blur();
    }
    this.setState({ showAnswer:true, }, () => {
      TextHeightAdjustment('answer');
    });
  }

  clickLearningBtn = (correct) => {
    console.log('clickLearningBtn');
    //console.log(correct);
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];

    // 正解、不正解の場合に応じてselectLearningIndexを調整。
    let selectLearningIndex = this.state.selectLearningIndex;
    if (correct) {
      // learningsから正解した問題を除外。除外した問題のところに次の問題がシフトするのでselectLearningIndexはそのまま。
      learnings.splice(selectLearningIndex,1);
    } else {
      selectLearningIndex += 1;
      learnings[this.state.selectLearningIndex].span *= 0.5;
      if (learnings[this.state.selectLearningIndex].span < 1) {
        learnings[this.state.selectLearningIndex].span = 0;
      }
    }

    // learningsの最後尾まで回答したら先頭にもどる
    if (learnings.length < selectLearningIndex + 1) {
      selectLearningIndex = 0;
    }

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation;
    if (0 < learnings.length) {
      congratulation = false;
    } else {
      congratulation = true;
    }

    // timerCnt(秒数)を分数に変換し、timesに加える
    let min = Math.floor(learning.timerCnt/60); // 分
    let sec = learning.timerCnt % 60; // 秒
    if (30 < sec) min++; // 秒部分で30秒以上経過しているのであれば1分プラスする
    (!learning.times) ? learning.times = String(min) : learning.times += "," + String(min);
    console.log("learning.times: " + learning.times);

    this.setState({ congratulation:congratulation, selectLearningIndex:selectLearningIndex, showAnswer:false, learnings:learnings, showTimer:false }, () => {
      TextHeightAdjustment('question');
      TextHeightAdjustment('answer');
      document.getElementById('qa-style').scrollTop = 0;
      this.setState({ showTimer:true }); // タイマーを再マウント
    });

    SaveDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo, learning.times, correct).then((saved) => {
    });

  }

  speech = () => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    var params = { speech_text:this.state.learnings[this.state.selectLearningIndex].question, from_lang:'en', };
    Polly(params).then((status) => {
      if (status == 'success') {
        let speech_path = 'https://s3-ap-northeast-1.amazonaws.com/media.takuman.me/static/speech.mp3';
        // 同一のurlの場合、キャッシュが効いて新しい音声が再生されないので、毎回乱数が挿入されるようにしてキャッシュを回避する。
        this.speeching = new Audio(speech_path + '?noCache=' + Math.floor(Math.random() * 1000000));
        this.setState({ speeching:true, });
        this.speeching.play();  // 再生

        // 読み上げが終了したらspeechingフラグを下ろす。
        this.speeching.onended = () => {
          this.setState({ speeching:false, });
        }
      }
    });

  }

  shutUp = () => {
    this.setState({ speeching:false, });
    this.speeching.pause(); // 一時停止
  }

  changeAnswer = (event) => {
    if (this.state.timerStatus != "count") return; // タイマー起動中でないのであれば処理を実行しない

    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    learnings[this.state.selectLearningIndex].answer = event.target.value;
    this.setState({ learnings:learnings, }, () => {
      TextHeightAdjustment('answer');
    });
  }

  deleteItem = () => {
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = this.state.learnings[this.state.selectLearningIndex];

    // 削除する問題をlearnings配列から除外
    let selectLearningIndex = this.state.selectLearningIndex;
    learnings.splice(selectLearningIndex,1);

    // learningsの最後尾まで回答したら先頭にもどる
    if (learnings.length < selectLearningIndex + 1) {
      selectLearningIndex = 0;
    }

    // learningsの個数が0になったら全問正解したということなのでcongratulationをtrueにする
    let congratulation;
    if (0 < learnings.length) {
      congratulation = false;
    } else {
      congratulation = true;
    }

    this.setState({ congratulation:congratulation, selectLearningIndex:selectLearningIndex, learnings:learnings, showAnswer:false, }, () => {
      TextHeightAdjustment('question');
      TextHeightAdjustment('answer');
    });

    DeleteDistributedLearning(learning.type, learning.question, learning.answer, learning.musiqui).then(() => {
    });
  }

  getTimerCnt = (timerCnt) => {
    // 現在出題されている問題を取得
    let learnings = JSON.parse(JSON.stringify(this.state.learnings));
    let learning = learnings[this.state.selectLearningIndex];

    // timerCntを更新
    learning.timerCnt = timerCnt;
    this.setState({ learnings:learnings });
  }

  getTimerStatus = (timerStatus) => {
    this.setState({ timerStatus:timerStatus });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let speech;
    if (this.state.speeching) {
      speech = (
        <div className='speech'>
          <i className='fas fa-stop' onClick={ () => {this.shutUp()} }></i>
        </div>
      );
    } else {
      speech = (
        <div className='speech'>
          <i className='fas fa-volume-up' onClick={ () => {this.speech()} }></i>
        </div>
      );
    }

    let span;
    if (0 < this.state.learnings.length) {
      span = this.state.learnings[this.state.selectLearningIndex].span;
    }

    let question;
    if (0 < this.state.learnings.length) {
      question = (
        <textarea type='text' id='question' value={this.state.learnings[this.state.selectLearningIndex].question} placeholder='question' readonly='readonly' />
      );
    }

    let answer;
    if (0 < this.state.learnings.length) {
      answer = (
        <textarea type='text' id='answer' placeholder='answer' readonly='readonly'
           value={this.state.showAnswer ? this.state.learnings[this.state.selectLearningIndex].answer : '**********'}
           onClick={ () => {this.showAnswer()} }
           onChange={ (event) => {this.changeAnswer(event)} } />
      );
    }


    let deleteBtn;
    if (this.state.showAnswer) {
      deleteBtn = ( <div className='delete'><i className='fas fa-trash-alt' onClick={ () => {this.deleteItem()} }></i></div> );
    } else {
      deleteBtn = ( <span></span> );
    }

    let correctBtn;
    let forgetBtn;
    if (this.state.timerStatus == "count") {
      if (this.state.showAnswer) {
        correctBtn = ( <div className='correct-btn' onClick={ () => {this.clickLearningBtn(true)} }>correct</div> );
        forgetBtn = ( <div className='forget-btn' onClick={ () => {this.clickLearningBtn(false)} }>forget</div> );
      } else {
        correctBtn = ( <span></span> );
        forgetBtn = ( <span></span> );
      }
    }


    let translateField;
    if (this.state.congratulation) {
      let imgs = [
                   'https://c.tenor.com/iNCbn635V04AAAAC/despicable-me2-minions.gif',
                   'https://media4.giphy.com/media/l1ugg8MSaCUpzyyyY/giphy.gif',
                   'https://thumbs.gfycat.com/EsteemedFloweryConch-max-1mb.gif',
                   'https://media0.giphy.com/media/5ULFBOHCMDiN4fUn2w/giphy.gif',
                   'https://media1.giphy.com/media/RMGHCIEkaey08FtcnJ/giphy-downsized-large.gif',
                   'https://media0.giphy.com/media/l4FGoHktFE5InlFEA/giphy.gif',
                   'http://24.media.tumblr.com/tumblr_mdj2jugP2B1ro9mijo1_500.gif',
                   'https://thumbs.gfycat.com/DecisiveMaleGoose-size_restricted.gif',
                   'https://i.pinimg.com/originals/d5/fc/51/d5fc51afb02bceb1de29e6b0eaebf3ac.gif',
                   'https://c.tenor.com/bQrjUlPjAnsAAAAC/despicable-me-minions.gif',
                   'https://i.makeagif.com/media/11-14-2015/Sy68Pp.gif',
                 ];
      translateField = (
        <QaFormField>
          <h1 className='title'>{this.state.type}</h1>
          <img className='congratulation' src={imgs[Math.floor(Math.random() * imgs.length)]} />
        </QaFormField>
      );
    } else {

      let selectType;
      if (0 < this.state.learnings.length) {
        selectType = (
          <div className='select'>
            <div className='select-item'>{this.state.type}</div>
          </div>
        );
      }

      let progressField;
      if (0 < this.state.learnings.length) {
        progressField = ( <div className='progress-field'>{this.state.selectLearningIndex + 1} / {this.state.learnings.length}</div> );
      }

      let timesField;
      if (0 < this.state.learnings.length) {
        let times = this.state.learnings[this.state.selectLearningIndex].times;
        timesField = (
          <div className="read-time-field">
            {(times) ? times.split(",")[times.split(",").length-1] + "min" : "?min"}
          </div>
        );
      }


      translateField = (
        <QaFormField id="qa-form-field">
          {/*
          <h1 className='title'>{this.state.type}</h1>
          <p>progress: {this.state.selectLearningIndex + 1} / {this.state.learnings.length}</p>
          */}
          <TypeField>
            {selectType}
            {progressField}
            {timesField}
          </TypeField>
          <TextField learning>
            <div className='title'>[question]</div>
            {speech}
            {question}
          </TextField>
          <TextField learning>
            <div className='title'>[answer] span:{span}</div>
            {deleteBtn}
            {answer}
            {forgetBtn}
            {correctBtn}
          </TextField>
        </QaFormField>
      );
    }

    let timer;
    if (0 < this.state.learnings.length && this.state.showTimer) {
      timer = ( <LearningTimer getTimerCnt={this.getTimerCnt} getTimerStatus={this.getTimerStatus} learningId="qa-form-field" /> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Q&A | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <QaFormStyle id="qa-style" onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
          {timer}
        </QaFormStyle>
      </div>
    );
  }

}

export default TodayQa;
