import axios from 'axios';
  
export default function GetFavorites(twitterId) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` }, timeout:1000 * 60 * 60 }
    axios.get('https://lab.takuman.me/api/oauth/twitter/' + twitterId + '/favorites/', headers)
    .then(res => {
      let tweets = JSON.parse(res.data);
      console.log(tweets);
      resolve({ 'tweets':tweets, });
    })
    .catch(err => {
      reject();
    });

  }).then((p) => {
    let tweets = p.tweets;
    return new Promise( (resolve, reject) => {
      let created_at;
      for (var i=0; i<tweets.length; i++) {
        // メディアファイルのURLは不要なので取り除く
        tweets[i].text = tweets[i].text.replace(/(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#]+)/gi, '');
        created_at = new Date(tweets[i].created_at);
        tweets[i].created_at = created_at.getFullYear() + '/' + (created_at.getMonth() + 1) + '/' + created_at.getDate() + ' ' + created_at.getHours() + ':' + created_at.getMinutes();

        // 動画が含まれているのであれば一番高画質な動画のurlをvideo_urlに挿入
        if (tweets[i].extended_entities) {
          for (var i2=0; i2<tweets[i].extended_entities.media.length; i2++) {
            if (tweets[i].extended_entities.media[i2].type == 'video') {
              let videos = tweets[i].extended_entities.media[i2].video_info.variants.filter( (variant) => variant.bitrate != undefined);
              videos.sort(function(a,b){
                if(a.bitrate < b.bitrate) return 1;
                if(a.bitrate > b.bitrate) return -1;
                return 0;
              });
              tweets[i].extended_entities.media[i2].video_url = videos[0].url;
            }
          }
        }

      }
      resolve({ 'tweets':tweets, });
    });
  }).then((p) => {
    let tweets = p.tweets;
    return new Promise( (resolve, reject) => {
      resolve({ 'tweets':tweets, });
    });

  }).catch(() => {
    // エラー発生
    return new Promise( (resolve, reject) => {
      resolve({ 'tweets':[], });
    });
  });

}
