import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/Form';
import { ShakyoField } from 'component/common/style/learning/DistributedLearning';

// function
import GetLearning from 'component/common/function/GetLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import UpdateDistributedLearning from 'component/common/function/UpdateDistributedLearning';
import ShakyoMatchCheck from 'component/common/function/ShakyoMatchCheck';
import ClickShakyoShadow from 'component/common/function/ClickShakyoShadow';


class UpdateShakyo extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      hash:'',
      learning:[],
      status:false,
    };
  }

  componentDidMount = () => {
    let hash = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      hash = pathItems[1].replace('distributed_learning_shakyo_update_','');
    } else {
      hash = pathItems[0].replace('distributed_learning_shakyo_update_','');
    }
    this.setState({ hash:hash, });
    this.getLearning(hash);
  }

  componentWillUpdate = () => { document.body.addEventListener('keydown', this.keydownTab); }
  componentWillUnmount = () => { document.body.removeEventListener('keydown', this.keydownTab); }

  keydownTab = (event) => {
    // #from-textか#shakyo-frontにfocusされていて、Tabキーが入力されたらタブ文字を挿入する
    if ((document.getElementById('from-text') == document.activeElement && event.code == 'Tab') ||
        (document.getElementById('shakyo-front') == document.activeElement && event.code == 'Tab')) {
      event.preventDefault();
      var elem = event.target;
      var val = elem.value;
      var pos = elem.selectionStart;
      elem.value = val.substr(0, pos) + '\t' + val.substr(pos, val.length);
      elem.setSelectionRange(pos + 1, pos + 1);
    }
  }

  getLearning = (hash) => {
    GetLearning(hash).then((learning) => {
      if (learning) {
        this.setState({ learning:learning, }, () => {
          TextHeightAdjustment('from-text');
          this.shakyoMatchCheck();
        });
      }
    });
  }

  spanChange = (span) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.span = span;
    this.setState({ learning:learning, });
  }

  changeTimes = (event) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.times = event.target.value.replace(/[^0-9,]/gi, "").replace(",,", ","); // 整数値とカンマ以外の文字は削除。カンマが連続する場合は１つにする。
    this.setState({ learning:learning });
  }

  changeShakyo = (event) => {
    let learning = JSON.parse(JSON.stringify(this.state.learning));
    learning.shakyo = event.target.value;
    this.setState({ learning:learning, }, () => {
      TextHeightAdjustment('from-text');
      if (document.getElementById('shakyo-front')) {
        document.getElementById('shakyo-front').value = '';
      }
      ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
        let status = res.status;
        this.setState({ status:status, });
      });
    });
  }

  updateDistributedLearning = () => {
    let learning = this.state.learning;
    if (learning.shakyo != '') {
      UpdateDistributedLearning(learning.hash, learning.type, learning.question, learning.answer, learning.musiqui, learning.shakyo, learning.span, learning.times).then((saved) => {
        if (saved) {
          this.props.handleFlashMessage('UPDATED');

          // SHAKYOの詳細画面にリダイレクト
          let pathItems = window.location.pathname.slice(1).split('/');
          if (this.props.full) {
            History.push('/distributed_learning_shakyo_' + this.state.learning.hash);
          } else if (this.props.one) {
            History.push('/distributed_learning_shakyo_' + this.state.learning.hash + '/' + pathItems[1]);
          } else if (this.props.two) {
            History.push('/' + pathItems[0] + '/distributed_learning_shakyo_' + this.state.learning.hash);
          }

        } else {
          this.props.handleFlashMessage('ERROR');
        }
      });
    }
  }

  clickShakyoShadow = (event) => {
    ClickShakyoShadow(event, 'shakyo-front', 'shakyo-shadow');
  }

  shakyoMatchCheck = () => {
    ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
      let status = res.status;
      this.setState({ status:status, });
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectType;
    if (this.state.learning) {
      selectType = (
        <div className='select'>
          <div className='select-item'>type: {this.state.learning.type}</div>
        </div>
      );
    }

    let selectSpanField;
    if (this.state.learning) {
      let spanArray = [0, 1, 2, 4, 8, 16, 32, 64, 128];
      selectSpanField = (
        <div className='select-span-field'>
          {spanArray.map( (span,index) => {
            return ( <div className={'span' + (this.state.learning.span == span ? ' select-span' : '') + (index == 0 ? ' first-span' : index == spanArray.length - 1 ? ' last-span' : '')} onClick={ (event) => {this.spanChange(span)} }>{span}</div> );


          })}
        </div>
      );
    }

    let timesField;
    if (this.state.learning) {
      timesField = ( <div className="times-field"><input type="text" id="times" name="times" value={this.state.learning.times} onChange={ (event) => {this.changeTimes(event)} } /></div> );
    }

    let saveBtnField;
    if (this.state.learning) {
      if (this.state.learning.shakyo) {
      if (0 < this.state.learning.shakyo.length) {
        saveBtnField = (
            <LinkBtnField>
              <LinkBtn submit onClick={ () => {this.updateDistributedLearning()} }>UPDATE SHAKYO</LinkBtn>
            </LinkBtnField>
        );
      } else {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn>UPDATE SHAKYO</LinkBtn>
          </LinkBtnField>
        );
      }
      }
    }

    let shakyo;
    if (this.state.learning) {
      shakyo = (
        <ShakyoField id='shakyo' status={this.state.status}>
          <textarea type='text' id='shakyo-front' onSelect={ () => {this.shakyoMatchCheck()} } onChange={ () => {this.shakyoMatchCheck()} } onBlur={ () => {this.shakyoMatchCheck()} } />
          <textarea type='text' id='shakyo-back' value={this.state.learning.shakyo} readonly />
          <div id='shakyo-highlight'></div>
          <div id='shakyo-shadow' onClick={ (event) => {this.clickShakyoShadow(event)} }></div>
        </ShakyoField>
      );
    }

    let translateField = (
      <DistributedLearningFormField>
        <TypeField>
          {selectType}
          {selectSpanField}
          {timesField}
        </TypeField>
        <div>
          <TextField>
            <textarea type='text' id='from-text' onChange={ (event) => {this.changeShakyo(event)} } value={this.state.learning.shakyo} placeholder='shakyo' />
          </TextField>
          <TextField>
            {shakyo}
          </TextField>
        </div>
        <div>
        {saveBtnField}
        </div>
      </DistributedLearningFormField>
    );




    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Update Shakyo | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learning_shakyo_' + this.state.learning.hash : this.props.one ? '/distributed_learning_shakyo_' + this.state.learning.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_shakyo_' + this.state.learning.hash}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningFormStyle id='translate-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
        </DistributedLearningFormStyle>
      </div>
    );
  }

}

export default UpdateShakyo;
