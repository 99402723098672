import styled from 'styled-components'
  
let white = '#c8c8c8';
//let white = '#FFFFFF';
//let black = '#2b2b2b';
let black = '#151515';
let red = '#d7003a'; // 紅色(くれないいろ) 
let blue = '#2ca9e1'; // 天色(あまいろ)
let green = '#38b48b'; // 翡翠色(ひすいいろ)
//let yellow = '#d0af4c'; // 芥子色(からしいろ)
let yellow = '#f8b500';

let cyan = '#00FFFF';
let magenta = '#FF00FF';
let mainColor = white; // 主に文字色に使われる
let subColor = black; // 主に背景色に使われる
let edgeColor = cyan; // 主にロゴや選択項目など、目立たせたいものに差し色として使われる

//let linkColor = '#2ca9e1'; // リンクの文字色に使われる
let linkColor = edgeColor; // リンクの文字色に使われる
let shadowColor = '#000000'; // 影の色に使われる
//let shadowColor = '#16160e'; // 影の色に使われる
//let shadowColor = edgeColor; // 影の色に使われる
let errorColor = red; // エラー時の色に使われる
let attentionColor = red; // 注意喚起時の色に使われる
let correctColor = green; // 処理完了時の色に使われる

export {
  white, black, red, blue, green, yellow, cyan, magenta,
  mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, attentionColor, correctColor
};
