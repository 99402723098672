import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { white, mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, correctColor } from 'component/common/style/Colors';
import { move } from 'component/common/style/Animations';

const MenuStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:500; 
    width:calc(100% + 3px); height:calc(100% + 200px); margin:auto; padding:calc(100px + 70px) 0; overflow:scroll;
    ${props => props.show ? move(0,0,-100,0,0.1) : move(0,0,0,-100,0.1)}

    &::after {
     content:''; position:absolute; top:0; bottom:0; left:0; right:0; z-index:-1; background:${rgba(edgeColor,0.2)}; backdrop-filter:blur(2px);
    }
  }
`

const Displays = styled.div`
  &&& {
    width:600px; height:auto; margin:auto; padding:20px; background:${subColor}; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
    h1 { color:${mainColor}; font-size:22px; margin-bottom:20px; }
    .path-name, .close-btn, .add-btn { color:${mainColor}; font-size:18px; }
    .close-btn, .add-btn { color:${mainColor}; cursor:pointer; }
    .close-btn { color:${rgba(mainColor,0.4)}; display:inline-block; }
    .close-btn:hover { color:${attentionColor}; }
    .add-btn { border:2px solid ${mainColor}; border-radius:2px; text-align:center; margin-top:20px; display:block; }
    .add-btn:hover { color:${correctColor}; border:2px solid ${correctColor}; }
  }
`

export { MenuStyle, Displays };
