import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const HomeStyle = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:40px 0;
  }
`

const Blocks = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '40'}vw; }
  }
`

const Block = styled.div`
  &&& {
    width:16vw; height:16vw; display:inline-block; float:left; overflow:hidden;
    &:hover { ${borderFlash(2)} }
  }
`

const BlockContent = styled(Link)`
  &&& {
    width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; padding:0; background:${subColor}; display:block;
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    .item-name-wrapper { width:100%; height:100%; padding:10px; overflow:hidden;
      .icon { width:100%; height:75%; margin:0; text-align:center; display:table;
        i { color:${rgba(subColor,0.5)}; font-size:80px; display:table-cell; vertical-align:middle; }
      }
      .item-name { width:100%; height:25%; color:${rgba(subColor,0.5)}; font-size:18px; font-weight:900; text-align:center; line-height:18px; overflow:hidden; }
    }
  }
`

export { HomeStyle, Blocks, Block, BlockContent };
