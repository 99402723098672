import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { white, black, red, blue, green, yellow, mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

const LayeredNoteStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:300;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};

    h1, h2, h3, h4, p { color:${mainColor}; clear:both; } 
    h1 { text-align:center; font-weight:900; } 
    h2 { margin-top:40px; font-weight:900; } 
    h3 { margin-top:30px; font-weight:900; } 
    h4 { margin-top:20px; font-weight:900; } 

    .note-title { display:block; width:90%; height:auto; margin:auto; padding:auto; color:${mainColor}; text-align:center; font-size:27px; font-weight:300; 
                  border:none; outline:0; resize:none; overflow:hidden; clear:both; background:${subColor}; } 
    .note-title:-webkit-autofill { animation-name:onAutoFillStar; transition:background-color 50000s ease-in-out 0s; -webkit-text-fill-color: ${mainColor} !important; } 
    .note-title:focus { border-bottom:3px solid $red; } 
    .report-title { cursor:default; caret-color:transparent; } 
    .report-title:-webkit-autofill { animation-name:onAutoFillStar; transition:background-color 50000s ease-in-out 0s; -webkit-text-fill-color: ${mainColor} !important; } 
    .report-title:focus { border:none; } 
    .note-description { color:${mainColor}; font-size:15px; margin:20px auto 20px auto; } 
    .note-about { color:${mainColor}; font-size:15px; margin:20px auto 50px auto; 
      .about-title { text-align:center; display:inline; cursor:pointer; -moz-user-select:none; -webkit-user-select:none; -ms-user-select:none; } 
    } 

    @media screen and (min-width:750px) {
      .note-title { width:${(props) => props.full ? '80%' : '100%'}; }
    }

    @media screen and (min-width:960px) {
      .note-title { width:${(props) => props.full ? '60%' : '100%'}; }
    }

  }
`

const NoteHeader = styled.div`
  &&& {
    width:100%; height:50px; position:relative; 
    .btn { width:40px; height:40px; display:table; text-align:center; color:${rgba(mainColor,0.3)}; font-size:20px; cursor:pointer; position:absolute; top:0;  
       i { display:table-cell; vertical-align:middle; } 
     } 
     .btn:hover { color:${rgba(mainColor,1)}; ${borderFlash(2)} } 
    .left-btn { left:0; } 
    .right-btn { right:0; } 
    .right-btn-menu { position:absolute; top:40px; right:0; width:auto; height:auto; margin:0; padding:0;  
                      border-radius:5px; z-index:200; 
      .menu { width:auto; height:40px; overflow:hidden; clear:both;  
        .content { display:inline-block; width:130px; height:40px; padding:8px; border-radius:5px 0 0 5px; text-align:center; font-size:15px; cursor:pointer; } 
        .remove-content { color:${rgba(attentionColor,0.8)}; float:left; background:${mainColor}; } 
        .remove-content:hover { background:${rgba(attentionColor,0.8)}; color:${subColor}; } 
        .icon { display:inline-block; width:40px; height:40px; padding:8px; text-align:center; font-size:15px; cursor:pointer; background:${mainColor}; } 
        .trash-icon { color:${rgba(attentionColor,0.8)}; border-radius:0 0px 0px 0; float:right; } 
        .trash-icon:hover { color:${attentionColor}; } 
        .cancel-icon { color:${rgba(subColor,0.5)}; } 
        .cancel-icon:hover { color:${rgba(subColor,7)}; } 
      } 
    } 
    .right-btn-menu-wrapper { position:fixed; top:0; bottom:0; left:0; right:0; z-index:150; width:120%; height:120%; margin:auto; } 
  }
`

const getItemBackground = (props) => {
  if (props) {
    if (props.background && props.opacity) {
      return lighten(props.background,props.opacity);
    } else if (props.background) {
      return props.background
    } else {
      return 'none'
    }
  } else {
    return 'none'
  }
}


const Item = styled.div`
  &&& {
    width:100%; height:auto; padding:0; position:relative; overflow:hidden; box-shadow:0 0 6px ${shadowColor};
    margin-top:${(props) => props.first ? '20px' : '0px'}; margin-right:auto; margin-bottom:${(props) => props.last ? '20px' : '0px'}; margin-left:auto;
    border-radius:${(props) => props.first && props.last ? '5px' : props.first ? '5px 5px 0px 0px' : props.last ? '0px 0px 5px 5px' : '0px'};

    &::before {
     content:''; position:absolute; top:0; bottom:0; left:0; right:0; background:${(props) => getItemBackground(props)};
    }

    .item-element { position:relative; 
      .layer-percentage {
        width:30%; height:15px; margin:0; padding:0; background:none; 
        border-bottom:2px solid ${(props) => props.mainColor ? props.mainColor : mainColor};
      } 
      .item-field {
        width:100%; height:40px; margin:auto; padding:3px 10px; background:none; font-size:18px; border:none;
        color:${(props) => props.mainColor ? props.mainColor : mainColor};
      } 
      .item-field:-webkit-autofill { animation-name:onAutoFillStar; transition:background-color 50000s ease-in-out 0s; -webkit-text-fill-color: ${mainColor} !important; } 
      .item-field:focus { outline:0; } 
      .show-description[contenteditable=true] {
        width:100%; margin:auto; padding:5px 10px; font-size:15px; line-height:18px;
        opacity:1; display:block; outline:0; border:none; background:none; resize:none;
        color:${(props) => props.mainColor ? props.mainColor : mainColor};
        span { background:none; }
        .select-text { color:${(props) => props.subColor ? props.subColor : subColor}; background:${(props) => props.mainColor ? rgba(props.mainColor,0.8) : rgba(mainColor,0.8)}; }
        a { color:${linkColor}; border-bottom:2px solid ${linkColor}; }
      }
      .show-description[contenteditable=true]::selection, .show-description[contenteditable=true] div::selection, .show-description[contenteditable=true] span::selection {
        color:${(props) => props.subColor ? props.subColor : subColor}; background:${(props) => props.mainColor ? props.mainColor : mainColor};
      }
      .show-description[contenteditable=true] a::selection {
	color:${(props) => props.mainColor ? props.mainColor : mainColor}; background:${edgeColor};
      }
      .show-description[contenteditable=true]:empty:before {
        content:attr(placeholder); pointer-events:none; padding:5px; opacity:0.4;
      }
      .hide-description[contenteditable=true] {
        width:0px; height:0px; margin:0; padding:0; opacity:0; display:inline; border:none; background:none; resize:none;
      }
      .description-toggle { width:100%; height:auto; margin:0px auto 0 auto; padding:3px 10px;
                            color:${(props) => props.mainColor ? rgba(props.mainColor,0.5) : rgba(mainColor,0.5)}; text-align:center; font-size:10px;
                            background:${(props) => props.mainColor ? rgba(props.mainColor,0.00) : rgba(mainColor,0.00)}; cursor:pointer; }
      .description-toggle.hide { margin-top:0; }
      .description-toggle.show { margin-top:-13px; }
      .description-toggle:hover { color:${(props) => props.mainColor ? rgba(props.mainColor,1) : rgba(mainColor,1)};
                                  background:${(props) => props.mainColor ? rgba(props.mainColor,0.03) : rgba(mainColor,0.03)}; }
      .add-item-btn { width:auto; height:auto; margin:20px 10px; padding:2px 10px; border-radius:10000px; display:inline-block; text-align:center; font-size:12px; cursor:pointer;
                      color:${(props) => getItemBackground(props)}; filter:invert(100%) grayscale(100%) contrast(100); opacity:0.4;
      }
      .add-item-btn:hover { opacity:0.8; }
      .submit-btn { width:auto; height:auto; margin:20px 10px; padding:2px 10px; font-size:12px; border-radius:10000px; display:inline-block; cursor:pointer; float:right; 
                    color:${(props) => getItemBackground(props)}; filter:invert(100%) grayscale(100%) contrast(100); opacity:0.4;
      }
      .submit-btn:hover { opacity:0.8; }
      .item-option { width:auto; height:auto; margin:10px 10px; padding:2px 10px; display:inline-block; float:right;  
        .btn { margin:0 5px; padding:0px 0px; font-size:18px; display:inline-block; border-radius:10000px; float:left; cursor:pointer;
          i { width:100%; height:100%; padding:5px 8px; color:${(props) => getItemBackground(props)}; filter:invert(100%) grayscale(100%) contrast(100) opacity(60%); } 
        } 
        .drip-btn { position:relative; 
          .palette-pop { width:200px; height:50px; position:absolute; top:-50px; right:-40px; background:${(props) => getItemBackground(props)};
                         border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow-x:scroll; overflow-y:hidden; white-space:nowrap;
            .palette-item { width:30px; height:30px; margin:10px 5px; border-radius:10000px; box-shadow:0 0 1px ${shadowColor}; display:inline-block; white-space:normal; position:relative;
              .sub-color, .main-color { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; border-radius:10000px; }
              .sub-color { width:100%; height:100%; }
              .main-color { width:30%; height:30%; }
            }
            .palette-item:hover { opacity:0.9; box-shadow:0 0 2px ${edgeColor}; }
            .white { background:${white}; border:0.5px solid ${rgba(mainColor,0.5)}; } 
            .black { background:${black}; border:0.5px solid ${rgba(mainColor,0.5)}; } 
            .blue { background:${blue}; } 
            .green { background:${green}; } 
            .red { background:${red}; } 
            .yellow { background:${yellow}; } 
          }
        }
        .remove-btn { position:relative; 
          .remove-item-confirm-pop { width:150px; height:auto; padding:0px; position:absolute; bottom:40px; right:-10px; background:${attentionColor}; border-radius:5px; 
                                     box-shadow:0 0 6px #16160e; color:${subColor}; text-align:center; font-size:15px; 
            .remove-btn { height:40px; padding:8px; } 
            .remove-btn:hover { opacity:0.85; } 
            .one-remove { border-bottom:1px solid ${subColor}; } 
          }
        } 
        .order-btn:hover, .drip-btn:hover { background:${rgba(mainColor,0.1)}; } 
        .remove-btn:hover { background:${rgba(mainColor,0.1)}; } 
      } 
    } 


    @media screen and (min-width:750px) {
      width:${(props) => props.full ? '80%' : '100%'};
    }

    @media screen and (min-width:960px) {
      width:${(props) => props.full ? '60%' : '100%'};
    }

  }
`

const AddBtn = styled(Item)`
  &&& {
    margin:auto; padding:10px; border-radius:5px; background:${rgba(mainColor,0.4)}; block:display;
    color:${subColor}; text-align:center; font-size:12px; cursor:pointer;
    &:hover { background:${rgba(mainColor,0.6)}; }
  }
`

const OrderSelect = styled(Item)`
  &&& {
    background:none; box-shadow:none; border:1px dashed ${mainColor}; text-align:center; 
    input { cursor:pointer; text-align:center; color:${rgba(mainColor,0.6)}; } 

    @media screen and (min-width:750px) {
      width:80%;
    }

    @media screen and (min-width:960px) {
      width:60%;
    }
  }
`

const SelectTextMenu = styled.div`
  &&& {
    position:absolute; z-index:100;
    top:${(props) => props.top ? props.top : 'auto'}; left:${(props) => props.left ? props.left : 'auto'}; right:${(props) => props.right ? props.right : 'auto'};
    width:auto; height:40px; margin:0; padding:5px; background:${subColor}; border-radius:5px; box-shadow:0 0 6px #16160e;
    .menu-item { width:30px; height:30px; text-align:center; display:table; cursor:pointer;
      i { color:${mainColor}; font-size:30px; display:table-cell; vertical-align:middle; }
    }
    .menu-item:hover { background:${rgba(mainColor,0.1)}; }

    input[type='text'] { width:240px; height:30px; margin:0; padding:0 10px; background:${mainColor}; font-size:12px; color:${subColor};
                         border:none; border-radius:2px; display:inline-block; }
    input[type='text']:focus { border:1px solid ${edgeColor}; outline:0; }
    input[type='text']:focus + .search-btn { background:${rgba(mainColor,1.0)}; }

    .btn { width:60px; height:30px; margin:0; padding:5px; background:${subColor}; color:${edgeColor}; text-align:center; font-size:10px; border:none; display:inline-block; cursor:pointer;  }
    .apply { color:${linkColor}; }
    .remove { color:${attentionColor}; }
  }
`


export { LayeredNoteStyle, NoteHeader, Item, AddBtn, OrderSelect, SelectTextMenu };
