import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningsStyle, Block, BlockName, LinkBtnField, LinkBtn, } from 'component/common/style/learning/Top';

// function
import GetDistributedLearningTypes from 'component/common/function/GetDistributedLearningTypes';

// module
import LearningTotalDataBlock from 'component/common/module/learning/LearningTotalDataBlock';
import TodayLearning from 'component/common/module/learning/TodayLearning';
import LearningTypeBlock from 'component/common/module/learning/LearningTypeBlock';


class DistributedLearnings extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      totalData:[],
      distributedLearningQaTypes:[],
      distributedLearningMusiquiTypes:[],
      distributedLearningShakyoTypes:[],
    };
  }

  componentDidMount = () => {
    this.getDistributedLearningTypes();
  }

  getDistributedLearningTypes = () => {
    GetDistributedLearningTypes('').then((res) => {
      let totalData = {"totalEstimateReadTime":0, "qaTotalEstimateReadTime":0, "musiquiTotalEstimateReadTime":0, "shakyoTotalEstimateReadTime":0, "totalTodayEstimateReadTime":0, "qaTodayEstimateReadTime":0, "misuqiuTodayEstimateReadTime":0, "shakyoTodayEstimateReadTime":0};

      let qaTypes = res.qaTypes;
      if (0 < qaTypes.length) {
        let timeData = this.getTotalEstimateReadTime(qaTypes);
        totalData.qaTotalEstimateReadTime = timeData.totalEstimateReadTime;
        totalData.qaTodayEstimateReadTime = timeData.todayEstimateReadTime;
        this.setState({ distributedLearningQaTypes:qaTypes });
      }

      let musiquiTypes = res.musiquiTypes;
      if (0 < musiquiTypes.length) {
        let timeData = this.getTotalEstimateReadTime(musiquiTypes);
        totalData.musiquiTotalEstimateReadTime = timeData.totalEstimateReadTime;
        totalData.musiquiTodayEstimateReadTime = timeData.todayEstimateReadTime;
        this.setState({ distributedLearningMusiquiTypes:musiquiTypes });
      }

      let shakyoTypes = res.shakyoTypes;
      if (0 < shakyoTypes.length) {
        let timeData = this.getTotalEstimateReadTime(shakyoTypes);
        totalData.shakyoTotalEstimateReadTime = timeData.totalEstimateReadTime;
        totalData.shakyoTodayEstimateReadTime = timeData.todayEstimateReadTime;
        this.setState({ distributedLearningShakyoTypes:shakyoTypes });
      }

      totalData.totalEstimateReadTime = totalData.qaTotalEstimateReadTime + totalData.musiquiTotalEstimateReadTime + totalData.shakyoTotalEstimateReadTime;
      totalData.totalTodayEstimateReadTime = totalData.qaTodayEstimateReadTime + totalData.musiquiTodayEstimateReadTime + totalData.shakyoTodayEstimateReadTime;
      console.log("totalData");
      console.log(totalData);

      this.setState({ totalData: totalData });

    });
  }

  getTotalEstimateReadTime = (typesData) => {
    let timeData = {"totalEstimateReadTime":0, "todayEstimateReadTime":0};
    for (var i=0; i<typesData.length; i++) {
      if (typesData[i].name.includes("basic-")) {
        timeData.totalEstimateReadTime += typesData[i].totalEstimateReadTime;
        timeData.todayEstimateReadTime += typesData[i].todayEstimateReadTime;
      }
    }
    return timeData;
  }



  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }

    let totalDataBlock = ( <LearningTotalDataBlock full={this.props.full} one={this.props.one} two={this.props.two} data={this.state.totalData} /> );

    let todayQa = (
      <TodayLearning full={this.props.full} one={this.props.one} two={this.props.two}
       types={this.state.distributedLearningQaTypes} label="Q&A" urlType="qa" />
    );
    let todayMusiqui = (
      <TodayLearning full={this.props.full} one={this.props.one} two={this.props.two}
       types={this.state.distributedLearningMusiquiTypes} label="MUSIQUI" urlType="musiqui" />
    );
    let todayShakyo = (
      <TodayLearning full={this.props.full} one={this.props.one} two={this.props.two}
       types={this.state.distributedLearningShakyoTypes} label="SHAKYO" urlType="shakyo" />
    );

    let distributedLearningTypesBlock = (
      <Block full={this.props.full} one={this.props.one} two={this.props.two} onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <BlockName>Today DistributedLearnings --- {this.state.totalData.totalTodayEstimateReadTime}min</BlockName>
        {todayQa}
        {todayMusiqui}
        {todayShakyo}
      </Block>
    );


    let qaBlock = ( <LearningTypeBlock full={this.props.full} one={this.props.one} two={this.props.two} types={this.state.distributedLearningQaTypes} label="Q&A" urlType="qa" /> );
    let musiquiBlock = ( <LearningTypeBlock full={this.props.full} one={this.props.one} two={this.props.two} types={this.state.distributedLearningMusiquiTypes} label="MUSIQUI" urlType="musiqui" /> );
    let shakyoBlock = ( <LearningTypeBlock full={this.props.full} one={this.props.one} two={this.props.two} types={this.state.distributedLearningShakyoTypes} label="SHAKYO" urlType="shakyo" /> );


    let createLearning = (
      <Block full={this.props.full} one={this.props.one} two={this.props.two} onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <BlockName>CREATE LEARNING</BlockName>
        <LinkBtnField>
          <LinkBtn to={this.props.full ? '/create_distributed_learning_qa' : this.props.one ? '/create_distributed_learning_qa/' + window.location.pathname.slice(1).split('/')[1] : '/' + window.location.pathname.slice(1).split('/')[0] + '/create_distributed_learning_qa'}>CREATE Q&A</LinkBtn>
        </LinkBtnField>
        <LinkBtnField>
          <LinkBtn to={this.props.full ? '/create_distributed_learning_musiqui' : this.props.one ? '/create_distributed_learning_musiqui/' + window.location.pathname.slice(1).split('/')[1] : '/' + window.location.pathname.slice(1).split('/')[0] + '/create_distributed_learning_musiqui'}>CREATE MUSIQUI</LinkBtn>
        </LinkBtnField>
        <LinkBtnField>
          <LinkBtn to={this.props.full ? '/create_distributed_learning_shakyo' : this.props.one ? '/create_distributed_learning_shakyo/' + window.location.pathname.slice(1).split('/')[1] : '/' + window.location.pathname.slice(1).split('/')[0] + '/create_distributed_learning_shakyo'}>CREATE SHAKYO</LinkBtn>
        </LinkBtnField>
      </Block>
    );

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'DistributedLearnings | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningsStyle>
          {totalDataBlock}
          {distributedLearningTypesBlock}
          {qaBlock}
          {musiquiBlock}
          {shakyoBlock}
          {createLearning}
        </DistributedLearningsStyle>
      </div>
    );
  }

}
export default DistributedLearnings;
