import React from 'react';
import axios from 'axios';
import History from 'History';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import ReactPlayer from 'react-player';
import { Block, BlockName, UserName, Media, FormField, LinkBtnField, LinkBtn, Links, LinkBlock } from 'component/common/style/Oauth';

class Tweets extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      tweets:this.props.tweets,
      tweetsCount:this.props.tweetsCount,
      tweetsAttribute:this.props.tweetsAttribute,
      showVideoIndex:undefined,
    };
  }

  componentDidMount = () => {
    console.log('Tweets');
    console.log(this.props.tweets);
  }

  showVideo = (index) => {
    console.log('index: ' + index);
    if (index != undefined) {
      console.log('true');
    } else {
      console.log('false');
    }
    this.setState({ showVideoIndex:index, });
  }


  render() {

    let tweetsBlock;
    let pathItems = window.location.pathname.slice(1).split('/');
    if (0 < this.state.tweets.length) {
      let i = 0;
      return tweetsBlock = this.state.tweets.map((tweet, index) => {

        let name;
        if (tweet.user.protected) {
          name = ( <p><i class="fas fa-lock"></i> <Link to={this.props.full ? '/oauth_twitter_account_' + tweet.user.id_str : this.props.one ? '/oauth_twitter_account_' + tweet.user.id_str + '/' + pathItems[1] : '/' + pathItems[0] + '/oauth_twitter_account_' + tweet.user.id_str}>{tweet.user.name}(@{tweet.user.screen_name})</Link></p> );
        } else {
          name = ( <p><Link to={'/twitter/' + tweet.user.id_str}>{tweet.user.name}(@{tweet.user.screen_name})</Link></p> );
        }
        let userName = (
          <UserName>
            <img src={tweet.user.profile_image_url != null ? tweet.user.profile_image_url : 'https://www.wallpapertip.com/wmimgs/30-309624_techno-music-wallpapers-hd-sonic-full-hd.jpg'} />
            {name}
          </UserName>
        );

        let text;
        if (tweet.text != '' && tweet.text != null) {
          text = ( <p>{tweet.text}</p> );
        } else {
          text = ( <span></span> );
        }

        let medias;
        if (this.state.tweetsAttribute != 'textOnly') {
          if (tweet.extended_entities) {
            medias = tweet.extended_entities.media.map((media, i) => {

              if (media.type == 'photo') {
                return (
                  <Media>
                    <img src={media.media_url} onClick={ () => {this.showVideo(undefined)} } />
                  </Media>
                );
              } else {
                if (this.state.showVideoIndex == index) {
                  return (
                    <Media>
                      <ReactPlayer url={media.video_url} width='calc(100% - 40px)' height='auto' controls playing />
                    </Media>
                  );
                } else {
                  return (
                    <Media>
                      <img src={media.media_url} onClick={ () => {this.showVideo(index)} } />
                      <div className='play-btn' onClick={ () => {this.showVideo(index)} } ><div className='play-btn-triangle'><i class="fas fa-play"></i></div></div>
                    </Media>
                  );
                }
              }

            });
          } else {
            medias = ( <span></span> );
          }
        } else {
          medias = ( <span></span> );
        }

        let createdAt = ( <p>created_at: {tweet.created_at}</p> );

        if (this.state.tweetsAttribute != 'mediaOnly' || (this.state.tweetsAttribute == 'mediaOnly' && tweet.extended_entities)) {
          i++;
          return (
            <Block full={this.props.full} one={this.props.one} two={this.props.two}>
              <p>{i}/{this.state.tweetsCount}</p>
              {userName}
              {text}
              {medias}
              {createdAt}
            </Block>
          );
        }

      });
    } else {
      return tweetsBlock = (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <p>ツイートはありません。</p>
        </Block>
      );
    }


  }

}
export default Tweets;
