import axios from 'axios';

export default function TextHeightAdjustment(elementId) {

  return new Promise((resolve, reject) => {

    let target = document.getElementById(elementId);
    if (target.scrollHeight > target.offsetHeight) {
      target.style.height = target.scrollHeight + 'px';
      resolve({'height':target.style.height});
    }else{
      var height, lineHeight;
      let i = 0;
      while (true){
        height = Number(target.style.height.split('px')[0]); // テキストエリア全体の高さ 
        lineHeight = Number(target.style.lineHeight.split('px')[0]); // 1行あたりの高さ
        target.style.height = '0px'; // 上記の計算で初期値を入れてしまうと配列の追加・削除でindexがずれた際に別の項目の初期値をとってしまい縦幅がおかしくなってしまう。 
        if (target.scrollHeight >= target.offsetHeight) { // スクロール込みの高さが全体の高さよりも大きければ、全体の高さにスクロール込みの高さの値を代入して処理を抜ける
          target.style.height = target.scrollHeight + 'px';
          break;
        } else {
          if (100 < i) {
            target.style.height = target.scrollHeight + 'px';
            break;
          } else {
            i++;
          }
        }
      }
      resolve({'height':target.style.height});
    }

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
