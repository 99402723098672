import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth, Menu } from 'component/common/style/GlobalStyle';
import { StudiesField, StudiesStyle, Study, StatusFilter } from 'component/common/style/Studies';

// function
import Deepl from 'component/common/function/Deepl';
import Polly from 'component/common/function/Polly';
import SearchStudies from 'component/common/function/SearchStudies';
//import DeleteStudyData from 'component/common/function/DeleteStudyData';
import ChangeStatusStudyData from 'component/common/function/ChangeStatusStudyData';
//import CheckedStudyData from 'component/common/function/CheckedStudyData';


class Studies extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      keyword:'no-check',
      searchKeywords:[],
      studies:[],
      statusFilter:['no-check', 'checked', 'no-interest', 'check-later'],
    };
  }

  componentDidMount = () => {
    this.searchStudies('no-check');
  }

  searchStudies = (keyword) => {
    console.log('getStudies');
    this.setState({ keyword:keyword, });

    SearchStudies(keyword).then((p) => {

      // 確認済みと興味なしのステータスが付いた論文はデフォルトで非表示にする
      let statusFilter = ['no-check', 'check-later'];
      //let statusFilter = this.state.statusFilter.slice();
      //statusFilter = statusFilter.filter((sf) => sf != 'checked');
      //statusFilter = statusFilter.filter((sf) => sf != 'no-interest');

      // keywordがno-checkかcheck-laterの場合、指定したステータスの論文のみを表示するので、statusFilterもその１つだけを格納しておく。
      if (keyword == 'no-check') {
        statusFilter = ['no-check'];
      } else if (keyword == 'check-later') {
        statusFilter = ['check-later'];
      }

      this.setState({ searchKeywords:p.searchKeywords, studies:p.studies, statusFilter:statusFilter, });
      document.getElementById('study-datas-style').scrollTop = 0;
    });

/*
    let params = { keyword:keyword, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/get_studies/', params, headers)
    .then(res => {
      let searchKeywords = res.data.search_keywords;
      let studies = res.data.studies;

      for (var i=0; i<studies.length; i++) {
        studies[i].titleJa = '**********';
        studies[i].pub_date = studies[i].pub_date.split('-')[0] + '/' + studies[i].pub_date.split('-')[1] + '/' + studies[i].pub_date.split('-')[2].substr(0,2);
      }

      this.setState({ searchKeywords:searchKeywords, studies:studies, });
      document.getElementById('study-datas-style').scrollTop = 0;
    })
    .catch(err => { });
*/
  }

  translate = (i,title) => {
    if (title != '' && this.state.studies[i].titleJa == '**********') {
      let studies = this.state.studies.slice();
      studies[i].titleJa = 'translating...';
      this.setState({ studies:studies, });

      Deepl(title, 'en', 'ja').then((text) => {
        if (text != '') {
          text = text.replace('(Alternatives: )', '');
          studies[i].titleJa = text;
        } else {
          studies[i].titleJa = 'translate failes';
        }
        this.setState({ studies:studies, });
      });

    }
  }


  changeStatus = (study, status) => {

    if (study.status != status) {
      ChangeStatusStudyData(study.hash, status).then((p) => {
        this.props.handleFlashMessage('CHANGE STATUS');

        // statusを更新
        let changeStatusStudyIndex = this.state.studies.findIndex((s) => s.hash == study.hash);
        let studies = JSON.parse(JSON.stringify(this.state.studies));
        studies[changeStatusStudyIndex].status = status;

        let searchKeywords = this.state.searchKeywords.slice();
        if (status == 'checked' || status == 'no-interest') {
          // 確認済み、または興味なしの場合はもう読まないので、その論文の検索ワードのcountを減らす

          let changeStatusSearchKeywords = this.state.studies[changeStatusStudyIndex].search_keyword;
          let index = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == changeStatusSearchKeywords);
          searchKeywords[index].count--;

          // searchKeywordsのno-checkとcheck-laterのところも数値を調整する
          if (study.status == 'no-check') {
            let noCheckIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'no-check');
            searchKeywords[noCheckIndex].count--;
          }
          if (study.status == 'check-later') {
            let checkLaterIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'check-later');
            searchKeywords[checkLaterIndex].count--;
          }

        } else {
          // 未確認、またはあとで読むの場合は、searchKeywordsの数値を調整する
          if (study.status == 'no-check') {
            let noCheckIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'no-check');
            searchKeywords[noCheckIndex].count--;
          }
          if (study.status == 'check-later') {
            let checkLaterIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'check-later');
            searchKeywords[checkLaterIndex].count--;
          }
          if (status == 'no-check') {
            let noCheckIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'no-check');
            searchKeywords[noCheckIndex].count++;
          }
          if (status == 'check-later') {
            let checkLaterIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == 'check-later');
            searchKeywords[checkLaterIndex].count++;
          }
        }


        // 同じ論文がほかの検索キーワードでも存在する場合は、そのキーワードのcountも調整する。
        if (p.other_search_keywords) { 
          let other_search_keywords = p.other_search_keywords;
          for (var i=0; i<other_search_keywords.length; i++) {
            let otherSearchKeywordIndex = searchKeywords.findIndex((searchKeyword) => searchKeyword.keyword == other_search_keywords[i].keyword);
            searchKeywords[otherSearchKeywordIndex].count = other_search_keywords[i].count;
          }
        }

        this.setState({ studies:studies, searchKeywords:searchKeywords, });

      });
    }

  }


  changeStatusFilter = (status) => {
    let statusFilter = this.state.statusFilter.slice();
    if (statusFilter.some((sf) => sf == status)) {
      statusFilter = statusFilter.filter((sf) => sf != status);
    } else {
      statusFilter.push(status);
    }
    this.setState({ statusFilter:statusFilter, });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let menu;
    if (0 < this.state.searchKeywords.length) {
      let searchKeywords = this.state.searchKeywords.map((searchKeyword,i) => {
        return (
          <div className={'item ' + (this.state.keyword == searchKeyword.keyword ? 'select-item': '')} onClick={ () => {this.searchStudies(searchKeyword.keyword)} }>
            <div className='item-name-wrapper'><div className='item-name'>({searchKeyword.count}) {searchKeyword.keyword}</div></div>
          </div>
        );
      });
      menu = (
        <Menu full={this.props.full} one={this.props.one} two={this.props.two}>
          {/*
          <div className={'item ' + (this.state.keyword == 'no-check' ? 'select-item': '')} onClick={ () => {this.searchStudies('no-check')} }>
            <div className='item-name-wrapper'><div className='item-name'>({this.state.searchKeywords.reduce((sum,i) => sum+i.count,0)}) no-check</div></div>
          </div>
          <div className={'item ' + (this.state.keyword == 'check-later' ? 'select-item': '')} onClick={ () => {this.searchStudies('check-later')} }>
            <div className='item-name-wrapper'><div className='item-name'>({this.state.searchKeywords.reduce((sum,i) => sum+i.count,0)}) check-later</div></div>
          </div>
          */}
          {searchKeywords}
        </Menu>
      );
    } else {
      menu = ( <span></span> );
    }




    let studies;
    if (0 < this.state.studies.length) {
      let studyList = this.state.studies.map((study,i) => {

        if (this.state.statusFilter.some((sf) => sf == study.status)) {
        return (
          <div>
            <Study full={this.props.full} one={this.props.one} two={this.props.two} status={study.status}>
              <div className='title'><a href={study.source.replace('sci-hub.do', 'sci-hub.se')} target='_blank'>{study.title}</a></div>
              <div className='title-ja' onClick={ () => {this.translate(i,study.title)} }>{study.titleJa}</div>
              <div className='study-footer'>
                <div className='search-keyword'>{study.search_keyword} {this.state.keyword == 'no-check' ? i+1 : study.rank}/{this.state.studies.length}</div>
                <div className='status-btns'>
                  <div className={'btn ' + (study.status == 'no-check' ? '': 'no-select')} onClick={ () => {this.changeStatus(study, 'no-check')} }><i className='far fa-check-circle' /><div className='description'>no-check</div></div>|
                  <div className={'btn ' + (study.status == 'checked' ? '': 'no-select')} onClick={ () => {this.changeStatus(study, 'checked')} }><i className='fas fa-check-circle' /><div className='description'>checked</div></div>|
                  <div className={'btn ' + (study.status == 'no-interest' ? '': 'no-select')} onClick={ () => {this.changeStatus(study, 'no-interest')} }><i className='fas fa-ban' /><div className='description'>no-interest</div></div>|
                  <div className={'btn ' + (study.status == 'check-later' ? '': 'no-select')} onClick={ () => {this.changeStatus(study, 'check-later')} }><i className='far fa-clock' /><div className='description'>check-later</div></div>
                </div>
                <div className='pub-date'>{study.pub_date}</div>
              </div>
              <ClearBoth />
            </Study>
          </div>
        );
        }

      });
      studies = ( <StudiesStyle id={'study-datas-style'} full={this.props.full} one={this.props.one} two={this.props.two}>{studyList}</StudiesStyle> );
    } else {
      studies = ( <span></span> );
    }


    let statusFilter;
    if (this.state.keyword != 'no-check' && this.state.keyword != 'check-later') {
      statusFilter = (
        <div>
          <StatusFilter status='no-check' on={this.state.statusFilter.some((sf) => sf == 'no-check')} onClick={ () => {this.changeStatusFilter('no-check')} }>
            <div className='label'><i className='far fa-check-circle' /> ({this.state.studies.filter((study) => study.status == 'no-check').length})</div>
          </StatusFilter>

          <StatusFilter status='check-later' on={this.state.statusFilter.some((sf) => sf == 'check-later')} onClick={ () => {this.changeStatusFilter('check-later')} }>
            <div className='label'><i className='far fa-clock' /> ({this.state.studies.filter((study) => study.status == 'check-later').length})</div>
          </StatusFilter>

          <StatusFilter status='checked' on={this.state.statusFilter.some((sf) => sf == 'checked')} onClick={ () => {this.changeStatusFilter('checked')} }>
            <div className='label'><i className='fas fa-check-circle' /> ({this.state.studies.filter((study) => study.status == 'checked').length})</div>
          </StatusFilter>

          <StatusFilter status='no-interest' on={this.state.statusFilter.some((sf) => sf == 'no-interest')} onClick={ () => {this.changeStatusFilter('no-interest')} }>
            <div className='label'><i className='fas fa-ban' /> ({this.state.studies.filter((study) => study.status == 'no-interest').length})</div>
          </StatusFilter>
        </div>
      );
    } else {
      statusFilter = ( <span></span> );
    }

    let studiesField = (
      <StudiesField onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        {menu}
        {studies}
        {statusFilter}
      </StudiesField>
    );


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Studies | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>

        {studiesField}
      </div>
    );
  }

}
export default Studies;
