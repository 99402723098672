import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// function
import GetDistributedLearningSchedule from 'component/common/function/learning/GetDistributedLearningSchedule';

// style
import { Block, BlockName, LearningTimeField, TotalDataImageField, CalendarField, Calendar } from 'component/common/style/learning/Top';
import { ClearBoth } from 'component/common/style/GlobalStyle';


class LearningTotalDataBlock extends React.Component{

  constructor(props) {
    super(props);
    this.state = { imgSrc:"", schedule_infos:[] };
  }

  componentDidMount = () => {
    this.randomPickImgSrc();
    this.getLearningScheduleData();
  }

  randomPickImgSrc = () => {
    let imgs = [
      "https://i0.wp.com/www.tabiroureview.jp/wp-content/uploads/2022/01/f858fdc607f053435950427c9a8a64e2.jpg?resize=599%2C337&ssl=1", // 槙島聖悟
      "https://www.wangoblog.com/wp-content/uploads/2020/06/49b4e87305aa9e445d9223ee4584f0d8.png", // 槙島聖悟
      "https://research.image.itmedia.co.jp/wp-content/uploads/2021/08/1629223358_84301dbb668eb5d1d2386ebcd41915b4.jpg", // 赤木しげる
      "https://image2.b-ch.com/ttl2/2415/2415004a.jpg?impolicy=fitin&ww=640&hh=380", // 赤木しげる
      "https://image.itmedia.co.jp/news/articles/1905/17/kf_sac_02.jpg", // 笑い男
      "https://i.pinimg.com/736x/49/4d/25/494d25fed2a316af84e891ccaac658c7.jpg", // 笑い男
    ];
    let imgSrc = imgs[Math.floor(Math.random() * imgs.length)];
    this.setState({ imgSrc:imgSrc });
  }

  getLearningScheduleData = () => {
    GetDistributedLearningSchedule().then((res) => {
      if (res.schedule_infos) this.setState({ schedule_infos:res.schedule_infos });
    });
  }

  render() {

    let learningTimeField = (
      <LearningTimeField on>
        <label>Learning Time</label>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>/4month</th>
              <th>/month</th>
              <th>/week</th>
              <th>/day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>qa</td>
              <td>{Math.round(this.props.data.qaTotalEstimateReadTime / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.qaTotalEstimateReadTime / 4 / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.qaTotalEstimateReadTime / 16)}min</td>
              <td>{Math.round(this.props.data.qaTotalEstimateReadTime / 16 / 7)}min</td>
            </tr>
            <tr>
              <td>musiqui</td>
              <td>{Math.round(this.props.data.musiquiTotalEstimateReadTime / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.musiquiTotalEstimateReadTime / 4 / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.musiquiTotalEstimateReadTime / 16)}min</td>
              <td>{Math.round(this.props.data.musiquiTotalEstimateReadTime / 16 / 7)}min</td>
            </tr>
            <tr>
              <td>shakyo</td>
              <td>{Math.round(this.props.data.shakyoTotalEstimateReadTime / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.shakyoTotalEstimateReadTime / 4 / 60 * 10) / 10}hour</td>
              <td>{Math.round(this.props.data.shakyoTotalEstimateReadTime / 16)}min</td>
              <td>{Math.round(this.props.data.shakyoTotalEstimateReadTime / 16 / 7)}min</td>
            </tr>
            <tr>
              <td><b>total</b></td>
              <td><b>{Math.round(this.props.data.totalEstimateReadTime / 60 * 10) / 10}hour</b></td>
              <td><b>{Math.round(this.props.data.totalEstimateReadTime / 4 / 60 * 10) / 10}hour</b></td>
              <td><b>{Math.round(this.props.data.totalEstimateReadTime / 16 / 60 * 10) / 10}hour</b></td>
              <td><b>{Math.round(this.props.data.totalEstimateReadTime / 16 / 7)}min</b></td>
            </tr>
          </tbody>
        </table>
      </LearningTimeField>
    );

{/*
    let imgs = [
      "https://i0.wp.com/www.tabiroureview.jp/wp-content/uploads/2022/01/f858fdc607f053435950427c9a8a64e2.jpg?resize=599%2C337&ssl=1", // 槙島聖悟
      "https://www.wangoblog.com/wp-content/uploads/2020/06/49b4e87305aa9e445d9223ee4584f0d8.png", // 槙島聖悟
      "https://research.image.itmedia.co.jp/wp-content/uploads/2021/08/1629223358_84301dbb668eb5d1d2386ebcd41915b4.jpg", // 赤木しげる
      "https://image2.b-ch.com/ttl2/2415/2415004a.jpg?impolicy=fitin&ww=640&hh=380", // 赤木しげる
      "https://image.itmedia.co.jp/news/articles/1905/17/kf_sac_02.jpg", // 笑い男
      "https://i.pinimg.com/736x/49/4d/25/494d25fed2a316af84e891ccaac658c7.jpg", // 笑い男
    ];
    let imgField = ( <TotalDataImageField><img src={imgs[Math.floor(Math.random() * imgs.length)]} /></TotalDataImageField> );
*/}
    let imgField;
    if (this.state.imgSrc != "") {
      imgField = ( <TotalDataImageField><img src={this.state.imgSrc} /></TotalDataImageField> );
    }

    let calendarsField;
    if (0 < this.state.schedule_infos.length) {
      let calendarWday = (
        <div>
          <div className="calendar-header-block"><span className="content">Sun</span></div>
          <div className="calendar-header-block"><span className="content">Mon</span></div>
          <div className="calendar-header-block"><span className="content">Tue</span></div>
          <div className="calendar-header-block"><span className="content">Wed</span></div>
          <div className="calendar-header-block"><span className="content">Thu</span></div>
          <div className="calendar-header-block"><span className="content">Fri</span></div>
          <div className="calendar-header-block"><span className="content">Sat</span></div>
        </div>
      );

      let calendarInfos = this.state.schedule_infos.map((calendarInfo, index) => {
        let calendarInfoBlock = calendarInfo.day_infos.map((info, index) => {
          let mday, learnTime;
          if (info.mday) { mday = ( <div className="mday">{info.mday}</div> ) }
          if (info.past_flg == false && 0 <= info.min_of_learning_time) { learnTime = (<div className="learn-time">{info.min_of_learning_time}<span className="unit">min</span></div>) }
          return (
            <div className={"calendar-block " + (info.past_flg ? "past" : info.today_flg ? "today" : "")}>
              {mday}
              {learnTime}
            </div>
          );
        });

        return (
          <CalendarField calendarIndex={index}>
            <label>{calendarInfo.month}</label>
            <Calendar on>
              {calendarWday}<ClearBoth/>
              {calendarInfoBlock}<ClearBoth/>
            </Calendar>
          </CalendarField>
        );
      });

      calendarsField = (
        <div>
          <label>Schedule</label><ClearBoth/>
          {calendarInfos}
        </div>
      );
    }

    if (learningTimeField && calendarsField) {
      return (
        <Block full={this.props.full} one={this.props.one} two={this.props.two} on>
          <BlockName on>TotalData</BlockName>
          {learningTimeField}
          {imgField}
          {calendarsField}
        </Block>
      );
    } else {
      return (
        <Block full={this.props.full} one={this.props.one} two={this.props.two} on>
          <BlockName on>TotalData Loading...</BlockName>
        </Block>
      );
    }

  }
}
export default LearningTotalDataBlock;
