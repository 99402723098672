import axios from 'axios';
  
export default function DeleteStudyData(hash, status) {

  return new Promise((resolve, reject) => {

    let params = { hash:hash, status:status, }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.put('https://lab.takuman.me/api/study_data/change_status/', params, headers)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => { });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve();
    });
  });

}
