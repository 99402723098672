import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { green, mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations } from 'component/common/style/Animations';

const FlashMessage = styled.div`
  &&& {
    background:${ props => props.success ? edgeColor : attentionColor };
    animation:move 50ms ease 0s forwards, fadeOut 2s ease 2s forwards, hide 0s ease-in 4s forwards;
    position:fixed; top:80px; right:0; z-index:1000; max-width:90%; padding:5px 50px 5px 30px; border-radius:2px 0 0 2px;
    p { color:${subColor}; font-size:18px; text-align:center; padding:0; }
    @media screen and (min-width:750px) {
      max-width:500px;
    }
  }
`

export { FlashMessage }
