import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
//import { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/Form';
import { QaFormStyle, QaFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/QaForm';

// function
import GetDistributedLearningTypes from 'component/common/function/GetDistributedLearningTypes';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';


class CreateQa extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      question:'',
      answer:'',
      qaTypes:[],
      typeIndex:0,
      typeMenu:false,
      newType:'',

      koubun:[],
    };
  }

  componentDidMount = () => {
    this.getDistributedLearningTypes();
    document.getElementById('question').focus();
  }

  getDistributedLearningTypes = () => {
    GetDistributedLearningTypes('qa').then((res) => {
      let qaTypes = res.qaTypes;
      let koubun = res.koubun;
      console.log(res.koubun);
      if (0 < qaTypes.length) {
        qaTypes.push({'name':'new type',});
        this.setState({ qaTypes:qaTypes, koubun:koubun, });
      }
    });
  }

  selectType = (index) => {
    this.setState({ typeIndex:index, typeMenu:false, }, () => {
      if (this.state.qaTypes[index].name == 'new type') {
        document.getElementById('new-type').focus();
      }
    });
  }

  handleTypeMenu = () => {
    if (this.state.typeMenu) {
      this.setState({ typeMenu:false, });
    } else {
      this.setState({ typeMenu:true, });
    }
  }

  handleCloseTypeMenu = () => {
    this.setState({ typeMenu:false, });
  }

  changeQuestion = (event) => {
    this.setState({ question:event.target.value, }, () => {
      TextHeightAdjustment('question');
    });
  }

  changeAnswer = (event) => {
    this.setState({ answer:event.target.value, }, () => {
      TextHeightAdjustment('answer');
    });
  }

  saveDistributedLearning = () => {
    let type;
    if (this.state.qaTypes[this.state.typeIndex].name == 'new type') {
      type = this.state.newType;
    } else {
      type = this.state.qaTypes[this.state.typeIndex].name;
    }

    let musiqui = '';
    let shakyo = '';

    if (type != '') {
      SaveDistributedLearning(type, this.state.question, this.state.answer, musiqui, shakyo).then((saved) => {
        if (saved) {
          this.props.handleFlashMessage('CREATED');
          this.setState({ question:'', answer:'', });
        } else {
          this.props.handleFlashMessage('ERROR');
        }
      });
    }
  }

  changeNewType = (event) => {
    let newType = event.target.value;
    this.setState({ newType:newType, });
  }

  insertKoubun = (question, answer) => {
    this.setState({ question:question, answer:answer, });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectType;
    if (0 < this.state.qaTypes.length) {
      let selectTypeMenu = (
        <div className='select-menu'>
          {this.state.qaTypes.map( (type,index) => {
            if (this.state.typeIndex != index) {
              return ( <div className='menu-item' onClick={ (event) => {this.selectType(index)} }>{type.name}</div> );
            }
          })}
        </div>
      );
      selectType = (
        <div className='select'>
          <div className='select-item' onClick={ (event) => {this.handleTypeMenu()} }>type: {this.state.qaTypes[this.state.typeIndex].name}</div>
          {this.state.typeMenu ? selectTypeMenu : ''}
        </div>
      );
    } else {
      selectType = ( <div className='select'><div className='select-item'></div></div> );
    }

    let newTypeForm;
    if (0 < this.state.qaTypes.length) {
      if (this.state.qaTypes[this.state.typeIndex].name == 'new type') {
        newTypeForm = ( <input type='text' id='new-type' className='new-type-form' onChange={ (event) => {this.changeNewType(event)} } value={this.state.newType} placeholder='new type' /> );
      } else {
        newTypeForm = ( <span></span> );
      }
    } else {
      newTypeForm = ( <span></span> );
    }


    let saveBtnField;
    if (0 < this.state.qaTypes.length) {
      if (this.state.qaTypes[this.state.typeIndex].name != 'new type' || (this.state.qaTypes[this.state.typeIndex].name == 'new type' && this.state.newType != '')) {
        if (this.state.question != '' && this.state.answer != '') {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn submit onClick={ () => {this.saveDistributedLearning()} }>CREATE Q&A</LinkBtn>
            </LinkBtnField>
          );
        } else {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn>CREATE Q&A</LinkBtn>
            </LinkBtnField>
          );
        }
      } else {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn>CREATE Q&A</LinkBtn>
          </LinkBtnField>
        );
      }
    } else {
      saveBtnField = (
        <LinkBtnField>
          <LinkBtn>CREATE Q&A</LinkBtn>
        </LinkBtnField>
      );
    }


    let translateField = (
      <QaFormField>
        <TypeField>
          {selectType}
          {newTypeForm}
        </TypeField>
        <TextField>
          <textarea type='text' id='question' onChange={ (event) => {this.changeQuestion(event)} } value={this.state.question} placeholder='Question' />
        </TextField>
        <TextField>
          <textarea type='text' id='answer' onChange={ (event) => {this.changeAnswer(event)} } value={this.state.answer} placeholder='Answer' />
        </TextField>
        {saveBtnField}
      </QaFormField>
    );


    let selectMenuWrapper;
    if (this.state.typeMenu) {
      selectMenuWrapper = ( <SelectMenuWrapper onClick={ (event) => {this.handleCloseTypeMenu()} } /> );
    } else {
      selectMenuWrapper = ( <span></span> );
    }



    let koubun;
    if (0 < this.state.koubun.length) {
      koubun = (
        <div className='koubuns'>
          {this.state.koubun.map( (k,index) => {
            if (150 <= index && index <= 150) {
              let num = index + 1;
              if (num < 10) {
                num = '00' + num;
              } else if (num < 100) {
                num = '0' + num;
              } else {
                num = num;
              }
              num = String(num);

              let a = k.answer.split(num)[0];
              let b = k.answer.split(num)[1];

              let question = a;
              let answer = k.question + ' ' + num + ' ' + b;
              return (
                <div className='koubun' onClick={ (event) => {this.insertKoubun(question, answer)} }>
                  <div>{num}:</div>
                  <div>{question}</div>
                  <div>{answer}</div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      koubun = ( <div></div> );
    }



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Create Q&A | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <QaFormStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
          {selectMenuWrapper}
          {koubun}
        </QaFormStyle>
      </div>
    );
  }

}
export default CreateQa;
