import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { fadeIn, borderFlash } from 'component/common/style/Animations';

const LayeredNotesStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};
  }
`

const SearchField = styled.div`
  &&& {
    width:100%; height:auto; margin:30px auto 30px auto; padding:20px 0;
    h1 { color:${mainColor}; text-align:center; font-size:35px; font-weight:100; margin:0 0 20px 0; padding:0; }
    p { color:${mainColor}; text-align:center; font-size:15px; font-weight:100; margin:0; padding:0 20px; } 
    .searchbox { width:90%; height:35px; margin:0 auto; padding:0; border-radius:10000px;
      input[type='text'] { width:90%; height:35px; margin:0; padding:0 10px; background:${subColor}; font-size:18px; color:${mainColor};
                           border:0.5px solid ${mainColor}; border-radius:10000px 0 0 10000px; display:inline-block; float:left; }
      input[type='text']:focus { background:${subColor}; opacity:0.9; outline:0; }
      input[type='text']:focus + .search-btn { background:${mainColor}; }
      .search-btn { width:10%; height:35px; margin:0; padding:0; background:${mainColor}; border:none; color:${subColor};
                    border-radius:0 10000px 10000px 0; display:inline-block; cursor:pointer; float:right; }
      .search-btn:hover { opacity:0.9; }
    }

    @media screen and (min-width:750px) {
      width:80%;
    }

    @media screen and (min-width:960px) {
      width:60%;
    }

  }
`

const BoxNote = styled.div`
  &&& {
    transition:.2s; width:50%; height:0; margin:0; padding-bottom:50%; position:relative; float:left; 
    .note-wrapper { position:absolute; top:0; bottom:0; left:0; right:0; width:95%; height:95%; margin:auto;
      .note-content { width:calc(95vmin); height:calc(95vmin); margin:10px auto; padding:0; background:${subColor}; border:0 solid white; border-radius:5px;
                      box-shadow:0 0 6px ${shadowColor}; cursor:pointer; position:absolute; top:0; bottom:0; left:0; right:0; width:93%; height:93%; overflow:hidden;
        .partition { width:100%; height:100%; background:${subColor}; border-radius:5px; position:absolute; top:0; bottom:0; left:0; right:0; z-index:10; }
        h1 { color:${mainColor}; font-size:24px; font-weight:300; line-height:27px; text-align:left;
             position:absolute; top:50%; width:100%; height:auto; padding:10px 20px; z-index:15; transform:translate(0, -50%); }
        img { position:absolute; top:0; bottom:0; left:0; right:0; z-index:5; width:100%; height:100%; object-fit:cover; transition-duration:0.3s; border-radius:5px; }
      }
      .note-content:hover { 
        img { transform:scale(1.1); transition-duration:0.3s; } // 画像をズーム
       }
    }

    @media screen and (min-width:750px) {
      width:33.333%; height:0; margin:0; padding-bottom:33.333%; position:relative; float:left;
      .note-wrapper { position:absolute; top:0; bottom:0; left:0; right:0; width:95%; height:95%; margin:auto;  
        .note-content { position:absolute; top:0; bottom:0; left:0; right:0; width:93%; height:93%; margin:auto; }
      }
      .note-wrapper:hover { ${borderFlash(2)} }
    }

    @media screen and (min-width:960px) {
      width:25%; height:0; margin:0; padding-bottom:25%; position:relative;
      .note-wrapper { position:absolute; top:0; bottom:0; left:0; right:0; width:95%; height:95%; margin:auto;
        .note-content { position:absolute; top:0; bottom:0; left:0; right:0; width:93%; height:93%; margin:auto; }
      }
    }

  }
`

const AddNote = styled(BoxNote)`
  &&& {
    .note-wrapper { 
      .note-content { background:none; border:2px dashed ${mainColor}; box-shadow:none; 
        h1 { color:${mainColor}; text-align:center; font-size:20px; padding-top:30px;  
          i { font-size:50px; margin-bottom:10px; }  
        } 
      } 
      .note-content:hover { ${borderFlash(2)} } 
    } 
  }
`

const Notes = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '40'}vw; }
  }
`

const Blocks = styled.div`
  &&& {
    width:100%; height:auto; margin:auto; padding:0;
    @media screen and (min-width:750px) { width:${props => props.full ? '80' : '40'}vw; }
  }
`

const Block = styled.div`
  &&& {
    width:20vw; height:20vw; display:inline-block; float:left; background:${subColor};
    &:hover { ${borderFlash(2)} }
  }
`

const BlockContent = styled(Link)`
  &&& {
    width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; padding:0; background:${subColor}; display:block; position:relative;
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    .item-name { position:absolute; top:0; bottom:0; left:0; right:0; z-index:30; width:100%; height:100%; padding:0; display:table;
      h2 { display:table-cell; vertical-align:middle; padding:10px; color:${mainColor}; font-size:10px; text-align:center; line-height:24px; font-weight:100; }
    }
  }
`


export { LayeredNotesStyle, SearchField, BoxNote, AddNote, Notes, Blocks, Block, BlockContent };
