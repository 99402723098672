import styled, { createGlobalStyle } from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, yellow } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const AssetManagementStyle = styled.div`
  &&& {
    position:fixed; top:0; bottom:0; left:${props => props.two ? '50' : '0'}%; right:${props => props.one ? '50' : '0'}%; z-index:100;
    width:${props => props.full ? '100' : '50'}%; height:100%; margin:auto; padding:50px 0; background:${subColor}; overflow:scroll;
    border-right:${props => props.one ? '0.5px solid ' + shadowColor : 'none'};

    @media screen and (min-width:750px) {
      padding:${props => props.full ? '50px 10vw' : '50px 25vw'};
    }
  }
`

const Card = styled.div`
  &&& {
    width:98%; height:auto; margin:10px; padding:20px 10px; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    color:${props => props.main ? subColor : yellow}; background:${props => props.main ? yellow : subColor}; display:inline-block;

    .card-name { font-size:20px; }

    .item { width:16.666%; height:auto; margin:0; padding:5px; display:inline-block; text-align:left; position:relative;
      label { width:100%; font-size:12px; text-align:left; }
      .data { width:100%; text-align:center;
        .value { font-size:30px; font-weight:900; }
        .unit { font-size:12px; }

        input {
          width:calc(100% - 32px); margin:0; padding:0 3px 0 0; color:${yellow}; font-size:30px; text-align:right; border:none; border-bottom:1px solid ${rgba(yellow,0.2)};
          background:${subColor}; display:inline;
        }
        input.short { width:calc(70% - 12px); }
        input:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
        input:focus { border-bottom:3px solid ${yellow}; outline:0; }
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance:none; margin:0; }
        input[type=number] { -moz-appearance:textfield; }
      }

      .data.select-btn:hover { background:${rgba(mainColor,0.1)}; cursor:pointer; }
      .select-popup { position:absolute; bottom:-100px; left:0; right:0; z-index:1000; width:100%; height:100px; border-radius:5px; box-shadow:0 0 6px ${shadowColor};
                      background:${subColor}; overflow-y:scroll;
        .select-item { width:100%; height:auto; margin:auto; padding:3px; color:${yellow}; font-size:14px; text-align:center; cursor:pointer; }
        .select-item:hover { background:${rgba(mainColor,0.1)}; }
      }

    }
    .wide-item { width:33.333%; }

    .asset-rank { font-size:35px; text-align:center; }
    meter { width:90%; height:15px; margin:auto; padding:0; display:block; }
    meter::-webkit-meter-bar { background:${rgba(mainColor,0.6)}; border:0.5px solid ${rgba(subColor,0.2)}; }
    meter::-webkit-meter-optimum-value { background:${subColor}; }

    .interest-type { width:150px; height:20px; margin:5px 0; padding:0; border:1px solid ${yellow}; border-radius:3px; overflow:hidden;
      .type { width:33.3333%; height:100%; padding:4px; color:${yellow}; font-size:12px; text-align:center; float:left; display:inline-block; }
      .off { cursor:pointer; }
      .off:hover { background:${rgba(mainColor,0.05)}; }
      .on { color:${subColor}; background:${yellow}; }
      .on:hover { background:${rgba(yellow,0.95)}; }
    }

    .table-wrapper { width:100%; height:auto; overflow:scroll; }
    table, th, td { color:${yellow}; font-size:18px; text-align:center; border:1px solid ${yellow}; }
    table { width:100%; height:auto; border-collapse:collapse; }
    tr.x-rank td { color:${subColor}; background:${correctColor}; }
    tr:hover { background:${rgba(mainColor,0.1)}; }

    .custom-tooltip { background:${subColor}; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; }

    animation:${props => props.loading ? 'fade 0.5s linear infinite' : 'none'};
    @keyframes fade {
      0% { opacity:1; }
      50% { opacity:0.7; }
      100% { opacity:1; }
    }

    @media screen and (min-width:750px) {
      width:${props => props.width ? 'calc(' + props.width + ' - 10px)' : 'calc(100% - 10px)'};
      height:${props => props.height ? props.height : 'auto'};
      margin:5px;
    }
  }
`

export { AssetManagementStyle, Card, };
