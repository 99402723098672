import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import History from 'History';
import * as escape from 'escape-html';

import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 

//import PlayAudio from 'component/common/PlayAudio'; // Web Audio APIでサウンド再生 
//import PlayVibrate from 'component/common/PlayVibrate'; // Vibration APIでデバイスを振動

// style
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { PostsStyle } from 'component/common/style/Posts';
import { LayeredNotesStyle, BoxNote, AddNote, Notes, Blocks, Block, BlockContent } from 'component/common/style/LayeredNotes';

// module
import LayeredNotesSearchBox from 'component/common/module/LayeredNotesSearchBox';
//import PostsBlockLayout from 'component/common/module/PostsBlockLayout';
//import PostsFeedLayout from 'component/common/module/PostsFeedLayout';
//import PostsFullpageLayout from 'component/common/module/PostsFullpageLayout';


class LayeredNotes extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = { scrollTop:0, notes:[], loading:true, };
  }

  componentDidMount = () => { 
    this.props.logoAnimation('kodo');
  }

  getNotes = (p) => {
    console.log('getNotes');
    console.log(p);
    this.setState({ notesCount:p.notesCount, paginationNum:p.paginationNum, notes:p.notes, });
    this.props.helloLab();
  }

/*
  componentDidMount = () => {
    window.scrollTo(0,0);
    //this.props.logoAnimation(''); 
    this.getNotes();
  }

  componentDidUpdate = () => {
    //console.log('componentDidUpdate');
    this.props.helloLab();
    if (this.state.loading == true && this.state.notes.length != 0) {
      this.setState({ loading:false, });
      this.props.logoAnimation('');
    }
  }


  getNotes = () => {
    //console.log('getNotes');
    this.props.logoAnimation('kodo');
    var params = { }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    //let notes; 
    axios
      .post('https://lab.takuman.me/api/layerednote/my_index/', params, headers)
      .then(res => {
        //notes = res.data.map((note) => { 
          //return note; 
        //}); 
        let notes = res.data;
        this.setState({ notes:notes });
      })
      .catch(err => {  }); // エラーログを出力 
  }
*/

  createLayeredNote = (event) => {
    //console.log('createLayeredNote');
    event.preventDefault();

    var params = { }
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.post('https://lab.takuman.me/api/layerednote/create/', params, headers)
    .then(res => {
      if (res.data['hash']) {
        //console.log('保存しました');
        History.push('/system/LayeredNote/' + res.data['hash'] + '/');
        let pathItems = window.location.pathname.slice(1).split('/');
        if (this.props.full) {
          History.push('/layerednote_' + res.data['hash']);
        } else if (this.props.one) {
          History.push('/layerednote_' + res.data['hash'] + '/' + pathItems[1]);
        } else if (this.props.two) {
          History.push('/' + pathItems[0] + '/layerednote_' + res.data['hash']);
        }
      //} else {
        //console.log('保存できませんでした。');
      }
    })
    .catch(err => console.log(err))

  }

/*
  componentWillUnmount = () => {
    //PlayAudio('click-01');
    //PlayVibrate('click-01');
  }

  componentWillUpdate = () => {
    document.body.addEventListener('click', (event) => {

      // Postの新規作成または更新のフォームでsubmitを押下したら、またはPostの削除ボタンを押下したら、
      // メソッド終了後にurlが変更(一覧ページにリダイレクト)されたタイミングでsearchメソッドでPost一覧を取得しなおす
      if (event.target.className.split(' ').includes('back-btn') || event.target.parentNode.parentNode.className.split(' ').includes('delete-btn')) {
        clearInterval(this.urlCheckInterval);
        let pathItem = window.location.pathname.slice(1).split('/')[this.props.two ? 1 : 0];
        let count = 0;
        this.urlCheckInterval = setInterval(() => {
          if (pathItem !=  window.location.pathname.slice(1).split('/')[this.props.two ? 1 : 0]) {
            this.getNotes();
            clearInterval(this.urlCheckInterval);
          }
          if (10 < count) {
            clearInterval(this.urlCheckInterval);
          }
          count = Math.round((count + 0.1) * 10) / 10;
        }, 100);
      }

    });
  }
*/

  render() {


{/*
      let searchBox;
      searchBox = (
        <SearchField>
          <h1>Layered Note</h1>
          <p></p>
        </SearchField>
      );

      let addNote;
      addNote = (
        <AddNote>
          <div className='note-wrapper' onClick={ (event) => {this.createLayeredNote()} }>
            <div className='note-content'>
              <div className='partition'></div>
              <h1><i className='fas fa-plus'></i><br/>New Note</h1>
            </div>
          </div>
        </AddNote>
      );

      const notes = this.state.notes.map((note, index) => {

        let title;
        if (note.title != '') {
          title = ( <h1>{note.title}</h1> );
        } else {
          title = ( <h1>no title</h1> );
        }
        let pathItems = window.location.pathname.slice(1).split('/');
        return (
          <BoxNote>
            <Link to={this.props.full ? '/layerednote_' + note.hash : this.props.one ? '/layerednote_' + note.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/layerednote_' + note.hash} className='note-wrapper'>
              <div className='note-content'>
                <div className='partition'></div>
                {title}
              </div>
            </Link>
          </BoxNote>
        );
      });


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <LayeredNotesStyle full={this.props.full} one={this.props.one} two={this.props.two}>
        <Helmet title={'Layered Note'} />
        <BackBtnField>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {searchBox}
        {addNote}
        {notes}
      </LayeredNotesStyle>
    );
*/}

{/*
    let notes;
    if (this.state.notes <= 0) {
      notes = ( <span></span> );
    } else {
      notes = ( <PostsBlockLayout posts={this.state.posts} full={this.props.full} one={this.props.one} two={this.props.two} postsCount={this.state.postsCount} paginationNum={this.state.paginationNum} /> );
    }
*/}


  let notes;
  if (this.state.notes.length != 0) {
    notes = this.state.notes.map((note, index) => {
      let title;
      if (note.title != '') {
        title = ( <h1>{note.title}</h1> );
      } else {
        title = ( <h1>no title</h1> );
      }
      let pathItems = window.location.pathname.slice(1).split('/');

      return (
        <Block full={this.props.full} one={this.props.one} two={this.props.two}>
          <BlockContent to={this.props.full ? '/layerednote_' + note.hash : this.props.one ? '/layerednote_' + note.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/layerednote_' + note.hash} className='note-wrapper'>
            <div className='item-name'><h2>{title}</h2></div>
          </BlockContent>
        </Block> 
      );
    });
  } else {
    notes = ( <span></span> );
  }

    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'LayeredNotes | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two} zIndex={200}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
          <div className='btn right'>
            <Link onClick={ (event) => {this.createLayeredNote(event)} }><i className='fas fa-plus'></i></Link>
          </div>
        </BackBtnField>

        <LayeredNotesStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          <LayeredNotesSearchBox getNotes={this.getNotes} getLayout={this.getLayout} full={this.props.full} one={this.props.one} two={this.props.two} />
          <Blocks full={this.props.full} one={this.props.one} two={this.props.two}>{notes}</Blocks>
        </LayeredNotesStyle>

      </div>
    );

  }

}

export default LayeredNotes;
