import React, { Component } from 'react';
import axios from 'axios';
import { Router, Route, Link, Switch, Redirect } from 'react-router-dom'; // SPA化するためのもの
import History from 'History'; // 関数内でページ遷移を行わせるためのもの


// common
import Header from 'component/common/Header'; // ヘッダー
import Menu from 'component/common/Menu'; // メニュー

// home
import Home from 'component/home/Home'; // ホーム

// user
import Login from 'component/user/Login'; // ログインフォーム

// post
import Posts from 'component/post/Posts'; // タイプ別ポスト一覧
import Post from 'component/post/Post'; // 記事詳細
import NewPost from 'component/post/NewPost'; // 記事投稿
import MultiFileUploadPost from 'component/post/MultiFileUploadPost'; // 複数の記事(メディアファイル)をまとめて投稿
import EditPost from 'component/post/EditPost'; // 記事編集

// tool
//import SoundDev from 'component/tool/SoundDev'; // サウンド開発
//import OutsideUpload from 'component/tool/OutsideUpload'; // 外部アップロード

//import TextComputeEffect from 'component/common/TextComputeEffect'; // テキストをババっと切り替えていくアニメーション


// studies
import Studies from 'component/studies/Studies'; // 論文

// porndata
import PornDatas from 'component/porndata/PornDatas';

// translate
import Translate from 'component/translate/Translate'; // 翻訳

// learning
import DistributedLearnings from 'component/learning/DistributedLearnings'; // 分散学習の一覧
import QaIndex from 'component/learning/QaIndex'; // 一問一答の分散学習の一覧
import MusiquiIndex from 'component/learning/MusiquiIndex'; // 虫食い問題の分散学習の一覧
import ShakyoIndex from 'component/learning/ShakyoIndex'; // 写経の分散学習の一覧
import TodayQa from 'component/learning/TodayQa'; // 今日出題される一問一答形式の分散学習
import TodayMusiqui from 'component/learning/TodayMusiqui'; // 今日出題される虫食い形式の分散学習
import TodayShakyo from 'component/learning/TodayShakyo'; // 今日の写経の分散学習
import CreateQa from 'component/learning/CreateQa'; // 一問一答の分散学習
import CreateMusiqui from 'component/learning/CreateMusiqui'; // 虫食いの分散学習の新規作成
import CreateShakyo from 'component/learning/CreateShakyo'; // 写経の分散学習の新規作成
import UpdateMusiqui from 'component/learning/UpdateMusiqui'; // 虫食いの分散学習の編集
import UpdateShakyo from 'component/learning/UpdateShakyo'; // 写経の分散学習の編集
import Qa from 'component/learning/Qa'; // 一問一答の詳細ページ
import Musiqui from 'component/learning/Musiqui'; // 虫食い問題の詳細ページ
import Shakyo from 'component/learning/Shakyo'; // 写経の詳細ページ

// scraping
import PareoNaOtoko from 'component/scraping/PareoNaOtoko'; // パレオな男

// oauth
import Oauth from 'component/oauth/Oauth'; // アカウント連携
import TwitterAccount from 'component/oauth/twitter/TwitterAccount'; // 任意のTwitterアカウントの詳細情報
import TwitterTweets from 'component/oauth/twitter/TwitterTweets'; // 任意のTwitterアカウントのツイート一覧
import TwitterFavorites from 'component/oauth/twitter/TwitterFavorites'; // 任意のTwitterアカウントのお気に入り一覧
import TwitterFriends from 'component/oauth/twitter/TwitterFriends'; // 任意のTwitterアカウントのフォロー一覧
import TwitterFollowers from 'component/oauth/twitter/TwitterFollowers'; // 任意のTwitterアカウントのフォロワー一覧

// layered note
import LayeredNotes from 'component/layerednote/LayeredNotes'; // 階層化ノート一覧
import LayeredNote from 'component/layerednote/LayeredNote'; // 階層化ノート

// asset management
import AssetManagement from 'component/assetmanagement/AssetManagement'; // 資産管理

// function
import GetMyUserData from 'component/common/function/GetMyUserData';

// style
import { GlobalStyle, ComponentField } from 'component/common/style/GlobalStyle';
import { InitializationBack, InitializationLogo, InitializationPhilosophy } from 'component/common/style/Init';
import { FlashMessage } from 'component/common/style/FlashMessage';


class App extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      labSystem:true,
      pathItems:window.location.pathname.slice(1).split('/'),
      loggedIn: localStorage.getItem('token') ? true : false,
      logoAnimation:'cold', // 読み込み時やアップロード時にヘッダーロゴのアニメーションを行わせるためのクラスをいれる。
      showMenu:false, // メニューを表示するためのフラグ
      isFlashMessage:false, // trueでフラッシュメッセージを表示
      messageText:'', // フラッシュメッセージの文章
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.urlChangeCheck);
    this.setState({ textEffect:'init', initializationBack:true, initializationLogo:true, initializationPhilosophy:true });
    this.getMyUserData();
  }

  componentDidUpdate = () => {
    document.body.addEventListener('click', this.urlChangeCheck);
    document.body.addEventListener('mousemove', this.mousemoveCheck);
  }

  urlChangeCheck = () => {
    clearInterval(this.urlCheckInterval);
    let pathname = window.location.pathname;
    let count = 0;
    this.urlCheckInterval = setInterval(() => {
      //console.log('urlCheckInterval: ' + count);
      if (pathname != window.location.pathname) {
        this.setState({ pathItems:window.location.pathname.slice(1).split('/'), showMenu:false, });
        this.logoAnimation('');
        clearInterval(this.urlCheckInterval);
      }
      if (3 < count) {
        //console.log('3秒より大きい');
        clearInterval(this.urlCheckInterval);
      //} else {
        //console.log('3秒以下');
      }
      count = Math.round((count + 0.1) * 10) / 10;
    }, 100);
  }

/*
  urlChangeCheck = () => {
    let pathname = window.location.pathname;
    let count = 0;
    const urlCheckInterval = setInterval(() => {
      if (pathname != window.location.pathname || 10 < count) {
        this.setState({ pathItems:window.location.pathname.slice(1).split('/'), showMenu:false, });
        this.logoAnimation('');
        clearInterval(urlCheckInterval);
      }
      if (3 < count) {
        clearInterval(urlCheckInterval);
      }
      count = Math.round((count + 0.1) * 10) / 10;
    }, 100);
  }
*/

  // mouseに3秒間動きがなければカーソルを非表示にする
  mousemoveCheck = () => {
    clearTimeout(this.cursorHide);
    document.body.style.cursor = 'auto';
    this.cursorHide = setTimeout(() => {
      document.body.style.cursor = 'none';
    }, 3000);
  }

  getMyUserData = () => {
    if (this.state.loggedIn) {
      GetMyUserData().then((user) => {
        if (0 < Object.keys(user).length) {
          this.setState({ loggedIn:true, });
        } else {
          // ユーザー情報がないということはログインしていないということなので、ログインページに飛ばす
          History.push('/login');
          this.setState({ pathItems:['login'], loggedIn:false, });
        }
      });
    } else {
      History.push('/login');
      this.setState({ pathItems:['login'], });
    }
  }

  helloLab = () => {
    clearTimeout(this.hello);
    this.hello = setTimeout(() => {
      if (this.state.labSystem && this.state.loggedIn) {
        var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
        axios
        .get('https://lab.takuman.me/api/hello_lab/', headers)
        .then(res => {
          if (this.state.showMenu == false) {
            if (this.state.labSystem) {
              this.logoAnimation('');
            } else if (this.state.labSystem == false) {
              this.logoAnimation('cold');
            }
          }
        })
        .catch(err => {
          if (this.state.labSystem && this.state.showMenu == false) {
            this.logoAnimation('cold');
          }
        });
      }
    }, 3000);

  }

  logoAnimation = (logoAnimation) => {
    // kodo 読み込み中 
    // cold バックエンドに接続できない 
    // timer ライハイタイマー起動中

    // メニュー画面を開いているのであれば、別のロゴ状態に変化させないようにする。
    if (this.state.logoAnimation == 'close' && logoAnimation != '') {
      logoAnimation = 'close';
    }
    this.setState({ logoAnimation:logoAnimation, });
  }

  showMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu:false, });
      this.logoAnimation('');
    } else {
      this.setState({ showMenu:true, });
      this.logoAnimation('close');
    }
  }

  // フラッシュメッセージ
  handleFlashMessage = (messageText) => {
    console.log('handleFlashMessage')
    console.log(messageText);
    this.setState({ isFlashMessage:false });
    this.setState({ isFlashMessage:true, messageText:messageText });
    // ログアウト処理を行うHeader.jsを常時マウントされている状態なのでこのように毎回isFlashMessageをリフレッシュさせてあげる必要がある。
    setTimeout(() => { this.setState({ isFlashMessage:false }); }, 3000);
    this.urlChangeCheck();
  }

  loginStateFunc = (loggedIn) => {
    this.setState({ loggedIn:loggedIn });
  }



  render(){

    let flashMessage;
    if (this.state.isFlashMessage) {
      flashMessage = ( <FlashMessage success><p>{this.state.messageText}</p></FlashMessage> );
    } else {
      flashMessage = ( <span></span> );
    }

    var components = [];
    for (var i=0; i<this.state.pathItems.length; i++) {
      let component;
      let pathItem = this.state.pathItems[i];

      if (this.state.loggedIn == false) {
        component = ( <Login loginStateFunc={this.loginStateFunc} handleFlashMessage={this.handleFlashMessage} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('post_')) {
        let post;
        if (pathItem.split('_').length == 3 && pathItem.split('_')[2] != 'new' && pathItem.split('_')[2] != 'multiupload') {
          post = ( <Post full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation}/> );
        } else if (pathItem.split('_').length == 3 && pathItem.split('_')[2] == 'new') {
          post = ( <NewPost full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} type={pathItem.split('_')[1]} handleFlashMessage={this.handleFlashMessage} /> );
        } else if (pathItem.split('_').length == 3 && pathItem.split('_')[2] == 'multiupload') {
          post = ( <MultiFileUploadPost full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} type={pathItem.split('_')[1]} handleFlashMessage={this.handleFlashMessage} /> );
        } else if (pathItem.split('_').length == 4 && pathItem.split('_')[2] == 'edit') {
          post = ( <EditPost full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} type={pathItem.split('_')[1]} handleFlashMessage={this.handleFlashMessage} /> );
        }
        component = (
          <div>
            <Posts full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} type={pathItem.split('_')[1]} />
            {post}
          </div>
        );
      } else if (pathItem == 'studies') {
        component = ( <Studies full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (pathItem == 'porndatas') {
        component = ( <PornDatas full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (pathItem == 'translate') {
        component = ( <Translate full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );

      } else if (pathItem == 'distributed_learnings') {
        component = ( <DistributedLearnings full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem == 'create_distributed_learning_shakyo') {
        component = ( <CreateShakyo full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (pathItem == 'create_distributed_learning_musiqui') {
        component = ( <CreateMusiqui full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (pathItem == 'create_distributed_learning_qa') {
        component = ( <CreateQa full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (0 <= pathItem.indexOf('today_distributed_learning_shakyo_')) {
        component = ( <TodayShakyo full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('today_distributed_learning_musiqui_')) {
        component = ( <TodayMusiqui full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('today_distributed_learning_qa_')) {
        component = ( <TodayQa full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_shakyo_index_')) {
        component = ( <ShakyoIndex full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_qa_index_')) {
        component = ( <QaIndex full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_musiqui_index_')) {
        component = ( <MusiquiIndex full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_musiqui_update_')) {
        component = ( <UpdateMusiqui full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_shakyo_update_')) {
        component = ( <UpdateShakyo full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_qa_')) {
        component = ( <Qa full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_musiqui_')) {
        component = ( <Musiqui full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );
      } else if (0 <= pathItem.indexOf('distributed_learning_shakyo_')) {
        component = ( <Shakyo full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} handleFlashMessage={this.handleFlashMessage} /> );




      } else if (pathItem == 'pareo_na_otoko') {
        component = ( <PareoNaOtoko full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem == 'oauth') {
        component = ( <Oauth full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem.split('_')[0] == 'oauth' && pathItem.split('_')[1] == 'twitter' && pathItem.split('_')[2] == 'account') {
        component = ( <TwitterAccount full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem.split('_')[0] == 'oauth' && pathItem.split('_')[1] == 'twitter' && pathItem.split('_')[2] == 'tweets') {
        component = ( <TwitterTweets full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem.split('_')[0] == 'oauth' && pathItem.split('_')[1] == 'twitter' && pathItem.split('_')[2] == 'favorites') {
        component = ( <TwitterFavorites full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem.split('_')[0] == 'oauth' && pathItem.split('_')[1] == 'twitter' && pathItem.split('_')[2] == 'friends') {
        component = ( <TwitterFriends full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (pathItem.split('_')[0] == 'oauth' && pathItem.split('_')[1] == 'twitter' && pathItem.split('_')[2] == 'followers') {
        component = ( <TwitterFollowers full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else if (0 <= pathItem.indexOf('layerednote')) {
        let note;
        if (pathItem.split('_').length == 2) {
          note = ( <LayeredNote full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} handleFlashMessage={this.handleFlashMessage} logoAnimation={this.logoAnimation} note_hash={pathItem.split('_')[1]}/> );
        }
        component = (
          <div>
            <LayeredNotes full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} />
            {note}
          </div>
        );
      } else if (0 <= pathItem.indexOf('assetmanagement')) {
        component = ( <AssetManagement full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      } else {
        component = ( <Home full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} helloLab={this.helloLab} logoAnimation={this.logoAnimation} /> );
      }

      components.push( <ComponentField className='component-field' full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} >{component}</ComponentField> );
    }

    return(
      <div>
        <Router history={History}>
          <GlobalStyle />

          <InitializationBack></InitializationBack>
          <InitializationLogo><div className='lo'><div className='go'></div></div></InitializationLogo>
          <InitializationPhilosophy><h1>minimum cost</h1><h1>maximum happiness</h1></InitializationPhilosophy>

          <Header logoAnimation={this.state.logoAnimation} labSystem={this.state.labSystem} showMenu={this.showMenu} />
          <Menu logoAnimation={this.logoAnimation} show={this.state.showMenu} showMenu={this.showMenu} full={this.state.pathItems.length==1} one={this.state.pathItems.length==2 && i==0} two={this.state.pathItems.length==2 && i==1} />
          {flashMessage}

          <Switch onScroll={ () => {console.log('000')} }>
            <Route exact path={['/', '/:one/', '/:one/:two/']}>
              {components}
            </Route>
          </Switch>

        </Router>
      </div>
    );

  }
}

export default App;
