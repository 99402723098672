import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';
import { BackBtnField } from 'component/common/style/GlobalStyle';
import { Block, BlockName, FormField, LinkBtnField, LinkBtn, Links, LinkBlock } from 'component/common/style/Oauth';

class Oauth extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { scrollTop:0, twitterOauthTokens:[], };
  }

  componentDidMount = () => {
    this.props.logoAnimation('kodo');
    // Oauth認証のアカウント連携後にコールバックでパラメータを受け取ったら、その値をバックエンドに送る。
    if (window.location.search.length) {
      let urlParams = window.location.search.slice(1).split('&');
      let params = { 'oauth_token':urlParams[0].split('=')[1], 'oauth_verifier':urlParams[1].split('=')[1], }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      axios.post('https://lab.takuman.me/api/oauth/get_twitter_access_token/', params, headers)
      .then(res => {
        console.log('連携完了');
        this.props.logoAnimation('');
        History.push('/oauth'); // コールバックパラメータなしのURLへ遷移
      })
      .catch(err => {
        this.props.helloLab();
      });
    } else {
      this.getMyTwitterOauthTokens();
    }
  }

  oauth = (event) => {
    event.preventDefault();
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.get('https://lab.takuman.me/api/oauth/twitter/', headers)
    .then(res => {
      console.log('認証ページへ移動');
      window.location.href=res.data['redirect_url']; // Oauth認証のアカウント連携ページへリダイレクト
    })
    .catch(err => {
      this.props.helloLab();
    });
  }

  getMyTwitterOauthTokens = () => {
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.get('https://lab.takuman.me/api/oauth/get_my_twitter_oauth_tokens/', headers)
    .then(res => {
      console.log('tokensを取得');
      console.log(res.data);
      var twitterOauthTokens = res.data;
      this.setState({ twitterOauthTokens:twitterOauthTokens, });
      this.props.logoAnimation('');
    })
    .catch(err => {
      this.props.helloLab();
    });
  }

  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let twitterOauthTokens;
    if (0 < this.state.twitterOauthTokens.length) {
      twitterOauthTokens = this.state.twitterOauthTokens.map((token, index) => {
        return (
          <p><Link to={this.props.full ? '/oauth_twitter_account_' + token.twitter_id : this.props.one ? '/oauth_twitter_account_' + token.twitter_id + '/' + window.location.pathname.slice(1).split('/')[1] : '/' + window.location.pathname.slice(1).split('/')[0] + '/oauth_twitter_account_' + token.twitter_id}>@{token.screen_name}</Link></p>
        );
      });
    } else {
      twitterOauthTokens = (
        <p>連携したTwitterアカウントはまだありません。</p>
      );
    }

    let twitterOauthBlock = (
      <Block full={this.props.full} one={this.props.one} two={this.props.two} onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} }>
        <BlockName>TWITTER OAUTH</BlockName>
        {twitterOauthTokens}
        <LinkBtnField>
          <LinkBtn onClick={ (event) => {this.oauth(event)} }>OAUTH</LinkBtn>
        </LinkBtnField>
      </Block>
    );


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Oauth | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/home' : this.props.one ? '/home/' + pathItems[1] : '/' + pathItems[0] + '/home'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        {twitterOauthBlock}
      </div>
    );
  }

}
export default Oauth;
