import styled, { createGlobalStyle } from 'styled-components'
import { rgba, lighten, darken } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn, borderFlash } from 'component/common/style/Animations';

const PostsStyle = styled.div`
  &&& {
  }
`

const Blocks = styled.div`
  &&& {
    width:100%; height:100vh; margin:0; padding:0; scroll-snap-type:y mandatory; scroll-snap-stop:always; overflow-y:auto;
  }
`

const Block = styled.div`
  &&& {
    width:100%; height:100vh; scroll-snap-align:start; transition:0.002s; position:relative;
    .image { width:100%; height:calc(100vh - 40px); object-fit:contain; position:absolute; top:20px; bottom:20px; left:0; right:0; }
    .video { width:100%; height:calc(100vh - 40px); object-fit:contain; position:absolute; top:20px; bottom:20px; left:0; right:0; }
      .play-btn { position:absolute; top:0; bottom:0; left:0; right:0; width:70px; height:70px; margin:auto;
                  border:2px solid ${rgba(subColor,0.6)}; border-radius:10000px; background:${rgba(edgeColor,0.8)}; cursor:pointer;
      .play-btn-triangle { width:100%; height:100%; display:table; text-align:center;
        i { color:${rgba(subColor,0.8)}; font-size:40px; display:table-cell; vertical-align:middle; padding-left:5px; }
      }
    }
  }
`

const BlockContent = styled(Link)`
  &&& {
    width:calc(100% - 10px); height:calc(100% - 10px); margin:5px; padding:10px; background:${subColor}; display:table;
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden;
    .item-name { color:${mainColor}; font-size:18px; text-align:center; line-height:18px; display:table-cell; vertical-align:middle; }
  }
`

export { PostsStyle, Blocks, Block, BlockContent };
