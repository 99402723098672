import axios from 'axios';

export default function MultiFileUploadPostFunc(state) {

  return new Promise((resolve, reject) => {

    var files = state.files.slice(); 
    for(var i=0; i<state.files.length; i++){
      const params = new FormData();
      params.append('hash', '');
      params.append('type', state.type);
      params.append('tag', state.tag);
      params.append('release_range', state.releaseRange);
      params.append('title', state.title);
      params.append('writes', state.writes);
      params.append('file', state.files[i]);
      params.append('file_name', state.files[i].name);
      if (state.type == 'porn') {
        if (state.releaseRange == 'public') { 
          // media.kumatan.me
          params.append('file_a', '');
          params.append('file_b', '');
          params.append('file_c', state.files[i]);
          params.append('file_d', '');
        } else {
          // private-media.kumatan.me
          params.append('file_a', '');
          params.append('file_b', '');
          params.append('file_c', '');
          params.append('file_d', state.files[i]);
        }
      } else { 
        if (state.releaseRange == 'public') {
          // media.takuman.me
          params.append('file_a', state.files[i]);
          params.append('file_b', '');
          params.append('file_c', '');
          params.append('file_d', '');
        } else { 
          // private-media.takuman.me
          params.append('file_a', '');
          params.append('file_b', state.files[i]);
          params.append('file_c', '');
          params.append('file_d', '');
        }
      }
      params.append('user', '');
      var headers = { headers: { 'content-type': 'multipart/form-data', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      axios.post('https://lab.takuman.me/api/post/create/', params, headers)
      .then(res => { 
        if (i+1 >= files.length) {
          resolve(true);
        }
      })
      .catch((err) => { reject() });
    }


  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
