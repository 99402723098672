import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { DistributedLearningFormStyle, DistributedLearningFormField, TypeField, TextField, SelectMenuWrapper, LinkBtnField, LinkBtn } from 'component/common/style/learning/Form';
import { ShakyoField } from 'component/common/style/learning/DistributedLearning';

// function
import GetDistributedLearningTypes from 'component/common/function/GetDistributedLearningTypes';
import SaveDistributedLearning from 'component/common/function/SaveDistributedLearning';
import TextHeightAdjustment from 'component/common/function/TextHeightAdjustment';
import ShakyoMatchCheck from 'component/common/function/ShakyoMatchCheck';
import ClickShakyoShadow from 'component/common/function/ClickShakyoShadow';


class CreateShakyo extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
    handleFlashMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      shakyo:'',
      shakyoTypes:[],
      typeIndex:0,
      typeMenu:false,
      newType:'',
      status:false,
    };
  }

  componentDidMount = () => {
    this.getDistributedLearningTypes();
    document.getElementById('from-text').focus();
  }

  componentWillUpdate = () => { document.body.addEventListener('keydown', this.keydownTab); }
  componentWillUnmount = () => { document.body.removeEventListener('keydown', this.keydownTab); }

  keydownTab = (event) => {
    // #from-textか#shakyo-frontにfocusされていて、Tabキーが入力されたらタブ文字を挿入する
    if ((document.getElementById('from-text') == document.activeElement && event.code == 'Tab') ||
        (document.getElementById('shakyo-front') == document.activeElement && event.code == 'Tab')) {
      event.preventDefault();
      var elem = event.target;
      var val = elem.value;
      var pos = elem.selectionStart;
      elem.value = val.substr(0, pos) + '\t' + val.substr(pos, val.length);
      elem.setSelectionRange(pos + 1, pos + 1);
    }
  }

  getDistributedLearningTypes = () => {
    GetDistributedLearningTypes('shakyo').then((res) => {
      let shakyoTypes = res.shakyoTypes;
      shakyoTypes.push({'name':'new type',});
      this.setState({ shakyoTypes:shakyoTypes, });
    });
  }

  selectType = (index) => {
    this.setState({ typeIndex:index, typeMenu:false, }, () => {
      if (this.state.shakyoTypes[index].name == 'new type') {
        document.getElementById('new-type').focus();
      }
    });
  }

  handleTypeMenu = () => {
    if (this.state.typeMenu) {
      this.setState({ typeMenu:false, });
    } else {
      this.setState({ typeMenu:true, });
    }
  }

  handleCloseTypeMenu = () => {
    this.setState({ typeMenu:false, });
  }

  changeShakyo = (event) => {
    let shakyo = event.target.value;
    this.setState({ shakyo:shakyo, }, () => {
      TextHeightAdjustment('from-text');
      if (document.getElementById('shakyo-front')) {
        document.getElementById('shakyo-front').value = '';
      }
      ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
        let status = res.status;
        this.setState({ status:status, });
      });
    });
  }

  saveDistributedLearning = () => {

    let type;
    if (this.state.shakyoTypes[this.state.typeIndex].name == 'new type') {
      type = this.state.newType;
    } else {
      type = this.state.shakyoTypes[this.state.typeIndex].name;
    }

    if (type != '') {
      let question = '';
      let answer = '';
      let musiqui = '';
      SaveDistributedLearning(type, question, answer, musiqui, this.state.shakyo).then((saved) => {
        if (saved) {
          this.props.handleFlashMessage('CREATED');
          this.setState({ shakyo:'', }, () => {
            TextHeightAdjustment('from-text');
            document.getElementById('translate-style').scrollTop = 0;
          });
        } else {
          this.props.handleFlashMessage('ERROR');
        }
      });
    }
  }

  changeNewType = (event) => {
    let newType = event.target.value;
    this.setState({ newType:newType, });
  }

  clickShakyoShadow = (event) => {
    ClickShakyoShadow(event, 'shakyo-front', 'shakyo-shadow');
  }

  shakyoMatchCheck = () => {
    ShakyoMatchCheck('shakyo-front', 'shakyo-back', 'shakyo-highlight', 'shakyo-shadow').then((res) => {
      let status = res.status;
      this.setState({ status:status, });
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }


    let selectType;
    if (0 < this.state.shakyoTypes.length) {
      let selectTypeMenu = (
        <div className='select-menu'>
          {this.state.shakyoTypes.map( (type,index) => {
            if (this.state.typeIndex != index) {
              return ( <div className='menu-item' onClick={ (event) => {this.selectType(index)} }>{type.name}</div> );
            }
          })}
        </div>
      );
      selectType = (
        <div className='select'>
          <div className='select-item' onClick={ (event) => {this.handleTypeMenu()} }>type: {this.state.shakyoTypes[this.state.typeIndex].name}</div>
          {this.state.typeMenu ? selectTypeMenu : ''}
        </div>
      );
    } else {
      selectType = ( <div className='select'><div className='select-item'></div></div> );
    }

    let newTypeForm;
    if (0 < this.state.shakyoTypes.length) {
      if (this.state.shakyoTypes[this.state.typeIndex].name == 'new type') {
        newTypeForm = ( <input type='text' id='new-type' className='new-type-form' onChange={ (event) => {this.changeNewType(event)} } value={this.state.newType} placeholder='new type' /> );
      } else {
        newTypeForm = ( <span></span> );
      }
    } else {
      newTypeForm = ( <span></span> );
    }


    let saveBtnField;
    if (0 < this.state.shakyoTypes.length) {
      if (this.state.shakyoTypes[this.state.typeIndex].name != 'new type' || (this.state.shakyoTypes[this.state.typeIndex].name == 'new type' && this.state.newType != '')) {
        if (this.state.shakyo != '') {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn submit onClick={ () => {this.saveDistributedLearning()} }>CREATE SHAKYO</LinkBtn>
            </LinkBtnField>
          );
        } else {
          saveBtnField = (
            <LinkBtnField>
              <LinkBtn>CREATE SHAKYO</LinkBtn>
            </LinkBtnField>
          );
        }
      } else {
        saveBtnField = (
          <LinkBtnField>
            <LinkBtn>CREATE SHAKYO</LinkBtn>
          </LinkBtnField>
        );
      }
    } else {
      saveBtnField = (
        <LinkBtnField>
          <LinkBtn>CREATE SHAKYO</LinkBtn>
        </LinkBtnField>
      );
    }

    let shakyo;
    if (0 < this.state.shakyo.length) {
      shakyo = (
        <ShakyoField id='shakyo' status={this.state.status}>
          <textarea type='text' id='shakyo-front' onSelect={ () => {this.shakyoMatchCheck()} } onChange={ () => {this.shakyoMatchCheck()} } onBlur={ () => {this.shakyoMatchCheck()} } />
          <textarea type='text' id='shakyo-back' value={this.state.shakyo} readonly />
          <div id='shakyo-highlight'></div>
          <div id='shakyo-shadow' onClick={ (event) => {this.clickShakyoShadow(event)} }></div>
        </ShakyoField>
      );
    }

    let translateField = (
      <DistributedLearningFormField>
        <TypeField>
          {selectType}
          {newTypeForm}
        </TypeField>
        <TextField>
          <textarea type='text' id='from-text' onChange={ (event) => {this.changeShakyo(event)} } value={this.state.shakyo} placeholder='shakyo' />
        </TextField>
        <TextField>
          {shakyo}
        </TextField>
        {saveBtnField}
      </DistributedLearningFormField>
    );


    let selectMenuWrapper;
    if (this.state.typeMenu) {
      selectMenuWrapper = ( <SelectMenuWrapper onClick={ (event) => {this.handleCloseTypeMenu()} } /> );
    } else {
      selectMenuWrapper = ( <span></span> );
    }



    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={'Create Shakyo | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningFormStyle id='translate-style' onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          {translateField}
          {selectMenuWrapper}
        </DistributedLearningFormStyle>
      </div>
    );
  }

}

export default CreateShakyo;
