import axios from 'axios';

export default function DeletePost(hash) {

  return new Promise((resolve, reject) => {

    var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
    axios.delete('https://lab.takuman.me/api/post/delete/' + hash + '/', headers)
    .then(res => {
      resolve(true);
    })
    .catch((err) => { reject() });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
