import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import History from 'History';
import * as escape from 'escape-html';

import TextComputeEffect from 'component/common/TextComputeEffect'; // テキストをババっと切り替えていくアニメーション

import { Player, ControlBar, PlaybackRateMenuButton } from "video-react"; // 動画再生ライブラリ
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの
import ConfirmPopup from 'component/common/ConfirmPopup'; // 確認ポップアップ

// function
import GetPost from 'component/common/function/GetPost';

// style
import { BackBtnField, MovieField } from 'component/common/style/GlobalStyle';
import { PostStyle, PostBlock, Tag, PdfPreview } from 'component/common/style/Post';


class Post extends React.Component {

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop:0,
      post:[],
      showMovieFile:'',
    }; 
  }

  componentDidMount = () => {
    this.getPost();
  }

  getPost = () => {
    this.props.logoAnimation('kodo');
    let hash;
    if (this.props.two) {
      hash = window.location.pathname.slice(1).split('/')[1].split('_')[2];
    } else {
      hash = window.location.pathname.slice(1).split('/')[0].split('_')[2];
    }
    GetPost(hash).then((post) => {
      this.setState({ post:post, });
      this.props.logoAnimation('');
    });
  }

  // クリックしたサムネイルだけ動画に変更。ブラウザのメモリ消費量を節約するために常に１つだけしか動画プレイヤーを表示させない。
  showMoviePlayer = (file) => {
    // 本来であればPostとPostChildでハッシュが被ってしまう可能性もあるが、かなり稀有な確率なので気にしないで１つのstateで管理する
    this.setState({ showMovieFile:file, });
  }


  render() {


    let post;
    let postChilds;
    if (0 < Object.keys(this.state.post).length) {

      let release_range;
      if (this.state.post.release_range == 'private') {
        release_range = ( <span><i className='fas fa-lock'></i></span> );
      }

      let title;
      if (this.state.post.title != '') {
        title = ( <h1>{this.state.post.title}</h1> );
      }

      let type;
      if (this.state.post.type != '') {
        type = ( <p>{this.state.post.type}</p> );
      }

      let tags;
      if (this.state.post.tag != '') {
        tags = this.state.post.tag.split(', ').map((tag,i) => {
          return ( <Tag>{tag}</Tag> );
        });
      }

      let writes;
      if (this.state.post.writes != '') {
        writes = ( <p>{this.state.post.writes}</p> );
      }

      let file;
      if (this.state.post.file != '') {
        let splitList = String(this.state.post.file).split('/');
        let fileName = splitList[splitList.length - 1];
        let fileNameSplitList = String(fileName).split('.');
        let extension = fileNameSplitList[fileNameSplitList.length - 1];
        extension = extension.toLowerCase();
        if (extension == 'pdf') {
          //file = ( <PdfPreview src={this.state.post.file + '#view=FitH'} /> );
          file = ( <PdfPreview src={this.state.post.file + '#view=FitH'} type='application/pdf' /> );
          //file = ( <h1>{this.state.post.file}</h1> );
        } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
            extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
            extension == 'ico' || extension == 'icns') {
          file = ( <img src={this.state.post.file} /> );
        } else {
          if (this.state.showMovieFile == this.state.post.file) {
            file = ( <ReactPlayer url={this.state.post.file} className='movie' width='100%' height='auto' controls playing loop /> );
          } else {
            let thumb_url = String(this.state.post.file).split('.').slice(0,-1).join('.') + '_thumb.jpg';
            file = ( 
              <MovieField>
                <img src={thumb_url} onClick={ () => {this.showMoviePlayer(this.state.post.file)}} />
                <div className='play-btn' onClick={ () => {this.showMoviePlayer(this.state.post.file)}}></div>
                <div className='play-btn-triangle play-btn-triangle1' onClick={ () => {this.showMoviePlayer(this.state.post.file)}}></div>
                <div className='play-btn-triangle play-btn-triangle2' onClick={ () => {this.showMoviePlayer(this.state.post.file)}}></div>
                <div className='play-btn-triangle play-btn-triangle3' onClick={ () => {this.showMoviePlayer(this.state.post.file)}}></div>
              </MovieField>
            );
          }
        }
      }


      post = (
        <div>
          {title}
          {release_range}
          {tags}
          {writes}
          {file}
        </div>
      );


      if (0 < this.state.post.postChilds.length) {
        postChilds = this.state.post.postChilds.map((postChild) => {

          let title;
          if (postChild.title != '') {
            if (postChild.title_size == 'h1') {
              title = ( <h1>{postChild.title}</h1> );
            } else if (postChild.title_size == 'h2') {
              title = ( <h2>{postChild.title}</h2> );
            } else if (postChild.title_size == 'h3') {
              title = ( <h3>{postChild.title}</h3> );
            } else if (postChild.title_size == 'h4') {
              title = ( <h4>{postChild.title}</h4> );
            }
          }

          let code;
          if (postChild.code != '') {
            if (postChild.code_show) {
              if (postChild.code_html_esc) {
                code = ( <p dangerouslySetInnerHTML={{__html : postChild.code}}></p> );
              } else {
                code = ( <p className='code'>{postChild.code}</p> );
              }
            }
          }

          let writes;
          if (postChild.writes != '') {
            writes = ( <p>{postChild.writes}</p> );
          }


          let file;
          if (postChild.file != '') {
            let splitList = String(postChild.file).split('/');
            let fileName = splitList[splitList.length -1];
            let fileNameSplitList = String(fileName).split('.');
            let extension = fileNameSplitList[fileNameSplitList.length - 1];
            extension = extension.toLowerCase(); // 下記の拡張子チェックを正確に行うために拡張子を小文字に変換。
            if (extension == 'pdf') {
              file = ( <PdfPreview src={postChild.file + '#view=FitH'} type='application/pdf' /> );
            } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' || extension == 'svg' || extension == 'svgz' ||
                extension == 'tif' || extension == 'tiff' || extension == 'eps' || extension == 'pict' || extension == 'bmp' || extension == 'dib' ||
                extension == 'ico' || extension == 'icns') {
              file = ( <img src={postChild.file} /> );
            } else {
              if (this.state.showMovieFile == postChild.file) {
                file = ( <ReactPlayer url={postChild.file} className='movie' width='100%' height='auto' controls playing loop /> );
              } else {
                let thumb_url = String(postChild.file).split('.').slice(0,-1).join('.') + '_thumb.jpg';
                file = ( 
                  <MovieField>
                    <img src={thumb_url} onClick={ () => {this.showMoviePlayer(postChild.file)}} />
                    <div className='play-btn' onClick={ () => {this.showMoviePlayer(postChild.file)}}></div>
                    <div className='play-btn-triangle play-btn-triangle1' onClick={ () => {this.showMoviePlayer(postChild.file)}}></div>
                    <div className='play-btn-triangle play-btn-triangle2' onClick={ () => {this.showMoviePlayer(postChild.file)}}></div>
                    <div className='play-btn-triangle play-btn-triangle3' onClick={ () => {this.showMoviePlayer(postChild.file)}}></div>
                  </MovieField>
                );
              }
            }
          }

          let url;
          if (postChild.url != '') {
            url = ( <a href={postChild.url} target='_blank'>{postChild.url_name != '' ? postChild.url_name : postChild.url}</a> );
          }

          return (
            <div>
              {title}
              {code}
              {writes}
              {file}
              {url}
            </div>
          );
        });
      }

    } else {
      post = ( <p>loading...</p> );
    }


    let pathItems = window.location.pathname.slice(1).split('/');
    return (
      <div>
        <Helmet title={this.state.post.title ? this.state.post.title + ' | TakumaN Lab' : this.state.post.hash + ' | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] : this.props.one ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1]}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
          <div className='btn right'>
            <Link to={this.props.full ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '_edit_' + pathItems[0].split('_')[2] : this.props.one ? '/' + pathItems[0].split('_')[0] + '_' + pathItems[0].split('_')[1] + '_edit_' + pathItems[0].split('_')[2] + '/' + pathItems[1] : '/' + pathItems[0] + '/' + pathItems[1].split('_')[0] + '_' + pathItems[1].split('_')[1] + '_edit_' + pathItems[1].split('_')[2]}><i className='far fa-edit'></i></Link>
          </div>
        </BackBtnField>

        <PostStyle onScroll={ (event) => {this.setState({ scrollTop:event.target.scrollTop, })} } full={this.props.full} one={this.props.one} two={this.props.two}>
          <PostBlock full={this.props.full} one={this.props.one} two={this.props.two}>
            {post}
            {postChilds}
          </PostBlock>
        </PostStyle>
      </div>
    );

  }
}
export default Post;
