import axios from 'axios';

export default function ClickShakyoShadow(event, sf, ss) {

  return new Promise((resolve, reject) => {

    // この関数内のスクリプトを実行するとスクロール位置がずれるので、最初に現在のスクロール量を取得しておく
    let scrollTop = document.getElementById('shakyo-style').scrollTop;

    let shakyoFront = document.getElementById(sf);
    let shakyoShadow = document.getElementById(ss);

    // クリックした要素から何行目の要素かを取得して、その行のshakyoFrontにcaretを移動
    let clickElementIndex = 0;
    let clickLineNode = event.target;
    if (event.target.classList.contains('line-num') || event.target.classList.contains('text')) {
      // lineクラスのdomの子要素がevent.targetにある場合は親要素を取得する
      clickLineNode = event.target.parentNode;
    }
    for (var i=0; i<shakyoShadow.children.length; i++) {
      if (shakyoShadow.children[i] == clickLineNode) {
        clickElementIndex = i;
        break;
      }
    }

    // クリックしたshakyoShadowの位置から入力できるようにcaretを移動させる。
    // ただし、shakyoFrontの値がクリックした要素の行よりも少ない行数しかないのであれば、
    // 足りない行数文の改行コードを追加した文字列をshakyoFrontにいれてから、
    // focusし、setSelectionRangeで移動する。
    let shakyoFrontNary = shakyoFront.value.split('\n');
    if (clickElementIndex+1 <= shakyoFrontNary.length) {
      // クリックした行数はすでにshakyoFrontにも存在する行数なので、そのままその行数の最後尾にcaretを移動させる。
      let topTextLength = shakyoFrontNary.slice(0,clickElementIndex+1).join('\n').length;
      shakyoFront.focus();
      shakyoFront.setSelectionRange(topTextLength, topTextLength);
    } else {
      // クリックした行数はまだshakyoFrontには存在しないので、クリックした行までの数だけ改行コードを挿入して行数を延ばす
      let addLineNum = (clickElementIndex+1) - shakyoFrontNary.length;
      let text = shakyoFront.value + '\n'.repeat(addLineNum);
      shakyoFront.value = text;
      shakyoFront.focus();
      shakyoFront.setSelectionRange(text.length, text.length);
    }

    // この関数の最初に取得しておいたスクロール量にscrollTopを戻して、同じスクロール位置から入力を始められるようにする。
    document.getElementById('shakyo-style').scrollTop = scrollTop;

    resolve({});


  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
