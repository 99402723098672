import React from 'react';
import axios from 'axios';
import History from 'History';
import { Helmet } from 'react-helmet'; // ページタイトルを動的に変更
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの 
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { BackBtnField, ClearBoth } from 'component/common/style/GlobalStyle';
import { LearningsSearchBoxStyle, Type, SearchBox, SearchFilter, Searching, LearningsCount } from 'component/common/style/learning/SearchBox';
import { DistributedLearningIndexStyle, Blocks, Block, BlockContent } from 'component/common/style/learning/Index';

// function
import LearningsSearch from 'component/common/function/LearningsSearch';


class QaIndex extends React.Component{

  static propTypes = {
    helloLab: PropTypes.func,
    logoAnimation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      type:'',
      searchWords:'',
      learnings:[],
      searching:false,
    };
  }

  componentDidMount = () => {
    let type = '';
    let pathItems = window.location.pathname.slice(1).split('/');
    if (this.props.two) {
      type = pathItems[1].replace('distributed_learning_qa_index_','');
    } else {
      type = pathItems[0].replace('distributed_learning_qa_index_','');
    }
    this.setState({ type:type, });
    this.getLearnings(type);
  }

  getLearnings = (type) => {
    LearningsSearch(type, this.state.searchWords).then((learnings) => {
      this.setState({ learnings:learnings, });
    });
  }

  searchWordsChange = (event) => {
    this.setState({ searchWords:event.target.value, });
  }

  search = (event) => {
    document.activeElement.blur();
    event.preventDefault();
    this.setState({ searching:true, });
    LearningsSearch(this.state.type, this.state.searchWords).then((learnings) => {
      this.setState({ learnings:learnings, searching:false, });
    });
  }


  render() {
    if (this.props.loggedIn==false) { return <Redirect to='/login' />; }

    let learningsSearch = (
      <LearningsSearchBoxStyle>
        <Type>{this.state.type}</Type>
        <SearchBox onSubmit={ (event) => {this.search(event)} }>
          <input type='text' name='search' value={this.state.searchWords} onChange={ (event) => {this.searchWordsChange(event)} } />
          <input type='submit' value='' className='search-btn' onClick={ (event) => {this.search(event)} } />
        </SearchBox>
      </LearningsSearchBoxStyle>
    );

    let pathItems = window.location.pathname.slice(1).split('/');
    let learnings;
    if (this.state.learnings.length != 0) {
      learnings = this.state.learnings.map((learning) => {
        let title = '';
        if (learning.question != '') {
          title = learning.question.split(/\n/)[0];
        }
        let time = (learning.times) ? learning.times.split(",")[learning.times.split(",").length-1] : "?";

        return (
          <Block full={this.props.full} one={this.props.one} two={this.props.two}>
            <BlockContent to={this.props.full ? '/distributed_learning_qa_' + learning.hash : this.props.one ? '/distributed_learning_qa_' + learning.hash + '/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learning_qa_' + learning.hash}>
              <div className='item-name'><h2>{title}</h2></div>
              <div className={'span ' + (learning.span < 1 ? 'level-1' : learning.span < 2 ? 'level-2' : learning.span < 4 ? 'level-3' : learning.span < 8 ? 'level-4' : learning.span < 16 ? 'level-5' : learning.span < 32 ? 'level-6' : learning.span < 64 ? 'level-7' : learning.span < 128 ? 'level-8' : 'level-max')}>{learning.span}</div>
              <div className='read-time'>{time}min</div>
            </BlockContent>
          </Block> 
        );
      });
    } else {
      learnings = ( <span></span> );
    }


    return (
      <div>
        <Helmet title={'Q&A INDEX | TakumaN Lab'} />
        <BackBtnField scrollTop={this.state.scrollTop} full={this.props.full} one={this.props.one} two={this.props.two}>
          <Link className='back-btn left' to={this.props.full ? '/distributed_learnings' : this.props.one ? '/distributed_learnings/' + pathItems[1] : '/' + pathItems[0] + '/distributed_learnings'}>
            <div className='line-one'/><div className='line-two'/>
          </Link>
        </BackBtnField>
        <DistributedLearningIndexStyle>
          {learningsSearch}
          <Blocks full={this.props.full} one={this.props.one} two={this.props.two}>{learnings}</Blocks>
        </DistributedLearningIndexStyle>
      </div>
    );
  }

}
export default QaIndex;
