import React, { Component } from 'react';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import {TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

export default class TextComputeEffect extends React.Component {

  // propのタイプを定義
  static propTypes = {
    setStateFunc: PropTypes.func, // 確認ポップアップを非表示
  };

  componentDidMount() {
      var brandNameBeforeText = this.props.text; // エフェクトをかける文字列
      var brandNameAfterText = brandNameBeforeText.split(''); // エフェクトをかける文字列を１文字ずつ分割
      var dummyBeforeText = 'hapiness'; // サブリミナルエフェクト
      var dummyAfterText = dummyBeforeText.split(''); // 文字列を１文字ずつ分割
      var brandName = ''; // 入れ替えが完了した文字を随時格納していくための変数

      //console.log('brandNameAfterText: ' + brandNameAfterText);
      //console.log('dummyAfterText: ' + dummyBeforeText);
      //console.log('brandName: ' + brandName);

      this.props.setStateFunc(this.props.stateName, '');

      setTimeout (() => { // エフェクト処理を実行するまでの間隔
      for (var l = 0; l < brandNameAfterText.length; l++) {
        ((l) => {
          for (var i = 0; i < dummyAfterText.length; i++) {
            var speed = 1; // 文字を切り替えるスピード
            var interval = (speed*i) + (dummyAfterText.length*speed*l);
            //console.log('interval(l=' + l + ')(i=' + i + '): ' + interval);
            ((i) => {
              setTimeout (() => {
                this.props.setStateFunc(this.props.stateName, brandName + dummyAfterText[i]);
                if (i+1 == dummyAfterText.length) { // iは0から始まるので+1する
                  brandName = brandName + brandNameAfterText[l];
                  //console.log('brandName: ' + brandName);
                  this.props.setStateFunc(this.props.stateName, brandName);
                }
              }, interval);
            })(i);
          }
        })(l);
      }
      }, this.props.delay);


      // interval変数の計算式を理解する(思い出す)ためのもの
      //var dummy = ['a', 'b', 'c'];
      //speed = 1000; // 文字を切り返すスピード

      // 1回目の処理 >>>>>>>>>> l = 0
      //a => 1000ms = (1000 * 0) + (dummy.length * 1000 * 0)
      //b => 2000ms = (1000 * 1) + (dummy.length * 1000 * 0)
      //c => 3000ms = (1000 * 2) + (dummy.length * 1000 * 0)

      // 2回目の処理 >>>>>>>>>> l = 1
      //a => 4000ms = (1000 * 0) + (dummy.length * 1000 * 1)
      //b => 5000ms = (1000 * 1) + (dummy.length * 1000 * 1)
      //c => 6000ms = (1000 * 2) + (dummy.length * 1000 * 1)

      // 3回目の処理 >>>>>>>>>> l = 2
      //a => 7000ms = (1000 * 0) + (dummy.length * 1000 * 2)
      //b => 8000ms = (1000 * 1) + (dummy.length * 1000 * 2)
      //c => 9000ms = (1000 * 2) + (dummy.length * 1000 * 2)

      //A.  (speed*i) + (dummy.length*speed*l)

  }

  render() { return( <span></span> ); }

}


