import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, correctColor } from 'component/common/style/Colors';


const SearchBlock = styled.div`
  &&& {
    width:98%; height:auto; margin:0 auto 20px auto; padding:20px 0px; opacity:0.95; background:${subColor}; overflow:hidden; clear:both;
    p { color:${mainColor}; font-size:15px; padding:10px 20px; }

    @media screen and (min-width:750px) { width:${(props) => props.full ? '80%' : '98%'}; }
    @media screen and (min-width:960px) { width:${(props) => props.full ? '60%' : '98%'}; }
  }
`

const RangeField = styled.div`
  &&& {
    width:calc(50% - 10px); height:auto; margin:0 auto; padding:10px auto;
    input { width:100%; padding:10px; color:${mainColor}; text-align:center; border:none; border-bottom:1px solid ${rgba(mainColor,0.2)}; background:${subColor}; }
    input:-webkit-autofill { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
    input::placeholder { color:${rgba(mainColor,0.4)}; }
    input:focus { border-bottom:3px solid ${mainColor}; outline:0; }
    .range-input { width:calc(50% - 25px); text-align:center; float:left; }
    .range-space-icon { width:50px; margin:0; padding:8px 0; color:${mainColor}; text-align:center; display:inline-block; float:left; }
  }
`

const SearchField = styled.div`
  &&& {
    width:100%; height:auto; margin:0 auto; padding:10px 0; clear:both;
    .searchbox { width:90%; height:35px; margin:0 auto; padding:0; border-radius:10000px;
      input[type='text'] { width:90%; height:35px; margin:0; padding:0 10px; font-size:18px; color:${mainColor}; background:${subColor};
                           border:0.5px solid ${rgba(mainColor,0.6)}; border-radius:10000px 0 0 10000px; display:inline-block; float:left; }
      input[type='text']:focus { outline:0; border:1px solid ${rgba(mainColor,1)}; }
      input[type='text']:focus + .search-btn { opacity:1; }
      .search-btn { width:10%; height:35px; margin:0; padding:0; border:none; color:${subColor}; background:${mainColor};
                    border-radius:0 10000px 10000px 0; display:inline-block; cursor:pointer; float:right; opacity:0.6; }
      .search-btn:hover { opacity:1; }
      .search-btn:focus { outline:0; }
    }
  }
`

const PostsBlock = styled.div`
  &&& {
    width:98%; height:auto; margin:0 auto 20px auto; padding:20px 0; opacity:0.95;
    background:${ props => props.on ? mainColor : subColor};
    border-radius:5px; box-shadow:0 0 6px ${shadowColor}; overflow:hidden; clear:both;
    p { color:${ props => props.on ? subColor : mainColor}; font-size:15px; padding:10px 20px; }

    @media screen and (min-width:750px) {
      width:${(props) => props.full ? '80%' : '98%'};
    }
    @media screen and (min-width:960px) {
      width:${(props) => props.full ? '60%' : '98%'};
    }
  }
`


export { SearchBlock, RangeField, SearchField, PostsBlock };
