import axios from 'axios';

export default function CreatePost(state) {

  return new Promise((resolve, reject) => {
    // fileがあるかないかでparamsとheadersを仕分け
    if (state.file.name != undefined) {
      const params = new FormData();
      params.append('hash', '');
      params.append('type', state.type);
      params.append('tag', state.tag);
      params.append('release_range', state.releaseRange);
      params.append('title', state.title);
      params.append('writes', state.writes);
      params.append('file', state.file);
      params.append('user', '');
      var headers = { headers: { 'content-type': 'multipart/form-data', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      resolve({'params':params, 'headers':headers, 'state':state});
    } else {
      var params = { hash:'', type:state.type, tag:state.tag, release_range:state.releaseRange, title:state.title, writes:state.writes, file:'', user:'' }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
      resolve({'params':params, 'headers':headers, 'state':state});
    }

  }).then((p) => {
    // Postの作成

    return new Promise( (resolve, reject) => {

      axios.post('https://lab.takuman.me/api/post/create/', p.params, p.headers)
      .then(res => {
        if (res.data.hash) {
          resolve({'post_id':res.data.id, 'state':p.state});
        }
      })
      .catch((err) => { reject() });
    });

  }).then((p) => {
    // postChildsをfor文で回してPostChildを作成
    let state = p.state;

    return new Promise( (resolve, reject) => {

      if (state.postChilds.length > 0) {
        for(var i=0; i<state.postChilds.length; i++){
          if (state.postChilds[i].file) {
            // fileあり
            const paramsChild = new FormData();
            paramsChild.append('hash', '');
            paramsChild.append('order', state.postChilds[i].order);
            paramsChild.append('type', state.type);
            paramsChild.append('release_range', state.releaseRange);
            paramsChild.append('title', state.postChilds[i].title);
            paramsChild.append('title_size', state.postChilds[i].title_size);
            paramsChild.append('code', state.postChilds[i].code);
            paramsChild.append('code_html_esc', state.postChilds[i].code_html_esc);
            paramsChild.append('code_show', state.postChilds[i].code_show);
            paramsChild.append('writes', state.postChilds[i].writes);
            paramsChild.append('file', state.postChilds[i].file);
            paramsChild.append('file_url', state.postChilds[i].file_url);
            paramsChild.append('url_name', state.postChilds[i].url_name);
            paramsChild.append('url', state.postChilds[i].url);
            paramsChild.append('post', p.post_id);
            paramsChild.append('user', state.user);
            var headersChild = { headers: { 'content-type': 'multipart/form-data', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
            axios.post('https://lab.takuman.me/api/post_child/create/', paramsChild, headersChild)
            .then(res => {
              if (res.data.hash && i+1 >= state.postChilds.length) {
                resolve(true);
              }
            })
            .catch((err) => {reject()});

          } else {
            // fileなしの場合
            var paramsChild = { hash:'', order:state.postChilds[i].order, type:state.type, release_range:state.releaseRange, title:state.postChilds[i].title, title_size:state.postChilds[i].title_size, code:state.postChilds[i].code, code_html_esc:state.postChilds[i].code_html_esc, code_show:state.postChilds[i].code_show, writes:state.postChilds[i].writes, file:'', file_url:state.postChilds[i].file_url, url_name:state.postChilds[i].url_name, url:state.postChilds[i].url, post:p.post_id, user:'' }
            var headersChild = { headers: { 'content-type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('token')}` } }
            axios.post('https://lab.takuman.me/api/post_child/create/', paramsChild, headersChild)
            .then(res => {
              if (res.data.hash && i+1 >= state.postChilds.length) {
                resolve(true);
              }
            })
            .catch((err) => {reject()});
          }
        }
      } else {
        // postChildsがない場合
        resolve(true);
      }

    });

  }).catch(() => {
    return new Promise( (resolve, reject) => {
      resolve(false);
    });
  });

}
